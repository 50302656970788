import { DollarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, Typography } from 'antd';
import { useAppDispatch } from 'hooks/useRedux';
import { useEffect, useState } from 'react';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useGetBillingDataQuery } from 'services/billingService';
import { useGetTaxForMultipleEstimatesQuery } from 'services/estimateService';
import { setPaymentModalOpen } from 'store/slices/visitHeaderSlice';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { formatCurrency } from 'utils/formatFuncs';
import { PaymentType } from 'utils/types/paymentTypes';
import { DirectPaymentModal } from './DirectPaymentModal';
import { TerminalPaymentModal } from './TerminalPaymentModal';
import { WebCheckoutModal } from './WebCheckoutModal';
import './payment_select.css';

interface PaymentSelectProps {
    visitId: number;
    customerId: string | null;
    approvedEstimateTotalCents: number;
    invoiceTotal: number;
    notInApprovedTotalCents: number;
    paidCents: number;
}

export const PaymentSelect = ({
    visitId,
    customerId,
    approvedEstimateTotalCents,
    invoiceTotal,
    paidCents,
    notInApprovedTotalCents,
}: PaymentSelectProps) => {
    const dispatch = useAppDispatch();
    const [paymentType, setPaymentType] = useState<PaymentType>();

    const [isVisibleNoPaymentModal, setIsVisibleNoPaymentModal] = useState(false);
    const [isVisibleWebCheckoutModal, setIsVisibleWebCheckoutModal] = useState(false);
    const [isVisibleDirectPaymentModal, setIsVisibleDirectPaymentModal] = useState(false);
    const [isVisibleTerminalPaymentModal, setIsVisibleTerminalPaymentModal] = useState(false);

    useEffect(() => {
        const isPaymentModalOpen =
            isVisibleNoPaymentModal || isVisibleWebCheckoutModal || isVisibleDirectPaymentModal || isVisibleTerminalPaymentModal;
        dispatch(setPaymentModalOpen(isPaymentModalOpen));
    }, [isVisibleNoPaymentModal, isVisibleWebCheckoutModal, isVisibleDirectPaymentModal, isVisibleTerminalPaymentModal]);

    const { Option } = Select;
    const [form] = Form.useForm();

    const { data: customerData } = useGetCustomerByIdQuery(customerId as string, { ...BASE_QUERY_OPTIONS, skip: !customerId });
    const { isFetching: isFetchingEstimateTaxes, refetch: refetchTaxes } = useGetTaxForMultipleEstimatesQuery(visitId, { pollingInterval: 120000 });
    const { isFetching: isFetchingBillingData, refetch: refetchBillingData } = useGetBillingDataQuery({visitId}, { ...BASE_QUERY_OPTIONS });

    const changeAmountInForm = (newAmountCents: number) => {
        form.setFieldsValue({
            amount: formatCurrency(newAmountCents).replace('$', ''),
        });
    };
    
    const estimateBalanceDue = approvedEstimateTotalCents - paidCents;
    const invoiceBalanceDue = invoiceTotal - paidCents;
    const maxAmountCents = estimateBalanceDue + notInApprovedTotalCents;

    const paymentInfoProps = {
        estimateBalanceDue: estimateBalanceDue,
        invoiceBalanceDue: invoiceBalanceDue,
        paidCents: paidCents,
        approvedEstimateTotalCents: approvedEstimateTotalCents,
        invoiceTotal: invoiceTotal,
        changeAmountInForm: changeAmountInForm,
    };

    return (
        <>
            <Select
                className='payment-button'
                data-cy='paymentButton'
                onChange={(value: string, option: any) => {
                    refetchBillingData();
                    refetchTaxes();
                    if (maxAmountCents <= 0) {
                        setIsVisibleNoPaymentModal(true);
                        return;
                    }
                    setPaymentType(option.value);
                    form.resetFields();
                    if (value === 'stripe_terminal') {
                        setIsVisibleTerminalPaymentModal(true);
                    } else if (value === 'stripe_link') {
                        setIsVisibleWebCheckoutModal(true);
                    } else {
                        setIsVisibleDirectPaymentModal(true);
                    }
                }}
                value='Apply Payment'
                dropdownMatchSelectWidth={false}
                getPopupContainer={(triggerNode) => triggerNode}
                suffixIcon={<DollarOutlined style={{ fontSize: '14px' }} />}
            >
                <Option data-cy='stripeTerminalOption' value='stripe_terminal'>
                    Send to Credit Card Terminal
                </Option>
                <Option data-cy='stripeLinkOption' value='stripe_link'>
                    Send Pay Link
                </Option>
                <Option data-cy='cashPaymentOption' value='cash'>
                    Cash Payment
                </Option>
                <Option data-cy='scratchPayOption' value='scratch_pay'>
                    ScratchPay
                </Option>
                <Option data-cy='careCreditOption' value='care_credit'>
                    CareCredit
                </Option>
                <Option data-cy='vegCaresOption' value='veg_cares'>
                    VEG Cares
                </Option>
                <Option data-cy='checkOption' value='check'>
                    Check
                </Option>
                <Option data-cy='allegroOption' value='allegro'>
                    Allegro
                </Option>
            </Select>

            <WebCheckoutModal
                visitId={visitId}
                customerData={customerData}
                isVisible={isVisibleWebCheckoutModal}
                isFetching={isFetchingBillingData || isFetchingEstimateTaxes}
                setIsVisible={setIsVisibleWebCheckoutModal}
                paymentInfoProps={paymentInfoProps}
                estimateBalanceDue={estimateBalanceDue}
                invoiceBalanceDue={invoiceBalanceDue}
                form={form}
            />
            <DirectPaymentModal
                visitId={visitId}
                paymentType={paymentType}
                isVisible={isVisibleDirectPaymentModal}
                isFetching={isFetchingBillingData || isFetchingEstimateTaxes}
                setIsVisible={setIsVisibleDirectPaymentModal}
                paymentInfoProps={paymentInfoProps}
                estimateBalanceDue={estimateBalanceDue}
                invoiceBalanceDue={invoiceBalanceDue}
                form={form}
            />
            <TerminalPaymentModal
                visitId={visitId}
                isVisible={isVisibleTerminalPaymentModal}
                isFetching={isFetchingBillingData || isFetchingEstimateTaxes}
                setIsVisible={setIsVisibleTerminalPaymentModal}
                paymentInfoProps={paymentInfoProps}
                estimateBalanceDue={estimateBalanceDue}
                invoiceBalanceDue={invoiceBalanceDue}
                form={form}
            />
            <Modal
                width='450px'
                visible={isVisibleNoPaymentModal}
                maskClosable={true}
                onOk={() => {
                    setIsVisibleNoPaymentModal(false);
                }}
                onCancel={() => {
                    setIsVisibleNoPaymentModal(false);
                }}
                destroyOnClose={true}
                footer={
                    <>
                        <Button autoFocus type='primary' onClick={() => setIsVisibleNoPaymentModal(false)}>
                            OK
                        </Button>
                    </>
                }
                centered={true}
            >
                <Row>
                    <Col span={1}>
                        <ExclamationCircleOutlined
                            style={{
                                fontSize: '32px',
                                color: '#FAAD14',
                            }}
                        />
                    </Col>
                    <Col offset={2} span={20}>
                        <Typography.Title level={4}>No Payment Required</Typography.Title>
                        <Typography.Paragraph style={{ marginBottom: 0 }}>
                            The estimate must be approved prior to accepting a pre-payment.
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
