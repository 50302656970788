import { Checkbox } from 'antd';
import { Button } from 'components/lib';
import { ReactNode, useState } from 'react';
import { ConsentFormHeader } from 'utils/types/consentFormTypes';
import { Header } from './Header';
import './css/PrintableForm.css';

interface PrintableFormProps {
    title: string;
    children: ReactNode;
    headerData: ConsentFormHeader;
    onApprove: Function;
    approved: boolean;
    approveText?: string;
    acceptButtonText?: string;
    printMessage?: string;
    showOptInMarketingEmail?: boolean;
}

const PrintableForm: React.FC<PrintableFormProps> = ({
    title,
    headerData,
    children,
    approved,
    onApprove,
    approveText,
    acceptButtonText,
    printMessage,
    showOptInMarketingEmail,
}) => {
    const [checked, setChecked] = useState(false);
    const [checkedOptInMarketingEmail, setCheckedOptInMarketingEmail] = useState(showOptInMarketingEmail ? true : null);

    return (
        <div className='container'>
            <div className='document' id='printableArea'>
                <Header title={title} headerData={headerData} />

                {children}
            </div>
            <div className='bottom-section approval-container'>
                <div style={{ fontWeight: 'bold' }}>
                    {printMessage ??
                        'For a copy of this plan, please print before consenting to treatment.'}
                </div>
                <div>
                    <div>
                        <Checkbox
                            checked={checked}
                            onChange={() => setChecked((isChecked) => !isChecked)}
                            style={{ marginBottom: '16px' }}
                            data-cy={'treatmentPlanEstimateApprovalCheckbox'}
                        >
                            {approveText ??
                                'I acknowledge that I have read through this document and understand its contents'}
                        </Checkbox>
                    </div>
                    {showOptInMarketingEmail &&
                        <div>
                            <Checkbox
                                checked={checkedOptInMarketingEmail ?? true}
                                onChange={() => setCheckedOptInMarketingEmail((isChecked) => !isChecked)}
                                style={{ marginBottom: '16px' }}
                            >
                                Please keep me updated on VEG and help keep my pet safe from emergencies.
                            </Checkbox>
                        </div>
                    }
                    <div>
                        <Button
                            ghost
                            type='primary'
                            className='print-button'
                            onClick={() => window.print()}
                        >
                            Print
                        </Button>
                        <Button
                            type='primary'
                            disabled={!checked || approved}
                            className='accept-button'
                            data-cy={'treatmentPlanEstimateApprovalButton'}
                            onClick={() => onApprove(checkedOptInMarketingEmail)}
                        >
                            {acceptButtonText ?? 'Accept'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrintableForm;
