import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, FormInstance, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';

interface DatePickerWithHoursProps {
    form: FormInstance;
    newTime: moment.Moment | null;
    setNewTime: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
    fieldName: string;
}
export const DatePickerWithHours = ({ form, newTime, setNewTime, fieldName }: DatePickerWithHoursProps) => {
    const [isMinusOneHourButtonDisabled, setIsMinusOneHourButtonDisabled] = useState(true);

    const plusMinusHours = (type: 'plus' | 'minus', previousTime: moment.Moment) => {
        const timeToAdd = type === 'plus' ? 1 : -1;
        const newTime = moment(previousTime).add(timeToAdd, 'hour');

        setNewTimeField(newTime);
    };

    const dateIsInThePast = (currentDate: moment.Moment) => {
        return currentDate.isBefore(moment());
    };

    const setNewTimeField = (date: moment.Moment | null) => {
        const dateMinusOneHour = moment(date).subtract(1, 'hour');
        setIsMinusOneHourButtonDisabled(dateIsInThePast(dateMinusOneHour));

        setNewTime(date);
        form.setFieldsValue({ newTime: date });
        form.validateFields([fieldName]);
    };
    return (
        <>
            <DatePicker
                autoFocus
                value={newTime}
                onChange={setNewTimeField}
                format={'MM/DD/YYYY hh:mm a'}
                suffixIcon={<ClockCircleOutlined />}
                disabledDate={dateIsInThePast}
                showTime
            />

            <span className='reschedule-action-form__new-time-funcs'>
                <Tooltip title={isMinusOneHourButtonDisabled ? 'New time cannot be in the past' : null}>
                    <Button onClick={() => plusMinusHours('minus', newTime || moment())} disabled={isMinusOneHourButtonDisabled || !newTime}>
                        - 1hr
                    </Button>
                </Tooltip>
                <Button onClick={() => plusMinusHours('plus',  newTime || moment())} disabled={!newTime}>
                    + 1hr
                </Button>
            </span>
        </>
    );
};
