import { Popover } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetMyMacrosQuery } from 'services/macroService';
import { Macro } from 'utils/dataTypes';
import { Keys } from 'utils/types/enums';
import NoteMacros from './NoteMacros';
import './note-editor.css';

interface NoteEditorProps {
	handleChange: (values: any) => void;
	name: string;
	defaultContent?: string;
	allowMacros?: boolean;
	rows?: number;
}

const CLEAR_MACRO_LIST: string[] = [Keys.ENTER, Keys.SPACE, Keys.ARROW_RIGHT, Keys.ARROW_LEFT, Keys.META];

const NoteEditor: React.FC<NoteEditorProps> = ({
	handleChange,
	name,
	defaultContent,
	// TODO make macros conditional
	allowMacros = true,
	rows = 8
}) => {
    let textareaRef = useRef<HTMLTextAreaElement>(null);
    let { data: macros, isSuccess: macroFetchSuccess } = useGetMyMacrosQuery();
    let [localValue, setLocalValue] = useState(defaultContent ?? '');
    let [macroStartIndex, setMacroStartIndex] = useState(-1);
    let [macroEndIndex, setMacroEndIndex] = useState(-1);
    let [highlightedMacroIndex, setHighlightedMacroIndex] = useState(-1);
    let [hilightedMacro, setHilightedMacro] = useState<Macro | null>(null);

    useEffect(() => {
        if (!!textareaRef.current) {
            focusAndScrollToEndOfTextArea();
        }
    }, [textareaRef.current]);

    const focusAndScrollToEndOfTextArea = () => {
        if (textareaRef.current !== null) {
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length);
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    };

    const clearMacro = () => {
        setMacroStartIndex(-1);
        setMacroEndIndex(-1);
        setHighlightedMacroIndex(-1);
        setHilightedMacro(null);
    };

    // Updates state handler for note
    useEffect(() => {
        handleChange({ [name]: localValue });
    }, [localValue]);

    // Gets the search query for macros
    let macroSearchQuery = useMemo<string | null>(() => {
        if (macroEndIndex > -1) {
            return textareaRef.current?.value.substring(macroStartIndex, macroEndIndex).replace('.', '') ?? null;
        } else return null;
    }, [macroStartIndex, macroEndIndex]);

    // Filters macros by search query
    let filteredMacros = useMemo(() => {
        if (macroFetchSuccess && macroSearchQuery !== null && macroSearchQuery.length) {
            let loweredMacro = String(macroSearchQuery).toLowerCase();

            // Return all macros
            if (loweredMacro === '*') {
                return macros ?? [];
            }

            return macros?.filter((macro) => macro.key_word.toLowerCase().includes(loweredMacro)) ?? [];
        } else {
            return [];
        }
    }, [macroSearchQuery, macroFetchSuccess]);

    // Set the initial hilighted index to first item
    useEffect(() => {
        if (filteredMacros) {
            setHighlightedMacroIndex(0);
        }
    }, [filteredMacros.length]);

    // Using keydown as well, so that we have ability to
    // prevent default.
    const handleKeyDown = (e: React.KeyboardEvent) => {
        let { key } = e;
        // Prevent moving of cursor when highlighting
        if ((key === Keys.ARROW_DOWN && macroEndIndex > -1) || (key === Keys.ARROW_UP && macroEndIndex > -1)) {
            e.preventDefault();
            handleHilightStep(key === Keys.ARROW_DOWN);
        }
    };

    const handleKeyUp = (e: React.KeyboardEvent) => {
        let { key } = e;
        let currentTarget = e.target as HTMLTextAreaElement;
        let cursorIndex = Number(textareaRef.current?.selectionStart);
        // Start the macro lookup process
        if (key === Keys.BACKSLASH) {
            setMacroStartIndex(cursorIndex);
        } else if (macroStartIndex > -1) {
            switch (true) {
                case key === Keys.BACKSPACE:
                    // If dot removed then clear
                    if (macroSearchQuery?.length === 0) {
                        clearMacro();
                    } else {
                        setMacroEndIndex(cursorIndex);
                    }
                    break;

                case key === Keys.ENTER && hilightedMacro !== null && !!macroEndIndex:
                    handleSelectMacro(hilightedMacro as Macro);
                    break;
                // If everything removed or a key that removes the lookup
                case !currentTarget.value.length || CLEAR_MACRO_LIST.includes(key):
                    clearMacro();
                    break;
                default:
                    setMacroEndIndex(cursorIndex);
            }
        }
    };

    // Handles moving up and down in hilight
    const handleHilightStep = (down: boolean) => {
        if (filteredMacros?.length) {
            let newIndex = 0;
            // if item hilighted
            if (highlightedMacroIndex > -1) {
                // Both these conditions go to start/end when out
                // of index.
                if (down) {
                    newIndex = highlightedMacroIndex < filteredMacros.length - 1 ? (highlightedMacroIndex += 1) : 0;
                } else {
                    newIndex = highlightedMacroIndex === 0 ? filteredMacros.length - 1 : (highlightedMacroIndex -= 1);
                }
            }
            setHighlightedMacroIndex(newIndex);
        }
    };

    // Adds the macro to textarea
    const handleSelectMacro = (macro: Macro) => {
        let currentNote = textareaRef.current?.value ?? '';
        // concat the macro
        let adaptedNote = currentNote.substring(0, macroStartIndex - 1) + macro.content + currentNote.substring(macroEndIndex);
        setLocalValue(adaptedNote);
        clearMacro();
    };

    const handleBlur = () => {
        // Using a set timeout here, so that
        // We can click on macro items before
        // it's cleared
        setTimeout(() => {
            clearMacro();
        }, 1000);
    };

	return (
		<div className='note-editor'>
			<Popover
				placement='leftTop'
				title={`Macro: ${macroSearchQuery ?? ''}..`}
				content={
					<NoteMacros
						macros={filteredMacros}
						handleSelect={handleSelectMacro}
						hilightedMacroIndex={highlightedMacroIndex}
						handleHilight={setHilightedMacro}
					/>
				}
				trigger='click'
				visible={macroEndIndex > -1}
				overlayClassName='macro-popover'
			>
				<textarea
					data-cy="noteField"
					value={localValue}
					ref={textareaRef}
					rows={rows}
					onChange={(e) => {
						setLocalValue(e.target.value);
					}}
					onKeyDown={handleKeyDown}
					onKeyUp={handleKeyUp}
					onBlur={handleBlur}
				/>
			</Popover>
		</div>
	);
};

export default NoteEditor;
