import { PatientRecordVisit } from 'utils/dataTypes';
import { Popover } from 'antd';
import { useState } from 'react';
import { escapeClose } from 'utils/formFuncs';
import { ReasonForVisitForm } from 'components/forms/ReasonForVisitForm';

interface ReasonForVisitPopoverProps {
    currentVisit: PatientRecordVisit;
    children: any;
    disabled?: any;
}

export const ReasonForVisitPopover: React.FC<ReasonForVisitPopoverProps> = ({ currentVisit, disabled, children }) => {
    const isDisabled = disabled ?? false;
    const [popoverState, setPopOverState] = useState(false);
    return (
        <Popover
            visible={popoverState && !isDisabled}
            destroyTooltipOnHide={true}
            onVisibleChange={(show) => {
                if (!isDisabled) {
                    setPopOverState(show);
                    escapeClose(popoverState, setPopOverState);
                }
            }}
            overlayClassName='rdvm-info-popover'
            trigger='click'
            title='Reason for Visit'
            content={<ReasonForVisitForm currentVisit={currentVisit} setPopoverState={setPopOverState} />}
            placement='rightBottom'
        >
            <span>{children}</span>
        </Popover>
    );
};
