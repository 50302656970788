import { Avatar as AntAvatar, AvatarProps as AntAvatarProps } from 'antd';
import { stringToHslColor, getInitials } from 'utils/formatFuncs';
import './avatar.css';

interface AvatarProps extends AntAvatarProps {
	content?: string;
	firstName?: string;
	lastName?: string;
	saturation?: number;
	lightness?: number;
	className?: string;
}

const Avatar: React.FC<AvatarProps> = ({
	content,
	size = 'default',
	saturation = 60,
	lightness = 60,
	firstName,
	lastName,
	className,
	...rest
}) => {
	return (
		<AntAvatar
			style={{
				backgroundColor: stringToHslColor(
					firstName
					? firstName + lastName ?? ''
					: content ?? '',
					saturation,
					lightness,
				),
				verticalAlign: 'middle',
				marginRight: '1rem',
				fontSize: '14px',
			}}
			className={className}
			size={size}
			gap={1}
			{...rest}
		>
			{firstName
				? getInitials(firstName).toLocaleUpperCase() + getInitials(lastName ?? '').toLocaleUpperCase()
				: getInitials(content ?? '')
			}
		</AntAvatar>
	);
};

export default Avatar;
