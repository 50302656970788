import { FormInstance, Form, Input } from "antd";
import { useEffect } from "react";
import { useGetUserDataQuery } from "services/authService";
import { BASE_QUERY_OPTIONS } from "utils/constants";
import { HiddenInput } from "./fields/HiddenInput";



export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';

interface ToGoMedOrderProps {
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    getFormData?: Function; //not actually optional -- always sent via FormWrapper
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
    form?: FormInstance<any>
    options: {
        id: number
        quantity: number
        name: string
        category: undefined
        priority: undefined
        start_time: number
        end_time: undefined
        dispense_unit: string

    }
}

export const ToGoMedOrder = (props: ToGoMedOrderProps) => {
    const {options: existingInstruction } = props;

    const { data: loggedInUserData } = useGetUserDataQuery(
        null,
        BASE_QUERY_OPTIONS,
    );


    const labelCol = props.labelCol || { span: 6 };
    const wrapperCol = props.wrapperCol || { span: 12 };

    const onFormChange =
        props.onFormChange ??
        (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));

    const getFormData =
        props.getFormData ??
        (() => console.error('ERROR, getFormData NOT PASSED THROUGH'));

    useEffect(() => {
        const  defaultInstruction = {...existingInstruction, ordered_by: loggedInUserData?.user_id ?? 0};

        if (props.form) {
            props.form.resetFields(Object.keys(defaultInstruction))
        }
        onFormChange(defaultInstruction)
    }, [existingInstruction]);

    return (
        <>
            <Form.Item
                preserve={false}
                name='quantity'
                label='Quantity'
                style={{width: "100%"}}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
            >
                        <Input
                            disabled
                            style={{'width': '100%'}}
                            type='number'
                            min={0}
                            value={existingInstruction.quantity}
                            suffix={existingInstruction.dispense_unit}
                        />
            </Form.Item>
            <HiddenInput fieldName='id' />
            <HiddenInput fieldName='type_id' />
            <HiddenInput fieldName='category' />
            <HiddenInput fieldName='ordered_by' />
            <HiddenInput fieldName='priority' />
            <HiddenInput fieldName='name' />
            <HiddenInput fieldName='start_time' />
        </>
    );
};
