import { FormInstance, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useGetUserDataQuery } from "services/authService";
import { useGetSupplementalByNameQuery } from "services/supplementalService";
import { BASE_QUERY_OPTIONS } from "utils/constants";
import { MedicineSearchOption } from "utils/types/InstructionOrderTypes";
import { ToGoMedEstimateRules } from "utils/types/validations";
import { validateFieldIsGreaterThan } from "utils/validationFuncs";
import { transformTogoMed } from "views/visit/DischargeTab/utils";
import { HiddenInput } from "../fields/HiddenInput";



export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';

interface ToGoMedEstimateProps {
    dataSource: MedicineSearchOption;
    patientWeight?: number;
    approxPatientWeightPackage?: number;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    getFormData?: Function; //not actually optional -- always sent via FormWrapper
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
    form?: FormInstance<any>
}

export const ToGoMedEstimate = (props: ToGoMedEstimateProps) => {
    const { dataSource } = props;

    const { data: loggedInUserData } = useGetUserDataQuery(
        null,
        BASE_QUERY_OPTIONS,
    );
    const [unit, setUnit] = useState("");

    const isTogoMed = dataSource.calculator_type === 'prepackaged';

    const {data: supplementalData} = useGetSupplementalByNameQuery({ name: "Dispense", controlledMedication: dataSource.controlled_drug });

    const labelCol = props.labelCol || { span: 6 };
    const wrapperCol = props.wrapperCol || { span: 12 };

    const onFormChange =
        props.onFormChange ??
        (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));


    useEffect(() => {
        const {dispense_unit, ratio} = transformTogoMed(dataSource)
        const supplemental_cost_cents = isTogoMed ? null : supplementalData?.cents ?? null;
        const is_recurring_supplemental = isTogoMed ? null : supplementalData?.recurring ?? null;
        const  defaultInstruction = {
            medication_id: dataSource.id,
            type_id: "TGH",
            name: dataSource.name,
            is_prn: false,
            prn_condition: null,

            unit_cost_cents: (dataSource.cents ?? 0)  * ratio,
            priority: false,
            is_free: false,
            reason: null,
            ordered_by: loggedInUserData?.user_id || 0,
            notes: null,
            supplemental_cost_cents,
            is_recurring_supplemental,
            dispense_unit: dispense_unit,
            ratio: ratio,
            dose_unit: dataSource.dose_unit,
            unit: dispense_unit,
            dispense_value: null,
            instructions: null,
            next_dose: null

        };

        if (props.form) {
            props.form.resetFields(Object.keys(defaultInstruction))
        }
        onFormChange(defaultInstruction)
        setUnit(dispense_unit)
    }, [dataSource]);

    useEffect(() => {
        if (supplementalData && !isTogoMed) {
            onFormChange({
                is_recurring_supplemental: supplementalData.recurring,
                supplemental_cost_cents: supplementalData.cents
            })
        }
    }, [supplementalData])


    const medicineEstimateRules: ToGoMedEstimateRules = {
        quantity: [
            { required: true},
            { validator: validateFieldIsGreaterThan('dose')}
        ],
    }
    return (
        <>
            <Form.Item
                preserve={false}
                name='quantity'
                label='Quantity'
                style={{width: "100%"}}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                rules={medicineEstimateRules.quantity}
                data-cy={'quantityInput'}
            >
                <Input
                    autoFocus
                    suffix={unit}
                    style={{'width': '100%'}}
                    type='number'
                />
            </Form.Item>
            <HiddenInput fieldName='is_prn' />
            <HiddenInput fieldName='prn_condition' />
            <HiddenInput fieldName='medication_id' />
            <HiddenInput fieldName='type_id' />
            <HiddenInput fieldName='unit_cost_cents' />
            <HiddenInput fieldName='unit' />
            <HiddenInput fieldName='is_free' />
            <HiddenInput fieldName='reason' />
            <HiddenInput fieldName='ordered_by' />
            <HiddenInput fieldName='notes' />
            <HiddenInput fieldName='name' />
            <HiddenInput fieldName='supplemental_cost_cents' />
            <HiddenInput fieldName='is_recurring_supplemental' />
            <HiddenInput fieldName='priority' />
            <HiddenInput fieldName='dispense_unit' />
            <HiddenInput fieldName='ratio' />
            <HiddenInput fieldName='dose_unit' />
            <HiddenInput fieldName='dispense_value' />
            <HiddenInput fieldName='instructions' />
            <HiddenInput fieldName='next_dose' />
            <HiddenInput fieldName="controlled_drug" initialValue={dataSource.controlled_drug} />
        </>
    );
};
