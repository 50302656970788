import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Modal, Radio, Space, Table, Tooltip, message } from "antd";
import { AppDispatch } from "hooks/useRedux";
import moment from "moment";
import { useState } from "react";
import { useSubmitIHDResultAssignmentMutation, useSubmitXRayResultAssignmentMutation } from "services/instructionService";
import { setActiveTreatmentSheetAsk } from "store/slices/treatmentSheetSlice";
import { PimsUser, TreatmentSheetInstructionAction, VCPExternalResult, VCPRunSummary, instructionType } from "utils/dataTypes";

interface RecentResultsModalProps {
    dispatch?: AppDispatch;
    patientName?: string;
    isOpen?: boolean;
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    order: instructionType;
    action: TreatmentSheetInstructionAction;
    recentResults?: VCPExternalResult[];
    selectedRow?: string;
    setSelectedRow?: React.Dispatch<React.SetStateAction<string>>;
    completedTime?: moment.Moment | null;
    setCompletedTime?: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
    modifyAction?: any;
    loggedInUserData?: PimsUser;
}

export const RecentResultsModal: React.FC<RecentResultsModalProps> = ({
    dispatch,
    patientName,
    isOpen,
    setIsOpen,
    order,
    action,
    recentResults,
    selectedRow,
    setSelectedRow,
    completedTime,
    setCompletedTime,
    modifyAction,
    loggedInUserData,
}) => {
    const [submitIHDResultAssignment, { isLoading: isIHDAssignmentLoading }] = useSubmitIHDResultAssignmentMutation();
    const [submitXRayResultAssignment, { isLoading: isXRayAssignmentLoading }] = useSubmitXRayResultAssignmentMutation();
    const [completedTimeRadioValue, setCompletedTimeRadioValue] = useState(1);
    const dateIsInTheFuture = (currentDate: moment.Moment) => {
        return currentDate > moment();
    };
    const [isPlusOneHourButtonDisabled, setIsplusOneHourButtonDisabled] = useState(true);
    const setNewTimeField = (date: moment.Moment | null) => {
        const datePlusOneHour = moment(date).add(1, 'hour');
        setIsplusOneHourButtonDisabled(dateIsInTheFuture(datePlusOneHour));
        setCompletedTime?.(date);
    };
    const plusMinusHours = (type: 'plus' | 'minus') => {
        const timeToAdd = type === 'plus' ? 1 : -1;
        const newTime = moment(completedTime).add(timeToAdd, 'hour');
        setNewTimeField(newTime);
    };

    return (
        <Modal
            visible={isOpen}
            width='75%'
            title='Recently Completed Results'
            onCancel={() => { setIsOpen?.(false) }}
            footer={[
                <Button key="back" onClick={() => setIsOpen?.(false)}>Cancel</Button>,
                <Button key="submit" type="primary" onClick={() => {
                    const assignment = order.category === 'IDEXX' ? submitIHDResultAssignment : submitXRayResultAssignment;
                    if (action.isGhostAction) {
                        modifyAction({
                            visitId: order.visit_id,
                            body: {
                                instructionId: order.id,
                                actionId: action.action_id,
                                status: 'inprogress',
                                assigned_to: loggedInUserData?.user_id,
                                due_at: action.due_at,
                            }
                        }).then((response: any) => {
                            if ('data' in response) {
                                !!selectedRow && !!completedTime && assignment(
                                    {
                                        visitId: order.visit_id,
                                        actionId: response.data.id,
                                        resultId: selectedRow,
                                        completedAt: completedTime.unix()
                                    }
                                ).unwrap().then(() => {
                                    dispatch?.(setActiveTreatmentSheetAsk(null));
                                    setIsOpen?.(false);
                                    message.success("Successfully assigned result", 10)
                                })
                                    .catch((err) => {
                                        message.error(err.data, 15)
                                    })
                            }
                        })
                    } else {
                        !!selectedRow && !!completedTime && assignment(
                            {
                                visitId: order.visit_id,
                                actionId: action.action_id,
                                resultId: selectedRow,
                                completedAt: completedTime.unix()
                            }
                        ).unwrap().then(() => {
                            dispatch?.(setActiveTreatmentSheetAsk(null));
                            setIsOpen?.(false);
                            message.success("Successfully assigned result", 10)
                        })
                            .catch((err) => {
                                message.error(err.data, 15)
                            })
                    }
                }}
                    loading={
                        order.category === 'IDEXX'
                            ? isIHDAssignmentLoading
                            : isXRayAssignmentLoading
                    }
                    disabled={selectedRow === ''}
                >Attach Results and Mark Complete</Button>
            ]}

        >
            <p>Patient name: {patientName}</p>
            <p>Order: {order.name}</p>
            <p>Below is a list of results for recently completed tests from IDEXX that
                are not yet associated with a patient's record.</p>
            {recentResults !== undefined && selectedRow !== undefined && !!setSelectedRow &&
                <RecentResultsTable
                    recentResults={recentResults}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                />
            }

            <div style={{ width: '100%', paddingLeft: '16px' }}>
                <Form.Item
                    className="completed-action-modal__radio"
                    label='Completed Time'
                    labelAlign='left'
                    labelCol={{ span: 3 }}
                >
                    <Radio.Group
                        onChange={(e) => {
                            setCompletedTimeRadioValue(e.target.value);
                        }}
                        value={completedTimeRadioValue}
                    >
                        <Space direction="vertical">
                            <Radio value={1}>Completed Now</Radio>
                            <Radio value={2}>Completed Earlier</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                {completedTimeRadioValue === 2 &&
                    <Form.Item
                        name='completed_at'
                        rules={[{ required: true, message: 'A new time is required' }]}
                        wrapperCol={{ offset: 3 }}
                        style={{ width: '100%' }}
                        initialValue={completedTime}
                    >
                        <DatePicker
                            value={completedTime}
                            onChange={setNewTimeField}
                            format={'MM/DD/YYYY hh:mm a'}
                            suffixIcon={<ClockCircleOutlined />}
                            disabledDate={dateIsInTheFuture}
                            showTime
                        />
                        <span className='reschedule-action-form__new-time-funcs'>
                            <Button onClick={() => plusMinusHours('minus')}>- 1hr</Button>
                            <Tooltip title={isPlusOneHourButtonDisabled ? 'The date cannot be in the future' : null}>
                                <Button onClick={() => plusMinusHours('plus')} disabled={isPlusOneHourButtonDisabled}>
                                    + 1hr
                                </Button>
                            </Tooltip>
                        </span>
                    </Form.Item>
                }
            </div>
        </Modal>
    )
}

interface RecentResultsTableProps {
    recentResults: VCPExternalResult[];
    selectedRow: string;
    setSelectedRow: React.Dispatch<React.SetStateAction<string>>;
}

export const RecentResultsTable: React.FunctionComponent<RecentResultsTableProps> = ({
    recentResults,
    selectedRow,
    setSelectedRow,
}) => {
    return (
        <>
            <Table
                className='idexx-recently-completed'
                rowSelection={{
                    type: 'radio',
                    selectedRowKeys: [selectedRow] as React.Key[],
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                        setSelectedRow(selectedRowKeys[0] as string);
                    },
                }}
                columns={[
                    {
                        title: 'Patient Name',
                        dataIndex: 'patientName',
                    },
                    {
                        title: 'Test Name',
                        dataIndex: 'runSummaries',
                        render: (summaries: Array<VCPRunSummary>) => {
                            if (summaries.length > 1) {
                                return (
                                    <>
                                        <ul style={{ listStyleType: 'none', marginBottom: 0, paddingLeft: 0 }}>
                                            {summaries.map((summary) => <li>{summary.name}</li>)}
                                        </ul>
                                    </>
                                )
                            } else {
                                return summaries[0].name
                            }
                        }
                    },
                    {
                        title: 'Last Updated',
                        dataIndex: 'updatedDate',
                        render: (rawDate: string) => new Date(rawDate).toLocaleString()
                    }
                ]}
                dataSource={recentResults}
                scroll={{ y: 220 }}
            />
        </>
    )
}

export default RecentResultsModal;
