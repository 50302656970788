import {
	BarChartOutlined,
	CalendarOutlined,
	DashboardOutlined,
	MedicineBoxOutlined,
} from '@ant-design/icons';
import { Image, Layout, Menu } from 'antd';
import { ContactIcon } from 'assets/img/ContactIcon';
import PendoBadge from 'components/pendo/pendoBadge';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import { useGetHospitalByIdQuery } from 'services/hospitalService';
import { ROUTES } from 'utils/types/enums';
import {
	USER_PERMISSIONS,
	checkIfUserHasNecessaryPermission,
} from 'utils/userPermissions';
import DogByteLogo from '../../assets/img/DogByte_Logo.svg';
import DogBytePaw from '../../assets/img/DogByte_Paw.svg';
import './side-bar.css';

const SideBar: React.FC = () => {
	const history = useHistory();
	const location = useLocation();
	const [collapsed, setCollapsed] = useState(window.innerWidth > 1200 ? false : true);
	const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');

	const { data: loggedInUserData } = useGetUserDataQuery(null);
	const { data: hospitalData } = useGetHospitalByIdQuery(loggedInUserData?.hospital_id ?? 0);

	useEffect(() => {
		if (location.pathname.startsWith(ROUTES.outpatientTS)) {
			setSelectedMenuItem('outpatient_ts');
		} else if (location.pathname.startsWith(ROUTES.inpatientTS)) {
			setSelectedMenuItem('inpatient_ts');
		} else if (
			location.pathname === '/' ||
			location.pathname.startsWith('/visit')
		) {
			setSelectedMenuItem('dashboard');
		} else if (location.pathname.startsWith(ROUTES.allvisits)) {
			setSelectedMenuItem('all_visits');
		} else if (location.pathname.startsWith(ROUTES.contacts) || location.pathname.startsWith('/customer')) {
			setSelectedMenuItem('all_contacts');
		} else if (
			location.pathname.startsWith(ROUTES.controlled_drug_report)
		) {
			setSelectedMenuItem('controlled_drugs_report');
		} else if (
			location.pathname.startsWith(ROUTES.accounts_receivable_report)
		) {
			setSelectedMenuItem('accounts_receivable_report');
		} else if (
			location.pathname.startsWith(ROUTES.doctor_production_report)
		) {
			setSelectedMenuItem('doctor_production_report');
		} else if (
			location.pathname.startsWith(ROUTES.payments_report)
		) {
			setSelectedMenuItem('payments_report');
		}
	}, [location]);

	const getDefaultOpenKeys = () => {
		if (
			location.pathname.startsWith(ROUTES.controlled_drug_report) ||
			location.pathname.startsWith(ROUTES.accounts_receivable_report) ||
			location.pathname.startsWith(ROUTES.doctor_production_report)
		) {
			return ['reports'];
		} else if (
			location.pathname.startsWith(ROUTES.outpatientTS) ||
			location.pathname.startsWith(ROUTES.inpatientTS)
		) {
			return ['treatmentSheets'];
		}
		return [];
	};

	const userHasPermissionToViewReports = checkIfUserHasNecessaryPermission(
		loggedInUserData?.user_permissions,
		USER_PERMISSIONS.reports_view,
	);

	return (
		<Layout.Sider
			collapsible
			collapsed={collapsed}
			className='sidebar'
			data-cy="sidebar"
			onCollapse={(collapsed) => setCollapsed(collapsed)}
		>
			<Link to={'/'} className='sidebar-header'>
				<>
					<Image
						data-cy="dogbytePawLogo"
						className='dogbyte-paw-logo'
						preview={false}
						src={DogBytePaw}
						style={{
							width: 47,
							marginTop: 5,
							display: collapsed ? 'block' : 'none',
						}}
						alt=''
					/>
					<Image
						data-cy="dogbyteMainLogo"
						preview={false}
						src={DogByteLogo}
						style={{
							width: 135,
							display: collapsed ? 'none' : 'block',
						}}
						alt=''
					/>
				</>
			</Link>

			<Menu
				className='sidebar-menu'
				selectedKeys={[selectedMenuItem]}
				mode='inline'
				defaultOpenKeys={getDefaultOpenKeys()}
			>
				<Menu.Item
					onClick={() => {
						history.push('/');
					}}
					key='dashboard'
					data-cy="sidebarDashboardLink"
				>
					<DashboardOutlined />
					<span>Dashboard</span>
				</Menu.Item>
				<Menu.SubMenu
					title='Treatment Sheets'
					icon={<MedicineBoxOutlined />}
					key='treatmentSheets'
					data-cy="sidebarTreatmentSheetsLink"
				>
					<Menu.Item
						onClick={() => {
							history.push(ROUTES.outpatientTS);
						}}
						key='outpatient_ts'
					>
						<span>Outpatient</span>
					</Menu.Item>
					<Menu.Item
						onClick={() => {
							history.push(ROUTES.inpatientTS);
						}}
						key='inpatient_ts'
					>
						<span>Inpatient</span>
					</Menu.Item>
				</Menu.SubMenu>
				<Menu.Item
					onClick={() => {
						history.push(ROUTES.allvisits);
					}}
					key='all_visits'
					data-cy="sidebarVisitsLink"
				>
					<CalendarOutlined />
					<span>Visits</span>
				</Menu.Item>
				<Menu.Item
					onClick={() => {
						history.push(ROUTES.contacts);
					}}
					key='all_contacts'
				>
					<ContactIcon style={{ width: '14px', height: '14px' }} />
					<span>Contacts</span>
				</Menu.Item>
				{userHasPermissionToViewReports && (
					<>
						<Menu.SubMenu
							title='Reports'
							icon={<BarChartOutlined />}
							key='reports'
							data-cy="sidebarReportsLink"
						>
							<Menu.Item
								onClick={() => {
									history.push(ROUTES.accounts_receivable_report);
								}}
								key='accounts_receivable_report'
							>
								<span>Accounts Receivable</span>
							</Menu.Item>
							<Menu.Item
								onClick={() => {
									history.push(ROUTES.controlled_drug_report);
								}}
								key='controlled_drugs_report'
							>
								<span>Controlled Drugs</span>
							</Menu.Item>
							<Menu.Item
								onClick={() => {
									history.push(ROUTES.doctor_production_report);
								}}
								key='doctor_production_report'
							>
								<span>Doctor Production</span>
							</Menu.Item>
							<Menu.Item
								onClick={() => {
									history.push(ROUTES.payments_report);
								}}
								key='payments_report'
							>
								<span>Payments</span>
							</Menu.Item>
						</Menu.SubMenu>
						{hospitalData?.fflags?.whiteboard &&
							<Menu.Item
								onClick={() => {
									history.push(ROUTES.whiteboard);
								}}
								key='whiteboard'
								data-cy="whiteboardLink"
							>
								<CalendarOutlined />
								<span>Whiteboard</span>
							</Menu.Item>
						}
					</>
				)}
			</Menu>
			<div className='pendo-container'>
				<PendoBadge absolute={false} collapsed={collapsed} />
			</div>
		</Layout.Sider>
	);
};

export default SideBar;
