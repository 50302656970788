import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Table from 'antd/lib/table';
import moment from 'moment';
import { calculateTotalCost } from 'utils/miscFuncs';
import { AdditionalServicesTableChildItem, BillingItem, BillingItemDisplay } from 'utils/types/billingTypes';
import { getBillingItemsDisplay } from '../getBillingItemsDisplay';
import './AdditionalServicesTable.css';

interface IAdditionalServicesTableProps {
    billingItems: BillingItem[];
}

const AdditionalServicesTable = ({ billingItems }: IAdditionalServicesTableProps) => {
    const columns = [
        {
            title: '',
            width: '1%',
            key: 'select',
        },
        {
            title: 'Order Type',
            width: '18%',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Frequency',
            width: '17%',
            key: 'frequency',
            dataIndex: 'frequency',
            align: 'left',
        },
        {
            title: 'Completed',
            width: '40%',
            key: 'end',
            dataIndex: 'end',
            align: 'left',
        },
    ];

    const generateBillingDisplayData = () => {
        const billingItemsDisplay = getBillingItemsDisplay(billingItems);

        return billingItemsDisplay
            .reduce((billingItemsByDoctor: BillingItemDisplay[], billingItemDisplay) => {
                const alreadyAddedIndex = billingItemsByDoctor.findIndex((lineItemsByOrder) => {
                    if (billingItemDisplay.instruction_id) {
                        return lineItemsByOrder.instruction_id === billingItemDisplay.instruction_id;
                    } else if (billingItemDisplay.non_medical_order_id) {
                        return lineItemsByOrder.non_medical_order_id === billingItemDisplay.non_medical_order_id;
                    } else {
                        return false;
                    }
                });
                if (alreadyAddedIndex === -1) {
                    billingItemsByDoctor.push(billingItemDisplay);
                    return billingItemsByDoctor;
                } else {
                    billingItemsByDoctor[alreadyAddedIndex] = {
                        ...billingItemsByDoctor[alreadyAddedIndex],
                        earned_revenue_line_items: [
                            ...billingItemsByDoctor[alreadyAddedIndex].earned_revenue_line_items,
                            ...billingItemDisplay.earned_revenue_line_items,
                        ],
                    };
                    return billingItemsByDoctor;
                }
            }, [])
            .map((billingItemDisplay, index) => {
                const localId: number = billingItemDisplay.instruction_id ?? billingItemDisplay.non_medical_order_id ?? -100;
                return {
                    key: `${localId}-${index}`,
                    id: localId,
                    name: billingItemDisplay.name,
                    instruction_id: billingItemDisplay.instruction_id,
                    type_id: billingItemDisplay.type_id,
                    non_medical_order_id: billingItemDisplay.non_medical_order_id,
                    price: calculateTotalCost(billingItemDisplay),
                    children: billingItemDisplay.earned_revenue_line_items.map(
                        (earnedRevenueItem): AdditionalServicesTableChildItem => ({
                            key: earnedRevenueItem.id,
                            id: earnedRevenueItem.id,
                            name: '',
                            frequency: billingItemDisplay.frequency || '-',
                            end: moment.unix(earnedRevenueItem.created_at).format('MM/DD/YYYY hh:mm a'),
                        }),
                    ),
                };
            });
    };

    return (
        <Table
            className='additional-service-table'
            //@ts-ignore
            columns={columns}
            dataSource={generateBillingDisplayData()}
            pagination={false}
            expandIcon={({ expanded, onExpand, record }) => {
                if (!record.children) {
                    return <></>;
                }
                if (expanded) {
                    return <UpOutlined onClick={(e) => onExpand(record, e)} />;
                } else {
                    return <DownOutlined onClick={(e) => onExpand(record, e)} />;
                }
            }}
        />
    );
};

export default AdditionalServicesTable;
