import { DownOutlined, ExclamationCircleFilled, UpOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Checkbox, Form, Input, Modal, Spin, message } from 'antd';
import { InternalNamePath } from 'antd/lib/form/interface';
import _ from 'lodash';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetInstructionsByVisitIdQuery, useGetPackageDetailsQuery } from 'services/instructionService';
import { useOrderPackageMutation } from 'services/packagesService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useGetVisitByIdQuery } from 'services/visitService';
import { PATIENT_WEIGHT_INSTRUCTION_NAME } from 'utils/constants';
import { getRecentVitalsFromInstructions } from 'utils/miscFuncs';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import {
    PackageDiagnosticInstruction,
    PackageFluidInstruction,
    PackageMedInstruction,
    PackageNonMedInstruction,
    PackageTaskInstruction,
} from 'utils/types/InstructionOrderTypes';
import DiagnosticsForm from './DiagnosticsForm';
import FluidsForm from './FluidsForm';
import NonMedicalsForm from './NonMedicalsForm';
import OxygenTherapyForm from './OxygenTherapyForm';
import './PackageFormModal.css';
import PackageMedicationForm from './PackageMedicationForm';
import TasksForm from './TasksForm';
import UpdateAllTimesFormItem from './UpdateAllTimesFormItem';

interface PackageFormModalProps {
    fromEstimate?: boolean;
    onFinish?: (items: PackageFormInstance) => void;
    durationHours?: number;
}

interface PackageFormModalRef {
    openModal: (id: number) => void;
}

interface SharedValues {
    id: number;
    checked: boolean;
    name: string;
    unit_cost_cents: number;
    required: boolean;
    no_charge: boolean;
}

interface StartEndTimeAndInstruction {
    end_time: moment.Moment;
    start_time: moment.Moment;
    instruction: string;
}

interface Frequency {
    frequency: string;
}

interface Serial {
    serial: boolean;
    serial_hours: number | null;
    price_cents_serial: number | null;
}

interface PackageFluids extends SharedValues, StartEndTimeAndInstruction {
    rate: string;
    route_id: string;
    volume: string;
    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;
}

interface PackageMedication extends SharedValues, StartEndTimeAndInstruction, Frequency, Serial {
    numerator_value: number;
    route_id: string;
    calculator_type: string;
    concentration: number;
    controlled_drug: boolean;
    denominator_unit: string;
    dose_unit: string;
    pricing_unit: string | null;
    pricing_unit_size: number | null;
    instruction: string;
    numerator_unit: string;
    latest_patient_weight_kg: number | null;
    approx_patient_weight_kg: number | null;
    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;
}

interface PackageNonMeds extends SharedValues {
    quantity: string;
    why: string | null;
    why_other: string | null;
    reason: string | null;
}

interface PackageOxygenTherapy extends SharedValues, StartEndTimeAndInstruction {
    quantity: string;
    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;
}

export interface PackageFormInstance {
    patient_weight: number;
    end_time: moment.Moment;
    start_time: moment.Moment;
    diagnostics: Array<SharedValues & StartEndTimeAndInstruction & Frequency & Serial>;
    fluids: Array<PackageFluids>;
    medications: Array<PackageMedication>;
    tasks: Array<SharedValues & StartEndTimeAndInstruction & Frequency & Serial>;
    nonMed: Array<PackageNonMeds>;
    oxygen_therapy: Array<PackageOxygenTherapy>;
}

const PackageFormModal: React.ForwardRefRenderFunction<PackageFormModalRef, PackageFormModalProps> = ({ fromEstimate, onFinish, durationHours }, ref) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);
    const mainDivRef = useRef<HTMLDivElement>(null);
    const syncDivRef = useRef<HTMLDivElement>(null);
    const gridTableRef = useRef<HTMLDivElement>(null);
    const [packageId, setPackageId] = useState<number | null>(null);
    const [checkAll, setCheckAll] = useState(true);
    const [collapseSection, setCollapseSection] = useState({ D: false, F: false, M: false, T: false, N: false, OT: false });
    const [hasError, setHasError] = useState(false);
    const [activeLineItem, setActiveLineItem] = useState('');

    const [form] = Form.useForm<PackageFormInstance>();

    const { data: instData } = useGetInstructionsByVisitIdQuery({ visitId }, { skip: !packageId });
    const { data: currentVisit } = useGetVisitByIdQuery(visitId, { skip: !visitId });
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id ?? skipToken, { skip: !currentVisit?.pet_id });
    const { data: packageInstruction, isFetching: loadingPackageItems } = useGetPackageDetailsQuery(packageId ?? skipToken, {
        skip: !packageId,
        refetchOnMountOrArgChange: true,
    });
    const [orderPackage, { isLoading: loadingOrderPackage }] = useOrderPackageMutation();

    useImperativeHandle(ref, () => ({
        openModal: (id: number) => {
            setPackageId(id);
        },
    }));

    const patientWeight = useMemo(() => {
        const vitalInstructions = getRecentVitalsFromInstructions(instData);

        return (
            parseFloat(
                (vitalInstructions?.find((vital) => vital?.name === PATIENT_WEIGHT_INSTRUCTION_NAME)?.lastAction?.value as string) ?? '0',
            ) || undefined
        );
    }, [instData]);

    const packageItemsGrouped = useMemo(() => _.groupBy(packageInstruction?.instructions, 'type_id'), [packageInstruction?.instructions]);

    const handleScroll = () => {
        if (mainDivRef.current && syncDivRef.current) {
            const MAX_WIDTH_SIZE = mainDivRef.current.scrollWidth - mainDivRef.current.clientWidth;

            if (mainDivRef.current.scrollLeft !== 0) {
                gridTableRef.current?.classList.add('show-left-shadow');
            }
            if (mainDivRef.current.scrollLeft === 0) {
                gridTableRef.current?.classList.remove('show-left-shadow');
            }
            if (mainDivRef.current.scrollLeft !== MAX_WIDTH_SIZE) {
                gridTableRef.current?.classList.add('show-right-shadow');
            }
            if (mainDivRef.current.scrollLeft === MAX_WIDTH_SIZE) {
                gridTableRef.current?.classList.remove('show-right-shadow');
            }

            syncDivRef.current.scrollLeft = mainDivRef.current.scrollLeft;
        }
    };

    const handleClose = () => {
        form.resetFields();
        setPackageId(null);
        setCheckAll(true);
        setHasError(false);
        setActiveLineItem('');
        setCollapseSection({ D: false, F: false, M: false, T: false, N: false, OT: false });
    };

    const handleCheckAll = (checked: boolean) => {
        setHasError(false);
        const values = form.getFieldsValue();
        setCheckAll(checked);
        const itemsToSet = { ...values };

        if (values.diagnostics) {
            itemsToSet.diagnostics = values.diagnostics.map((d: any) => {
                return d.required ? d : { ...d, checked };
            });
        }
        if (values.fluids) {
            itemsToSet.fluids = values.fluids.map((f: any) => {
                return f.required ? f : { ...f, checked };
            });
        }
        if (values.medications) {
            itemsToSet.medications = values.medications.map((m: any) => {
                return m.required ? m : { ...m, checked };
            });
        }
        if (values.tasks) {
            itemsToSet.tasks = values.tasks.map((t: any) => {
                return t.required ? t : { ...t, checked };
            });
        }
        if (values.nonMed) {
            itemsToSet.nonMed = values.nonMed.map((n: any) => {
                return n.required ? n : { ...n, checked };
            });
        }
        if (values.oxygen_therapy) {
            itemsToSet.oxygen_therapy = values.oxygen_therapy.map((ot: any) => {
                return ot.required ? ot : { ...ot, checked };
            });
        }

        form.setFieldsValue(itemsToSet);
    };

    const handleOnFinish = (values: PackageFormInstance) => {
        setHasError(false);
        const diags = values.diagnostics?.filter((i) => i.checked) ?? [];
        const fluids = values.fluids?.filter((i) => i.checked) ?? [];
        const meds =
            values.medications
                ?.filter((i) => i.checked)
                ?.map((med) => {
                    const packageMedInstruction = packageInstruction?.instructions.find(item => item.type_id === 'M' && item.medication_id === med.id) as PackageMedInstruction;
                    return {
                        ...med,
                        latest_patient_weight_kg: patientWeight ?? null,
                        approx_patient_weight_kg: !!patientWeight ? null : values.patient_weight,
                        pricing_unit: packageMedInstruction?.pricing_unit,
                        pricing_unit_size: packageMedInstruction?.pricing_unit_size,
                    };
                }) ?? [];
        const tasks = values.tasks?.filter((i) => i.checked) ?? [];
        const nonMeds = values.nonMed?.filter((i) => i.checked) ?? [];
        const oxygenTherapy = values.oxygen_therapy?.filter((i) => i.checked) ?? [];
        const totalItems = diags.length + fluids.length + meds.length + tasks.length + nonMeds.length + oxygenTherapy.length;

        if (totalItems === 0) {
            handleClose();
            return;
        }

        if (fromEstimate && onFinish) {
            onFinish({
                ...values,
                diagnostics: diags,
                fluids: fluids,
                medications: meds,
                tasks: tasks,
                nonMed: nonMeds,
                oxygen_therapy: oxygenTherapy,
            });
            handleClose();
        } else {
            orderPackage({
                visitId,
                orderItems: {
                    diagnostics: diags,
                    fluids: fluids,
                    medications: meds,
                    tasks: tasks,
                    nonMed: nonMeds,
                    oxygen_therapy: oxygenTherapy,
                },
            })
                .unwrap()
                .then(() => {
                    message.success(`${totalItems} ${totalItems > 1 ? 'items' : 'item'} ordered successfully.`);
                    handleClose();
                })
                .catch(() => {
                    message.error('Unable order package items.');
                });
        }
    };

    const handleOnFinishFailed = (errorFields: { name: InternalNamePath; errors: string[] }[]) => {
        const fieldName = errorFields[0].name;

        _.uniq(errorFields.map((ef) => ef.name[0].toString().charAt(0).toUpperCase())).forEach((id) => {
            setCollapseSection((oldState) => ({ ...oldState, [id]: false }));
            setHasError(true);
        });

        form.getFieldInstance(fieldName).focus();
        form.validateFields(errorFields.map((ef) => ef.name));
    };

    useEffect(() => {
        if (packageItemsGrouped['D']) {
            setActiveLineItem(`D-0`);
        } else if (packageItemsGrouped['M']) {
            setActiveLineItem(`M-0`);
        } else if (packageItemsGrouped['F']) {
            setActiveLineItem(`F-0`);
        } else if (packageItemsGrouped['T']) {
            setActiveLineItem(`T-0`);
        } else if (packageItemsGrouped['N']) {
            setActiveLineItem(`N-0`);
        }
    }, [packageItemsGrouped]);

    const modalTitle = (
        <>
            <span>Package: </span>
            <span>{loadingPackageItems ? <Spin /> : packageInstruction?.name}</span>
        </>
    );

    return (
        <Modal
            bodyStyle={{ maxHeight: '68vh', overflow: 'auto' }}
            okText={fromEstimate ? 'Add to Estimate' : 'Save'}
            okButtonProps={{
                autoFocus: true,
            }}
            className='package-modal'
            destroyOnClose
            maskClosable={false}
            title={modalTitle}
            visible={!!packageId}
            width={fromEstimate ? '61vw' : '80vw'}
            onCancel={handleClose}
            onOk={form.submit}
            confirmLoading={loadingOrderPackage}
        >
            {loadingPackageItems ? (
                <Spin />
            ) : (
                <Form
                    form={form}
                    onFinish={handleOnFinish}
                    onFinishFailed={({ errorFields }) => handleOnFinishFailed(errorFields)}
                    preserve={false}
                    scrollToFirstError
                >
                    <h1 className='package-modal__title'>
                        <span>{capitalizeFirstLetter(patientData?.name ?? '')}</span>
                        <Form.Item
                            initialValue={patientWeight}
                            label={patientWeight ? '' : 'Approx Weight'}
                            name='patient_weight'
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (isNaN(value)) {
                                            return Promise.reject('Required');
                                        }

                                        if (value <= 0) {
                                            return Promise.reject('Patient weight cannot be smaller or equal to 0');
                                        }

                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            {patientWeight ? (
                                <span>{patientWeight}kg</span>
                            ) : (
                                <Input
                                    className='package-modal__patient-weight-input'
                                    suffix='kg'
                                    type='number'
                                    min={0}
                                    onChange={(e) => {
                                        const values = form.getFieldsValue();

                                        form.setFieldsValue({ ...values, patient_weight: parseFloat(e.target.value) });
                                    }}
                                />
                            )}
                        </Form.Item>

                        {!fromEstimate && <UpdateAllTimesFormItem form={form} />}
                    </h1>

                    {hasError && (
                        <div className='package-modal__error-message'>
                            Missing required information <ExclamationCircleFilled style={{ color: 'var(--veg-red)' }} />
                        </div>
                    )}

                    <div className={`grid-table ${fromEstimate ? 'estimate-table' : 'show-right-shadow'}`} ref={gridTableRef}>
                        <div className='grid-table__header' ref={syncDivRef}>
                            <div className='grid-table__sticky-column'>
                                <Checkbox checked={checkAll} onChange={(e) => handleCheckAll(e.target.checked)} />
                            </div>
                            <div className='grid-table__sticky-column'>Order Name</div>
                            <div>Dose/QTY</div>
                            <div>Route</div>
                            <div>Frequency</div>
                            {!fromEstimate && (
                                <>
                                    <div>Start Time</div>
                                    <div>End Time</div>
                                    <div>Instructions</div>
                                </>
                            )}
                            <div className='grid-table__sticky-column' style={{ height: '100%' }} />
                        </div>

                        <div className='grid-table__body' ref={mainDivRef} onScroll={handleScroll}>
                            {packageItemsGrouped['D']?.length && (
                                <>
                                    <section className='grid-table__full-width-row'>
                                        <div
                                            className='instruction-type-section'
                                            onClick={() => setCollapseSection((oldState) => ({ ...oldState, D: !oldState['D'] }))}
                                        >
                                            {collapseSection['D'] ? <DownOutlined /> : <UpOutlined />}
                                            <span>Diagnostics</span>
                                        </div>
                                    </section>

                                    {packageItemsGrouped['D']?.map((diag, index) => (
                                        <DiagnosticsForm
                                            form={form}
                                            diagnostic={diag as PackageDiagnosticInstruction}
                                            index={index}
                                            key={`${diag.type_id}_${diag.id}`}
                                            hiddenSectionClass={collapseSection['D'] ? 'hidden-section' : ''}
                                            fromEstimate={fromEstimate}
                                            durationHours={durationHours}
                                            setActiveLineItem={setActiveLineItem}
                                            activeLineItem={activeLineItem}
                                        />
                                    ))}
                                </>
                            )}

                            {packageItemsGrouped['M']?.length && (
                                <>
                                    <section className='grid-table__full-width-row'>
                                        <div
                                            className='instruction-type-section'
                                            onClick={() => setCollapseSection((oldState) => ({ ...oldState, M: !oldState['M'] }))}
                                        >
                                            {collapseSection['M'] ? <DownOutlined /> : <UpOutlined />}
                                            <span>Medication</span>
                                        </div>
                                    </section>

                                    {packageItemsGrouped['M']?.map((med, index) => (
                                        <PackageMedicationForm
                                            form={form}
                                            medication={med as PackageMedInstruction}
                                            index={index}
                                            key={`${med.type_id}_${med.id}`}
                                            hiddenSectionClass={collapseSection['M'] ? 'hidden-section' : ''}
                                            fromEstimate={fromEstimate}
                                            durationHours={durationHours}
                                            setActiveLineItem={setActiveLineItem}
                                            activeLineItem={activeLineItem}
                                        />
                                    ))}
                                </>
                            )}

                            {packageItemsGrouped['F']?.length && (
                                <>
                                    <section className='grid-table__full-width-row'>
                                        <div
                                            className='instruction-type-section'
                                            onClick={() => setCollapseSection((oldState) => ({ ...oldState, F: !oldState['F'] }))}
                                        >
                                            {collapseSection['F'] ? <DownOutlined /> : <UpOutlined />}
                                            <span>Fluids</span>
                                        </div>
                                    </section>

                                    {packageItemsGrouped['F']?.map((fluid, index) => (
                                        <FluidsForm
                                            fluid={fluid as PackageFluidInstruction}
                                            form={form}
                                            index={index}
                                            fromEstimate={fromEstimate}
                                            hiddenSectionClass={collapseSection['F'] ? 'hidden-section' : ''}
                                            key={`${fluid.type_id}_${fluid.id}`}
                                            setActiveLineItem={setActiveLineItem}
                                            activeLineItem={activeLineItem}
                                        />
                                    ))}
                                </>
                            )}

                            {packageItemsGrouped['OT']?.length && (
                                <>
                                    <section className='grid-table__full-width-row'>
                                        <div
                                            className='instruction-type-section'
                                            onClick={() => setCollapseSection((oldState) => ({ ...oldState, OT: !oldState['OT'] }))}
                                        >
                                            {collapseSection['OT'] ? <DownOutlined /> : <UpOutlined />}
                                            <span>Oxygen therapy</span>
                                        </div>
                                    </section>

                                    {packageItemsGrouped['OT']?.map((fluid, index) => (
                                        <OxygenTherapyForm
                                            fluid={fluid as PackageFluidInstruction}
                                            form={form}
                                            index={index}
                                            fromEstimate={fromEstimate}
                                            hiddenSectionClass={collapseSection['OT'] ? 'hidden-section' : ''}
                                            key={`${fluid.type_id}_${fluid.id}`}
                                            setActiveLineItem={setActiveLineItem}
                                            activeLineItem={activeLineItem}
                                        />
                                    ))}
                                </>
                            )}

                            {packageItemsGrouped['T']?.length && (
                                <>
                                    <section className='grid-table__full-width-row'>
                                        <div
                                            className='instruction-type-section'
                                            onClick={() => setCollapseSection((oldState) => ({ ...oldState, T: !oldState['T'] }))}
                                        >
                                            {collapseSection['T'] ? <DownOutlined /> : <UpOutlined />}
                                            <span>Tasks</span>
                                        </div>
                                    </section>

                                    {packageItemsGrouped['T']?.map((task, index) => (
                                        <TasksForm
                                            form={form}
                                            task={task as PackageTaskInstruction}
                                            index={index}
                                            key={`${task.type_id}_${task.id}`}
                                            hiddenSectionClass={collapseSection['T'] ? 'hidden-section' : ''}
                                            fromEstimate={fromEstimate}
                                            durationHours={durationHours}
                                            setActiveLineItem={setActiveLineItem}
                                            activeLineItem={activeLineItem}
                                        />
                                    ))}
                                </>
                            )}

                            {packageItemsGrouped['N']?.length && (
                                <>
                                    <section className='grid-table__full-width-row'>
                                        <div
                                            className='instruction-type-section'
                                            onClick={() => setCollapseSection((oldState) => ({ ...oldState, N: !oldState['N'] }))}
                                        >
                                            {collapseSection['N'] ? <DownOutlined /> : <UpOutlined />}
                                            <span>Non-Medicals</span>
                                        </div>
                                    </section>

                                    {packageItemsGrouped['N']?.map((nonMed, index) => (
                                        <NonMedicalsForm
                                            form={form}
                                            nonMed={nonMed as PackageNonMedInstruction}
                                            index={index}
                                            key={`${nonMed.type_id}_${nonMed.id}`}
                                            hiddenSectionClass={collapseSection['N'] ? 'hidden-section' : ''}
                                            fromEstimate={fromEstimate}
                                            setActiveLineItem={setActiveLineItem}
                                            activeLineItem={activeLineItem}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </Modal>
    );
};

export default forwardRef(PackageFormModal);
