import { Modal, Row, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FormWrapper } from 'components/forms/FormWrapper';
import { EstimateFormFields } from 'utils/types/billingTypes';
import {
    BaseSearchOption,
    isInstanceOfPackageSearchOption,
} from 'utils/types/InstructionOrderTypes';

interface EstimateItemModalProps {
    searchOption: BaseSearchOption | undefined;
    formJSX: JSX.Element;
    setSearchOptions: React.Dispatch<
        React.SetStateAction<BaseSearchOption | undefined>
    >;
    onSubmit: Function;
}
export const EstimateItemModal = ({
    formJSX,
    searchOption,
    setSearchOptions,
    onSubmit,
}: EstimateItemModalProps) => {
    const [form] = useForm();
    const isPackage = isInstanceOfPackageSearchOption(searchOption);
    let formContent = <></>;
    if (isPackage) {
        return null;
    } else {
        formContent = (
            <FormWrapper
                form={form}
                getFormData={form.getFieldsValue}
                onFormChange={form.setFieldsValue}
                onFinish={(incomingEstimate: EstimateFormFields) => {
                    onSubmit(incomingEstimate, searchOption);
                }}
                onFinishFailed={function (values: any): void {
                    console.error('Function not implemented.');
                }}
                bottomBar={
                    <Row justify='end' style={{ width: '100%' }}>
                        <Button
                            type='primary'
                            htmlType='submit'
                            style={{ marginTop: '12px' }}
                        >
                            {'Submit'}
                        </Button>
                    </Row>
                }
            >
                {formJSX}
            </FormWrapper>
        );
    }
    return (
        <Modal
            title={searchOption?.name ?? ''}
            visible={searchOption !== undefined}
            onCancel={() => setSearchOptions(undefined)}
            destroyOnClose={true}
            footer={null}
            centered={true}
            width='50%'
            focusTriggerAfterClose={false}
        >
            <div style={{ margin: '10px' }}>{formContent}</div>
        </Modal>
    );
};
