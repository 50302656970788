import { Button, Col, Modal, Radio, Row, Typography, message } from 'antd';
import CollapseContent from 'components/CollapseContent';
import { Ledger } from 'components/lib';
import { useEffect, useState } from 'react';
import {
    useGetBillingDataQuery,
    useGetLedgerByVisitIdQuery,
    useGetStripePaymentErrorsByVisitIdQuery,
    useGetVisitDiscountQuery,
    useLazyDownloadInvoiceQuery,
} from 'services/billingService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { ExpandedPatient, PatientRecordVisit } from 'utils/dataTypes';
import { formatCurrency } from 'utils/formatFuncs';
import { convertBasisToDecimal, getLedgerValues } from 'utils/miscFuncs';
import { Billing, BillingDisplayType } from 'utils/types/billingTypes';
import AdditionalServicesTable from './AdditionalServicesTable';
import { AttributionSwap } from './AttributionSwap';
import { BillingTable } from './BillingTable';
import DeleteRevenue from './DeleteRevenue';
import { DonationsTable } from './DonationsTable';
import { MarkItemAsFree } from './MarkItemAsFree';
import { PaymentsTable } from './PaymentsTable';
import './billing.css';
import { CheckCircleOutlined, ExclamationCircleOutlined, StopOutlined } from '@ant-design/icons';
import { CheckoutDrawer } from 'components/CheckoutDrawer';
import { useAppSelector } from 'hooks/useRedux';
import { useGetTaxForMultipleEstimatesQuery } from 'services/estimateService';
import { useTransitionStatusMutation, useGetDischargeTogoMedsByVisitIdQuery } from 'services/visitService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDischargePatientInstructionsMutation, useGetInstructionsByVisitIdQuery } from 'services/instructionService';

interface BillingTabProps {
    visitId: number;
    currentVisit: PatientRecordVisit;
    patientData: ExpandedPatient | undefined;
    isActive: boolean;
}


export const BillingTab = (props: BillingTabProps) => {
    const [isVisibleRefundModal, setIsVisibleRefundModal] = useState(false);
    const [isCheckoutDrawerOpen, setIsCheckoutDrawerOpen] = useState(false);
    const paymentModalOpen = useAppSelector((state) => state.visitHeaderSlice.paymentModalOpen);

    const { visitId, isActive, currentVisit } = props;

    const [pollingInterval, setPollingInterval] = useState(60000);
    useEffect(() => {
        if ( !isVisibleRefundModal && !paymentModalOpen && !isCheckoutDrawerOpen && isActive){
            setPollingInterval(60000)
        } else {
            setPollingInterval(0)
        }
    }, [isVisibleRefundModal, paymentModalOpen, isCheckoutDrawerOpen, isActive])

    const [isRefresh, setIsRefresh] = useState(false);
    const [billingData, setBillingData] = useState<undefined | Billing>(undefined);
    
    const { data: localBillingData, refetch: refetchBillingData, isFetching: isFetchingBillingData, isSuccess: isSuccessBillingData  } = useGetBillingDataQuery(
        {visitId, isRefresh},
        { pollingInterval }
    );
    const { data: ledgerItems, refetch: refetchLedgerByVisit } = useGetLedgerByVisitIdQuery(
        visitId,
        { pollingInterval },
    );
    const { data: stripePaymentErrorItems, refetch: refetchStripePaymentErrorsByVisit } = useGetStripePaymentErrorsByVisitIdQuery(
        visitId,
        { pollingInterval },
    );
    const { data: visitDiscount } = useGetVisitDiscountQuery(
        visitId,
        { ...BASE_QUERY_OPTIONS },
    );
    const {refetch: refetchTaxes } = useGetTaxForMultipleEstimatesQuery(visitId, { pollingInterval: 120000 });
    const { data: instData } = useGetInstructionsByVisitIdQuery({ visitId });
    const criAndFluidInstructions = instData?.filter(instruction => instruction.type_id === 'C' || instruction.type_id === 'F' || instruction.type_id === 'OT') ?? [];
    const [discontinuePatientInstructions] = useDischargePatientInstructionsMutation();
    const discontinueCriAndFluidInstructions = () => {
        if (props.currentVisit?.id) {
            discontinuePatientInstructions({
                visitId,
                ids: criAndFluidInstructions?.filter(instruction => instruction.discontinued_at === null).map((instruction) => instruction.id) ?? [],
            }).then((response) => {
                if ('data' in response) {
                    refetchBillingData();
                    refetchLedgerByVisit();
                    refetchTaxes();
                }
            });
        }
    };



    const {
        data: togoMeds,
        refetch: refetchGetDischargeTogoMedsByVisitId,
    } = useGetDischargeTogoMedsByVisitIdQuery(visitId);


    useEffect(() => {
        if (isSuccessBillingData && !isFetchingBillingData) {
            if (localBillingData?.refresh) {
                setIsRefresh(true);
                setBillingData(localBillingData);
            }
        }
    }, [localBillingData, isSuccessBillingData, isFetchingBillingData]);


    // Todo: fix this, currently since the instructions and billing are on separate apis we can't automatically update the tags between them.
    // so its required for us to refetch the data every time the tab is opened
    useEffect(() => {
        setIsRefresh(false);
        setBillingData(undefined);
        if (isActive) {
            refetchBillingData();
            refetchLedgerByVisit();
            refetchStripePaymentErrorsByVisit();
            refetchTaxes();
            refetchGetDischargeTogoMedsByVisitId();
        }
    }, [isActive])

    const [selected, setSelected] = useState<number[]>([]);
    // Refetch on refund modal open to keep data consistent 
    useEffect(() => {
        if (isVisibleRefundModal) {
            refetchBillingData();
            refetchLedgerByVisit();
        }
    }, [isVisibleRefundModal])

    const [billingType, setBillingType] = useState<BillingDisplayType>('order');

    const [downloadInvoice, { isFetching: downloadingInvoice }] = useLazyDownloadInvoiceQuery();

    const { due } = getLedgerValues(
        billingData?.subtotal,
        billingData?.amount_paid,
        billingData?.tax,
        visitDiscount?.basis,
        visitDiscount?.cap_amount_cents,
    )
    const donations = (ledgerItems || []).reduce((total, ledgerItem) => total + convertBasisToDecimal(ledgerItem.donation_basis) * ledgerItem.amount_cents, 0);

    const isVisitFinalized = Boolean(currentVisit?.finalized_at);
    
    const getIcon = () => {
        if (currentVisit.status === 'checked_out') {
            return <CheckCircleOutlined style={{color: 'var(--green-alert-success)'}} />;
        }
        if (currentVisit.status === 'discharged') {
            return <ExclamationCircleOutlined style={{color: 'var(--veg-secondary-red)'}} />;
        }
        return <ExclamationCircleOutlined />;
    };

    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id ?? skipToken, { skip: !currentVisit?.pet_id });

    const [transitionStatus] = useTransitionStatusMutation();

    const doCheckout = () => {
        transitionStatus({ visitId, status: 'checked_out' }).then((response) => {
            if ('data' in response) {
                message.success('Patient status has been updated to Checked Out.');
                setIsCheckoutDrawerOpen(false);
            } else {
                message.error('There was an error checking out the visit.');
            }
        });
    }

    return (<div>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <Typography.Text
                        style={{
                            fontSize: '20px',
                            fontWeight: 500,
                            lineHeight: '28px',
                        }}
                    >
                        Billing
                    </Typography.Text>

                            <Row style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                <Col>
                    <span>
                        {due <= 0 ? 'balance' : 'balance due'}
                    </span>
                    <span
                        className='billing-currency-display'
                        data-cy={'billingBalance'}
                        style={{ color: due === 0 ? 'inherit' : due < 0 ? 'var(--veg-green)' : 'var(--veg-red)' }}
                    >
                        {formatCurrency(Math.abs(due))} {due < 0 ? 'CR' : ''}
                    </span>
                </Col>

                <Col style={{ alignSelf: 'flex-end', marginTop: '24px' }}>
                    <Button
                        className='generate-invoice-button'
                        onClick={() => {
                            downloadInvoice({
                                visitId,
                            });
                        }}
                        loading={downloadingInvoice}
                        disabled={downloadingInvoice}
                    >
                        Generate Invoice
                    </Button>
                    <Button
                        disabled={
                            (currentVisit.finalized_at && currentVisit.status !== 'checked_out') ||
                            (currentVisit.status !== 'discharged' && currentVisit.status !== 'checked_out')
                        }
                        icon={getIcon()}
                        onClick={() => {
                            // referral source is the only requirement for checkout
                            // if patient is deceased bypass it and checkout right away
                            if (!!patientData?.deceased_at) {
                                doCheckout();
                            } else {
                                setIsCheckoutDrawerOpen(true);
                            }
                        }}
                        style={{marginLeft: '4px'}}
                        data-cy={'checkoutButton'}
                    >
                        Checkout
                    </Button>
                </Col>
            </Row>
        </Row>

        <CollapseContent Title='Services' isExpandedDefault>
            <>
                <Row style={{ marginBottom: "8px" }}>
                    <Radio.Group
                        onChange={(e) => setBillingType(e.target.value)}
                        className="billing-buttons"
                        defaultValue={billingType}
                    >
                        <Radio.Button value="order">Grouped By Order</Radio.Button>
                        <Radio.Button value="doctor">Grouped By Doctor</Radio.Button>
                    </Radio.Group>

                    <AttributionSwap
                        selected={selected}
                        billingItems={billingData?.billing_items || []}
                        setSelected={setSelected}
                        visitId={visitId}
                        visitFinalizedAt={currentVisit.finalized_at}
                    />
                    <MarkItemAsFree 
                        selected={selected}
                        billingItems={billingData?.billing_items || []}
                        setSelected={setSelected}
                        visitId={visitId}
                    />
                    <DeleteRevenue
                        isVisitFinalized={isVisitFinalized}
                        billingItems={billingData?.billing_items || []}
                        selected={selected}
                        setSelected={setSelected}
                    />
                    {criAndFluidInstructions.some(instruction => instruction.discontinued_at === null) &&
                        <Button
                            style={{marginLeft: 'var(--spacing-sm)'}}
                            icon={<StopOutlined />}
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Discontinue All CRI, Fluids, and Oxygen',
                                    content: (
                                        <>
                                            <p>
                                                CRIs, Fluids and Oxygen are automatically billed at an hourly rate.
                                            </p>
                                            <p>
                                                In the case of euthanasia and to get an accurate final invoice amount, all CRI, fluid and oxygen orders should be discontinued.
                                            </p>
                                        </>
                                    ),
                                    okText: 'Discontinue All',
                                    centered: true,
                                    maskClosable: true,
                                    onOk: discontinueCriAndFluidInstructions,
                                });
                            }}
                        >
                            Stop continuous orders
                        </Button>
                    }
                </Row>

                <BillingTable
                    billingItems={billingData?.billing_items?.filter(bi => bi.unit_price_cents > 0 || bi.type_id === 'M') || []}
                    isFetching={isFetchingBillingData}
                    type={billingType}
                    selected={selected}
                    setSelected={setSelected}
                />
            </>
        </CollapseContent>

        <CollapseContent Title='Additional Services - No Cost'>
            <AdditionalServicesTable
                billingItems={billingData?.billing_items?.filter(bi => bi.unit_price_cents === 0 && bi.type_id !== 'M') || []}
            />
        </CollapseContent>

        <CollapseContent Title='Payments/Refunds'>
            <PaymentsTable
                visitId={visitId}
                ledgerItems={ledgerItems || []}
                stripePaymentErrorItems={stripePaymentErrorItems || []}
                isVisibleRefundModal={isVisibleRefundModal} 
                setIsVisibleRefundModal={setIsVisibleRefundModal}
                isFetchingBillingData={isFetchingBillingData}
                balanceDue={due}
                isVisitFinalized={isVisitFinalized}
            />
        </CollapseContent>

        <CollapseContent Title='Donations'>
            <DonationsTable
                visitId={visitId}
                ledgerItems={ledgerItems || []}
            />
        </CollapseContent>

        <Row justify="end">
            <Ledger
                subtotal={billingData?.subtotal ?? 0}
                paid={billingData?.amount_paid ?? 0}
                tax={billingData?.tax ?? 0}
                donations={donations}
                visitDiscount={visitDiscount}
                type="billing"
                visitId={visitId}
            />
        </Row>
        <CheckoutDrawer toGoMeds={togoMeds ?? []} isOpen={isCheckoutDrawerOpen} setIsOpen={setIsCheckoutDrawerOpen} doCheckout={doCheckout} />
    </div>)
}
