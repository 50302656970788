import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Divider, Form, Modal, Select, message } from 'antd';
import { useDebounce } from 'hooks/useDebounce';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetHospitalByIdQuery } from 'services/hospitalService';
import { useAttachRdvmToPatientMutation, useSearchRdvmQuery } from 'services/rdvmService';
import { useGetVisitByIdQuery, useModifyVisitMutation } from 'services/visitService';
import { MY_VET_IS_NOT_ON_THE_LIST, US_STATES_MAP } from 'utils/constants';
import { RDVM } from '../../../utils/dataTypes';
import './AttachRdvm.css';
import { RdvmCustomData } from './RdvmCustomData';

interface AttachRdvmFormProps {
    pet_id: string;
    visit_id: number;
    current_rdvm: string;
    setPopoverState?: Function;
    currentRdvm?: string;
}

export const AttachRdvmForm = ({ pet_id, visit_id, current_rdvm, setPopoverState, currentRdvm }: AttachRdvmFormProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [rdvmSearch, setRdvmSearch] = useState('');
    const [selectedRdvm, setSelectedRdvm] = useState<RDVM | undefined>();

    const [form] = Form.useForm();

    const [addRdvmToPet, { isLoading: loadingAddRdvmToPet }] = useAttachRdvmToPatientMutation();
    const [modifyVisit, { isLoading: loadingModifyVisit }] = useModifyVisitMutation();

    const { data: currentVisit } = useGetVisitByIdQuery(visit_id);
    const { data: rdvms } = useSearchRdvmQuery(currentVisit?.id ?? null);
    const { data: hospital } = useGetHospitalByIdQuery(currentVisit?.hospital_id ?? skipToken);

    useEffect(() => {
        setSelectedRdvm(rdvmById(current_rdvm));
    }, [current_rdvm]);

    const rdvmById = (rdvm_id: string) => rdvms?.find((item) => item.clinic_id === rdvm_id);

    const rdvmRequiresCustomData = (rdvm?: RDVM) => rdvm?.display_name === MY_VET_IS_NOT_ON_THE_LIST;

    const allowModal = !rdvmRequiresCustomData(rdvmById(current_rdvm));

    const rdvmOptions = useMemo(() => {
        const regex = new RegExp(rdvmSearch.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return _.groupBy(
            rdvms?.filter((rdvm) => 
                (rdvm.is_active &&
                (rdvmSearch ? (rdvm.display_name.match(regex) || rdvm.section_group === 0) : true))
            ),
            'section_group',
        );
    }, [rdvms, rdvmSearch]);

    const submit = (values?: any) => {
        if (selectedRdvm) {
            const override_display_name = values ? values.override_display_name || '' : null;
            const override_email = values ? values.override_email || '' : null;

            //TODO conditionally add this relationship
            addRdvmToPet({
                clinic_id: selectedRdvm.clinic_id,
                pet_id: pet_id,
            });

            //Attach rDVM to this visit
            modifyVisit({
                visitId: visit_id,
                body: {
                    rdvm_id: selectedRdvm.clinic_id,
                    owner_id: currentVisit?.customer_id || null,
                    discharge_summary_location: null,
                    recheck_time: currentVisit?.recheck_time || null,
                    override_display_name: override_display_name,
                    override_email: override_email,
                },
            })
                .unwrap()
                .then(() => message.success('rDVM info updated'));

            if (setPopoverState) {
                setPopoverState(false);
            }
        }
    };

    const optionsMapping = (rdvm: RDVM) => ({ value: rdvm.clinic_id, label: rdvm.display_name });

    const getNearbyLocations = useCallback(() => {
        return rdvmOptions?.[1]
            ? [
                  {
                      label: (
                          <>
                              <Divider className='rdvm-options-divider' />
                              <span className='rdvm-options-title'>Nearby locations</span>
                          </>
                      ),
                      options: rdvmOptions?.[1]?.map(optionsMapping) ?? [],
                  },
              ]
            : [];
    }, [rdvmOptions]);

    const getMoreLocations = useCallback(() => {
        return rdvmOptions?.[2]
            ? [
                  {
                      label: (
                          <>
                              <Divider className='rdvm-options-divider' />
                              <div className='rdvm-options-title'>More locations</div>
                              <div className='rdvm-options-state'>{US_STATES_MAP[hospital?.state ?? '']}</div>
                          </>
                      ),
                      options: rdvmOptions?.[2]?.map(optionsMapping) ?? [],
                  },
              ]
            : [];
    }, [rdvmOptions, hospital?.state]);

    const getRdmvsGroupedByState = useCallback(() => {
        const formattedRdvmsWithStates = rdvmOptions?.[3]?.map((option) => ({ ...option, state: US_STATES_MAP[option.state] }));
        const rdvmsGroupByState = _.groupBy(_.orderBy(formattedRdvmsWithStates, 'state'), 'state');
        const arr = [];
        let index = 0;

        if (rdvmsGroupByState) {
            for (const [state, rdvms] of Object.entries(rdvmsGroupByState)) {
                arr.push({
                    label: (
                        <>
                            {index === 0 && <Divider className='rdvm-options-divider' />}
                            <span className='rdvm-options-state'>{state}</span>
                        </>
                    ),
                    options: rdvms.map(optionsMapping) ?? [],
                });

                index++;
            }
        }

        return arr;
    }, [rdvmOptions]);

    return (
        <div className='attach-rdvm-container'>
            <Form.Item name='clinic_id' label='rDVM:' data-cy='customerRdvm'>
                <Select
                    autoFocus
                    showSearch
                    onChange={(value: string) => {
                        setSelectedRdvm(rdvmById(value));
                    }}
                    onSearch={setRdvmSearch}
                    defaultValue={currentRdvm ?? current_rdvm}
                    filterOption={false}
                    options={
                        rdvmOptions && [
                            ...(rdvmOptions?.[0]?.map(optionsMapping) ?? []),
                            ...getNearbyLocations(),
                            ...getMoreLocations(),
                            ...getRdmvsGroupedByState(),
                        ]
                    }
                />
            </Form.Item>

            {allowModal && (
                <Modal
                    title={MY_VET_IS_NOT_ON_THE_LIST}
                    zIndex={1030}
                    visible={isModalVisible}
                    onOk={form.submit}
                    okText='Save'
                    okButtonProps={{ loading: loadingAddRdvmToPet || loadingModifyVisit }}
                    onCancel={() => setIsModalVisible(false)}
                >
                    <RdvmCustomData
                        form={form}
                        submit={submit}
                        override_display_name={selectedRdvm?.override_display_name}
                        override_email={selectedRdvm?.override_email}
                    />
                </Modal>
            )}

            {!allowModal && rdvmRequiresCustomData(selectedRdvm) && (
                <RdvmCustomData
                    form={form}
                    submit={submit}
                    override_display_name={selectedRdvm?.override_display_name}
                    override_email={selectedRdvm?.override_email}
                />
            )}

            <Button
                type='primary'
                className='self-end'
                disabled={!selectedRdvm}
                data-cy='formSubmitButton'
                onClick={() => {
                    if (rdvmRequiresCustomData(selectedRdvm)) {
                        if (allowModal) {
                            setIsModalVisible(true);
                        } else {
                            form.submit();
                        }
                    } else {
                        submit();
                    }
                }}
            >
                Save
            </Button>
        </div>
    );
};
