import { Button, Form, message } from 'antd';
import { Avatar, MarkdownNote } from 'components/lib';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import useIsMounted from 'hooks/useIsMounted';
import _ from 'lodash';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import {
    useCreateEditPatientHistoryCacheMutation,
    useModifyPatientHistoryMutation,
    useRemoveEditPatientHistoryCacheMutation,
} from 'services/visitService';
import { PatientHistory } from 'utils/dataTypes';

interface PatientHistoryNoteProps {
    editPatientHistory?: PatientHistory;
    composeBoxId?: string;
    isInDrawer?: boolean;
    rowsNoteEditor?: number;
}

interface AddPatientHistoryNoteRef {
	submitPatientHistoryNote: () => void;
}


const PatientHistoryNote: React.ForwardRefRenderFunction<AddPatientHistoryNoteRef, PatientHistoryNoteProps> = ({ composeBoxId, editPatientHistory, isInDrawer, rowsNoteEditor }, ref) => {

    const [autosavedAt, setAutosavedAt] = useState<moment.Moment>();
    const [justAutosaved, setJustAutosaved] = useState(false);
    const [patientHistory, setPatientHistory] = useState<PatientHistory | undefined>(editPatientHistory);

    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const { boxState, removeComposeBox } = useComposeBoxContext();
    const getIsMounted = useIsMounted();

    const [form] = Form.useForm();

    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const [modifyPatientHistory, { isLoading: loadingModifyPatientHistory }] = useModifyPatientHistoryMutation();
    const [createEditPatientHistoryCache] = useCreateEditPatientHistoryCacheMutation();
    const [removeEditPatientHistoryCache] = useRemoveEditPatientHistoryCacheMutation();

    useEffect(() => {
        if (patientHistory) {
            createEditPatientHistoryCache({ visitId });
        }
    }, []);

    useEffect(() => {
        return () => {
            if (!getIsMounted() && patientHistory) {
                removeEditPatientHistoryCache({ visitId });
            }
        };
    }, [patientHistory, getIsMounted]);

    useEffect(() => {
		const handleClose = (event: any) => {
			event.preventDefault();

			if (patientHistory) {
				removeEditPatientHistoryCache({ visitId });
			}

			return (event.returnValue = '');
		};

		window.addEventListener('beforeunload', handleClose);

		return () => {
			window.removeEventListener('beforeunload', handleClose);
		};
	}, [patientHistory]);

    useImperativeHandle(ref, () => ({
		submitPatientHistoryNote: () => form.submit()
    }));

    const autoSaveFunction = useMemo(
        () =>
            _.debounce(() => {
                form.validateFields().then(() => {
                    handleFinish(form.getFieldsValue(), true);
                });
            }, 3000),
        [],
    );

    const handleFinish = (values: any, autosave?: boolean) => {
        modifyPatientHistory({ content: values.note, visitId })
            .unwrap()
            .then((patientHistory: PatientHistory) => {
                setPatientHistory(patientHistory);

                if (autosave) {
                    setAutosavedAt(moment());
                    message.success('Patient history successfully autosaved.');
                    setJustAutosaved(true);
                    _.delay(() => {
                        setJustAutosaved(false);
                    }, 1000);
                } else {
                    removeCurrentComposeBox();
                    message.success('Patient history successfully saved.');
                }
            });
    };

    const removeCurrentComposeBox = () => {
        if (composeBoxId) {
            const boxItem = boxState.find((box) => box.id === composeBoxId);

            if (!!boxItem) {
                removeComposeBox(boxItem.slot);
            }
        }
    };

    return (
        <Form form={form} onFinish={handleFinish}>
            <MarkdownNote
                fieldName={'note'}
                content={patientHistory?.content}
                getFormData={form.getFieldsValue}
                onFormChange={(values) => {
                    form.setFieldsValue(values);

                    if (!!values.note && values.note !== patientHistory?.content) {
                        autoSaveFunction();
                    }
                }}
                rowsNoteEditor={rowsNoteEditor}
            />

            <div className='note__submit-container'>
                {autosavedAt && (
                    <>
                        <Avatar
                            firstName={loggedInUserData?.first_name ?? ''}
                            lastName={loggedInUserData?.last_name ?? ''}
                            className={loggedInUserData?.role_name === 'Nurse' ? 'nurse-avatar' : undefined}
                        />
                        <span
                            style={{
                                color: justAutosaved ? 'var(--veg-secondary-blue)' : 'inherit',
                                fontWeight: justAutosaved ? 600 : 'inherit',
                            }}
                        >
                            Autosaved {moment(autosavedAt).format('YYYY-MM-DD, hh:mm a')}
                        </span>
                    </>
                )}
                {!isInDrawer && (
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldsError }) => (
                            <Button
                                disabled={getFieldsError().filter(({ errors }) => errors.length).length > 0 || loadingModifyPatientHistory}
                                loading={loadingModifyPatientHistory}
                                type='primary'
                                onClick={form.submit}
                            >
                                Submit
                            </Button>
                        )}
                    </Form.Item>
                )}
            </div>
        </Form>
    );
};

export default forwardRef(PatientHistoryNote);
