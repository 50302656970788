import React from 'react';
import './VisitStatusTag.css';

interface VisitStatusTagProps {
    estimateStatus: string | null;
    doctorReview: number | null;
}

const VisitStatusTag: React.FC<VisitStatusTagProps> = ({ doctorReview, estimateStatus }) => {
    if (doctorReview) {
        return <span className='visit-status-tag doctor-review-tag'>Dr Review</span>;
    }

    if (estimateStatus) {
        return <span className={`visit-status-tag estimate-status-tag--${estimateStatus}`}>{estimateStatus.replace('_', ' ')}</span>;
    }

    return null;
};

export default VisitStatusTag;
