import { EditOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { CustomerInfoForm } from 'components/forms/CustomerInfoForm';
import { MarkdownNote } from 'components/lib';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import remarkBreaks from 'remark-breaks';
import { useGetCustomerByIdQuery, useModifyCustomerMutation } from 'services/admissionService';
import { BIRTHDAY_FORMAT, FormName } from 'utils/constants';
import { formatPhoneNumber } from 'utils/formatFuncs';
import { ComposeBoxContainer } from 'views/visit/ComposeBoxContainer';
import './CustomerSidebar.css';

const CustomerSidebar = () => {
    const { customerId } = useParams<{ customerId: string }>();

    const { data: customer } = useGetCustomerByIdQuery(customerId, { skip: !customerId });
    const [modifyCustomer] = useModifyCustomerMutation();
    const { addComposeBox } = useComposeBoxContext();

    const onFinishForm = (values: any, formName: FormName) => {
        if (formName === FormName.owner_info && customer) {
            return modifyCustomer({
                customer_id: customer.customer_id,
                body: {
                    ...values,
                    note: customer.note || null,
                    how_did_you_hear_about_us: customer.how_did_you_hear_about_us || null,
                    additional_info: customer.additional_info || null,
                    birthday: values.birthday ? values.birthday.format(BIRTHDAY_FORMAT) : null,
                    unable_to_collect_birthday: values.birthday ? false : customer.unable_to_collect_birthday,
                },
            }).unwrap()
                .then(() => 'Customer has been successfully updated.');
        }

        if (formName === FormName.owner_note && customer) {
            const { customer_id, visit_count, pets, ...body } = customer;
            modifyCustomer({
                customer_id: customer_id,
                body: {
                    ...body,
                    note: values.note || null,
                },
            }).then((res) => {
                if ('data' in res) {
                    message.success('Customer note has been successfully edited.');
                } else {
                    message.error('There was an error editing the customer note.');
                }
            });
        }
        return Promise.resolve();
    };

    return (
        <section className='customer-sidebar'>
            <section className='customer-sidebar__customer-name'>
                <div>
                    {customer?.first_name}
                    <Button
                        className='customer-sidebar__edit-button'
                        icon={<EditOutlined />}
                        onClick={() =>
                            addComposeBox({
                                formName: FormName['owner_info'],
                                content: <CustomerInfoForm customer={customer} />,
                                id: FormName['owner_info'],
                            })
                        }
                    />
                </div>
                <div>{customer?.last_name}</div>
            </section>

            <section className='customer-sidebar__customer-info'>
                {customer?.phone_number && <div>{formatPhoneNumber(customer.phone_number)}</div>}
                {customer?.email && <div>{customer.email}</div>}

                <div className='customer-sidebar__customer-address'>
                    <div>{customer?.street}</div>
                    <div>
                        {customer?.city} {customer?.state} {customer?.zipcode}
                    </div>
                </div>
                {customer?.birthday &&
                    <div className='customer-sidebar__customer-birthday'>
                        Customer Birthdate: {customer?.birthday}
                    </div>
                }
            </section>

            <section className='customer-sidebar__customer-note'>
                <div>
                    <span className='customer-sidebar__section-title'>Note</span>
                    <Button
                        className='customer-sidebar__edit-button'
                        icon={<EditOutlined />}
                        onClick={() =>
                            addComposeBox({
                                formName: FormName['owner_note'],
                                content: <MarkdownNote fieldName={'note'} content={customer?.note ?? ''} />,
                                id: FormName['owner_note'],
                            })
                        }
                    />
                </div>
                <div>
                    <Markdown
                        remarkPlugins={[remarkBreaks]}
                        children={customer?.note ?? ''}
                        components={{
                            p: 'span',
                        }}
                    />
                </div>
            </section>

            <ComposeBoxContainer onFinish={onFinishForm} />
        </section>
    );
};

export default CustomerSidebar;
