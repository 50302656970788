import { calculateTotalCost } from 'utils/miscFuncs';
import { BillingItem, BillingItemDisplay } from 'utils/types/billingTypes';

export const getBillingItemsDisplay = (billingItems: BillingItem[]): BillingItemDisplay[] => {
    return billingItems.map((billingItem) => ({
        ...billingItem,
        instructionName: billingItem.name,
        end_time: null,
        price: calculateTotalCost(billingItem),
        earned_revenue_line_items: billingItem.earned_revenue_line_items.map((earnedRevenueItem) => ({
            ...earnedRevenueItem,
            instructionName: billingItem.name,
            first_name: billingItem.doctor_first_name,
            last_name: billingItem.doctor_last_name,
            type_id: billingItem.type_id,
            instruction_start_time: billingItem.start_time,
            why: billingItem.why,
            why_other: billingItem.why_other,
            reason: billingItem.reason,
        })),
    }));
};
