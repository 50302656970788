import { Button, Form, Select } from 'antd';
import { useState } from 'react';
import { useGetUserDataQuery, useLogoutMutation, useSelectHospitalMutation } from 'services/authService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import HospitalSelectionStyles from './hospitalSelection.module.css';

const { Option } = Select;

interface HospitalSelectionPageProps { }

export const HospitalSelectionPage: React.FC<HospitalSelectionPageProps> = () => {
    const {
        data: loggedInUserData,
        isFetching: isUserFetching,
        isLoading: isUserLoading
    } = useGetUserDataQuery(
        null,
        BASE_QUERY_OPTIONS,
    );
    const [logout] = useLogoutMutation();
    const [selectHospital, { isLoading: isSelectionLoading }] = useSelectHospitalMutation();
    const [hospitalId, setHospitalId] = useState(-1);

    return (
        <div className={HospitalSelectionStyles.wrapper}>
            <p data-cy="loggedInMessage">Logged in as {loggedInUserData?.email} </p>
            <a data-cy="logOutMessage" href="https://veg.okta.com" target="_self">Not you? Log out</a>
            {loggedInUserData?.allowed_hospitals && Object.keys(loggedInUserData?.allowed_hospitals).length === 0 ?
                <p className={HospitalSelectionStyles.errorMessage}>
                    You have no assigned hospital. Please reach out to your administrator
                </p>
                :
                <Form onFinish={() => { selectHospital({ hospitalId }) }}>
                    <Form.Item name="hospital">
                        <Select
                            data-cy= "hospitalSelectionDropdown"
                            style={{ width: 300 }}
                            onChange={(value: number) => setHospitalId(value)}
                            showSearch
                            placeholder="Select a hospital"
                            optionFilterProp="children"
                            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        // Sort is only available in antd version 4.9.0
                        // filterSort={(optionA, optionB) =>
                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        // }
                        >
                            {loggedInUserData?.allowed_hospitals ? Object.keys(loggedInUserData?.allowed_hospitals).map(
                                (obj) => <Option key={obj} value={loggedInUserData?.allowed_hospitals[obj]}>{obj}</Option>
                            ) : null}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            data-cy="submitHospitalButton"
                            loading={isSelectionLoading || isUserLoading || isUserFetching}
                        >
                            Continue
                        </Button>
                    </Form.Item>
                </Form>
            }
        </div>
    );
};
