import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, FormInstance, Modal, Row, Typography, message } from 'antd';
import { useState } from 'react';
import { FormName } from 'utils/constants';
import { FormWrapper } from 'components/forms/FormWrapper';
import { ConfirmModal } from 'components/Modals/ConfirmModal';
import { ComposeBoxState } from 'hooks/ComposeBoxProvider';
import {confirmDeceasedModalContent} from 'components/Modals/ConfirmModalContent';
import { ReconfirmModal } from 'components/Modals/ReconfirmModal';
import { reconfirmDeceasedModalContent } from 'components/Modals/ReconfirmModalContent';
import { useGetInstructionsByVisitIdQuery } from 'services/instructionService';
import { useGetAllConsentDocumentsQuery } from 'services/visitService';
import { useParams } from 'react-router-dom';
import { isInstanceOfMedicineInstruction } from '../../utils/dataTypes';

/**
 * "Dumb" component for ComposeBoxes. Almost entirely just "view"
 * Can toggle mini/maxi and "remove"
 * @param ComposeBoxState
 * @returns JSX.Element for one "ComposeBox"
 */
export const ComposeBox = (
	props: ComposeBoxState & {
		content: JSX.Element;
		bottomBar?: JSX.Element;
		title?: string;
		toggleMiniComposeBox: (id: number) => void;
		removeComposeBox: (id: number) => void;
		onFinish: (
			values: any,
			formName: FormName,
			additionalState?: {},
		) => Promise<any>;
	},
) => {
	const {
		toggleMiniComposeBox,
		removeComposeBox,
		slot,
		mini,
		content,
		onFinish,
		bottomBar,
	} = props;
	let drawerForm: FormInstance<any>
	let [defaultForm] = Form.useForm();
	if (props?.content?.props?.form){
		drawerForm = props?.content?.props?.form
	} else {
		drawerForm = defaultForm;
	}

	const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
	const [isReconfirmModalVisible, setIsReconfirmModalVisible] = useState(false);
	const [formValues, setFormValues] = useState({name: ""});
	const [confirmModalType, setConfirmModalType] = useState("");

	const confirmModalContent = confirmModalType === "deceased patient"
		? confirmDeceasedModalContent(formValues.name)
		: null
	
	const reconfirmModalContent = reconfirmDeceasedModalContent(formValues.name)

	const { urlVisitId } = useParams<{ urlVisitId: string }>();
	const visitId = parseInt(urlVisitId);

	const { data: instData } = useGetInstructionsByVisitIdQuery({ visitId });

	const deceasedMedication = instData?.some((instruction) => (
		isInstanceOfMedicineInstruction(instruction) && instruction?.required_before_discharge === true && instruction.actions.some((a) => a.status === 'complete') 
	));

	const { data: consentFormsList } = useGetAllConsentDocumentsQuery(visitId);
	const deceasedDocument = consentFormsList?.some((document) => (
		document?.status === "approved" && (document?.prefix_char === "a" || document?.prefix_char === "c")) 
	);

	const handleOk = () => {
		if (!!deceasedMedication || !!deceasedDocument) {
			onFinish(formValues, props.id, props.additionalState);
			drawerForm.resetFields();
			removeComposeBox(slot);
			setIsConfirmModalVisible(!isConfirmModalVisible)
			setFormValues({name: ""})
		} else {
			setIsConfirmModalVisible(!isConfirmModalVisible)
			setIsReconfirmModalVisible(!isReconfirmModalVisible)		
		}
	}

	const handleCancel = () => {
		drawerForm.resetFields();
		removeComposeBox(slot);
		setIsConfirmModalVisible(!isConfirmModalVisible)
		setFormValues({name: ""})
	}

	const handleReconfirmOk = () => {
		onFinish(formValues, props.id, props.additionalState);
		drawerForm.resetFields();
		removeComposeBox(slot);
		setIsReconfirmModalVisible(!isReconfirmModalVisible)
		setFormValues({name: ""})		
	}

	const handleReconfirmCancel = () => {
		drawerForm.resetFields();
		removeComposeBox(slot);
		setIsReconfirmModalVisible(!isReconfirmModalVisible)
		setFormValues({name: ""})
	}



	let drawerContent = content;

	let title = '';
	if(props?.title) {
		title = props.title;
	} else if (props?.content?.props?.options?.medication_name) {
		title = props?.content?.props?.options?.medication_name;
	} else if (props?.content?.props?.options?.name) {
		title = props?.content?.props?.options?.name;
	}

	const handleOnFinish = (values: any) => {
		onFinish(values, props.id, props.additionalState).then((result) => {
			drawerForm.resetFields();
			removeComposeBox(slot);
			if (typeof result === 'string') {
				message.success(result);
			}
		}).catch(e => {
			if (e.error_code === 'email_already_exists') {
				drawerForm.setFields([{
					name: 'email',
					errors: [e.msg],
				}])
			} else {
				message.error('Something went wrong');
			}
		});
	};

	if (props.formName && props.formName !== '') {
		drawerContent = (
			<FormWrapper
				onFormChange={drawerForm.setFieldsValue}
				getFormData={drawerForm.getFieldsValue}
				onFinishFailed={(errorInfo: any) => {
					console.error('Failed:', errorInfo);
				}}
				onFinish={(allValues: any) => {
					const {is_deceased_reverted, ...values} = allValues;
					if (props.formName === 'Patient Info' && is_deceased_reverted) {
						Modal.confirm({
							title: 'Remove Deceased Date',
							content: (
								<>
									<p>By removing the deceased date for <strong>{values.name}</strong> the following will happen...</p>
									<p>1. Require checkout requirements like <strong>referral source</strong> at checkout.</p>
									<p>2. Allow the <strong>survey</strong> and <strong>follow-up text</strong> to send at close.</p>
									
								</>
							),
							okText: 'Remove and Save',
							cancelText: 'Go Back',
							centered: true,
							maskClosable: true,
							onOk: () => {
								handleOnFinish(values);
							},
							onCancel: () => {
								props?.content?.props.cancelDeceasedReverted();                                         
							},
							className: 'remove-deceased-date-modal',
							zIndex: 1040,
						});
					}
					else if (props.formName === "Patient Info" && !!values.deceased_at && !!values.is_deceased) {
						setFormValues(values)
						setIsConfirmModalVisible(!isConfirmModalVisible)
						setConfirmModalType("deceased patient")
					} else {
						handleOnFinish(values);
					}
				}}
				form={drawerForm}
				bottomBar={bottomBar}
			>
				{content}
			</FormWrapper>
		);
	}

	return (
		<>
		<Drawer
			title={
				<ComposeTitle
					title={title}
					composeBoxState={props}
					toggleMiniComposeBox={toggleMiniComposeBox}
				/>
			}
			placement='bottom'
			closable={true}
			onClose={() => {
				if (props.formName === FormName.discharge_togo_med) {
					if (drawerForm.isFieldsTouched()) {
						Modal.confirm({
							title: "Unsaved Changes",
							centered: true,
							maskClosable: true,
							autoFocusButton: null,
							okText: 'Discard Changes',
							onOk: () => {
								removeComposeBox(slot);
							},
							content: (
								<>
									<Typography.Paragraph>
										You have unsaved changes. Select cancel to continue editing.
									</Typography.Paragraph>
								</>
							),
						});
						return;
					}
				}
				removeComposeBox(slot);
			}}
			visible={true}
			key={`form-${slot}`}
			mask={false}
			destroyOnClose={true}
			className={`drawer-${slot} ${mini ? 'mini' : 'open'}`}
			height='inherit'
		>
			{drawerContent}
		</Drawer>
		<ConfirmModal title={"Mark Patient as Deceased"} visible={isConfirmModalVisible} handleOk={handleOk} handleCancel={handleCancel} formValues={formValues}>
			{confirmModalContent}
		</ConfirmModal>
		<ReconfirmModal title={"Just Double Checking..."} visible={isReconfirmModalVisible} handleOk={handleReconfirmOk} handleCancel={handleReconfirmCancel}>
			{reconfirmModalContent}
		</ReconfirmModal>
		</>
	);
};

interface ComposeTitleProps {
	title?: string;
	composeBoxState: ComposeBoxState;
	toggleMiniComposeBox: (id: number) => void;
}
export const ComposeTitle = ({
	title,
	composeBoxState,
	toggleMiniComposeBox,
}: ComposeTitleProps) => {
	return (
		<Row
			onClick={() => {
				toggleMiniComposeBox(composeBoxState.slot);
			}}
		>
			<Col span={19}>{` ${
				title ? title : composeBoxState.formName
			}`}</Col>
			<Col>
				<Button type='primary' ghost>
					{composeBoxState.mini ? (
						<PlusOutlined />
					) : (
						<MinusOutlined />
					)}
				</Button>
			</Col>
		</Row>
	);
};
