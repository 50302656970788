import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { initializeForm } from 'hooks/useOnFormInitialize';
import moment from 'moment';
import { CSSProperties, useEffect } from 'react';
import { BIRTHDAY_FORMAT, SUPPORTED_PHONE_NUMBER_REGEX, SUPPORTED_STATE_REGEX, SUPPORTED_ZIPCODE_REGEX, US_STATES } from 'utils/constants';
import { ContactInfoRules } from 'utils/types/validations';
import { CustomerInfo } from '../../utils/dataTypes';
import { PhoneNumberField } from './fields/PhoneNumberField';

const formItemStyle: CSSProperties = {
    width: '100%',
};

interface CustomerInfoFormProps {
    customer?: CustomerInfo;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
}
export const CustomerInfoForm = (props: CustomerInfoFormProps) => {
    const onFormChange = props.onFormChange ?? (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));
    const dateIsInTheFuture = (currentDate: moment.Moment) => {
        return currentDate > moment();
    };
    const contactInfoRules: ContactInfoRules = {
        phone_number: [
            {
                required: true,
                type: 'string',
                // pattern: SUPPORTED_PHONE_NUMBER_REGEX,
            },
            {
                pattern: SUPPORTED_PHONE_NUMBER_REGEX,
                message: 'invalid phone number',
            },
        ],
        first_name: [
            {
                required: true,
                type: 'string',
            },
        ],
        last_name: [
            {
                type: 'string',
            },
        ],
        email: [
            {
                type: 'email',
            },
        ],
        street: [
            {
                type: 'string',
            },
        ],
        city: [
            {
                type: 'string',
            },
        ],
        state: [
            {
                type: 'string',
            },
            {
                pattern: SUPPORTED_STATE_REGEX,
                message: 'enter 2 digit state code',
            },
        ],
        zipcode: [
            {
                type: 'string',
                pattern: SUPPORTED_ZIPCODE_REGEX,
            },
            {
                pattern: SUPPORTED_ZIPCODE_REGEX,
                message: 'invalid zipcode',
            },
        ],
    };

    useEffect(() => {
        initializeForm(props.customer as CustomerInfo, onFormChange);
    }, []);

    return (
        <>
            <Row style={{ width: '100%' }}>
                <Col span={24}>
                    <PhoneNumberField
                        autoFocus
                        formItemProps={{
                            preserve: false,
                            name: 'phone_number',
                            label: 'Phone number',
                            style: formItemStyle,
                            labelCol: { span: 6 },
                            wrapperCol: { span: 18 },
                            rules: contactInfoRules.phone_number,
                        }}
                        onChange={(value: string | null) => {
                            onFormChange({ phone_number: value });
                        }}
                        dataCy='customerPhoneNumber'
                    />
                    <Form.Item
                        preserve={false}
                        name='first_name'
                        label='First name'
                        style={formItemStyle}
                        labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
                        wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={18})
                        rules={contactInfoRules.first_name}
                        data-cy='customerFirstName'
                    >
                        <Input autoComplete={'off'} />
                    </Form.Item>
                    <Form.Item
                        preserve={false}
                        name='last_name'
                        label='Last name'
                        style={formItemStyle}
                        labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
                        wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={18})
                        rules={contactInfoRules.last_name}
                        data-cy='customerLastName'
                    >
                        <Input autoComplete={'off'} />
                    </Form.Item>

                    <Form.Item
                        preserve={false}
                        name='email'
                        label='Email'
                        style={formItemStyle}
                        labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
                        wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={18})
                        rules={contactInfoRules.email}
                        data-cy='customerEmail'
                    >
                        <Input autoComplete={'off'} />
                    </Form.Item>
                    <Form.Item
                        preserve={false}
                        name='street'
                        label='Street'
                        style={formItemStyle}
                        labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
                        wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={18})
                        rules={contactInfoRules.street}
                        data-cy='customerStreet'
                    >
                        <Input autoComplete={'off'} />
                    </Form.Item>
                    <Form.Item
                        preserve={false}
                        name='city'
                        label='City'
                        style={formItemStyle}
                        labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
                        wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={18})
                        rules={contactInfoRules.city}
                        data-cy='customerCity'
                    >
                        <Input autoComplete={'off'} />
                    </Form.Item>
                    <Form.Item
                        preserve={false}
                        name='state'
                        label='State'
                        style={formItemStyle}
                        labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
                        wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={18})
                        rules={contactInfoRules.state}
                        data-cy='customerState'
                    >
                        <Select
                            showSearch
                            options={US_STATES.map(({ abbreviation }) => ({
                                label: abbreviation,
                                value: abbreviation,
                                key: abbreviation,
                            }))}
                            filterOption={(input, option) => option?.label?.toString()?.toUpperCase().indexOf(input.toUpperCase()) !== -1}
                        />
                    </Form.Item>
                    <Form.Item
                        preserve={false}
                        name='zipcode'
                        label='Zipcode'
                        style={formItemStyle}
                        labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
                        wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={18})
                        rules={contactInfoRules.zipcode}
                        data-cy='customerZipcode'
                    >
                        <Input autoComplete={'off'} maxLength={5} />
                    </Form.Item>
                    <Form.Item
                        preserve={false}
                        name='birthday'
                        label='Birthdate'
                        data-cy='customerBirthday'
                        style={formItemStyle}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                    >
                        <DatePicker
                            showTime={false}
                            format={BIRTHDAY_FORMAT}
                            style={{ width: '100%' }}
                            disabledDate={dateIsInTheFuture}
                            placeholder='0000-00-00'
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
