import { EditOutlined, EllipsisOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Dropdown, Menu, Row, Typography, message } from 'antd';
import { warningConfirm } from 'components/confirmModals';
import { useGetPimsUser } from 'hooks';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import remarkBreaks from 'remark-breaks';
import { useGetNoteFileURLQuery } from 'services/filesService';
import { useGetPatientByIdQuery } from 'services/patientService';
import {
    useDeleteProgressNoteMutation,
    useGetVisitByIdQuery,
    useValidateEditNoteMutation,
    useValidateEditPatientHistoryMutation,
} from 'services/visitService';
import { BaseNote } from 'utils/dataTypes';
import { NoteCategories } from 'utils/types/enums';
import NoteCategoryAvatar from './NoteCategoryAvatar';
import { NotesDrawerRef } from 'components/NotesDrawer';
import { FormName } from 'utils/constants';

interface ProgressNoteItemProps {
    note: BaseNote;
    characterOverflowLimit?: number;
    isFinalizedVisit?: boolean;
    openEditNotesDrawer?: NotesDrawerRef['openEditNotesDrawer'];
}

const NoteItem: React.FC<ProgressNoteItemProps> = ({ note, characterOverflowLimit = 2000, isFinalizedVisit, openEditNotesDrawer}) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);
    const [showAll, setShowAll] = useState(false);
    const [user] = useGetPimsUser(note.author_id);
    const doesContentOverflow = note.content.length > characterOverflowLimit;
    const disableEdit = isFinalizedVisit && note.category !== NoteCategories.ADDENDUM_NOTE;
    const { addComposeBox } = useComposeBoxContext();

    const { data: fileUrl, refetch: refetchFileURL } = useGetNoteFileURLQuery({
        visitId,
        noteId: note.id,
    });
    const { data: currentVisit } = useGetVisitByIdQuery(visitId, { skip: !visitId });
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id ?? '', { skip: !currentVisit?.pet_id });

    const [deleteProgressNote] = useDeleteProgressNoteMutation();
    const [validateEditNote] = useValidateEditNoteMutation();
    const [validateEditPatientHistory] = useValidateEditPatientHistoryMutation();

    const handleEdit = () => {
        const override = note.category === NoteCategories.ADDENDUM_NOTE;

        if (note.category === NoteCategories.PATIENT_HISTORY_NOTE) {
            validateEditPatientHistory({ visitId })
                .unwrap()
                .then(() => {
                    if (openEditNotesDrawer) {
                        openEditNotesDrawer({
                            formType: FormName['patient_history'],
                            editPatientHistory: note,
                        });
                    }
                })
                .catch((err) => {
                    if (err.originalStatus === 403) {
                        message.error(err.data);
                    }
                });
        } else {
            validateEditNote({ note_id: note.id })
                .unwrap()
                .then(() => {
                    if (openEditNotesDrawer) {
                        openEditNotesDrawer({ formType: note.category, noteData: note, userId: user.user_id });
                    }
                })
                .catch((err) => {
                    if (err.originalStatus === 403) {
                        message.error(err.data);
                    }
                });
        }
    };

    const handleDelete = () => {
        warningConfirm(
            {
                title: 'Remove Note',
                content: `Are you sure you want to remove this note from ${patientData?.name}'s visit?`,
                onOk: async () => {
                    const res = await deleteProgressNote({ visitId, id: note.id });

                    if ('data' in res) {
                        message.success('The note has been successfully removed.');
                    } else {
                        if ('originalStatus' in res.error && res.error.originalStatus === 403) {
                            message.error(res.error.data);
                        } else {
                            message.error('There was an error deleting the note.');
                        }
                    }
                },
            },
            { okText: 'Remove' },
        );
    };

    useEffect(() => {
        refetchFileURL();
    }, [note.file_name]);

    return (
        <li>
            <Card
                title={
                    <div className='treatment-sheet-metadata'>
                        <NoteCategoryAvatar noteCategory={note.category} />

                        <div className='note-details'>
                            <Typography.Text>{note.category}</Typography.Text>

                            <div>
                                <span>{note.author_name}</span>
                                <span className='note-details__separator'>{' | '}</span>
                                {note.note_date_time && (
                                    <span className='note-details__time'>{moment.unix(note.note_date_time).format('MMM Do, h:mm A')}</span>
                                )}
                            </div>
                        </div>
                    </div>
                }
                extra={
                    note.category === NoteCategories.PATIENT_HISTORY_NOTE ? (
                        <Button onClick={() => handleEdit()} disabled={disableEdit} style={{ padding: '0 var(--spacing-sm)' }}>
                            <EditOutlined />
                        </Button>
                    ) : (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item onClick={handleEdit} disabled={disableEdit}>
                                        Edit
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={handleDelete}
                                        disabled={disableEdit}
                                        style={{ color: 'var(--red-5)', opacity: disableEdit ? '.5' : '1' }}
                                    >
                                        Remove
                                    </Menu.Item>
                                </Menu>
                            }
                            placement='bottomRight'
                            arrow
                        >
                            <Button style={{ padding: '0 var(--spacing-sm)' }}>
                                <EllipsisOutlined style={{ transform: 'rotate(90deg)', height: '14px' }} />
                            </Button>
                        </Dropdown>
                    )
                }
            >
                <Row gutter={[4, 4]}>
                    <Col span={24}>
                        <div className='note-content'>
                            <Markdown
                                remarkPlugins={[remarkBreaks]}
                                children={showAll ? note.content : note.content.substring(0, characterOverflowLimit)}
                            />
                        </div>
                    </Col>
                </Row>
                {doesContentOverflow && (
                    <>
                        <Divider />
                        <div style={{ textAlign: 'center' }}>
                            <Button onClick={() => setShowAll(!showAll)}>
                                <div>{`Show ${showAll ? 'less' : 'more'}`}</div>
                            </Button>
                        </div>
                    </>
                )}
                {note.file_name && fileUrl && (
                    <div
                        className='notes-file-section'
                        onClick={() => {
                            window.open(fileUrl);
                            refetchFileURL();
                        }}
                    >
                        <PaperClipOutlined className='notes-file-icon' />
                        <Typography.Text className='notes-file-name'>{note.file_name}</Typography.Text>
                    </div>
                )}
            </Card>
        </li>
    );
};

export default NoteItem;
