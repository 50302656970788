import { useEffect, useState } from 'react';
import { Row, Col, Modal, Input, Form, Button, FormInstance, Spin } from 'antd';
import { formatCurrency, roundTo } from 'utils/formatFuncs';
import { FormWrapper } from 'components/forms/FormWrapper';
import { SUPPORTED_PAYMENT_AMOUNT_REGEX } from 'utils/constants';
import { useAddDirectPaymentMutation } from 'services/paymentService';
import { PaymentType, PAYMENT_MODAL_TITLE } from 'utils/types/paymentTypes';
import { validatePaymentAmountInput } from 'utils/miscFuncs';
import './payment_modal.css';
import { PaymentInfo, PaymentInfoProps } from './PaymentInfo';
import { EstimateInvoiceRadio, EstimateInvoiceRadioValues } from './EstimateInvoiceRadio';

interface DirectPaymentModalProps {
    visitId: number;
    paymentType: PaymentType | undefined;
    isVisible: boolean;
    isFetching: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    estimateBalanceDue: number;
    invoiceBalanceDue: number;
    form: FormInstance;
    paymentInfoProps: PaymentInfoProps;
}

export const DirectPaymentModal = ({
    visitId,
    paymentType,
    isVisible,
    isFetching,
    setIsVisible,
    estimateBalanceDue,
    invoiceBalanceDue,
    form,
    paymentInfoProps,
}: DirectPaymentModalProps) => {
    const [finalAmountCents, setFinalAmountCents] = useState<number | null>(null);
    const [isSent, setIsSent] = useState(false);
    const [isError, setIsError] = useState(false);
    const [addDirectPayment, { isLoading, isSuccess, isError: _isError }] = useAddDirectPaymentMutation();

    const [radioValue, setRadioValue] = useState<EstimateInvoiceRadioValues>('estimate');

    const balanceDue = radioValue === 'estimate' ? estimateBalanceDue : invoiceBalanceDue;

    useEffect(() => {
        if (isSuccess) {
            setIsSent(true);
        }
    }, [isSuccess]);

    useEffect(() => {
        setIsError(_isError);
    }, [_isError]);

    const PaymentForm = () => {
        return (
            <FormWrapper
                form={form}
                onFormChange={form.setFieldsValue}
                onFinishFailed={() => {}}
                onFinish={(values: any) => {
                    if (!paymentType) {
                        return;
                    }
                    const amountCents = roundTo(parseFloat(values.amount.replaceAll(',', '')) * 100, 0);
                    setFinalAmountCents(amountCents);
                    addDirectPayment({
                        visitId: visitId,
                        body: {
                            payment_type: paymentType,
                            amount_cents: amountCents,
                        },
                    });
                }}
                bottomBar={<></>}
            >
                <Row style={{ width: '100%' }}>
                    <Col span={24}>
                        <Form.Item
                            name='amount'
                            label='Amount'
                            className='payment-amount-input'
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                            initialValue={formatCurrency(finalAmountCents || balanceDue).replace('$', '')}
                            validateFirst={true}
                            rules={[
                                { required: true, message: 'Please enter an amount' },
                                { pattern: SUPPORTED_PAYMENT_AMOUNT_REGEX, message: 'Invalid amount' },
                                () => ({
                                    validator(_, value: string) {
                                        return validatePaymentAmountInput(value, balanceDue, false);
                                    },
                                }),
                            ]}
                        >
                            <Input addonBefore={'$'} disabled={false} style={{ textAlign: 'right' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </FormWrapper>
        );
    };

    const errorContent = <p>Payment can not be saved.</p>;
    const nonErrorContent = isSent ? (
        <p>Payment saved.</p>
    ) : (
        <>
            <EstimateInvoiceRadio radioValue={radioValue} setRadioValue={setRadioValue} />

            <PaymentInfo {...paymentInfoProps} mode={radioValue} />
            <Row>
                <Col span={24}>{balanceDue > 0 && <PaymentForm />}</Col>
            </Row>
        </>
    );

    const ModalContent = () => {
        return isError ? errorContent : nonErrorContent;
    };

    const onClose = () => {
        setIsVisible(false);
        setIsError(false);
        setIsSent(false);
        setFinalAmountCents(null);
    };

    const ModalFooter = () => {
        if (balanceDue <= 0) {
            return (
                <Button type='primary' onClick={onClose}>
                    Close
                </Button>
            );
        }
        return (
            <div style={{ visibility: isFetching ? 'hidden' : 'visible' }}>
                {!isError && !isSent && (
                    <>
                        <Button
                            type='default'
                            onClick={() => {
                                setIsVisible(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button autoFocus type='primary' onClick={form.submit} loading={isLoading}>
                            Apply Payment
                        </Button>
                    </>
                )}

                {isError && (
                    <>
                        <Button
                            autoFocus
                            type='default'
                            onClick={() => {
                                setIsError(false);
                            }}
                        >
                            Retry
                        </Button>
                        <Button type='primary' onClick={onClose}>
                            Close
                        </Button>
                    </>
                )}

                {isSent && (
                    <>
                        <Button autoFocus type='primary' onClick={onClose}>
                            Close
                        </Button>
                    </>
                )}
            </div>
        );
    };

    return (
        <Modal
            centered={true}
            visible={isVisible}
            title={paymentType ? PAYMENT_MODAL_TITLE[paymentType] : ''}
            footer={<ModalFooter />}
            onCancel={() => {
                if (isSent) {
                    setIsSent(false);
                }
                setIsVisible(false);
                setFinalAmountCents(null);
            }}
            onOk={form.submit}
        >
            <Spin spinning={isFetching}>
                <div style={{ visibility: isFetching ? 'hidden' : 'visible' }}>
                    <ModalContent />
                </div>
            </Spin>
        </Modal>
    );
};
