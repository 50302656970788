import { BLANK_FLUID_OPTION } from 'components/forms/CriOrder';
import { useParams } from 'react-router-dom';
import { useModifyCustomerMutation } from 'services/admissionService';
import { useOrderNonMedicalEstimateMutation } from 'services/estimateService';
import {
    useAddCriInstructionMutation,
    useAddDiagInstructionMutation,
    useAddTaskInstructionMutation,
    useAddFluidInstructionMutation,
    useAddMedInstructionMutation,
    useAddOffScheduleActionMutation,
    useDiscontinueInstructionMutation,
    useGetInstructionOptionsQuery,
    useModifyActionMutation,
    useSubmitIHDConfirmationMutation,
    useSetInitialWeightMutation,
    useAddOxygenTherapyInstructionMutation,
} from 'services/instructionService';
import { useModifyPatientMutation } from 'services/patientService';
import { useAttachRdvmToPatientMutation, useSetRdvmNoteMutation } from 'services/rdvmService';
import {
    useAddNewProblemMutation,
    useAddPhysicalExamMutation,
    useAddProgressNoteMutation,
    useGetVisitByIdQuery,
    useModifyDischargeNoteMutation,
    useModifyPatientHistoryMutation,
    useModifyProblemMutation,
    useDeleteProblemMutation,
    useModifyProgressNoteMutation,
    useModifyVisitMutation,
    useFinalizeVisitMutation,
    useReopenVisitMutation,
    useTextSurveyMutation,
    useTextFollowUpMutation,
    useEmailRecordToCustomerMutation,
    useEmailRecordToRdvmMutation,
    useEditPhysicalExamMutation,
    useLazyGetDischargeTogoMedsByVisitIdQuery,
} from 'services/visitService';
import { BASE_QUERY_OPTIONS, BIRTHDAY_FORMAT, FALLBACK_FLUID_ROUTES, FormName } from 'utils/constants';
import { BaseExistingInstruction, BaseNote, CustomerInfo, NewExamSection } from 'utils/dataTypes';
import { roundTo } from 'utils/formatFuncs';
import {
    MedicineSearchOption,
    OutgoingCriOrder,
    OutgoingDiagOrder,
    OutgoingFluidOrder,
    OutgoingMedicineOrder,
    OutgoingNonMedicalOrder,
    OutgoingTaskOrder,
} from 'utils/types/InstructionOrderTypes';
import { UploadFile } from 'antd/lib/upload/interface';
import { useUploadNoteFileDirectMutation } from 'services/filesService';
import { useLazyDownloadMedicalRecordDocQuery } from 'services/taskService';
import { useAddNonMedOrderMutation } from 'services/nonMedicalService';
import { useEffect } from 'react';
import { useAddCubexOrderMutation } from 'services/cubexService';
import { message, Modal } from 'antd';
import { pimsApi } from 'services/pimsApi';
import { useAppDispatch } from 'hooks/useRedux';
import { TagType } from 'utils/types/enums';

const PROBLEM_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Exposes `onFinish` function, which handles form submission
 */
export const useFormSubmission = () => {
    let { urlVisitId } = useParams<{ urlVisitId: string }>();
    let visitId = parseInt(urlVisitId);
    const dispatch = useAppDispatch();

    const urlParams: { pet_id?: string; urlVisitId?: string } = useParams();
    const [addCubexOrder] = useAddCubexOrderMutation();
    // const [closeCubexForVisit] = useCloseCubexForVisitMutation();
    const [modifyVisit] = useModifyVisitMutation();
    const [finalizeVisit] = useFinalizeVisitMutation();
    const [reopenVisit] = useReopenVisitMutation();
    const [emailRecordToCustomer] = useEmailRecordToCustomerMutation();
    const [emailRecordToRdvm] = useEmailRecordToRdvmMutation();
    const [textSurvey] = useTextSurveyMutation();
    const [textFollowUp] = useTextFollowUpMutation();
    const [modifyPatient] = useModifyPatientMutation();
    const [setInitialWeight] = useSetInitialWeightMutation();
    const [attachRdvmToPatient] = useAttachRdvmToPatientMutation();
    const [addPhysicalExam] = useAddPhysicalExamMutation();
    const [editPhysicalExam] = useEditPhysicalExamMutation();
    const [addProgressNote] = useAddProgressNoteMutation();
    const [addMedInstruction] = useAddMedInstructionMutation();
    const [addDiagInstruction] = useAddDiagInstructionMutation();
    const [addTaskInstruction] = useAddTaskInstructionMutation();
    const [addCriInstruction] = useAddCriInstructionMutation();
    const [addFluidInstruction] = useAddFluidInstructionMutation();
    const [addOxygenTherapyInstruction] = useAddOxygenTherapyInstructionMutation();
    const [discontinueInstruction] = useDiscontinueInstructionMutation();
    const [addOffSchedAction] = useAddOffScheduleActionMutation();
    const [modifyAction] = useModifyActionMutation();
    const [addNewProblem] = useAddNewProblemMutation();
    const [deleteProblem] = useDeleteProblemMutation();
    const [modifyProblem] = useModifyProblemMutation();
    const [modifyNote] = useModifyProgressNoteMutation();
    const [modifyDischargeNote] = useModifyDischargeNoteMutation();
    const [modifyPatientHistory] = useModifyPatientHistoryMutation();
    const [submitIHDConfirmation] = useSubmitIHDConfirmationMutation();
    const [setRdvmNote] = useSetRdvmNoteMutation();
    const [uploadNoteFileDirectMutation, { isError: uploadNoteFileError }] = useUploadNoteFileDirectMutation();
    const [addNonMedOrder, { isSuccess: NonMedicalAddSuccess }] = useAddNonMedOrderMutation();
    const [orderNonMedicalEstimate, { isSuccess: NonOrderAddSuccess }] = useOrderNonMedicalEstimateMutation();
    const [downloadMedicalRecordDoc] = useLazyDownloadMedicalRecordDocQuery();
    const [getToGoMeds] = useLazyGetDischargeTogoMedsByVisitIdQuery();

    // The modify Customer is being used in both `useFormSubmission.ts` and `Visit.tsx`
    const [modifyCustomer] = useModifyCustomerMutation();

    const { data: currentVisit } = useGetVisitByIdQuery(visitId, {
        ...BASE_QUERY_OPTIONS,
        skip: !visitId,
    });

    /**
     * If on the visit page, rely on `currentVisit` for the pet_id
     * Otherwise, get it from the URL
     */
    const pet_id = visitId ? currentVisit?.pet_id : urlParams.pet_id;

    const { data: medicineOptions } = useGetInstructionOptionsQuery(null, BASE_QUERY_OPTIONS);


    useEffect(() => {
        if (NonMedicalAddSuccess) {
            dispatch(pimsApi.util.invalidateTags([{ type:  TagType.Billing }]))
        }
    }, [NonMedicalAddSuccess]);
    useEffect(() => {
        if (NonOrderAddSuccess) {
            dispatch(pimsApi.util.invalidateTags([{ type: TagType.Billing }]))
        }
    }, [NonOrderAddSuccess]);
    useEffect(() => {
        if (uploadNoteFileError) {
            Modal.warning({
                title: 'An error has occurred.',
                content: 'The file cannot be uploaded at this time. Please try again later.',
                centered: true,
            });
        }
    }, [uploadNoteFileError]);

    const fileUploadOnNoteComplete = (file: UploadFile, visitId: number, noteId: number) => {
        if (file && file.name && file.originFileObj) {
            uploadNoteFileDirectMutation({
                visitId,
                noteId,
                body: {
                    file_name: file.name,
                    file_obj: file.originFileObj,
                },
            });
        }
    };

	const onFinish = (
		values: any, // Todo Figure out how to keep type data
		formName: FormName,
		additionalState = {}, // Todo Figure out how to keep type data
	) => {
		if (formName === FormName.med_order) {
			if (values.medication_type === 'cri') {
				const formattedStart = values.start_time
					? typeof values.start_time === 'number'
						? values.start_time
						: values.start_time.unix()
					: null;
				const formattedEnd = values.end_time
					? typeof values.end_time === 'number'
						? values.end_time
						: values.end_time.unix()
					: null;
				const formattedFluidsVolumeMl = values.fluids_volume_ml
					? parseFloat(values.fluids_volume_ml)
					: values.fluids_volume_ml;
				const { id, ...outgoingFields } = values;
				const formattedOrderValues: OutgoingCriOrder = {
					fluids_id:
						outgoingFields.fluids_id === BLANK_FLUID_OPTION.id
							? null
							: outgoingFields.fluids_id || null,
					medication_id: values.medication_id,
					fluids_volume_ml: formattedFluidsVolumeMl || null,
					rate_mcl_per_hr: roundTo(values.rate_ml_per_hr * 100, 0) * 10,
					label: values.label,
					dose: values.dose,
					dose_unit: values.dose_unit,
					latest_patient_weight_kg:
						parseFloat(values.latest_patient_weight_kg) || null,
                    approx_patient_weight_kg:
                        values.latest_patient_weight_kg ? null : 
                        values.approx_patient_weight_kg ? values.approx_patient_weight_kg : 
                        null,
					start_time: formattedStart,
					end_time: formattedEnd,
					frequency: undefined,
					ordered_by: values.userId,
					priority: values.priority || false,
					is_prn: values.is_prn || false,
					prn_condition: values.prn_condition || null,
					notes: values.notes || null,
                    estimate_id: values.fromEstimate ? values?.id : null,
                    is_free: values.is_free,
				};
                addCriInstruction({ visitId, body: formattedOrderValues })
			} else {
				const formattedStart = values.start_time
					? typeof values.start_time === 'number'
						? values.start_time
						: values.start_time.unix()
					: null;
				const formattedEnd = values.end_time
					? typeof values.end_time === 'number'
						? values.end_time
						: values.end_time.unix()
					: null;

				/**
				 * Api gets really upset when you send extra fields
				 * This is how we make extra sure that we don't send any extra
				 */
				const formattedOrderValues: OutgoingMedicineOrder = {
					medication_id: values.medication_id
						? values.medication_id
						: (medicineOptions?.find(
							(item) => item.name === values.medication_name,
						)?.id as number),
					route_id: values.route_id,
					dose: values.dose,
					dose_unit: values.dose_unit
						? values.dose_unit
						: (
							medicineOptions?.find(
								(item) =>
									item.name === values.medication_name,
							) as MedicineSearchOption
						)?.dose_unit, //TODO this should maybe get set by the `formData`? I am unsure
					start_time: parseInt(formattedStart),
					end_time: parseInt(formattedEnd),
					frequency: values.frequency,
					is_prn: values.is_prn || false,
					prn_condition: values.prn_condition || null,
					notes: values.notes || null,
					ordered_by: values.userId,
					priority: values.priority || false,
					latest_patient_weight_kg: values.latest_patient_weight_kg ?? null,
					approx_patient_weight_kg:
                        values.latest_patient_weight_kg ? null : 
                        values.approx_patient_weight_kg ? values.approx_patient_weight_kg : 
                        null,
                    controlled_drug: values.controlled_drug,
                    estimate_id: values.fromEstimate ? values?.id : null,
                    serial: values.serial || false,
                    is_free: values.is_free,
				};
                addMedInstruction({ visitId, body: formattedOrderValues })
                    .then(response => {
                        if ('data' in response && values?.fromEstimate) {
                            getToGoMeds(visitId);
                        }
                    })
                
			}
		} else if (formName === FormName.diag_order) {
			const formattedStart = values.start_time
				? typeof values.start_time === 'number'
					? values.start_time
					: values.start_time.unix()
				: null;
			const formattedEnd = values.end_time
				? typeof values.end_time === 'number'
					? values.end_time
					: values.end_time.unix()
				: null;
			const formattedOrderValues: OutgoingDiagOrder = {
				start_time: formattedStart,
				end_time: formattedEnd,
				frequency: values.frequency,
				ordered_by: values.userId,
				diagnostic_id: values.diagnostic_id,
				is_prn: values.is_prn || false,
				prn_condition: values.prn_condition || null,
				priority: values.priority || false,
				notes: values.notes || null,
                estimate_id: values.fromEstimate ? values?.id : null,
                serial: values.serial || false,
                is_free: values.is_free,
			};
            addDiagInstruction({ visitId, body: formattedOrderValues })
		} else if (formName === FormName.task_order) {
			const formattedStart = values.start_time
				? typeof values.start_time === 'number'
					? values.start_time
					: values.start_time.unix()
				: null;
			const formattedEnd = values.end_time
				? typeof values.end_time === 'number'
					? values.end_time
					: values.end_time.unix()
				: null;
			const formattedOrderValues: OutgoingTaskOrder = {
				start_time: formattedStart,
				end_time: formattedEnd,
				frequency: values.frequency,
				ordered_by: values.userId,
				task_id: values.task_id,
				is_prn: values.is_prn || false,
				prn_condition: values.prn_condition || null,
				priority: values.priority || false,
				notes: values.notes || null,
                estimate_id: values.fromEstimate ? values?.id : null,
                serial: values.serial || false,
                is_free: values.is_free,
			};        
            addTaskInstruction({ visitId, body: formattedOrderValues })
		} else if (formName === FormName.fluid_order) {
			const formattedStart = values.start_time
				? typeof values.start_time === 'number'
					? values.start_time
					: values.start_time.unix()
				: null;
			const formattedEnd = values.end_time
				? typeof values.end_time === 'number'
					? values.end_time
					: values.end_time.unix()
				: null;
			const formattedVolume = values.fluids_volume_ml
				? typeof values.end_time === 'string'
					? values.fluids_volume_ml
					: parseFloat(values.fluids_volume_ml)
				: null;
			const formattedRoute =
				FALLBACK_FLUID_ROUTES[values.route_id] || values.route_id;
            const formattedFluidAdditives = values.fluid_additives?.map(
                (additive: { additive_id: string; dose_qty: string; name: string; }) => {
                return {
                    id: additive.additive_id,
                    dose_qty: additive.dose_qty,
                };
            });
			const formattedOrderValues: OutgoingFluidOrder = {
				fluids_id: values.fluids_id,
				start_time: formattedStart,
				end_time: formattedEnd,
				rate_mcl_per_hr: roundTo(values.rate_ml_per_hr * 100, 0) * 10,
				fluids_volume_ml: formattedVolume,
				ordered_by: values.userId,
				frequency: undefined,
				priority: values.priority || false,
				is_prn: values.is_prn || false,
				prn_condition: values.prn_condition || null,
				notes: values.notes || null,
				route_id: formattedRoute,
                estimate_id: values.fromEstimate ? values?.id : null,
                fluid_additives: formattedFluidAdditives ?? null,
                is_free: values.is_free,
			};
            addFluidInstruction({ visitId, body: formattedOrderValues })
		} else if (formName === FormName.oxygen_therapy_order) {
			const formattedStart = values.start_time
				? typeof values.start_time === 'number'
					? values.start_time
					: values.start_time.unix()
				: null;
			const formattedEnd = values.end_time
				? typeof values.end_time === 'number'
					? values.end_time
					: values.end_time.unix()
				: null;

			const formattedOrderValues = {
				...values,
				start_time: formattedStart,
				end_time: formattedEnd,
				notes: values.notes || null,
                quantity: roundTo(values.quantity * 100, 0),
			};
            addOxygenTherapyInstruction({ visitId, body: formattedOrderValues });
        } else if (formName === FormName.non_medical_order) {
			const formattedOrderValues: OutgoingNonMedicalOrder = {
				non_medical_id: values.non_medical_id,
				unit: values.unit,
                quantity: parseInt(values.quantity),
                why: values.why ?? null,
                why_other: values.why_other ?? null,
                reason: values.reason ?? null,
                non_medical_order_id: values?.id ?? null,
			};
            addNonMedOrder({ visitId, body: formattedOrderValues })
		} else if (formName === FormName.patient_info) {
			delete values['ageYears'];
			delete values['ageMonths'];
			delete values['is_deceased'];
            const initial_weight = values.initial_weight;
            delete values['initial_weight'];
			const formattedBirthday =
				values.birthday ?
					values.birthday.format(BIRTHDAY_FORMAT) :
					null;
			const formattedName = values.name ? values.name : null;
			const formattedBreed = values.breed ? values.breed : null;
			const formattedSpecies = values.species ? values.species : null;
			const formattedSex = values.sex ? values.sex : null;
			const formattedNote = values?.note ? values.note : null;
			const formattedIsIntact =
				values?.is_intact !== null && values?.is_intact !== undefined
					? values.is_intact
					: null;
			// const formattedIsDeceased =
			// 	!! values?.is_deceased
			// 		? values.is_deceased
			// 		: null;
			const formattedDeceasedAt =
				!!values?.deceased_at
					? values.deceased_at.startOf('day').unix()
					: null;
            modifyPatient({
                body: {
                    ...values,
                    birthday: formattedBirthday,
                    name: formattedName,
                    breed: formattedBreed,
                    species: formattedSpecies,
                    sex: formattedSex,
                    note: formattedNote,
                    is_intact: formattedIsIntact,
                    // is_deceased: formattedIsDeceased,
                    deceased_at: formattedDeceasedAt,
                },
            }).unwrap().then(
                () => {
                    if (initial_weight) {
                        const weight = parseFloat(initial_weight);
                        
                        setInitialWeight({
                            visitId: visitId,
                            weight: isNaN(weight) ? null : weight.toString()
                        });
                    }
                }
            );
        } else if (formName === FormName.rdvm_info) {
            attachRdvmToPatient({
                pet_id: pet_id as string,
                clinic_id: values.clinic_id,
            });
            if (visitId) {
                modifyVisit({
                    body: {
                        rdvm_id: values.clinic_id,
                        owner_id: null,
                        discharge_summary_location: null,
                        recheck_time: null,
                        override_display_name: null,
                        override_email: null,
                    },
                    visitId: visitId,
                });
            }
        } else if (formName === FormName.add_complaint) {
            const formattedStart = values.start_date ? values.start_date.format(PROBLEM_DATE_FORMAT) : null;
            const formattedEnd = values.end_date ? values.end_date.format(PROBLEM_DATE_FORMAT) : null;

            addNewProblem({
                visitId,
                body: {
                    ...values,
                    start_date: formattedStart,
                    end_date: formattedEnd,
                },
            });
        } else if (formName === FormName.delete_problem) {
            deleteProblem({
                visitId: visitId,
                problem_id: values.id_to_delete,
            });
        } else if (formName === FormName.edit_problem) {
            const formattedStart = values.start_date ? values.start_date.format(PROBLEM_DATE_FORMAT) : null;
            const formattedEnd = values.end_date ? values.end_date.format(PROBLEM_DATE_FORMAT) : null;
            modifyProblem({
                visitId,
                body: {
                    ...values,
                    start_date: formattedStart,
                    end_date: formattedEnd,
                },
            });
        } else if (formName === FormName.physical_exam) {
            //Filter out comment-fields, they get added in to the corresponding section directly
            //Also filter out the top-level "Note" field
            const formattedExam: NewExamSection[] = Object.keys(values)
                .filter(
                    (exam_section) =>
                        !exam_section.includes('comment') &&
                        !exam_section.includes('note') &&
                        !exam_section.includes('is_editing') &&
                        !exam_section.includes('exam_id') &&
                        !exam_section.includes('note_date_time') &&
                        !exam_section.includes('created_at'),
                )
                .map((exam_section) => {
                    return {
                        section: exam_section,
                        status: values[exam_section],
                        note: values[exam_section + '_comment'],
                    } as NewExamSection;
                });

            if (values.is_editing) {
                editPhysicalExam({
                    id: values.exam_id,
                    visit_id: visitId,
                    exam: formattedExam,
                    note: values.note,
                    note_date_time: values.note_date_time.unix(),
                });
            } else {
                addPhysicalExam({
                    visit_id: visitId,
                    exam: formattedExam,
                    note: values.note,
                    note_date_time: values.note_date_time.unix(),
                });
            }
        } else if (formName === FormName.merge_patients) {
            //TODO follow up with Chris about 404 error
            // dispatch(
            // 	mergePatients(
            // 		values.source_pet_id,
            // 		values.target_pet_id,
            // 		visitId,
            // 	),
            // );
        } else if (formName === FormName.med_admin) {
            let resultValue = values.value;
            if (values.value && (values.type_id === 'C' || values.type_id === 'F')) {
                resultValue = roundTo(values.value * 1000, 0).toString();
            }
            const formattedCompletedAt = values.completed_at?.unix();
            const { parentOrder } = additionalState as {
                parentOrder: BaseExistingInstruction;
            };
            const drawnAmount = parseFloat(values.drawn_amount);
            const administeredAmount = parseFloat(values.administered_amount);
            modifyAction({
                body: {
                    ...values,
                    value: resultValue,
                    completed_at: formattedCompletedAt,
                    drawn_amount: isNaN(drawnAmount) ? null : drawnAmount,
                    administered_amount: isNaN(administeredAmount) ? null : administeredAmount,
                },
                visitId,
            }).then((response) => {
                if ('data' in response) {
                    if (values?.send_to_cubex) {
                        addCubexOrder({
                            visitId,
                            actionId: response.data?.id,
                            instructionId: response.data?.instruction_id,
                            dueAt: values?.due_at,
                        });
                    }
                    if (values.status === 'complete') {
                        if ((parentOrder?.type_id === 'C' || parentOrder?.type_id === 'F') && !resultValue) {
                            message.success(`${parentOrder?.name} has been successfully marked as started.`);
                        } else {
                            message.success(`${parentOrder?.name} has been successfully marked as complete.`);
                        }
                    }
                }
            });
        } else if (formName === FormName.off_sch_med_admin) {
            addOffSchedAction({
                body: { ...values, due_at: values.due_at.unix() },
                visitId,
            });
        } else if (formName === FormName.discon_inst) {
            const { instructionId, userId, isTogoMed } = values;
            discontinueInstruction({
                instructionId,
                on_behalf_of: userId,
                visitId,
            }).then((response) => {
                if (isTogoMed && 'data' in response) {
                    getToGoMeds(visitId);
                }
            });
        } else if (
            formName === FormName.prog_note ||
            formName === FormName.communication_note ||
            formName === FormName.recommendation_note ||
            formName === FormName.procedure_note ||
            formName === FormName.addendum_note
        ) {
            addProgressNote({
                visitId,
                content: values.content,
                category: formName,
                internal: values.internal,
                note_date_time: values.note_date_time.unix(),
                author_id: values.author_id || null,
            }).then((response) => {
                if ('data' in response) {
                    const file: UploadFile = values?.files?.[0];
                    if (file && file.name && file.originFileObj) {
                        fileUploadOnNoteComplete(file, visitId, response.data.id);
                    }
                }
            });
        } else if (formName === FormName.discharge_note) {
            modifyDischargeNote({
                visitId,
                content: values.content,
            });
        } else if (formName === FormName.edit_note) {
            // Typing needs to be changed here - unsafe.
            let { category, id, internal, author_id } = additionalState as BaseNote;
            const file: UploadFile = values?.files?.[0];
            modifyNote({
                content: values.content,
                visit_id: visitId,
                note_date_time: values.note_date_time.unix(),
                category,
                id,
                internal,
                author_id,
            });
            if (file && file.name && file.originFileObj) {
                fileUploadOnNoteComplete(file, visitId, id);
            }
        } else if (formName === FormName.owner_note) {
            const customer = additionalState as CustomerInfo; //Todo Bad but this will need to be a part of a larger fix to remove this case
            const { customer_id } = customer;
            modifyCustomer({
                customer_id: customer_id,
                body: {
                    first_name: customer.first_name,
                    last_name: customer.last_name || null,
                    phone_number: customer.phone_number,
                    email: customer.email || null,
                    street: customer.street || null,
                    city: customer.city || null,
                    state: customer.state || null,
                    zipcode: customer.zipcode || null,
                    note: values.note || null,
                    how_did_you_hear_about_us: customer.how_did_you_hear_about_us || null,
                    additional_info: customer.additional_info || null,
                    birthday: customer.birthday,
                    unable_to_collect_birthday: customer.unable_to_collect_birthday,
                },
            });
        } else if (formName === FormName.rdvm_note) {
            setRdvmNote({ id: currentVisit?.rdvm_id!, note: values.note });
        } else if (formName === FormName.close_chart) {
            const { shouldDownloadMedicalRecord, showInfoModalAfterClose } = additionalState as {
                shouldDownloadMedicalRecord: boolean;
                showInfoModalAfterClose: Function;
            };
            finalizeVisit({
                visitId,
            }).then(() => {
                // closeCubexForVisit({ visitId });

				if (values.email_customer) {
					emailRecordToCustomer({
						visitId, email_type: "medical record"
					});
				}
				if (values.email_rdvm) {
					emailRecordToRdvm({
						visitId, email_type: "medical record"
					});
				}
				if (values.text_survey) {
					textSurvey({
						visitId, sms_type: "survey"
					});
				}
				if (values.text_follow_up) {
					textFollowUp({
						visitId, sms_type: "follow up"
					})
				}
				if (shouldDownloadMedicalRecord) {
					downloadMedicalRecordDoc({ visitId });
				}
				showInfoModalAfterClose();
			});
		} else if (formName === FormName.reopen_chart) {
			reopenVisit({
				visitId,
			});
		} else if (formName === FormName.patient_history) {
			modifyPatientHistory({
				visitId,
				content: values.note,
			});
		} else if (formName === FormName.ivls_confirm) {
			submitIHDConfirmation({ actions: values }).unwrap()
				.then(() => {
					message.success("Successfully sent in-house diagnostics order")
				})
				.catch((err) => {
					console.error(err)
					message.error("Failed to confirm in-house diagnostics order")
				})
		} else if (formName === FormName.adjust_fluid_rate) {
			modifyAction({ body: { ...values }, visitId })
				.unwrap()
				.catch(error => {
					message.error("Failed to add rate adjustment")
				})
		}
	};

    return [onFinish];
};
