import { AutoComplete, Checkbox, Col, Modal, Row, Table } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { MarkdownNote, PageHeader } from 'components/lib';
import moment from 'moment';
import { useState } from 'react';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useGetNotificationsQuery } from 'services/userService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { Notification } from 'utils/dataTypes';
import classes from './inbox.module.css';

const { Option } = AutoComplete;

const INBOX_SENT_FORMAT = 'ddd MMM Do, h:mma';

const ALL_SEEN_FILTERS: string[] = ['read', 'unread'];
const INITIAL_SEEN_FILTERS: string[] = ['unread'];

interface InboxProps {
	notifications?: Notification[];
	tableSize?: SizeType;
}

/**
 *
 * @param notifications Optionally accepts an array of Notifications to display
 * (INSTEAD of generic API call for Notifications)
 * @param tableSize Optionally accepts a string to pass thru to ANTD table
 *  @returns
 */
const Inbox: React.FC<InboxProps> = (props) => {
	const tableSize: SizeType = props.tableSize ?? ('default' as SizeType);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [selectedNoti, setSelectedNoti] = useState({} as Notification);
	const [replyContent, setReplyContent] = useState('');

	const [notiFiltersBySeen, setNotiFiltersBySeen] =
		useState(INITIAL_SEEN_FILTERS);

	const { data } = useGetNotificationsQuery(null, {
		...BASE_QUERY_OPTIONS,
		skip: !!props.notifications,
	});

	let notifications: Notification[] = [];

	//If notifications were passed in, rely on those as the source of truth
	if (props.notifications) {
		notifications = props.notifications;
	} else if (data) {
		//Otherwise, once we have data back from API, use that.
		notifications = data;
	}

	notifications = notifications.filter((noti) =>
		notiFiltersBySeen.includes(noti.read ? 'read' : 'unread'),
	);

	return (
		<>
			<div>
				<PageHeader title='Inbox'>
					<AutoComplete
						className={classes.searchInput}
						filterOption={(inputValue, option) => {
							return (
								(option?.label as string)
									?.toUpperCase()
									.indexOf(inputValue.toUpperCase()) !== -1
							);
						}}
						onSelect={(e) => {
							setSelectedNoti(
								notifications?.find(
									(noti) => noti.id === parseInt(e),
								) as Notification,
							);
							setIsModalVisible(true);
							setSearchValue('');
						}}
						onSearch={setSearchValue}
						placeholder='Search...'
						value={searchValue}
					>
						{notifications?.map((noti) => {
							return (
								<Option key={noti.id} value={noti.id}>
									<Markdown
										remarkPlugins={[remarkBreaks]}
										children={noti.message.substring(0, 25)}
									/>
								</Option>
							);
						})}
					</AutoComplete>
				</PageHeader>
				<div className={classes.tableWrapper}>
					<Table
						size={tableSize}
						title={() => (
							<InboxTableTitle
								filters={{ seen: notiFiltersBySeen }}
								setFilters={{ seen: setNotiFiltersBySeen }}
							/>
						)} //IDK why we have to pass a function that returns an Element... something wonky with ANTD
						pagination={
							notifications && notifications.length > 5
								? undefined
								: false
						}
						rowClassName={
							//Sets background color darker for read notis
							(record) => (record.read ? classes.readNotif : '')
						}
						tableLayout='fixed'
						dataSource={notifications}
						rowKey={(record) => record.id}
						onRow={(record, rowIndex) => {
							return {
								onDoubleClick: (event) => {
									setSelectedNoti(record);
									setIsModalVisible(true);
								}, // double click row
							};
						}}
						columns={[
							{
								title: 'From',
								key: 'sender_id',
								dataIndex: 'sender_id',
								sorter: (a, b) => a.sender_id - b.sender_id,
							},
							{
								title: 'Patient',
								key: 'pet_id',
								dataIndex: 'pet_id',
								render: (text, record) => {
									return (
										<InboxPatientCell
											pet_id={record.pet_id}
										/>
									);
								},
							},
							{
								title: 'Received at',
								key: 'received_at',
								dataIndex: 'received_at',
								sorter: (a, b) => a.received_at - b.received_at,
								render: (text, record) => {
									return moment(text).format(
										INBOX_SENT_FORMAT,
									);
								},
							},
							{
								title: 'Preview',
								key: 'message',
								dataIndex: 'message',
								width: 300,
								render: (text, record) => {
									return (
										<div
											className={classes.markdownPreview}
										>
											<Markdown
												remarkPlugins={[remarkBreaks]}
												children={text.substring(0, 25)}
											/>
										</div>
									);
								},
							},
						]}
					/>
				</div>
			</div>
			<Modal
				className={classes.inboxNotificationModal}
				width='75%'
				visible={isModalVisible}
				maskClosable={false}
				title={`sender_id: ${selectedNoti?.sender_id} sent:`}
				onOk={(e) => {
					setIsModalVisible(false);
				}}
				okText='Send'
				onCancel={() => {
					setIsModalVisible(false);
				}}
				destroyOnClose={true}
			>
				<Row>
					<Col span={24} className={classes.markdownContainer}>
						<Markdown
							remarkPlugins={[remarkBreaks]}
							children={selectedNoti.message}
						/>
					</Col>

					<Col span={24} className={classes.markdownFormWrapper}>
						<MarkdownNote
							fieldName='text'
							onFormChange={(value: { text: string }) => {
								setReplyContent(value.text);
							}}
							getFormData={() => ({ text: replyContent })}
						/>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

interface InboxPatientCellProps {
	pet_id: string;
}
export const InboxPatientCell = ({ pet_id }: InboxPatientCellProps) => {
	const { data: patientData } = useGetPatientByIdQuery(
		pet_id,
		BASE_QUERY_OPTIONS,
	);

	return <>{patientData ? patientData.name : pet_id}</>;
};

interface InboxTableTitleProps {
	filters: {
		seen: string[];
	};
	setFilters: {
		seen: React.Dispatch<React.SetStateAction<string[]>>;
	};
}
export const InboxTableTitle = (props: InboxTableTitleProps) => {
	return (
		<Row justify='space-between'>
			<Col></Col>
			<Col>
				<InboxFilters {...props} />
			</Col>
		</Row>
	);
};

interface InboxFiltersProps {
	filters: {
		seen: string[];
	};
	setFilters: {
		seen: React.Dispatch<React.SetStateAction<string[]>>;
	};
}
export const InboxFilters = ({ filters, setFilters }: InboxFiltersProps) => {
	return (
		<>
			<SeenFilters
				filters={filters.seen}
				setFilters={setFilters.seen}
				labels={ALL_SEEN_FILTERS}
			/>
		</>
	);
};

interface SeenFiltersProps {
	filters: string[];
	setFilters: React.Dispatch<React.SetStateAction<string[]>>;
	labels: string[];
}
export const SeenFilters = ({
	filters,
	setFilters,
	labels,
}: SeenFiltersProps) => {
	return (
		<>
			{labels.map((label) => (
				<Checkbox
					key={`seen_filter_${label}`}
					checked={filters.includes(label)}
					onChange={(e) => {
						//TODO there is most certainly a better way to do this...
						//Take existing filters, but remove the one that just got changed
						const newFilters = filters.filter(
							(filterString) => filterString !== label,
						);

						if (e.target.checked) {
							//If current filter just became checked, add it to new filters
							newFilters.push(label);
						}
						//Then set state
						setFilters(newFilters);
					}}
				>
					{label}
				</Checkbox>
			))}
		</>
	);
};

export default Inbox;
