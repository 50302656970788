import { CaretDownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Dropdown, Layout, Menu, Row, Typography } from 'antd';
import { EarningsDrawer } from 'components/EarningsDrawer';
import AdmitPatient from 'components/Modals/AdmitPatient';
import { PatientSearchBox } from 'components/TopBar/PatientSearch';
import { MacrosDrawer } from 'components/drawers/Macros';
import { Avatar } from 'components/lib';
import { useMemo, useState } from 'react';
import { useGetUserDataQuery, useLogoutMutation } from 'services/authService';
import { useGetHospitalByIdQuery } from 'services/hospitalService';
import { ADMIN_URL, BASE_QUERY_OPTIONS } from 'utils/constants';
import { USER_PERMISSIONS, checkIfUserHasNecessaryPermission } from 'utils/userPermissions';
import topBarStyles from './top-bar.module.css';

interface TopBarProps {}

const TopBar: React.FC<TopBarProps> = () => {
    const [logout] = useLogoutMutation();

    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    const { data: hospitalData } = useGetHospitalByIdQuery(loggedInUserData?.hospital_id || 0, {
        ...BASE_QUERY_OPTIONS,
        skip: !loggedInUserData?.hospital_id,
    });

    let userName = useMemo(() => {
        if (loggedInUserData) {
            return `${loggedInUserData.first_name} ${loggedInUserData.last_name}`;
        }
        return '';
    }, [loggedInUserData]);
    const [isAdmitPatientModalOpen, setIsAdmitPatientModalOpen] = useState(false);
    const [isEarningsDrawerOpen, setIsEarningsDrawerOpen] = useState(false);
    const [isMacrosDrawerOpen, setIsMacrosDrawerOpen] = useState(false);

    const userHasPermissionToViewEarnings = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.earnings_view,
    );
    const userHasAccessToAdminPanel = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.admin_panel_access,
    );

    const viewMacros = checkIfUserHasNecessaryPermission(loggedInUserData?.user_permissions, USER_PERMISSIONS.macros_view);

    return (
        <Layout.Header
            className={topBarStyles.header}
            data-cy='topbar'
            style={{
                position: 'sticky',
                top: 0,
                left: 0,
                zIndex: 999,
            }}
        >
            <Row
                justify='space-between'
                align='middle'
                /*
                 * Height 100% is because the antD `top-nav` has a fixed height
                 * Without it, we would be unable to vertically align all nav items to the middle of the Row
                 * Because the row is not "naturally" tall enough to occupy the entire `top-nav` fixed height
                 */
                style={{ height: '100%' }}
            >
                <Col span={11} lg={12} xl={14}>
                    <Row gutter={[24, 24]}>
                        <Col>
                            <Typography.Text className={topBarStyles.hospital_title} data-cy='topbarHospitalName'>
                                {hospitalData?.display_name || 'No Hospital Select'}
                            </Typography.Text>
                        </Col>
                    </Row>
                </Col>

                <Col span={13} lg={12} xl={10}>
                    <Row justify='end' gutter={{ sm: 6, lg: 12 }} data-cy='topbarContainer'>
                        <Col className={topBarStyles.notifications_container}>
                            <PlusCircleOutlined
                                data-cy='newPatientButton'
                                className={topBarStyles.new_patient_button}
                                onClick={(e) => {
                                    setIsAdmitPatientModalOpen(true);
                                }}
                            />
                        </Col>
                        <Col className={topBarStyles.search_container}>
                            <PatientSearchBox />
                        </Col>
                        <Col>
                            <Dropdown
                                className={topBarStyles.userDropdown}
                                overlay={
                                    <Menu>
                                        {userHasPermissionToViewEarnings && (
                                            <Menu.Item data-cy='earnings' onClick={() => setIsEarningsDrawerOpen(true)}>
                                                Earnings
                                            </Menu.Item>
                                        )}
                                        {viewMacros && (
                                            <Menu.Item data-cy='macros' onClick={() => setIsMacrosDrawerOpen(true)}>
                                                Macros
                                            </Menu.Item>
                                        )}
                                        {userHasAccessToAdminPanel && (
                                            <>
                                                <Divider style={{ margin: 'var(--spacing-xs) 0' }} />
                                                <Menu.Item>
                                                    <a href={ADMIN_URL}>Admin Panel</a>
                                                </Menu.Item>
                                                <Divider style={{ margin: 'var(--spacing-xs) 0' }} />
                                            </>
                                        )}
                                        <Menu.Item
                                            onClick={() => {
                                                logout(null);
                                            }}
                                            data-cy='signOut'
                                        >
                                            Sign Out
                                        </Menu.Item>
                                    </Menu>
                                }
                                placement='bottomRight'
                                arrow
                                trigger={['click']}
                            >
                                <div className={topBarStyles.userDropdownButton} data-cy='loggedInUserDropdown'>
                                    <Avatar
                                        firstName={loggedInUserData?.first_name ?? ''}
                                        lastName={loggedInUserData?.last_name ?? ''}
                                        className={loggedInUserData?.role_name === 'Nurse' ? 'nurse-avatar' : undefined}
                                    />
                                    <span className={topBarStyles.user_name}>
                                        {userName} <CaretDownOutlined />
                                    </span>
                                </div>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <AdmitPatient isOpen={isAdmitPatientModalOpen} setIsOpen={setIsAdmitPatientModalOpen} hospitalData={hospitalData} />
            <EarningsDrawer open={isEarningsDrawerOpen} setOpen={setIsEarningsDrawerOpen} />
            <MacrosDrawer open={isMacrosDrawerOpen} setOpen={setIsMacrosDrawerOpen} />
        </Layout.Header>
    );
};

export default TopBar;
