import { Col, Form } from 'antd';
import { Rule } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';

interface FreeNoteProps {
    autoSaveFunction: () => void;
    name?: string;
    noteRules?: Rule[];
    initialNoteValue?: string;
}

export const FreeNote = ({ autoSaveFunction, name, noteRules, initialNoteValue }: FreeNoteProps) => {
    return (
        <Col span={23} style={{ padding: '0 var(--spacing-sm)', backgroundColor: '#55a2e133' }}>
            <div style={{ color: 'var(--gray-7)', paddingTop: '4px' }}>Note (optional):</div>
            <Form.Item
                key={name}
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                name={name}
                style={{ paddingTop: '4px', paddingBottom: '12px' }}
                rules={noteRules ?? []}
                initialValue={initialNoteValue}
            >
                <TextArea
                    data-cy={'physicalExamNote'}
                    key={name}
                    placeholder='Explain the observation'
                    rows={1}
                    style={{ width: '100%', maxWidth: '100%' }}
                    value={initialNoteValue}
                    defaultValue={initialNoteValue}
                    onChange={autoSaveFunction}
                    autoFocus
                />
            </Form.Item>
        </Col>
    );
};
