import { AlertFilled, CheckOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, Input, Select, Tooltip } from 'antd';
import { SelectValue } from 'antd/lib/select';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice';
import MedicationDoseConfirmation from 'components/forms/MedicationDoseAlert/MedicationDoseConfirmation';
import { calculateDefaultDose } from 'components/forms/MedicineOrder';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetMedicationRoutesQuery } from 'services/instructionService';
import { useLazyGetSupplementalByNameQuery } from 'services/supplementalService';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import { roundTo } from 'utils/formatFuncs';
import { getMedicationAlertDescription, getMedicationConcentration } from 'utils/miscFuncs';
import { PackageMedInstruction } from 'utils/types/InstructionOrderTypes';
import MedicationData from 'views/visit/TreatmentSheet/MedicationData';
import { PackageFormInstance } from '..';
import MedicationDoseAlert from '../../MedicationDoseAlert';
import { HiddenInput } from '../../fields/HiddenInput';
import EditLineButton from '../EditLineButton';
import FrequencyFormItem from '../FrequencyFormItem';
import InstructionFormItem from '../InstructionFormItem';
import PackageItemTitleContainer from '../PackageItemTitleContainer';
import StartEndTimeFormItems from '../StartEndTimeFormItems';
import './PackageMedicationForm.css';

interface PackageMedicationFormProps {
    medication: PackageMedInstruction;
    form: FormInstance<PackageFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    fromEstimate?: boolean;
    durationHours?: number;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const PackageMedicationForm: React.FC<PackageMedicationFormProps> = ({
    medication,
    form,
    index,
    hiddenSectionClass,
    fromEstimate,
    durationHours,
    setActiveLineItem,
    activeLineItem,
}) => {
    const [hasError, setHasError] = useState(false);
    const lineId = `M-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const { data: routeOptions } = useGetMedicationRoutesQuery(null);

    const [getSupplemental, { data: supplementalData }] = useLazyGetSupplementalByNameQuery();

    const medConcentration = useMemo(() => getMedicationConcentration(medication) ?? 1, [medication]);
    const medFields = [
        ['medications', index, 'numerator_value'],
        ['medications', index, 'denominator_value'],
        ['medications', index, 'dose_per_kg'],
        ['medications', index, 'dose_acknowledge'],
        ['medications', index, 'route_id'],
        ['medications', index, 'frequency'],
        ['medications', index, 'end_time'],
        ['medications', index, 'start_time'],
        ['medications', index, 'instruction'],
    ];
    const medErrorArray = form.getFieldsError(medFields);

    const [isSerialCheaper, setIsSerialCheaper] = useState(false);

    const setSerial = (changes: { [key in string]: string | SelectValue | number }) => {
        const fieldsValue = form.getFieldsValue();
        const medicationFields = {
            ...fieldsValue.medications[index],
            ...changes,
        };
        const dose = getDose(medicationFields.numerator_value);
        let startTime, endTime;
        if (fromEstimate) {
            startTime = 0;
            endTime = (durationHours ?? 0) * 3600;
        } else {
            startTime = (medicationFields?.start_time ?? fieldsValue.start_time)?.unix();
            endTime = (medicationFields?.end_time ?? fieldsValue.end_time)?.unix();
        }
        const serial = checkIfSerialCheaper(
            medication,
            medicationFields.frequency,
            startTime,
            endTime,
            medicationFields.supplemental_cost_cents,
            dose,
        );
        const unit_cost_cents = serial ? medication.price_cents_serial ?? 0 : medication.cents ?? 0;
        setIsSerialCheaper(serial);
        form.setFieldsValue({
            ...fieldsValue,
            medications: fieldsValue.medications.map((diag: any, indx: number) => {
                return indx === index ? { ...diag, ...changes, serial, unit_cost_cents } : diag;
            }),
        });
    };

    const hasMedicationDoseAlert = (patientWeight: number, dose: number) => {
        if (!patientWeight || patientWeight <= 0) return;

        const { dose_unit, low_dose_alert, high_dose_alert } = medication;

        const dosePerKG = roundTo(dose / patientWeight, 3);
        const roundedLowDoseAlert = roundTo(low_dose_alert, 3);
        const roundedHighDoseAlert = roundTo(high_dose_alert, 3);

        return getMedicationAlertDescription(dosePerKG, dose_unit, roundedLowDoseAlert, roundedHighDoseAlert);
    };

    const getDose = (numeratorValue: number) => {
        return medication.dose_unit === medication.numerator_unit ? numeratorValue : numeratorValue / medConcentration;
    };

    const getDosePerKg = (numerator_value: number, dose_unit: string, patientWeight: number) => {
        const dose = getDose(numerator_value);

        if (dose_unit === medication.numerator_unit) {
            return roundTo(dose / patientWeight, 3);
        } else if (dose_unit === medication.denominator_unit) {
            return roundTo((dose * medConcentration) / patientWeight, 3);
        } else {
            return roundTo(dose, 3);
        }
    };

    const setMedicationFieldValue = (value: any) => {
        const fieldsValue = form.getFieldsValue();

        if (fieldsValue.medications) {
            form.setFieldsValue({
                ...fieldsValue,
                medications: fieldsValue.medications.map((med: any, indx: number) => {
                    return indx === index ? { ...med, ...value } : med;
                }),
            });
        }
        setSerial({});
    };

    const handleShouldUpdate = (prev: PackageFormInstance, curr: PackageFormInstance) => {
        if (prev.patient_weight !== curr.patient_weight) {
            handleUpdateDosePerKg();
        }
        return (
            prev.patient_weight !== curr.patient_weight ||
            prev.medications[index].checked !== curr.medications[index].checked ||
            prev.medications[index].frequency !== curr.medications[index].frequency ||
            prev.medications[index].route_id !== curr.medications[index].route_id ||
            prev.medications[index].numerator_value !== curr.medications[index].numerator_value ||
            prev.medications[index].start_time !== curr.medications[index].start_time ||
            prev.medications[index].end_time !== curr.medications[index].end_time
        );
    };

    const handleUpdateDosePerKg = () => {
        const patientWeight = form.getFieldValue('patient_weight');
        const numeratorValue = roundTo(form.getFieldValue(['medications', index, 'numerator_value']), 3);

        if (!patientWeight || patientWeight <= 0) return;

        if (!!numeratorValue) {
            const defaultDose = calculateDefaultDose(medication, medication.approx_patient_weight_kg ?? undefined, patientWeight);

            if (!!defaultDose) {
                setMedicationFieldValue({
                    numerator_value: defaultDose.dose,
                    denominator_value: defaultDose.dose / medConcentration,
                });
            } else {
                setMedicationFieldValue({
                    dose_per_kg: getDosePerKg(numeratorValue, medication.dose_unit, patientWeight),
                });
            }
        } else if (medication.default_dose) {
            const defaultDose = calculateDefaultDose(medication, medication.approx_patient_weight_kg ?? undefined, patientWeight);

            if (!!defaultDose) {
                setMedicationFieldValue({
                    numerator_value: defaultDose.dose,
                    denominator_value: defaultDose.dose / medConcentration,
                    dose_per_kg: getDosePerKg(defaultDose.dose, defaultDose.unit, patientWeight),
                });
            } else {
                setMedicationFieldValue({
                    numerator_value: medication.default_dose,
                    denominator_value: medication.default_dose / medConcentration,
                    dose_per_kg: getDosePerKg(medication.default_dose, medication.default_dose_unit, patientWeight),
                });
            }
        }
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['medications', index, 'numerator_value'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (medErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, medErrorArray]);

    useEffect(() => {
        const patientWeight = form.getFieldValue('patient_weight');

        if (patientWeight) {
            handleUpdateDosePerKg();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (supplementalData) {
            setMedicationFieldValue({
                is_recurring_supplemental: supplementalData.recurring,
                supplemental_cost_cents: supplementalData.cents,
            });
        }
    }, [supplementalData]);

    useEffect(() => {
        if (medication.route_id) {
            getSupplemental({ name: medication.route_id, controlledMedication: medication.controlled_drug });
        }
    }, [medication.route_id]);

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const patientWeight = getFieldValue('patient_weight');
                const checked =
                    getFieldValue(['medications', index, 'checked']) ??
                    (medication.required ? medication.required : medication.toggled) ??
                    true;
                const numeratorValue = roundTo(getFieldValue(['medications', index, 'numerator_value']), 3);
                const dose = getDose(numeratorValue);
                const disabledClass = !checked ? 'disabled-line' : '';
                const complexMedFieldsValidation =
                    medication.calculator_type === 'complex'
                        ? !getFieldValue(['medications', index, 'denominator_value']) ||
                          !getFieldValue(['medications', index, 'dose_per_kg'])
                        : false;
                const initialError =
                    !getFieldValue(['medications', index, 'numerator_value']) ||
                    complexMedFieldsValidation ||
                    !(getFieldValue(['medications', index, 'route_id']) ?? medication.route_id) ||
                    !(getFieldValue(['medications', index, 'frequency']) ?? medication.frequency);
                const hasMedicationAlert = hasMedicationDoseAlert(patientWeight, dose);
                const serialName = isSerialCheaper ? `${medication.name} - Serial ${medication.serial_hours} Hours` : medication.name;

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass} ${disabledClass}`}
                        onClick={() => {
                            if (!hasError && !isEditing) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['medications', index, 'id']} initialValue={medication.medication_id} />
                            <HiddenInput fieldName={['medications', index, 'dose_unit']} initialValue={medication.dose_unit} />
                            <HiddenInput fieldName={['medications', index, 'calculator_type']} initialValue={medication.calculator_type} />
                            <HiddenInput fieldName={['medications', index, 'numerator_unit']} initialValue={medication.numerator_unit} />
                            <HiddenInput
                                fieldName={['medications', index, 'denominator_unit']}
                                initialValue={medication.denominator_unit}
                            />
                            <HiddenInput fieldName={['medications', index, 'concentration']} initialValue={medConcentration} />
                            <HiddenInput fieldName={['medications', index, 'unit_cost_cents']} initialValue={medication.cents} />
                            <HiddenInput fieldName={['medications', index, 'name']} initialValue={medication.name} />
                            <HiddenInput fieldName={['medications', index, 'controlled_drug']} initialValue={medication.controlled_drug} />
                            <HiddenInput fieldName={['medications', index, 'supplemental_cost_cents']} initialValue={null} />
                            <HiddenInput fieldName={['medications', index, 'is_recurring_supplemental']} initialValue={null} />
                            <HiddenInput fieldName={['medications', index, 'required']} initialValue={medication.required} />
                            <HiddenInput fieldName={['medications', index, 'serial']} initialValue={isSerialCheaper} />
                            <HiddenInput fieldName={['medications', index, 'serial_hours']} initialValue={medication.serial_hours} />
                            <HiddenInput fieldName={['medications', index, 'no_charge']} initialValue={medication.no_charge} />
                        </div>

                        <Form.Item
                            className='grid-table__sticky-column'
                            initialValue={medication.required ? medication.required : medication.toggled}
                            valuePropName='checked'
                            name={['medications', index, 'checked']}
                        >
                            {medication.required ? (
                                <Tooltip title='Required order. Cannot be removed.'>
                                    <CheckOutlined />
                                </Tooltip>
                            ) : (
                                <Checkbox />
                            )}
                        </Form.Item>

                        <div className='grid-table__sticky-column'>
                            <PackageItemTitleContainer checked={checked} no_charge={medication.no_charge}>
                                <Tooltip title={serialName.length > 45 ? serialName : null}>
                                    <span className='treatment-sheet-side-column__title'>{serialName}</span>
                                </Tooltip>
                            </PackageItemTitleContainer>

                            {checked && (
                                <div className='package-medication-container'>
                                    {!fromEstimate && (
                                        <MedicationData
                                            group={{
                                                ...medication,
                                                frequency: getFieldValue(['medications', index, 'frequency']) ?? medication.frequency ?? '',
                                                route_id: getFieldValue(['medications', index, 'route_id']) ?? medication.route_id ?? '',
                                                dose: dose ?? medication.default_dose ?? medication.dose,
                                            }}
                                            instructionIcon={<></>}
                                            patientWeight={patientWeight > 0 ? patientWeight : null}
                                        />
                                    )}

                                    {!!dose && !!hasMedicationAlert && (
                                        <Tooltip
                                            className='package-medication__dose-alert-icon'
                                            title={'The dose is not within the recommended range.'}
                                        >
                                            <AlertFilled style={{ color: 'var(--veg-secondary-red)' }} />
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        </div>

                        {checked ? (
                            <>
                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    Dose
                                </div>
                                <div>
                                    {medication.calculator_type === 'complex' ? (
                                        <div className='package-medication-dose__inputs'>
                                            <Tooltip
                                                title={
                                                    (!patientWeight || patientWeight <= 0) && isEditing ? 'Patient weight required' : null
                                                }
                                            >
                                                <Form.Item
                                                    name={['medications', index, 'numerator_value']}
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (isNaN(value)) {
                                                                    return Promise.reject('Required');
                                                                }

                                                                if (value <= 0) {
                                                                    return Promise.reject('Cannot be smaller or equal to 0');
                                                                }

                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {isEditing ? (
                                                        <Input
                                                            disabled={!patientWeight || patientWeight <= 0}
                                                            type='number'
                                                            suffix={medication.numerator_unit}
                                                            min={0}
                                                            onChange={(e) => {
                                                                const value = parseFloat(e.target.value);

                                                                setMedicationFieldValue({
                                                                    numerator_value: value,
                                                                    denominator_value: roundTo(value / medConcentration, 3),
                                                                    dose_per_kg: roundTo(value / patientWeight, 3),
                                                                });
                                                            }}
                                                        />
                                                    ) : (
                                                        <span>
                                                            {numeratorValue ?? '-'}
                                                            {medication.numerator_unit}
                                                        </span>
                                                    )}
                                                </Form.Item>

                                                <Form.Item
                                                    name={['medications', index, 'denominator_value']}
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (isNaN(value)) {
                                                                    return Promise.reject('Required');
                                                                }

                                                                if (value <= 0) {
                                                                    return Promise.reject('Cannot be smaller or equal to 0');
                                                                }

                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {isEditing ? (
                                                        <Input
                                                            disabled={!patientWeight || patientWeight <= 0}
                                                            type='number'
                                                            suffix={medication.denominator_unit}
                                                            min={0}
                                                            onChange={(e) => {
                                                                const value = parseFloat(e.target.value);

                                                                setMedicationFieldValue({
                                                                    numerator_value: roundTo(value * medConcentration, 3),
                                                                    denominator_value: value,
                                                                    dose_per_kg: roundTo((value * medConcentration) / patientWeight, 3),
                                                                });
                                                            }}
                                                        />
                                                    ) : (
                                                        <span>
                                                            {roundTo(getFieldValue(['medications', index, 'denominator_value']), 3) ?? '-'}{' '}
                                                            {medication.denominator_unit}
                                                        </span>
                                                    )}
                                                </Form.Item>

                                                <Form.Item
                                                    name={['medications', index, 'dose_per_kg']}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (isNaN(value)) {
                                                                    return Promise.reject('Required');
                                                                }

                                                                if (value <= 0) {
                                                                    return Promise.reject('Cannot be smaller or equal to 0');
                                                                }

                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {isEditing ? (
                                                        <Input
                                                            disabled={!patientWeight || patientWeight <= 0}
                                                            type='number'
                                                            suffix={`${medication.numerator_unit}/kg`}
                                                            min={0}
                                                            onChange={(e) => {
                                                                const value = parseFloat(e.target.value);

                                                                setMedicationFieldValue({
                                                                    numerator_value: roundTo(value * patientWeight, 3),
                                                                    denominator_value: roundTo(
                                                                        (value * patientWeight) / medConcentration,
                                                                        3,
                                                                    ),
                                                                    dose_per_kg: value,
                                                                });
                                                            }}
                                                        />
                                                    ) : (
                                                        <span>
                                                            {getFieldValue(['medications', index, 'dose_per_kg']) ?? '-'}
                                                            {`${medication.numerator_unit}/kg`}
                                                        </span>
                                                    )}
                                                </Form.Item>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <Form.Item
                                            name={['medications', index, 'numerator_value']}
                                            initialValue={medication.default_dose}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (isNaN(value)) {
                                                            return Promise.reject('Required');
                                                        }

                                                        if (value <= 0) {
                                                            return Promise.reject('Cannot be smaller or equal to 0');
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            {isEditing ? (
                                                <Input type='number' suffix={medication.dose_unit} />
                                            ) : (
                                                <span>
                                                    {numeratorValue ?? '-'}
                                                    {medication.dose_unit}
                                                </span>
                                            )}
                                        </Form.Item>
                                    )}
                                </div>

                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    Route
                                </div>
                                <Form.Item
                                    name={['medications', index, 'route_id']}
                                    initialValue={medication.route_id}
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    {isEditing ? (
                                        <Select
                                            showSearch
                                            options={routeOptions?.map((route: any) => ({
                                                key: route.name,
                                                value: route.id,
                                                label: route.id,
                                            }))}
                                            filterOption={(input, option) =>
                                                (option?.label as string).toLowerCase().includes(input.toLowerCase()) ||
                                                (option?.key as string).toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={(value) => {
                                                getSupplemental({ name: `${value}` });
                                            }}
                                        />
                                    ) : (
                                        <span>{getFieldValue(['medications', index, 'route_id']) ?? medication.route_id}</span>
                                    )}
                                </Form.Item>

                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    Frequency
                                </div>
                                <FrequencyFormItem
                                    formFieldName={['medications', index, 'frequency']}
                                    initialValue={medication.frequency}
                                    isEditing={isEditing}
                                    formFieldValue={getFieldValue(['medications', index, 'frequency']) ?? medication.frequency}
                                    onFormChange={(changes) => {
                                        setSerial(changes);
                                    }}
                                />

                                {!fromEstimate && (
                                    <>
                                        <StartEndTimeFormItems
                                            endTimeFormFieldName={['medications', index, 'end_time']}
                                            startTimeFormFieldName={['medications', index, 'start_time']}
                                            isEditing={isEditing}
                                            endTimeFormFieldValue={getFieldValue(['medications', index, 'end_time'])}
                                            startTimeFormFieldValue={getFieldValue(['medications', index, 'start_time'])}
                                        />

                                        <InstructionFormItem
                                            formFieldName={['medications', index, 'instruction']}
                                            initialValue={null}
                                            isEditing={isEditing}
                                            formFieldValue={getFieldValue(['medications', index, 'instruction'])}
                                        />
                                    </>
                                )}
                                {isEditing && isSerialCheaper ? (
                                    <div
                                        className={`serial-alert-column-medication ${
                                            !isEditing || !isSerialCheaper ? 'serial-alert-column--hide' : ''
                                        }`}
                                    >
                                        <AlertSerialPrice serialHours={medication.serial_hours ?? 0} />
                                    </div>
                                ) : (
                                    <div className='package-medication__empty-column' />
                                )}

                                <div
                                    className={`package-medication__dose-alert ${!isEditing ? 'package-medication__dose-alert--hide' : ''}`}
                                >
                                    <MedicationDoseAlert
                                        formName={['medications', index, 'dose_acknowledge']}
                                        doseUnit={medication.dose_unit}
                                        dose={getDose(numeratorValue)}
                                        highDoseAlert={medication.high_dose_alert}
                                        lowDoseAlert={medication.low_dose_alert}
                                        patientWeight={patientWeight > 0 ? patientWeight : null}
                                        medication={medication}
                                        alertDirection='horizontal'
                                        hideConfirmation
                                    />
                                    {hasMedicationAlert && (
                                        <MedicationDoseConfirmation formName={['medications', index, 'dose_acknowledge']} />
                                    )}
                                </div>

                                <EditLineButton
                                    disabled={isEditing}
                                    hasError={hasError || initialError}
                                    onClick={() => {
                                        if (!hasError) setActiveLineItem(lineId);
                                    }}
                                />
                            </>
                        ) : (
                            <div className='disabled-line__form-inputs' />
                        )}
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default PackageMedicationForm;
