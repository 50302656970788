import { AlertFilled, DeleteOutlined, FireFilled, InfoCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Form, FormInstance, Input, Row, Select, Tooltip } from 'antd';
import { CSSProperties, useState } from 'react';
import { useGetPatientAlertCategoriesQuery } from 'services/patientService';
import { MAX_PET_ALERTS } from 'utils/constants';
import { PatientAlert } from 'utils/dataTypes';
import { validateFieldIsShorterThan } from 'utils/validationFuncs';

interface PatientAlertsProps {
    initialAlerts?: PatientAlert[];
    form: FormInstance<any>;
}

const formItemStyle: CSSProperties = {
    width: '100%',
};

export const PatientAlerts = ({ initialAlerts, form }: PatientAlertsProps) => {
    const { data: patientAlertCategories } = useGetPatientAlertCategoriesQuery(null);
    const [alertsToDelete, setAlertsToDelete] = useState<any[]>([]);

    const getIcon = (name: string) => {
        switch (name) {
            case 'Information':
                return <InfoCircleOutlined className='option-icon' style={{ color: 'var(--veg-blue)' }} />;
            case 'Use Caution - Staff':
                return <FireFilled className='option-icon' style={{ color: 'var(--veg-secondary-gold)' }} />;
            case 'Medical Risk - Patient':
                return <AlertFilled className='option-icon' style={{ color: 'var(--veg-red)' }} />;
            default:
                return <span className='option-icon no-category-icon'></span>;
        }
    };

    return (
        <Collapse ghost className='patient-alerts-collapse'>
            <Collapse.Panel
                className='patient-alerts-collapse'
                key='alerts'
                header={<Divider orientation='left'>Alert</Divider>}
                forceRender
            >
                <Form.List name='alerts' initialValue={initialAlerts?.map((_) => {}) ?? undefined}>
                    {(fields, { add, remove }) => (
                        <>
                            <div className='patient-alert-info' style={{ marginBottom: fields.length > 0 ? '16px' : '8px' }}>
                                <h4>FOR INTERNAL USE ONLY</h4>
                                <div style={{ color: 'var(--gray-8)' }}>
                                    Alerts highlight important details, cautions, or risks and will appear in all visits associated with the
                                    patient. Alerts will not appear on medical records.
                                </div>
                            </div>
                            {fields.map((field, index) => {
                                const toBeDeleted = alertsToDelete.includes(field.name);
                                return (
                                    <div
                                        style={{
                                            display: toBeDeleted ? 'none' : 'block',
                                        }}
                                    >
                                        <Form.Item
                                            hidden
                                            name={[field.name, 'alert_id']}
                                            initialValue={initialAlerts && initialAlerts[index] ? initialAlerts[index].id : null}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={[field.name, 'alert_delete']} initialValue={null}>
                                            <Input />
                                        </Form.Item>
                                        <div style={{ display: toBeDeleted ? 'none' : 'block' }}>
                                            <Row style={{ width: '100%' }}>
                                                <Col span={22}>
                                                    <Form.Item
                                                        name={[field.name, 'alert_name']}
                                                        label={
                                                            <Tooltip title='Limited to short messages and used only when prominent visibility is necessary.'>
                                                                Alert <QuestionCircleOutlined style={{ margin: '0 4px' }} />
                                                            </Tooltip>
                                                        }
                                                        preserve={false}
                                                        style={formItemStyle}
                                                        labelCol={{ span: 7 }}
                                                        wrapperCol={{ span: 17 }}
                                                        initialValue={
                                                            initialAlerts && initialAlerts[index] ? initialAlerts[index].alert : null
                                                        }
                                                        rules={
                                                            toBeDeleted
                                                                ? undefined
                                                                : [
                                                                      { required: true, message: 'Alert is required' },
                                                                      { validator: validateFieldIsShorterThan('alert', 51) },
                                                                  ]
                                                        }
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2} style={{ display: 'flex' }}>
                                                    <Button
                                                        type='ghost'
                                                        icon={<DeleteOutlined />}
                                                        style={{ margin: '8px 0 0 auto', border: 0 }}
                                                        onClick={() => {
                                                            const { alerts } = form?.getFieldsValue();
                                                            if (alerts[field.name].alert_id === null) {
                                                                remove(field.name);
                                                            } else {
                                                                setAlertsToDelete([...alertsToDelete, field.name]);
                                                                Object.assign(alerts[field.name], { alert_delete: true });
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={22}>
                                                    <Form.Item
                                                        name={[field.name, 'alert_category_id']}
                                                        label={
                                                            <Tooltip title='Color of the alert is determined by category.'>
                                                                Category <QuestionCircleOutlined style={{ margin: '0 4px' }} />
                                                            </Tooltip>
                                                        }
                                                        preserve={false}
                                                        style={formItemStyle}
                                                        labelCol={{ span: 7 }}
                                                        wrapperCol={{ span: 17 }}
                                                        initialValue={
                                                            initialAlerts && initialAlerts[index]
                                                                ? initialAlerts[index].category_id
                                                                : patientAlertCategories?.find((item) => item.name === 'Information')?.id
                                                        }
                                                        rules={
                                                            toBeDeleted ? undefined : [{ required: true, message: 'Category is required' }]
                                                        }
                                                    >
                                                        <Select<string>
                                                            allowClear
                                                            showSearch
                                                            className='select-category'
                                                            filterOption={(inputValue, option) =>
                                                                (option?.key as string)
                                                                    ?.toUpperCase()
                                                                    .indexOf(inputValue.trim().toUpperCase()) !== -1
                                                            }
                                                            placeholder='Please select'
                                                        >
                                                            {patientAlertCategories?.map((item: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        className='patient-alert-category-option'
                                                                        value={item.id}
                                                                        key={item.name}
                                                                    >
                                                                        {getIcon(item.name)} {item.name}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Divider />
                                        </div>
                                    </div>
                                );
                            })}
                            <Tooltip
                                title={
                                    fields.length - alertsToDelete.length >= MAX_PET_ALERTS
                                        ? `A patient cannot have more than ${MAX_PET_ALERTS} alerts.`
                                        : null
                                }
                            >
                                <Button
                                    onClick={() => {
                                        add();
                                    }}
                                    disabled={fields.length - alertsToDelete.length >= MAX_PET_ALERTS}
                                >
                                    <PlusOutlined /> Alert
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </Form.List>
            </Collapse.Panel>
        </Collapse>
    );
};
