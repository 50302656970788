import { Col, Row, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useGetEstimateTaxQuery, useGetTaxForMultipleEstimatesQuery } from 'services/estimateService';
import { formatCurrency, roundTo } from 'utils/formatFuncs';
import { ConsentFormEstimateItem, isInstanceOfConsentFormEstimateItemMedical } from 'utils/types/consentFormTypes';
import styles from './EstimateSummary.module.css';
import { EstimatesLedger } from './EstimatesUserLedger';
import './estimate-overrides.css';
import { calculateItemCost, calculateTotalEstimateCost, calculateUnitCost } from '../EstimatesNew/utils';


const generateColumnName = (estimateItem: ConsentFormEstimateItem): string =>{
    let name = estimateItem.name;
    if (estimateItem.serial){
        name += ` - Serial ${estimateItem.serial_hours} hours`;
    }
    if (estimateItem.is_free){
        name += ' - Discount';
    }
    return name;
}
const generateColumnUnitCost = (estimateItem: ConsentFormEstimateItem): number =>{
    const unit_cost = calculateUnitCost(estimateItem);
    if (estimateItem.serial && estimateItem.serial_hours){
        return estimateItem.unit_cost_cents * estimateItem.serial_hours
    }
    return unit_cost

}


interface UserEstimateContentProps {
    estimate_items: ConsentFormEstimateItem[];
    estimate_name: string | null;
    prior_approved_cost_cents: number | null;
    tax_cents: number | null;

    amountPaid: number;
    estimateId: number;
    visitId: number;
    discountBasis?: number;
    cap_amount_cents?: number;
}

export const UserEstimateContent = ({
    estimate_items,
    tax_cents,
    prior_approved_cost_cents,
    amountPaid,
    discountBasis,
    cap_amount_cents,
    estimateId,
}: UserEstimateContentProps) => {
    const totalTax = (tax_cents ?? 0);

    // Set State Variables
    const columns: ColumnType<ConsentFormEstimateItem>[] = [
        {
            title: (
                <Typography.Text strong className={styles['grey-5-force']}>
                    Order
                </Typography.Text>
            ),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: (
                <Typography.Text strong className={styles['grey-5-force']}>
                    Quantity
                </Typography.Text>
            ),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text: number, row: ConsentFormEstimateItem) => {
                if (row.serial && row.serial_hours){
                    return `${roundTo(text / row.serial_hours, 0)} unit`
                }
                return (
                    <>
                        {roundTo(text, 3)}{' '}
                        {isInstanceOfConsentFormEstimateItemMedical(row) && row.pricing_unit ? row.pricing_unit : row.unit}
                    </>
                )
            }
        },
        {
            title: (
                <Typography.Text strong className={styles['grey-5-force']}>
                    Unit Price
                </Typography.Text>
            ),
            dataIndex: 'price',
            key: 'price',
            render: (text: number, record: ConsentFormEstimateItem) => (
                <Typography.Text>{formatCurrency(record?.unit_cost_cents_with_supp ?? 0)}</Typography.Text>
            ),
            align: 'right',
        },
        {
            title: (
                <Typography.Text strong className={styles['grey-5-force']}>
                    Total Price
                </Typography.Text>
            ),
            dataIndex: 'instruction_price',
            key: 'totalPrice',
            render: (text: number, record: ConsentFormEstimateItem) => (
                <Typography.Text data-cy={'totalPriceValue'}>{formatCurrency(calculateTotalEstimateCost(0, record))}</Typography.Text>
            ),
            align: 'right',
        },
    ];
    const columnsMobile: ColumnType<ConsentFormEstimateItem>[] = [
        {
            title: <Typography.Text strong>Order</Typography.Text>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <Typography.Text strong>Total Price</Typography.Text>,
            dataIndex: 'instruction_price',
            key: 'totalPrice',
            render: (text: number, record: any) => (
                <Typography.Text data-cy={'totalPriceValue'}>{formatCurrency(record.total_cost_cents)}</Typography.Text>
            ),
            align: 'right',
        },
    ];

    const estimateDataWithExtraDiscountRows =
        estimate_items
            .filter((estimateItem) => estimateItem.present_quantity > 0)
            .reduce<ConsentFormEstimateItem[]>((estimatesWithFree, estimateItem) => {
                const usableQuantity = estimateItem.present_quantity;
                const unitCosts = generateColumnUnitCost(estimateItem);
                const totalCostCents = calculateItemCost({ ...estimateItem});

                const newEstimateItem = {
                    ...estimateItem,
                    quantity: usableQuantity,
                    total_cost_cents: totalCostCents,
                    unit_cost_cents_with_supp: unitCosts,
                    name: generateColumnName(estimateItem),
                };
                if (estimateItem.is_free) {
                    const estimateItemFree: ConsentFormEstimateItem = {
                        ...estimateItem,
                        present_quantity: usableQuantity,
                        name: generateColumnName(estimateItem),
                        unit_cost_cents: estimateItem.unit_cost_cents * -1,
                        unit_cost_cents_with_supp: unitCosts * -1,
                    };
                    return [...estimatesWithFree, newEstimateItem, estimateItemFree];
                } else {
                    return [...estimatesWithFree, newEstimateItem];
                }
            }, []) ?? [];

    const totalCostNew: number = estimateDataWithExtraDiscountRows.reduce(calculateTotalEstimateCost, 0);
    const totalCostPreviouslyApproved = prior_approved_cost_cents ?? 0;
    const totalOldAndNew = totalCostNew + totalCostPreviouslyApproved;

    const UserEstimateTable = (
        <Table
            className={`${styles['estimates-table']} estimates-table-test`}
            scroll={{
                x: true,
            }}
            locale={{ emptyText: ' ' }}
            columns={columns}
            pagination={false}
            dataSource={estimateDataWithExtraDiscountRows}
            data-cy={'userEstimateTable'}
        />
    );
    const UserEstimateTableMobile = (
        <Table
            className={`${styles['estimates-table']}`}
            scroll={{
                x: true,
            }}
            locale={{ emptyText: ' ' }}
            columns={columnsMobile}
            pagination={false}
            dataSource={estimateDataWithExtraDiscountRows}
        />
    );

    return (
        <>
            <div className={styles['estimate-user-mobile']}>
                <Row className={styles['estimate-row-header']} justify='space-between'>
                    <Col>
                        <Typography.Text strong> New Recommended</Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text strong>{formatCurrency(totalCostNew)}</Typography.Text>
                    </Col>
                </Row>

                {UserEstimateTableMobile}

                <Row className={styles['estimate-row-header']} justify='space-between'>
                    <Col>
                        <Typography.Text strong>Previously Approved</Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text strong>{formatCurrency(totalCostPreviouslyApproved)} </Typography.Text>
                    </Col>
                </Row>
                <Row justify='end'>
                    <EstimatesLedger
                        totalOldAndNew={totalOldAndNew}
                        totalTax={totalTax ?? 0}
                        amountPaid={amountPaid}
                        discountBasis={discountBasis}
                        cap_amount_cents={cap_amount_cents}
                    />
                </Row>
            </div>
            <div className={styles['estimate-user-desktop']}>
                <Row className={styles['estimate-row-header']} justify='space-between' align='middle'>
                    <Typography.Title level={4}> New Recommended Treatments</Typography.Title>
                    <Typography.Title data-cy={'newRecommendedTreatmentsValue'} level={5}>
                        {formatCurrency(totalCostNew)}{' '}
                    </Typography.Title>
                </Row>

                {UserEstimateTable}
                <Row className={styles['estimate-row-header']} justify='space-between' align='middle'>
                    <Typography.Title level={4}> Previously Approved Treatments</Typography.Title>
                    <Typography.Title data-cy={'previouslyApprovedTreatmentsValue'} level={5}>
                        {formatCurrency(totalCostPreviouslyApproved)}{' '}
                    </Typography.Title>
                </Row>
                <Row justify='end' data-cy={'estimatesLedgerRow'}>
                    <EstimatesLedger
                        totalOldAndNew={totalOldAndNew}
                        totalTax={totalTax ?? 0}
                        amountPaid={amountPaid}
                        discountBasis={discountBasis}
                        cap_amount_cents={cap_amount_cents}
                    />
                </Row>
            </div>
        </>
    );
};
