import { useEffect, useState } from 'react';
import { Row, Col, Modal, Input, Form, Button, FormInstance, Spin } from 'antd';
import { formatCurrency, formatPhoneNumber, roundTo } from 'utils/formatFuncs';
import { useAddWebCheckoutMutation, useDeactivateCheckoutMutation, useGetCurrentFormLinkIdQuery } from 'services/paymentService';
import { CustomerInfo } from 'utils/dataTypes';
import { FormWrapper } from 'components/forms/FormWrapper';
import { SUPPORTED_PAYMENT_AMOUNT_REGEX, SUPPORTED_PHONE_NUMBER_REGEX } from 'utils/constants';
import { validatePaymentAmountInput } from 'utils/miscFuncs';
import { PhoneNumberField } from 'components/forms/fields/PhoneNumberField';
import { EstimateInvoiceRadio, EstimateInvoiceRadioValues } from './EstimateInvoiceRadio';
import { PaymentInfo, PaymentInfoProps } from './PaymentInfo';

interface WebCheckoutModalProps {
    visitId: number;
    customerData: CustomerInfo | undefined;
    isVisible: boolean;
    isFetching: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    paymentInfoProps: PaymentInfoProps;
    estimateBalanceDue: number;
    invoiceBalanceDue: number;
    form: FormInstance;
}

export const WebCheckoutModal = ({
    isVisible,
    isFetching,
    setIsVisible,
    visitId,
    customerData,
    paymentInfoProps,
    estimateBalanceDue,
    invoiceBalanceDue,
    form,
}: WebCheckoutModalProps) => {
    const [finalAmountCents, setFinalAmountCents] = useState<number | null>(null);
    const [mobileNumber, setMobileNumber] = useState('');
    const [isSent, setIsSent] = useState(false);
    const [resend, setResend] = useState(false);
    const [addWebCheckout, { isLoading, isSuccess, isError }] = useAddWebCheckoutMutation();
    const [deactivateCheckout, { isSuccess: isDeactivated }] = useDeactivateCheckoutMutation();
    const { data: currentFormLink } = useGetCurrentFormLinkIdQuery(visitId, { skip: !isVisible, refetchOnMountOrArgChange: true });

    const [radioValue, setRadioValue] = useState<EstimateInvoiceRadioValues>('estimate');

    const balanceDue = radioValue === 'estimate' ? estimateBalanceDue : invoiceBalanceDue;

    useEffect(() => {
        if (isSuccess) {
            setResend(false);
            setIsSent(true);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isDeactivated) {
            setIsSent(false);
        }
    }, [isDeactivated]);

    const ModalContent = () => {
        return (
            <>
                {isError ? (
                    <p>A mobile payment link can not be created. Please refresh the page and try again.</p>
                ) : (
                    <>
                        {!resend && currentFormLink && !isSent && (
                            <p>
                                A payment link already exists for this visit. Please discontinue the current link before sending a new one.
                            </p>
                        )}

                        {!resend && isSent && (
                            <p>
                                A link to pay {formatCurrency(finalAmountCents || 0)} was sent to {customerData?.first_name}{' '}
                                {customerData?.last_name} at {formatPhoneNumber(mobileNumber)}.
                            </p>
                        )}

                        {(resend || (!currentFormLink && !isSent)) && (
                            <>
                                <EstimateInvoiceRadio radioValue={radioValue} setRadioValue={setRadioValue} />
                                <PaymentInfo {...paymentInfoProps} mode={radioValue} />
                                {balanceDue > 0 && (
                                    <Row>
                                        <Col>
                                            <FormWrapper
                                                form={form}
                                                onFormChange={form.setFieldsValue}
                                                onFinishFailed={() => {}}
                                                onFinish={(values: any) => {
                                                    const amountCents = roundTo(parseFloat(values.amount.replaceAll(',', '')) * 100, 0);

                                                    setFinalAmountCents(amountCents);
                                                    setMobileNumber(values.phoneNumber.toString());
                                                    addWebCheckout({
                                                        visitId: visitId,
                                                        body: {
                                                            amount_cents: amountCents,
                                                            phone_number: values.phoneNumber.toString(),
                                                        },
                                                    });
                                                }}
                                                bottomBar={<></>}
                                            >
                                                <PayLinkFormItem />
                                                <PhoneNumberFormItem />
                                            </FormWrapper>
                                        </Col>
                                        <Col
                                            span={24}
                                            style={{
                                                marginTop: 16,
                                                color: '#8C8C8C',
                                            }}
                                        >
                                            A text message containing a link to pay with credit card will be sent to the mobile number
                                            provided.
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
    };

    const ModalFooter = () => {
        const close = () => {
            setIsVisible(false);
            setResend(false);
        };
        const discontinue = () => {
            deactivateCheckout({ visitId: visitId });
        };
        const doResend = () => {
            setResend(true);
        };

        if (balanceDue <= 0) {
            return (
                <>
                    {!resend && (currentFormLink || isSent) && (
                        <>
                            <Button type='default' onClick={doResend}>
                                Resend
                            </Button>
                            <Button type='default' onClick={discontinue}>
                                Discontinue
                            </Button>
                        </>
                    )}
                    <Button type='primary' autoFocus onClick={close}>Close</Button>
                </>
            );
        }

        return (
            <div style={{ visibility: isFetching ? 'hidden' : 'visible' }}>
                {!isError && !resend && (currentFormLink || isSent) && (
                    <>
                        <Button type='default' onClick={doResend}>
                            Resend
                        </Button>
                        <Button type='default' onClick={discontinue}>
                            Discontinue
                        </Button>
                        <Button type='primary' autoFocus onClick={close}>
                            Close
                        </Button>
                    </>
                )}

                {!isError && (resend || (!currentFormLink && !isSent)) && (
                    <>
                        <Button type='default' onClick={close}>
                            Cancel
                        </Button>

                        <Button type='primary' autoFocus onClick={form.submit} loading={isLoading}>
                            Send Link
                        </Button>
                    </>
                )}

                {isError && (
                    <Button type='primary' autoFocus onClick={close}>
                        Close
                    </Button>
                )}
            </div>
        );
    };

    const PayLinkFormItem = () => {
        return (
            <Form.Item
                name='amount'
                label='Amount'
                className='payment-amount-input'
                style={{ width: '100%' }}
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 8 }}
                initialValue={formatCurrency(finalAmountCents || balanceDue).replace('$', '')}
                validateFirst={true}
                rules={[
                    { required: true, message: 'Please enter an amount' },
                    { pattern: SUPPORTED_PAYMENT_AMOUNT_REGEX, message: 'Invalid amount' },
                    () => ({
                        validator(_, value: string) {
                            return validatePaymentAmountInput(value, balanceDue, true);
                        },
                    }),
                ]}
            >
                <Input addonBefore={'$'} style={{ textAlign: 'right' }} />
            </Form.Item>
        );
    };

    const PhoneNumberFormItem = () => {
        return (
            <PhoneNumberField
                formItemProps={{
                    preserve: false,
                    name: 'phoneNumber',
                    label: 'Mobile Phone',
                    style: { width: '100%', paddingTop: '16px' },
                    labelCol: { span: 16 },
                    wrapperCol: { span: 8 },
                    validateFirst: true,
                    initialValue: customerData?.phone_number,
                    rules: [
                        { required: true, message: 'Mobile Phone is required' },
                        () => ({
                            validator(_, value: string) {
                                const cvLength = value.length;
                                const countryCodeLength = cvLength - 10;
                                const newValue = value.substring(countryCodeLength);

                                return newValue.match(SUPPORTED_PHONE_NUMBER_REGEX)
                                    ? Promise.resolve()
                                    : Promise.reject('Invalid phone number');
                            },
                        }),
                    ],
                }}
                onChange={(value: string | null) => {
                    form.setFieldsValue({ phoneNumber: value });
                }}
            />
        );
    };

    return (
        <Modal
            centered={true}
            visible={isVisible}
            title={'Send Pay Link'}
            okText={isSent ? 'Close' : 'Send'}
            footer={<ModalFooter />}
            onCancel={() => {
                setIsVisible(false);
                setResend(false);
            }}
            onOk={form.submit}
        >
            <Spin spinning={isFetching}>
                <div style={{ visibility: isFetching ? 'hidden' : 'visible' }}>
                    <ModalContent />
                </div>
            </Spin>
        </Modal>
    );
};
