import { Button, Checkbox, Col, DatePicker, Drawer, Form, Row, Space, Typography, message } from 'antd';
import moment from 'moment';
import { CSSProperties, useEffect, useState } from 'react';
import { useModifyCustomerMutation } from 'services/admissionService';
import { BIRTHDAY_FORMAT } from 'utils/constants';
import { CustomerInfo, PatientRecordVisit } from 'utils/dataTypes';

const formItemStyle: CSSProperties = {
    width: '100%',
};

interface CustomerBirthdayDrawerProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    currentVisit: PatientRecordVisit | undefined;
    customer: CustomerInfo | undefined;
}

export const CustomerBirthdayDrawer = ({ isOpen, setIsOpen, currentVisit, customer }: CustomerBirthdayDrawerProps) => {
    const [form] = Form.useForm();
    const [unableToCollectBirthday, setUnableToCollectBirthday] = useState(customer?.unable_to_collect_birthday);
    const [modifyCustomer, { isLoading: isLoadingModifyCustomer }] = useModifyCustomerMutation();

    useEffect(() => {
        if (isOpen && customer) {
            form.setFieldsValue({
                birthday: customer.birthday ? moment(customer.birthday) : undefined,
                unable_to_collect_birthday: customer.unable_to_collect_birthday,
            });
            setUnableToCollectBirthday(customer.unable_to_collect_birthday);
        }
    }, [isOpen, customer]);

    const onFinish = (values: any) => {
        if (customer) {
            modifyCustomer({
                body: {
                    first_name: customer.first_name,
                    last_name: customer.last_name,
                    phone_number: customer.phone_number,
                    email: customer.email,
                    street: customer.street,
                    city: customer.city,
                    state: customer.state,
                    zipcode: customer.zipcode,
                    note: customer.note,
                    how_did_you_hear_about_us: customer.how_did_you_hear_about_us,
                    additional_info: customer.additional_info,
                    birthday: values.birthday ? values.birthday.format(BIRTHDAY_FORMAT) : null,
                    unable_to_collect_birthday: values.unable_to_collect_birthday ?? false,
                },
                customer_id: customer.customer_id,
            })
                .unwrap()
                .then(() => {
                    message.success('Customer has been successfully updated.');
                    setIsOpen(false);
                })
                .catch(() => {
                    message.error('There was an error updating the customer.');
                });
        }
    };

    const DrawerFooter = () => {
        return (
            <>
                <Button
                    type='default'
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    Cancel
                </Button>
                {currentVisit?.status === 'discharged' && (
                    <Button
                        type='primary'
                        onClick={() => {
                            form.submit();
                        }}
                        loading={isLoadingModifyCustomer}
                    >
                        Save
                    </Button>
                )}
            </>
        );
    };

    const dateIsInTheFuture = (currentDate: moment.Moment) => {
        return currentDate > moment();
    };

    const CustomerBirthdayForm = () => {
        if (!customer || !currentVisit) {
            return null;
        }

        return (
            <Form form={form} className='customer-birthday-form' colon={false} onFinish={onFinish}>
                <>
                    <Form.Item
                        dependencies={['unable_to_collect_birthday']}
                        name='birthday'
                        label='Customer Birthdate'
                        data-cy='customerBirthdate'
                        style={formItemStyle}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 18 }}
                        required={unableToCollectBirthday ? false : true}
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (unableToCollectBirthday && value) {
                                        return Promise.reject('Unable to collect is selected');
                                    }
                                    if (!unableToCollectBirthday && !value) {
                                        return Promise.reject('Birthdate required');
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <DatePicker
                            showTime={false}
                            format={BIRTHDAY_FORMAT}
                            style={{ width: '100%', marginTop: 0 }}
                            disabledDate={dateIsInTheFuture}
                            placeholder='0000-00-00'
                        />
                    </Form.Item>
                    <Form.Item
                        name='unable_to_collect_birthday'
                        valuePropName='checked'
                        labelAlign='right'
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Checkbox
                            onChange={(e) => {
                                form.setFieldsValue({
                                    unable_to_collect_birthday: e.target.checked,
                                });
                                setUnableToCollectBirthday(e.target.checked);
                            }}
                        >
                            Unable to collect this information
                        </Checkbox>
                    </Form.Item>
                </>
            </Form>
        );
    };

    return (
        <Drawer
            title='Customer Birthdate'
            className='veg-drawer'
            placement='right'
            visible={isOpen}
            width={378}
            key='customer_birthdate_drawer'
            onClose={() => {
                setIsOpen(false);
                form.resetFields();
            }}
            footer={<DrawerFooter />}
        >
            <Row className='required-message'>
                <Col>
                    <Row>
                        <Col span={24}>
                            <strong>Required</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            The state requires the customer’s birthdate be recorded when receiving a controlled drug prescription.
                        </Col>
                    </Row>
                </Col>
            </Row>
            {currentVisit?.status === 'checked_out' && (
                <Row style={{ marginTop: '16px' }}>
                    <Col>
                        <Typography.Text strong>Customer Birthdate:</Typography.Text>{' '}
                        {customer?.unable_to_collect_birthday ? 'Not collected' : customer?.birthday}
                    </Col>
                </Row>
            )}
            {customer && <>{currentVisit?.status !== 'checked_out' && <CustomerBirthdayForm />}</>}
        </Drawer>
    );
};
