import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Checkbox, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PriceDisplay from 'components/lib/PriceDisplay';
import moment from 'moment';
import { useGetUserDataQuery } from 'services/authService';
import { displayUser, formatCurrency, roundTo } from 'utils/formatFuncs';
import { calculateTotalCost } from 'utils/miscFuncs';
import {
    BillingDisplayType,
    BillingItem,
    BillingItemDisplay,
    BillingTableEarnedRevenueItem,
    BillingTableEarnedRevenueItemGroup,
    BillingTableRow,
    EARNABLE_TYPES,
    isInstanceOfBillingTableEarnedRevenueItemGroup,
    isInstanceOfBillingTableParentRow,
} from 'utils/types/billingTypes';
import { checkIfUserHasNecessaryPermission, USER_PERMISSIONS } from 'utils/userPermissions';
import './billing_panel.css';
import { getBillingItemsDisplay } from './getBillingItemsDisplay';

const OnSelect = (item: BillingTableEarnedRevenueItem, selected: number[], setSelected: React.Dispatch<React.SetStateAction<number[]>>) => {
    if (selected.includes(item.id)) {
        const newSelectedList = selected.filter((selectedId) => selectedId !== item.id);
        setSelected(newSelectedList);
    } else {
        const newSelectedList = [...selected, item.id];
        setSelected(newSelectedList);
    }
};

const OnSelectGroup = (item: BillingTableEarnedRevenueItemGroup, selected: number[], setSelected: React.Dispatch<React.SetStateAction<number[]>>) => {
    const groupSelected = isGroupSelected(item, selected);
    if (groupSelected) {
        const newSelectedList = selected.filter((selectedId) => !item.ids.includes(selectedId));
        setSelected(newSelectedList);
    } else {
        const newSelectedList = [...selected, ...item.ids];
        setSelected(newSelectedList);
    }
};
const OnSelectMany = (row: BillingTableRow, selected: number[], setSelected: React.Dispatch<React.SetStateAction<number[]>>) => {
    const allSelected = isSelectedAll(row, selected);
    const newSelected: number[] = row.children.reduce((selectedItems: number[], item: BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup) => {
        if (allSelected) {
            if (isInstanceOfBillingTableEarnedRevenueItemGroup(item)){
                return selectedItems.filter((selectedId) => !item.ids.includes(selectedId));
            } else {
                return selectedItems.filter((selectedId) => selectedId !== item.id);
            }
        } else {
            if (isInstanceOfBillingTableEarnedRevenueItemGroup(item)){
                return [...selectedItems, ...item.ids];
            } else {
                return [...selectedItems, item.id];
            }
        }
    }, selected as number[]);
    setSelected(newSelected);
};

const isSelected = (id: number, selected: number[]) => selected.some((selectedId) => selectedId === id);
const isGroupSelected = (row: BillingTableEarnedRevenueItemGroup, selected: number[]) => row.ids.every((id) => isSelected(id, selected));
const isSelectedAll = (row: BillingTableRow, selected: number[]) => row.children.every((item) => {
    if (isInstanceOfBillingTableEarnedRevenueItemGroup(item)) {
        return isGroupSelected(item, selected);
    }else {
        return isSelected(item.id, selected)
    }
    
});

const reduceEarnedRevenueItems = (
    earnedRevenueItems: (BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup)[],
    earnedRevenueItem: BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup,
) => {
    if (earnedRevenueItem.serial && earnedRevenueItem.serial_hours) {
        const isAlreadyOnListIndex = earnedRevenueItems.findIndex((item) => item.created_at === earnedRevenueItem.created_at);
        if (isAlreadyOnListIndex !== -1) {
            const earnedRevenueItemGroupExisting = earnedRevenueItems[isAlreadyOnListIndex];
            const quantity = earnedRevenueItemGroupExisting.quantity + earnedRevenueItem.quantity;
            if (isInstanceOfBillingTableEarnedRevenueItemGroup(earnedRevenueItemGroupExisting)) {
                earnedRevenueItemGroupExisting.ids.push(earnedRevenueItem.id);
                earnedRevenueItemGroupExisting.quantity = quantity;
                earnedRevenueItemGroupExisting.displayQuantity = `${roundTo(quantity / earnedRevenueItem.serial_hours, 0)} unit`;
            } else {
                const newEarnedRevenueItemGroupExisting: BillingTableEarnedRevenueItemGroup = {
                    ...earnedRevenueItemGroupExisting,
                    quantity,
                    displayQuantity: `${roundTo(quantity / earnedRevenueItem.serial_hours, 0)} ${earnedRevenueItemGroupExisting.displayUnit}`,
                    ids: [earnedRevenueItemGroupExisting.id, earnedRevenueItem.id],
                };
                earnedRevenueItems[isAlreadyOnListIndex] = newEarnedRevenueItemGroupExisting;
            }
        } else {
            earnedRevenueItems.push(earnedRevenueItem);
        }
    } else {
        earnedRevenueItems.push(earnedRevenueItem);
    }
    return earnedRevenueItems;
};

export const generateBillingDisplayData = (billingItems: BillingItem[], displayType: BillingDisplayType): BillingTableRow[] => {
    const billingItemsDisplay = getBillingItemsDisplay(billingItems);

    if (displayType === 'order') {
        return billingItemsDisplay
            .reduce((billingItemsByDoctor: BillingItemDisplay[], billingItemDisplay) => {
                const alreadyAddedIndex = billingItemsByDoctor.findIndex((lineItemsByOrder) => {
                    if (billingItemDisplay.instruction_id) {
                        return lineItemsByOrder.instruction_id === billingItemDisplay.instruction_id;
                    } else if (billingItemDisplay.non_medical_order_id) {
                        return lineItemsByOrder.non_medical_order_id === billingItemDisplay.non_medical_order_id;
                    } else {
                        return false;
                    }
                });
                if (alreadyAddedIndex === -1) {
                    billingItemsByDoctor.push(billingItemDisplay);
                    return billingItemsByDoctor;
                } else {
                    billingItemsByDoctor[alreadyAddedIndex] = {
                        ...billingItemsByDoctor[alreadyAddedIndex],
                        earned_revenue_line_items: [
                            ...billingItemsByDoctor[alreadyAddedIndex].earned_revenue_line_items,
                            ...billingItemDisplay.earned_revenue_line_items,
                        ],
                    };
                    return billingItemsByDoctor;
                }
            }, [])
            .map((billingItemDisplay, index) => {
                const localId: number = billingItemDisplay.instruction_id ?? billingItemDisplay.non_medical_order_id ?? -100;
                return {
                    key: `${localId}-${index}`,
                    id: localId,
                    name: billingItemDisplay.name,
                    instruction_id: billingItemDisplay.instruction_id,
                    type_id: billingItemDisplay.type_id,
                    non_medical_order_id: billingItemDisplay.non_medical_order_id,
                    price: calculateTotalCost(billingItemDisplay),
                    children: billingItemDisplay.earned_revenue_line_items
                    .map(
                        (earnedRevenueItem): BillingTableEarnedRevenueItem => ({
                            key: earnedRevenueItem.id,
                            id: earnedRevenueItem.id,
                            name: displayUser(earnedRevenueItem),
                            instructionName: earnedRevenueItem.instructionName,
                            frequency: billingItemDisplay.frequency || '-',
                            start: '-',
                            end: moment.unix(earnedRevenueItem.created_at).format('MM/DD/YYYY hh:mm a'),
                            quantity: earnedRevenueItem.quantity,
                            unit: billingItemDisplay.unit,
                            unit_price: `${formatCurrency(earnedRevenueItem.price_cents)}`,
                            price: Math.floor(earnedRevenueItem.price_cents * earnedRevenueItem.quantity),
                            is_comped: earnedRevenueItem.is_comped,
                            is_supplement: earnedRevenueItem.is_supplement,
                            why: null,
                            why_other: null,
                            reason: null,
                            created_at: earnedRevenueItem.created_at,
                            displayQuantity: `${earnedRevenueItem.quantity} ${earnedRevenueItem.additive_name || earnedRevenueItem.unit}`,
                            displayUnit: earnedRevenueItem.additive_name || earnedRevenueItem.unit,
                            serial: billingItemDisplay.serial,
                            serial_hours: billingItemDisplay?.serial_hours,

                        }),
                    )
                    .reduce(reduceEarnedRevenueItems, [])
                    .sort((a, b) => {
                        return a.created_at -  b.created_at;
                    }),

                    why: billingItemDisplay.why,
                    why_other: billingItemDisplay.why_other,
                    reason: billingItemDisplay.reason,
                    pricing_unit: billingItemDisplay.pricing_unit,
                    pricing_unit_size: billingItemDisplay.pricing_unit_size,
                    unit: billingItemDisplay.unit,
                    serial: billingItemDisplay.serial,
                    serial_hours: billingItemDisplay?.serial_hours,

                };
            });
    } else if (displayType === 'doctor') {
        return billingItemsDisplay
            .reduce((billingItemsByDoctor: BillingItemDisplay[], billingItemDisplay) => {

                billingItemDisplay.earned_revenue_line_items = billingItemDisplay.earned_revenue_line_items.map((earnedRevenueItem) => {
                    return {
                        ...earnedRevenueItem,
                        serial: billingItemDisplay.serial,
                        serial_hours: billingItemDisplay?.serial_hours,
                    }
                })
                const alreadyAddedIndex = billingItemsByDoctor.findIndex(
                    (lineItemsByOrder) => lineItemsByOrder.doctor_id === billingItemDisplay.doctor_id,
                );
                if (alreadyAddedIndex === -1) {
                    billingItemsByDoctor.push(billingItemDisplay);
                    return billingItemsByDoctor;
                } else {
                    billingItemsByDoctor[alreadyAddedIndex] = {
                        ...billingItemsByDoctor[alreadyAddedIndex],
                        earned_revenue_line_items: [
                            ...billingItemsByDoctor[alreadyAddedIndex].earned_revenue_line_items,
                            ...billingItemDisplay.earned_revenue_line_items,
                        ],
                    };
                    return billingItemsByDoctor;
                }
            }, [])
            .map((billingItemDisplay, index) => {
                const localId: number = billingItemDisplay.instruction_id ?? billingItemDisplay.non_medical_order_id ?? -100;
                return {
                    key: `${localId}-${index}`,
                    id: localId,
                    name: displayUser({
                        first_name: billingItemDisplay.doctor_first_name,
                        last_name: billingItemDisplay.doctor_last_name,
                    }),
                    price: calculateTotalCost(billingItemDisplay),
                    instruction_id: billingItemDisplay.instruction_id,
                    type_id: null,
                    non_medical_order_id: billingItemDisplay.non_medical_order_id,
                    children: billingItemDisplay.earned_revenue_line_items
                    .map(
                        (earnedRevenueItem): BillingTableEarnedRevenueItem => ({
                            key: earnedRevenueItem.id,
                            id: earnedRevenueItem.id,
                            name: `${earnedRevenueItem.instructionName}`,
                            instructionName: earnedRevenueItem.instructionName,
                            frequency: 'No Freq',
                            start: '-',
                            end: moment.unix(earnedRevenueItem.created_at).format('MM/DD/YYYY hh:mm a'),
                            quantity: earnedRevenueItem.quantity,
                            unit: billingItemDisplay.unit,
                            unit_price: `${formatCurrency(earnedRevenueItem.price_cents)}`,
                            price: Math.floor(earnedRevenueItem.price_cents * earnedRevenueItem.quantity),
                            is_comped: earnedRevenueItem.is_comped,
                            is_supplement: earnedRevenueItem.is_supplement,
                            why: earnedRevenueItem.why,
                            why_other: earnedRevenueItem.why_other,
                            reason: earnedRevenueItem.reason,
                            created_at: earnedRevenueItem.created_at,
                            displayQuantity: `${earnedRevenueItem.quantity} ${earnedRevenueItem.additive_name || earnedRevenueItem.unit}`,
                            displayUnit: earnedRevenueItem.additive_name || earnedRevenueItem.unit,
                            serial: earnedRevenueItem.serial,
                            serial_hours: earnedRevenueItem?.serial_hours,

                        }),
                    )
                    .reduce(reduceEarnedRevenueItems, [])
                    .sort((a, b) => {
                        return a.created_at -  b.created_at;
                    }),
                    why: null,
                    why_other: null,
                    reason: null,
                    pricing_unit: billingItemDisplay.pricing_unit,
                    pricing_unit_size: billingItemDisplay.pricing_unit_size,
                    unit: billingItemDisplay.unit,
                    serial: billingItemDisplay.serial,
                    serial_hours: billingItemDisplay?.serial_hours,

                };
            })
    }
    return [];
};

const generateBillingColumns = (
    displayType: BillingDisplayType,
    selected: number[],
    setSelected: React.Dispatch<React.SetStateAction<number[]>>,
): ColumnsType<BillingTableRow | BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup> => {
    if (displayType === 'order') {
        return [
            {
                title: '',
                width: '0%',
                key: 'select',
                render: (_, record) => {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if(isInstanceOfBillingTableEarnedRevenueItemGroup(record)){
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isGroupSelected(record, selected)}
                                    onClick={() => {
                                        OnSelectGroup(record, selected, setSelected);
                                    }}
                                />
                            );
    

                        } else {
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isSelected(record.id, selected)}
                                    onClick={() => {
                                        OnSelect(record, selected, setSelected);
                                    }}
                                />
                            );

                        }
                    } 
                    else {
                        return (
                            <Checkbox
                                checked={isSelectedAll(record, selected)}
                                onClick={() => {
                                    OnSelectMany(record, selected, setSelected);
                                }}
                            />
                        );
                    }
                },
            },
            {
                title: 'Order/Vet',
                width: '25%',
                key: 'name',
                dataIndex: 'name',
                render: (value, record) => {
                    let name = value;
                    let serialTitle = '';
                    if (!record.serial && isInstanceOfBillingTableParentRow(record) && record.pricing_unit_size) {
                        name += ` - ${record.pricing_unit_size} ${record.unit}`;
                    } 
                    if (record.serial && record.serial_hours){
                        serialTitle = `- Serial ${record.serial_hours} Hours`
                    }
                    return {
                        props: {
                            colSpan: record.why ? 3 : 1,
                            style: {
                                whiteSpace: record.why ? 'initial' : undefined,
                            },
                        },
                        children: isInstanceOfBillingTableParentRow(record) ? (
                            record.why ? (
                                <>
                                    <div>{`${name} ${serialTitle} (${getChildActionsCount(record.type_id, record.children)})`}</div>
                                    <div style={{ fontWeight: 'normal' }}>Why: {record.why}</div>
                                    {record.why_other && <div style={{ fontWeight: 'normal' }}>Other: {record.why_other}</div>}
                                    <div style={{ fontWeight: 'normal' }}>Reason: {record.reason}</div>
                                </>
                            ) : (
                                `${name} ${serialTitle} (${getChildActionsCount(record.type_id, record.children)})`
                            )
                        ) : (
                            name
                        ),
                    }
                },
            },
            {
                title: 'Frequency',
                width: '5%',
                key: 'frequency',
                dataIndex: 'frequency',
                render: (value, record) => ({
                    props: {
                        colSpan: record.why ? 0 : 1,
                    },
                    children: value,
                }),
            },
            {
                title: 'Start',
                width: '15%',
                key: 'start',
                dataIndex: 'start',
                render: (value, record) => ({
                    props: {
                        colSpan: record.why ? 0 : 1,
                    },
                    children: value,
                }),
            },
            {
                title: 'Completed',
                width: '15%',
                key: 'end',
                dataIndex: 'end',
            },
            {
                title: 'Qty',
                width: '10%',
                key: 'displayQuantity',
                dataIndex: 'displayQuantity',
                align: 'right',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (record.unit === 'dollar') {
                            return '';
                        }
                    }
                    return value;
                },
            },
            {
                title: 'Unit Price',
                width: '10%',
                key: 'unit_price',
                dataIndex: 'unit_price',
                align: 'right',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (record.unit === 'dollar') {
                            return '';
                        }
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return `${formatCurrency(roundTo(record.price * record.serial_hours, 0))}`
                        }

                    }
                    return value;
                },
            },
            {
                title: 'Total Cost',
                width: '10%',
                key: 'price',
                dataIndex: 'price',
                align: 'right',
                render(value, record, index) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return <PriceDisplay price={roundTo(record.price * record.serial_hours, 0)} isFreeOrRefund={record.is_comped} />;
                        }
                        return <PriceDisplay price={record.price} isFreeOrRefund={record.is_comped} />;
                    }
                    return <PriceDisplay price={record.price} isFreeOrRefund={false} />;
                },
            },
        ];
    } else {
        return [
            {
                title: '',
                width: '0%',
                key: 'select',
                render: (_, record) => {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if(isInstanceOfBillingTableEarnedRevenueItemGroup(record)){
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isGroupSelected(record, selected)}
                                    onClick={() => {
                                        OnSelectGroup(record, selected, setSelected);
                                    }}
                                />
                            );
    

                        } else {
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isSelected(record.id, selected)}
                                    onClick={() => {
                                        OnSelect(record, selected, setSelected);
                                    }}
                                />
                            );

                        }
                    } 
                    else {
                        return (
                            <Checkbox
                                checked={isSelectedAll(record, selected)}
                                onClick={() => {
                                    OnSelectMany(record, selected, setSelected);
                                }}
                            />
                        );
                    }
                },
            },
            {
                title: 'Vet',
                width: '25%',
                key: 'name',
                dataIndex: 'name',
                render: (value, record) => {
                    let serialTitle = '';
                    if (record.serial && record.serial_hours){
                        serialTitle = `- Serial ${record.serial_hours} Hours`
                    }

                    return {
                        props: {
                            colSpan: record.why ? 3 : 1,
                        },
                        children: isInstanceOfBillingTableParentRow(record) ? (
                            `${value} (${getChildActionsCount(record.type_id, record.children)})`
                        ) : record.why ? (
                            <>
                                <div>{value}</div>
                                <div>Why: {record.why}</div>
                                {record.why_other && <div>Other: {record.why_other}</div>}
                                <div>Reason: {record.reason}</div>
                            </>
                        ) : (
                            `${value} ${serialTitle}`
                        ),
                    }
            },
            },
            {
                title: 'Supplemental',
                width: '5%',
                key: 'supplemental',
                dataIndex: 'supplemental',
                render: (value, record) => ({
                    props: {
                        colSpan: record.why ? 0 : 1,
                    },
                    children: value,
                }),
            },
            {
                title: 'Start',
                width: '15%',
                key: 'start',
                dataIndex: 'start',
                align: 'center',
                render: (value, record) => ({
                    props: {
                        colSpan: record.why ? 0 : 1,
                    },
                    children: value,
                }),
            },
            {
                title: 'Completed',
                width: '15%',
                key: 'end',
                dataIndex: 'end',
                align: 'center',
            },
            {
                title: 'Qty',
                width: '10%',
                key: 'displayQuantity',
                dataIndex: 'displayQuantity',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (record.unit === 'dollar') {
                            return '';
                        }
                    }
                    return value;
                },
            },
            {
                title: 'Unit Price',
                width: '10%',
                key: 'unit_price',
                dataIndex: 'unit_price',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (record.unit === 'dollar') {
                            return '';
                        }
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return `${formatCurrency(roundTo(record.price * record.serial_hours, 0))}`
                        }
                    }
                    return value;
                },
            },
            {
                title: 'Total Cost',
                width: '10%',
                key: 'price',
                dataIndex: 'price',
                render(value, record) {
                    if (isInstanceOfBillingTableParentRow(record)) {
                        return <PriceDisplay price={record.price} isFreeOrRefund={false} />;
                    }
                    if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                        return <PriceDisplay price={roundTo(record.price * record.serial_hours, 0)} isFreeOrRefund={record.is_comped} />;
                    }
                    return <PriceDisplay price={record.price} isFreeOrRefund={record.is_comped} />;
                },
            },
        ];
    }
};

const getChildActionsCount = (type_id: EARNABLE_TYPES | null, children: BillingTableEarnedRevenueItem[]) => {
    if (type_id === 'C' || type_id === 'F') {
        return 1;
    }
    return children.reduce((total: number, item: BillingTableEarnedRevenueItem) => {
        return item.is_supplement ? total : total + 1;
    }, 0);
};

interface BillingTableProps {
    billingItems: BillingItem[];
    isFetching: boolean;
    type: 'order' | 'doctor';
    selected: number[];
    setSelected: React.Dispatch<React.SetStateAction<number[]>>;
}

export const BillingTable = ({ billingItems, isFetching, type, selected, setSelected }: BillingTableProps) => {
    const columns = generateBillingColumns(type, selected, setSelected);
    const dataSource = generateBillingDisplayData(billingItems, type);

    return (
        <>
            <Table
                className='billing-table'
                columns={columns}
                loading={isFetching}
                dataSource={dataSource}
                scroll={{
                    x: true,
                }}
                data-cy={'billingTable'}
                pagination={false}
                expandIcon={({ expanded, onExpand, record }) => {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        return <div></div>;
                    }
                    if (expanded) {
                        return (
                            <UpOutlined
                                style={{
                                    width: '20px',
                                    paddingRight: '4px',
                                }}
                                onClick={(e) => onExpand(record, e)}
                            />
                        );
                    } else {
                        return (
                            <DownOutlined
                                style={{
                                    width: '20px',
                                    paddingRight: '4px',
                                }}
                                onClick={(e) => onExpand(record, e)}
                            />
                        );
                    }
                }}
            ></Table>
        </>
    );
};
