import { DatePicker, Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import moment from 'moment';
import React from 'react';
import { next30Min } from 'utils/formFuncs';
import { dateIsInThePast } from 'utils/miscFuncs';

interface StartEndTimeFormItemsProps {
    startTimeFormFieldName: NamePath;
    endTimeFormFieldName: NamePath;
    isEditing?: boolean;
    startTimeFormFieldValue?: moment.Moment;
    endTimeFormFieldValue?: moment.Moment;
    startTimeLabel?: string;
    endTimeLabel?: string;
    hideMobileHeader?: boolean;
}

const StartEndTimeFormItems: React.FC<StartEndTimeFormItemsProps> = ({
    endTimeFormFieldName,
    startTimeFormFieldName,
    endTimeFormFieldValue,
    isEditing,
    startTimeFormFieldValue,
    endTimeLabel,
    startTimeLabel,
    hideMobileHeader,
}) => {
    const dateFormat = 'YYYY-MM-DD, hh:mm a';

    return (
        <>
            {!hideMobileHeader && (
                <div className='package-modal__mobile-header'>
                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                    Start Time
                </div>
            )}
            <Form.Item dependencies={['start_date', 'end_date']}>
                {() => (
                    <Form.Item
                        initialValue={next30Min(moment())}
                        label={startTimeLabel}
                        name={startTimeFormFieldName}
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        {isEditing ? (
                            <DatePicker disabledDate={dateIsInThePast} format={dateFormat} showTime />
                        ) : (
                            <span>{(startTimeFormFieldValue ?? next30Min(moment()))?.format(dateFormat)}</span>
                        )}
                    </Form.Item>
                )}
            </Form.Item>

            {!hideMobileHeader && <div className='package-modal__mobile-header'>End Time</div>}
            <Form.Item label={endTimeLabel} dependencies={[startTimeFormFieldName, 'start_date', 'end_date']}>
                {({ getFieldValue }) => (
                    <Form.Item
                        name={endTimeFormFieldName}
                        initialValue={null}
                        rules={[
                            {
                                validator: (_, value) => {
                                    const startTime = getFieldValue(startTimeFormFieldName);

                                    if (!!value && value < startTime) {
                                        return Promise.reject(new Error('End time cannot be before start time'));
                                    }

                                    return Promise.resolve();
                                },
                            },
                        ]}
                        noStyle
                    >
                        {isEditing ? (
                            <DatePicker
                                disabledDate={(currentDate) => {
                                    const startTime = getFieldValue(startTimeFormFieldName);

                                    return dateIsInThePast(currentDate) || currentDate < startTime;
                                }}
                                format={dateFormat}
                                showTime
                            />
                        ) : (
                            <span>{endTimeFormFieldValue?.format(dateFormat)}</span>
                        )}
                    </Form.Item>
                )}
            </Form.Item>
        </>
    );
};

export default StartEndTimeFormItems;
