import { Button, Modal, message } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { PrefixChar, useCreateAndSendConsentFormMutation, useCreateConsentFormMutation, useGetVisitByIdQuery } from 'services/visitService';
import { formatPhoneNumber } from 'utils/formatFuncs';

interface DispensedMedicationConsentProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const DispensedMedicationConsent: React.FC<DispensedMedicationConsentProps> = ({ isOpen, setIsOpen }) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const { data: currentVisit } = useGetVisitByIdQuery(visitId);
    const { data: existingCustomer } = useGetCustomerByIdQuery(currentVisit?.customer_id ?? '', { skip: !currentVisit?.customer_id });
    const [createConsentForm, { isLoading: loadingCreateConsentForm }] = useCreateConsentFormMutation();
    const [createAndSendConsentForm, { isLoading: loadingCreateAndSendConsentForm }] = useCreateAndSendConsentFormMutation();

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleSubmitForm = (sendForm?: boolean) => {
        if (sendForm) {
            createAndSendConsentForm({ prefixChar: PrefixChar.MEDICATION_CONSENT, visitId })
                .then(() => {
                    message.success('Dispensed Medication Consent has been successfully created and shared.');
                    closeModal();
                })
                .catch(() => message.error('There was an error adding Dispensed Medication Consent.'));
        } else {
            createConsentForm({ prefixChar: PrefixChar.MEDICATION_CONSENT, visitId })
                .unwrap()
                .then(() => {
                    message.success('Dispensed Medication Consent has been successfully added.');
                    closeModal();
                })
                .catch(() => message.error('There was an error adding Dispensed Medication Consent.'));
        }
    };

    return (
        <Modal
            footer={
                <>
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button onClick={() => handleSubmitForm()}>Create Form</Button>
                    <Button type='primary' onClick={() => handleSubmitForm(true)}>
                        Create and Send
                    </Button>
                </>
            }
            title='Dispensed Medication Consent Link'
            visible={isOpen}
            confirmLoading={loadingCreateConsentForm || loadingCreateAndSendConsentForm}
            onCancel={closeModal}
        >
            <p>
                A text message containing a link to the Dispensed Medication Consent Form will be sent to {existingCustomer?.first_name}{' '}
                {existingCustomer?.last_name} at {formatPhoneNumber(existingCustomer?.phone_number)}.
            </p>
        </Modal>
    );
};

export default DispensedMedicationConsent;
