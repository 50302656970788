import { Popover } from 'antd';
import { AttachRdvmForm } from 'components/forms/AttachRdvm';
import { useState } from 'react';
import { PatientRecordVisit } from 'utils/dataTypes';
import { escapeClose } from 'utils/formFuncs';

interface RdvmInfoPopoverProps {
    currentVisit: PatientRecordVisit;
    children: any;
    currentRdvm?: string;
}


const RdvmInfoPopover: React.FC<RdvmInfoPopoverProps> = ({
    currentVisit,
    children,
    currentRdvm
}) => {
    const disabled = false;
    const [popoverState, setPopOverState] = useState(false)

    return (
      <Popover
        visible={popoverState && !disabled}
        destroyTooltipOnHide={true}
        onVisibleChange={(show) => {
            if (!disabled) {
                setPopOverState(show)
                escapeClose(
                    popoverState,
                    setPopOverState,
                );
            }
        }}
        overlayClassName='rdvm-info-popover'
        trigger='click'
        title='rDVM Info'
        content={
          <AttachRdvmForm
            pet_id={currentVisit.pet_id}
            visit_id={currentVisit.id}
            setPopoverState={setPopOverState}
            current_rdvm={currentVisit.rdvm_id ?? ''}
            currentRdvm={currentRdvm}
          />
        }
        placement='rightBottom'
      >
        <span>{children}</span>
      </Popover>
    );
};

export default RdvmInfoPopover;
