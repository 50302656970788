import { Col, Form, Input, Row } from 'antd';
import { CSSProperties, useState } from 'react';
import './CompleteStatusActionForm.css';
import { roundTo } from 'utils/formatFuncs';
import { FLOAT_REGEX } from 'utils/constants';

const formItemStyle: CSSProperties = {
    width: '100%',
};

const valueColumnStyle: CSSProperties = {
    fontWeight: 500,
};

const DEFAULT_LABEL_SPAN = 6;
const DEFAULT_VALUE_SPAN = 18;
const DECIMAL_PLACES = 3;

interface WastedControlledDrugsProps {
    denominator_unit: string;
    initial_numerator_dose: number;
    numerator_value: number;
    denominator_value: number;
    drawn_only: boolean;
}

export const WastedControlledDrugs: React.FC<WastedControlledDrugsProps> = ({
    denominator_unit,
    initial_numerator_dose,
    numerator_value,
    denominator_value,
    drawn_only,
}) => {
    const initial_dose_denominator_med =
        numerator_value === null
            ? roundTo(initial_numerator_dose, DECIMAL_PLACES)
            : roundTo((initial_numerator_dose / numerator_value) * denominator_value, DECIMAL_PLACES);

    const initial_dose_denominator = initial_dose_denominator_med;

    const [drawnAmount, setDrawnAmount] = useState(initial_dose_denominator);
    const [administeredAmount, setAdministeredAmount] = useState(0);
    const [wastedAmount, setWastedAmount] = useState(initial_dose_denominator);

    const updateWastedAmount = (drawn: number, administered: number) => {
        setWastedAmount(roundTo(drawn - administered, DECIMAL_PLACES));
    };

    return (
        <>
            <Form.Item
                name='drawn_amount'
                dependencies={['administered_amount']}
                rules={[
                    { required: true },
                    {
                        pattern: FLOAT_REGEX,
                    },
                    {
                        validator: (_, value) => {
                            const newValue = parseFloat(value);
                            if (!isNaN(newValue) && administeredAmount > newValue) {
                                return Promise.reject(new Error('Must be greater than ' + administeredAmount));
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
                label='Drawn:'
                initialValue={drawnAmount}
                labelCol={{ span: 6 }}
                style={formItemStyle}
            >
                <Input
                    style={formItemStyle}
                    suffix={denominator_unit}
                    onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        if (!isNaN(newValue)) {
                            setDrawnAmount(newValue);
                            updateWastedAmount(newValue, administeredAmount);
                        }
                    }}
                    type='number'
                />
            </Form.Item>
            {!drawn_only && (
                <>
                    <Form.Item
                        name='administered_amount'
                        label='Administered:'
                        dependencies={['drawn_amount']}
                        rules={[
                            { required: true },
                            {
                                pattern: FLOAT_REGEX,
                            },
                            {
                                validator: (_, value) => {
                                    const newValue = parseFloat(value);
                                    if (!isNaN(newValue) && newValue > drawnAmount) {
                                        return Promise.reject(new Error('Must be less than or equal to ' + drawnAmount));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                        labelCol={{ span: 6 }}
                        initialValue=''
                        style={formItemStyle}
                    >
                        <Input
                            style={formItemStyle}
                            suffix={denominator_unit}
                            onChange={(e) => {
                                const newValue = parseFloat(e.target.value);
                                if (!isNaN(newValue)) {
                                    setAdministeredAmount(newValue);
                                    updateWastedAmount(drawnAmount, newValue);
                                }
                            }}
                            type='number'
                        />
                    </Form.Item>
                    <Row
                        style={{
                            ...formItemStyle,
                            height: '32px',
                            display: 'flex',
                            marginTop: '12px',
                            alignItems: 'center',
                        }}
                    >
                        <Col span={DEFAULT_LABEL_SPAN}>Wasted:</Col>
                        <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                            {wastedAmount === Infinity ? '*' : wastedAmount} {denominator_unit}
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};
