import { CheckCircleOutlined, ExclamationCircleFilled, InfoCircleFilled, StopOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Drawer, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { 
    useGetVisitByIdQuery, 
    useGetDischargeNoteByVisitIdQuery 
} from 'services/visitService';
import { UserNameWrapper } from 'utils/componentWrappers';
import './CheckoutDrawer.css';
import { CustomerBirthdayDrawer } from './CustomerBirthdayDrawer';
import { ReferralSourceDrawer } from './ReferralSourceDrawer';
import { useGetHospitalByIdQuery } from 'services/hospitalService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DischargeExistingInstruction } from 'utils/dataTypes';

interface CheckoutDrawerProps {
    toGoMeds: DischargeExistingInstruction[];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    doCheckout: Function;
}

export const CheckoutDrawer = ({ toGoMeds, isOpen, setIsOpen, doCheckout }: CheckoutDrawerProps) => {
    const [isReferralSourceDrawerOpen, setIsReferralSourceDrawerOpen] = useState(false);
    const [isCustomerBirthdayDrawerOpen, setIsCustomerBirthdayDrawerOpen] = useState(false);
    let { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);
    const { data: currentVisit } = useGetVisitByIdQuery(visitId, {
        skip: !visitId,
    });
    const { data: hospitalData } = useGetHospitalByIdQuery(currentVisit?.hospital_id ?? skipToken, {
        ...BASE_QUERY_OPTIONS,
        skip: !currentVisit?.hospital_id,
    });
    const { data: customer } = useGetCustomerByIdQuery(currentVisit?.customer_id || '', {
        skip: !currentVisit?.customer_id,
        refetchOnMountOrArgChange: true,
    });
    const {data: dischargeNote} = useGetDischargeNoteByVisitIdQuery(visitId);

    const { data: customerData } = useGetCustomerByIdQuery(
        currentVisit?.customer_id as string,
        { ...BASE_QUERY_OPTIONS, skip: !currentVisit?.customer_id },
    );

    const getIsTghMedsFinalized = () => {
        const hasTghMedsInDraftStatus = toGoMeds.some(
            (item) => !item.is_shown_on_tx_sheet && item.instruction_status !== 'discontinued'
        );
        return !hasTghMedsInDraftStatus
    };

    const getIsTghMedsFinalizedEnabled = () => {
        const hasNotDiscontinuedStatusInTxSheet = toGoMeds.some(
            (item) => item.instruction_status !== "discontinued"
        );
        return toGoMeds.length && hasNotDiscontinuedStatusInTxSheet
    };

    const getIsDischarged = () => {
        const isClosed = currentVisit?.finalized_at && currentVisit?.status !== "noshow"
        if (currentVisit?.status === 'checked_out' || currentVisit?.status === 'discharged' || isClosed) {
            return true;
        } else {
            return false;
        }
    };

    const getIsCustomerInformationCompleted = () => {
        return (
            !!(
                customerData && 
                customerData.first_name &&
                customerData.last_name &&
                customerData.email)
        )
    };

    const getIsRdvmInformationCompleted = () => {
        return !!currentVisit?.rdvm_id;
    };

    const getIsTghMedsFilled = () => {
        const hasTghMedsFilled = toGoMeds.some(
            (item) => item.is_shown_on_tx_sheet && item.action_status !== "complete" && item.instruction_status !== "discontinued"
        );
        return !hasTghMedsFilled
    };

    const getIsDischargeNotesCompleted = () => {
        return !!(dischargeNote && dischargeNote.content);
    };

    const getIsReferralSourceComplete = () => {
        if (currentVisit?.status === 'checked_out') {
            return true;
        }
        if (customer?.visit_count && customer?.visit_count > 1) {
            return !!currentVisit?.referred_by_primary_vet;
        }
        return !!customer?.how_did_you_hear_about_us;
    };

    const getIsCustomerBirthdayRequired = () => {
        if (!hospitalData?.require_customer_birthday) {
            return false;
        }
        const hasFinalizedControlledTogoMeds = toGoMeds?.some(
            (item) => item.controlled_drug && item.discontinued_at === null && item.is_shown_on_tx_sheet,
        );
        return hasFinalizedControlledTogoMeds;
    };

    const getIsCustomerBirthdayComplete = () => {
        if (customer?.unable_to_collect_birthday || customer?.birthday) {
            return true;
        }
        return false;
    };

    const getIsTghMedsFilledEnabled = () => {
        const hasTghMedsInTxSheet = toGoMeds.some(
            (item) => item.is_shown_on_tx_sheet && item.instruction_status !== "discontinued"
        );
        return toGoMeds.length && hasTghMedsInTxSheet
    };
    

    const isCustomerInformationCompleted = getIsCustomerInformationCompleted();
    const isTghMedsFinalized = getIsTghMedsFinalized();
    const isDischarged = getIsDischarged();
    const isRdvmInformationCompleted = getIsRdvmInformationCompleted();
    const isTghMedsFilled = getIsTghMedsFilled();
    const isDischargeNotesCompleted = getIsDischargeNotesCompleted();
    const isReferralSourceComplete = getIsReferralSourceComplete();
    const isCustomerBirthdayRequired = getIsCustomerBirthdayRequired();
    const isCustomerBirthdayComplete = getIsCustomerBirthdayComplete();
    const isTghMedsFinalizedEnabled = getIsTghMedsFinalizedEnabled();
    const isTghMedsFilledEnabled = getIsTghMedsFilledEnabled();

    // referral source is the only requirement for checkout
    // if additional requirements are added,
    //     update the onClick of the Checkout button in the Billing Tab accordingly
    const isReadyToCheckout = isCustomerInformationCompleted && isDischarged && isRdvmInformationCompleted && isReferralSourceComplete && (!isCustomerBirthdayRequired || isCustomerBirthdayComplete);

    const DrawerFooter = () => {
        return (
            <>
                <Button
                    type='default'
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    Cancel
                </Button>
                {currentVisit?.status === 'discharged' && (
                    <Button type='primary' disabled={!isReadyToCheckout} onClick={() => doCheckout()} data-cy={'finalCheckoutButton'}>
                        Checkout
                    </Button>
                )}
            </>
        );
    };

    const getIconRequired = (completed: boolean) => {
        if (completed) {
            return <CheckCircleOutlined style={{ fontSize: '20px', color: 'var(--green-alert-success)' }} />;
        }
        return <ExclamationCircleFilled style={{ fontSize: '20px', color: 'var(--veg-secondary-red)' }} />;
    };

    const getIconOptional = (completed: boolean) => {
        if (completed) {
            return <CheckCircleOutlined style={{ fontSize: '20px', color: 'var(--green-alert-success)' }} />;
        }
        return <ExclamationCircleFilled style={{ fontSize: '20px', color: 'var(--veg-secondary-gold)' }} />;
    };

    const getIconDisabled = () => {
        return <StopOutlined style={{ fontSize: '20px', color: 'var(--gray-7)' }} />;
    };

    return (
        <Drawer
            title='Checkout'
            className='veg-drawer checkout-drawer'
            placement='right'
            closable={true}
            width={378}
            onClose={() => {
                setIsOpen(false);
            }}
            visible={isOpen}
            key='checkout_drawer'
            mask={true}
            maskClosable={true}
            footer={<DrawerFooter />}
        >
            {currentVisit?.status === 'checked_out' && (
                <Alert
                    type='info'
                    icon={<InfoCircleFilled />}
                    showIcon
                    message={
                        <>
                            Checkout completed by {<UserNameWrapper>{currentVisit.last_status_transition_by}</UserNameWrapper>} on{' '}
                            {moment.unix(currentVisit.last_status_transition_at).format('MM/DD/YYYY HH:mm A')}.
                        </>
                    }
                />
            )}

            <Row className='required-message'>
                <Col>
                    <Row>
                        <Col span={24}>
                            <strong>Required</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{color: 'var(--gray-8)'}}>Must be completed prior to checkout.</Col>
                    </Row>
                </Col>
            </Row>
            <Row className='checkout-item'>
                <Col>
                    <Row>
                        <Col span={24}>
                            <Button
                                type='link'
                                icon={getIconRequired(isCustomerInformationCompleted)}
                                style={{color: 'var(--gray-9)', cursor: 'default'}}
                            >
                                Customer information
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{marginLeft: '30px', color: 'var(--gray-8)'}}>Full name, email, and phone required.</Col>
                    </Row>
                </Col>
            </Row>
            <Row className='checkout-item'>
                <Button
                    type='link'
                    icon={getIconRequired(isRdvmInformationCompleted)}
                    style={{color: 'var(--gray-9)', cursor: 'default'}}
                >
                    rDVM information
                </Button>
            </Row>
            <Row className='checkout-item'>
                <Button
                    type='link'
                    icon={getIconRequired(isDischarged)}
                    style={{color: 'var(--gray-9)', cursor: 'default'}}
                >
                    Discharged
                </Button>
            </Row>
            <Row className='checkout-item'>
                <Button
                    type='link'
                    icon={getIconRequired(isReferralSourceComplete)}
                    onClick={() => {
                        setIsReferralSourceDrawerOpen(true);
                    }}
                    data-cy={'referralSourceButton'}
                >
                    Referral Source
                </Button>
            </Row>
            {isCustomerBirthdayRequired && (
                <Row className='checkout-item'>
                    <Button
                        type='link'
                        icon={getIconRequired(customer?.unable_to_collect_birthday || !!customer?.birthday)}
                        onClick={() => {
                            setIsCustomerBirthdayDrawerOpen(true);
                        }}
                    >
                        Customer Birthdate
                    </Button>
                </Row>
            )}
            <Row className='required-message' style={{marginTop:'var(--spacing-md)'}}>
                <Col>
                    <Row>
                        <Col span={24}>
                            <strong>Optional</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{color: 'var(--gray-8)'}}>Recommended, but not required, prior to checkout.</Col>
                    </Row>
                </Col>
            </Row>
            <Row className='checkout-item'>
                <Button
                    type='link'
                    icon={isTghMedsFinalizedEnabled? getIconOptional(isTghMedsFinalized) : getIconDisabled()}
                    style={{color: isTghMedsFinalizedEnabled ? "var(--gray-9)" : "var(--gray-7)", cursor: 'default'}}
                >
                    {isTghMedsFinalizedEnabled ? "TGH Meds Finalized" : "TGH Meds Finalized - no meds ordered"}
                </Button>
            </Row>
            <Row className='checkout-item'>
                <Button
                    type='link'
                    icon={isTghMedsFilledEnabled ? getIconOptional(isTghMedsFilled) : getIconDisabled()}
                    style={{color: isTghMedsFilledEnabled ? "var(--gray-9)" : "var(--gray-7)", cursor: 'default'}}
                >
                    {isTghMedsFilledEnabled ? "TGH Meds Filled" : "TGH Meds Filled - no meds ordered"}
                </Button>
            </Row>
            <Row className='checkout-item'>
                <Button
                    type='link'
                    icon={getIconOptional(isDischargeNotesCompleted)}
                    style={{color: 'var(--gray-9)', cursor: 'default'}}
                >
                    Discharge Notes
                </Button>
            </Row>
            <ReferralSourceDrawer
                isOpen={isReferralSourceDrawerOpen}
                setIsOpen={setIsReferralSourceDrawerOpen}
                currentVisit={currentVisit}
                customer={customer}
            />
            <CustomerBirthdayDrawer
                isOpen={isCustomerBirthdayDrawerOpen}
                setIsOpen={setIsCustomerBirthdayDrawerOpen}
                currentVisit={currentVisit}
                customer={customer}
            />
        </Drawer>
    );
};
