import { Col, Row, Space, Typography } from 'antd';
import { formatCurrency } from 'utils/formatFuncs';
import { getLedgerValues } from 'utils/miscFuncs';
import styles from './EstimateSummary.module.css';

interface EstimatesLedgerProps {
    totalOldAndNew: number;
    totalTax: number;
    amountPaid: number;
    discountBasis?: number;
    cap_amount_cents?: number;
}

export const EstimatesLedger = ({ totalOldAndNew, totalTax, amountPaid, discountBasis, cap_amount_cents }: EstimatesLedgerProps) => {
    const { due, total, discountAmount } = getLedgerValues(totalOldAndNew, amountPaid, totalTax, discountBasis, cap_amount_cents);

    return (
        <Space direction='vertical' className={styles['ledger']} size={0}>
            <Row className={styles['ledger-box']} justify='space-between'>
                <Col span={12}>
                    <Typography.Text strong>Treatment Estimate</Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text data-cy={'treatmentEstimateValue'}>{formatCurrency(totalOldAndNew)}</Typography.Text>
                </Col>
            </Row>
            <Row className={styles['ledger-box']} justify='space-between'>
                <Col span={12}>
                    <Typography.Text strong>Discount</Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text>{formatCurrency(discountAmount)}</Typography.Text>
                </Col>
            </Row>

            <Row className={styles['ledger-box']} justify='space-between'>
                <Col span={12}>
                    <Typography.Text strong>Tax</Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text>{formatCurrency(totalTax)}</Typography.Text>
                </Col>
            </Row>
            <Row className={styles['ledger-box']} justify='space-between'>
                <Col span={12}>
                    <Typography.Title level={5}>Total</Typography.Title>
                </Col>
                <Col span={12}>
                    <Typography.Title level={5}>{formatCurrency(total)}</Typography.Title>
                </Col>
            </Row>
            <Row className={styles['ledger-box']} justify='space-between'>
                <Col span={12}>
                    <Typography.Text strong>Payments Received</Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text>{formatCurrency(amountPaid)}</Typography.Text>
                </Col>
            </Row>
            <Row className={styles['ledger-box-bottom']} justify='space-between'>
                <Col span={12}>
                    <Typography.Text strong>Remaining Balance</Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text>{formatCurrency(due)}</Typography.Text>
                </Col>
            </Row>
        </Space>
    );
};
