import { convertEstimateItemToLocal, convertEstimateItemToLocalNew, convertToAPI, convertToLocal } from 'utils/dataTypes';
import { roundTo } from 'utils/formatFuncs';
import { transformErrorResponse } from 'utils/requestResponseFuncs';
import {
    EstimateItem,
    EstimateOrderBody,
    NonMedicalEstimateOrderBody,
    backendCriEstimateItemToFrontendCriEstimateItem,
    backendFluidEstimateItemToFrontendFluidEstimateItem,
    isInstanceOfAPICriEstimateItem,
    isInstanceOfAPIFluidEstimateItem,
    isInstanceOfCriEstimateFormFields,
    isInstanceOfMedicalEstimateItem,
    isInstanceOfMedicineEstimateFormFields,
    isInstanceOfToGoMedEstimateFormFields,
} from 'utils/types/billingTypes';
import { TagType } from 'utils/types/enums';
import {
    Estimate,
    EstimateCreate,
    EstimateEdit,
    EstimateItemNew,
    backendCriEstimateItemtoFrontend,
    backendFluidEstimateItemtoFrontend,
    frontendCriEstimateItemtoApi,
    frontendFluidEstimateItemtoApi,
    isInstanceOfAPICriEstimateItemNew,
    isInstanceOfAPIFluidEstimateItemNew,
    isInstanceOfCriEstimateItemNew,
    isInstanceOfFluidEstimateItemNew,
    isInstanceOfMedicalEstimateItemNew,
    isInstanceOfOxygenTherapyEstimateItemNew,
    isInstanceOfToGoMedEstimateItemNew,
} from 'utils/types/estimateTypesNew';
import { instructionApi } from './instructionApi';
import { pimsApi } from './pimsApi';
import moment from 'moment';
import { getOxygenTherapyUnit } from 'views/visit/EstimatesNew/utils';

export interface TransferEstimateItemToTx {
    id: number;
    type_id: string;
    start_time?: number | null;
    end_time?: number | null;
    notes?: string | null;
    quantity?: number | null;
    unit?: string | null;

    frequency?: string | null;
    is_prn?: boolean | null;
    prn_condition?: any | null;
    priority?: boolean | null;
    medication_id?: number,
    dose?: number | null,
    dose_unit?: string;
    route_id?: string;
    controlled_drug?: boolean;
    latest_patient_weight_kg?: number | null;
    approx_patient_weight_kg?: number | null;
    ordered_by?: number,
    fluids_id?: number,
    fluids_volume_ml?: number,
    rate_mcl_per_hr?: number,
    diagnostic_id?: number,
    task_id?: number,
    non_medical_id?: number,
    why?: string | null;
    why_other?: string | null;
    reason?: string | null;
    created_at?: number | null;
    serial?: boolean;
    is_free?: boolean;
}

// Define a service using a base URL and expected endpoints
export const estimateService = instructionApi.injectEndpoints({
    endpoints: (builder) => ({
        getEstimatesByVisitId: builder.query<EstimateItem[], number>({
            query: (visitId) => `visit/${visitId}/estimate_instructions`,
            providesTags: (result, error, id) => [
                { type: TagType.Estimate, id },
            ],
            transformResponse: (response: { data: EstimateItem[] }) => {
                const values: EstimateItem[] = response.data.map((item) => {
                    if (isInstanceOfAPIFluidEstimateItem(item)) {
                        return backendFluidEstimateItemToFrontendFluidEstimateItem(item);
                    }
                    else if(isInstanceOfAPICriEstimateItem(item)) {
                        return convertEstimateItemToLocal(
                            backendCriEstimateItemToFrontendCriEstimateItem(item)
                            );
                    } else if (isInstanceOfMedicalEstimateItem(item)){
                        return convertEstimateItemToLocal(item)

                    }
                    return item;
                });
                return values;
            },
        }),
        getEstimatesByVisitIdNew: builder.query<Estimate[], {visitId: number, isRefresh?: boolean}>({
            query: ({visitId, isRefresh}) => {
                if (isRefresh) {
                    const lastCheckTime = moment().subtract(2, 'minutes').unix();
                    return `visit/${visitId}/estimate_instructions?lastchecktime=${lastCheckTime}`;
                } else {
                    return `visit/${visitId}/estimate_instructions`;
                }
            },

            providesTags: (result, error, {visitId}) => [
                { type: TagType.Estimate, id: visitId },
            ],
            transformResponse: (response: { data: Estimate[] }) => {
                const values: Estimate[] = response.data.map((item) => {
                    const estimate_items: EstimateItemNew[] = item.estimate_items
                    .map((estimateItem) => {
                        if (isInstanceOfAPIFluidEstimateItemNew(estimateItem)) {
                            
                            return backendFluidEstimateItemtoFrontend(estimateItem);
                        }
                        else if(isInstanceOfAPICriEstimateItemNew(estimateItem)) {
                            return convertEstimateItemToLocalNew(
                                backendCriEstimateItemtoFrontend(estimateItem)
                                );
                        } else if (isInstanceOfMedicalEstimateItemNew(estimateItem)){
                            return convertEstimateItemToLocalNew(estimateItem)
    
                        } else if (isInstanceOfToGoMedEstimateItemNew(estimateItem)){

                            const toGoMedEstimateItem = {...estimateItem}
                            const {dose: dispense_value, dose_unit: dispense_unit} = convertToLocal(estimateItem.dispense_value, estimateItem.dispense_unit);
                            const {dose: quantity, dose_unit: unit} = convertToLocal(estimateItem.quantity, estimateItem.unit);
                            const {dose_unit: dose_unit} = convertToLocal(1, estimateItem.dose_unit);
                            toGoMedEstimateItem.dispense_value = dispense_value
                            toGoMedEstimateItem.dose_unit = dose_unit
                            toGoMedEstimateItem.unit = unit
                            toGoMedEstimateItem.dispense_unit = dispense_unit
                            toGoMedEstimateItem.quantity = quantity
                            toGoMedEstimateItem.unit_cost_cents = toGoMedEstimateItem.unit_cost_cents * toGoMedEstimateItem.ratio
        
                            return toGoMedEstimateItem
                            }
                        return estimateItem
                    })
                    .filter(estimateItem => estimateItem.quantity > 0)
                    return {
                        ...item,
                        estimate_items
                    }
                });
                return values;
            },
        }),

        getLatestEstimateFormLink: builder.query<{form_link_id: string}, {visitId: number, estimateId: number }>({
            /**
             * TODO when real endpoint gets written, change this URL
             * Will also need to change the api from `instructionApi` to wherever `billing` lives
             * And don't map the data with a random price...
             */
            query: ({visitId, estimateId}) => `visit/${visitId}/estimate/${estimateId}/estimate_form_link`,
            providesTags: (result, error, visitId) => [
                { type: TagType.Estimate, visitId },
            ],

            transformResponse: (response: { data: {form_link_id: string} }) => {
                return response.data;
            },
        }),
        addNewEstimate: builder.mutation<
        null,
        {
            visitId: number;
            body: EstimateCreate;
        }
        >({
            query: ({ visitId, body }) => {
                const {estimate_items, duration_hours} = body;
                const name = body.name ?? '';

                const newItems = estimate_items.map((item) => {
                    let returnBody

                    if (
                        isInstanceOfMedicineEstimateFormFields(item) ||
                        isInstanceOfCriEstimateFormFields(item)
                    ) {
                        const {
                            unit_cost_cents,
                            name,
                            supplemental_cost_cents,
                            is_recurring_supplemental,
                            serial_hours,
                            from_package,
                            pricing_unit,
                            pricing_unit_size,
                            ...restOfBody
                        } = item;
                        const newMedication = { ...restOfBody };

                        if (item.type_id === 'C' && item.dose_unit === 'mcg/kg/min') {
                            item.dose_unit = 'mg/kg/hr';
                            item.dose = item.dose * 60 / 1000;
                        }

                        const { dose, dose_unit, conversionFactor, pricingUnitConversionFactor } =
                            convertToAPI(item.dose, item.dose_unit, item.pricing_unit, item.pricing_unit_size);
                        const { dose_unit: unit } = convertToAPI(
                            item.dose,
                            item.unit,
                            item.pricing_unit,
                            item.pricing_unit_size,
                        );
                        newMedication.dose = dose;
                        newMedication.dose_unit = dose_unit;
                        if (item.pricing_unit) {
                            newMedication.unit = item.pricing_unit;
                            newMedication.quantity = roundTo(roundTo(newMedication.quantity, 3) * pricingUnitConversionFactor, 0);
                        } else {
                            newMedication.unit = unit;
                            newMedication.quantity = roundTo(roundTo(newMedication.quantity, 3) * conversionFactor, 0);
                        }

                        returnBody = newMedication;
                    } else if (isInstanceOfToGoMedEstimateFormFields(item)){
                        const {
                            unit_cost_cents,
                            name,
                            supplemental_cost_cents,
                            is_recurring_supplemental,
                            serial_hours,
                            from_package,
                            ...restOfBody
                        }  = item
                        const newToGoMed = {...restOfBody}
                        const {dose: dispense_value, dose_unit: dispense_unit, conversionFactor} = convertToAPI(item.dispense_value, item.dispense_unit);
                        const {dose_unit: unit} = convertToAPI(1, item.unit);
                        const { dose_unit } = convertToAPI(1, item.dose_unit);
                        newToGoMed.dispense_value = dispense_value
                        newToGoMed.dose_unit = dose_unit
                        newToGoMed.unit = unit
                        newToGoMed.dispense_unit = dispense_unit
                        newToGoMed.quantity = roundTo((roundTo(newToGoMed.quantity, 3) * conversionFactor), 0);
    
                        returnBody =  newToGoMed;
                    } else if (isInstanceOfOxygenTherapyEstimateItemNew(item)) {
                        const {
                            unit_cost_cents,
                            name,
                            supplemental_cost_cents,
                            is_recurring_supplemental,
                            notes,
                            from_package,
                            ...restOfBody
                        } = item;

                        returnBody = {
                            ...restOfBody,
                            unit: getOxygenTherapyUnit(item.name)
                        };
                    } else {
                        const {
                            unit_cost_cents,
                            name,
                            supplemental_cost_cents,
                            is_recurring_supplemental,
                            serial_hours,
                            from_package,
                            ...restOfBody
                        } = item;
                        returnBody = restOfBody;
                    }

                    if (isInstanceOfCriEstimateItemNew(returnBody)) {
                        return frontendCriEstimateItemtoApi(returnBody);
                    } else if (isInstanceOfFluidEstimateItemNew(returnBody)) {
                        return frontendFluidEstimateItemtoApi(returnBody);  
                    }
                    return returnBody;

                })
                const newReturn = {new_items: newItems, name, duration_hours}
                return {
                    url: `visit/${visitId}/estimate`,
                    method: 'POST',
                    body: newReturn,
                };
            },

            invalidatesTags: (result, error, { visitId }) => [
                { type: TagType.Estimate, id: visitId },
                { type: TagType.Instruction, id: visitId },
            ],
            transformResponse: (response: { data: null }) => {
                return response.data;
            },
            transformErrorResponse: transformErrorResponse,
        }),
        editEstimate: builder.mutation<
        null,
        {
            visitId: number;
            estimateId: number;
            body: EstimateEdit;
        }
        >({
            query: ({ visitId, estimateId, body }) => {
                const {new_items, free_items, discontinued_items } = body;
                const name = body.name ?? '';

                const newItems = new_items.map((item) => {
                    let returnBody

                    if (isInstanceOfMedicineEstimateFormFields(item) || isInstanceOfCriEstimateFormFields(item)){
                        const {
                            unit_cost_cents,
                            name,
                            supplemental_cost_cents,
                            is_recurring_supplemental,
                            serial_hours,
                            pricing_unit,
                            pricing_unit_size,
                            ...restOfBody
                        }  = item
                            const newMedication = {...restOfBody}

                            if (item.type_id === 'C' && item.dose_unit === 'mcg/kg/min') {
                                item.dose_unit = 'mg/kg/hr';
                                item.dose = item.dose * 60 / 1000;
                            }

                            const {dose, dose_unit, conversionFactor, pricingUnitConversionFactor} = convertToAPI(item.dose, item.dose_unit, item.pricing_unit, item.pricing_unit_size);
                            const {dose_unit: unit} = convertToAPI(item.dose, item.unit, item.pricing_unit, item.pricing_unit_size);
                            newMedication.dose = dose
                            newMedication.dose_unit = dose_unit
                            if (item.pricing_unit) {
                                newMedication.unit = item.pricing_unit;
                                newMedication.quantity = roundTo(roundTo(newMedication.quantity, 3) * pricingUnitConversionFactor, 0);
                            } else {
                                newMedication.unit = unit;
                                newMedication.quantity = roundTo(roundTo(newMedication.quantity, 3) * conversionFactor, 0);
                            }
        
                            returnBody =  newMedication;

                    } else if (isInstanceOfToGoMedEstimateFormFields(item)){
                        const {
                            unit_cost_cents,
                            name,
                            supplemental_cost_cents,
                            is_recurring_supplemental,
                            serial_hours,
                            ...restOfBody
                        }  = item
                        const newToGoMed = {...restOfBody}
                        const {dose: dispense_value, dose_unit: dispense_unit, conversionFactor} = convertToAPI(item.dispense_value, item.dispense_unit);
                        const {dose_unit: unit} = convertToAPI(1, item.unit);
                        const {dose_unit} = convertToAPI(1, item.dose_unit);
                        newToGoMed.dispense_value = dispense_value
                        newToGoMed.dose_unit = dose_unit
                        newToGoMed.unit = unit
                        newToGoMed.dispense_unit = dispense_unit
                        newToGoMed.quantity = roundTo(roundTo(newToGoMed.quantity, 3) * conversionFactor, 0)
                            
                        returnBody =  newToGoMed;


                    } else if (isInstanceOfOxygenTherapyEstimateItemNew(item)) {
                        const {
                            unit_cost_cents,
                            name,
                            supplemental_cost_cents,
                            is_recurring_supplemental,
                            notes,
                            reason,
                            ...restOfBody
                        } = item;

                        returnBody = {
                            ...restOfBody,
                            unit: getOxygenTherapyUnit(item.name)
                        };
                    } else {    
                        const {
                            unit_cost_cents,
                            name,
                            supplemental_cost_cents,
                            is_recurring_supplemental,
                            serial_hours,
                            ...restOfBody
                        }  = item
                        returnBody = restOfBody;
                        
                    }

                    if (isInstanceOfCriEstimateItemNew(returnBody)) {
                        return frontendCriEstimateItemtoApi(returnBody);
                    } else if (isInstanceOfFluidEstimateItemNew(returnBody)) {
                        return frontendFluidEstimateItemtoApi(returnBody);  
                    }

                    return returnBody;

                })
                const discontinued = discontinued_items.map((discItem) => {

                    if ((discItem.type_id === "C" || discItem.type_id === "M") && discItem.dose){
   
                            const newMedication = {...discItem};
                            const {dose, dose_unit: unit, conversionFactor, pricingUnitConversionFactor} = convertToAPI(discItem.dose, discItem.unit, discItem.pricing_unit, discItem.pricing_unit_size);
                            newMedication.dose = dose
                            if (discItem.pricing_unit) {
                                newMedication.unit = discItem.pricing_unit;
                                newMedication.quantity = roundTo(roundTo(newMedication.quantity, 3) * pricingUnitConversionFactor, 0);
                            } else {
                                newMedication.unit = unit;
                                newMedication.quantity = roundTo(roundTo(newMedication.quantity, 3) * conversionFactor, 0);
                            }
                            const {pricing_unit, pricing_unit_size, ...restOfNewMedication} = newMedication;
                            return restOfNewMedication;

                    } else if (discItem.type_id === 'TGH') {
                        const newTGH = {...discItem};
                        const {dose: quantity, dose_unit: unit, conversionFactor} = convertToAPI(discItem.quantity, discItem.unit);
                        newTGH.quantity = quantity
                        newTGH.dose = quantity
                        newTGH.unit = unit

                        return newTGH;
                    }

                    return discItem;

                })

                const newReturn = {new_items: newItems, name, free: free_items, discontinued}
                return {
                    url: `visit/${visitId}/estimate/${estimateId}/edit`,
                    method: 'POST',
                    body: newReturn,
                };
            },

            invalidatesTags: (result, error, { visitId }) => [
                { type: TagType.Estimate, id: visitId },
                { type: TagType.Instruction, id: visitId },
            ],
            transformResponse: (response: { data: null }) => {
                return response.data;
            },
            transformErrorResponse: transformErrorResponse,
        }),

        approveEstimate: builder.mutation<
            null,
            {
                visitId: number;
                estimateId: number;
            }
        >({
            query: ({ visitId, estimateId }) => {
                return {
                    url: `visit/${visitId}/estimate/${estimateId}/approve`,
                    method: 'PUT',
                    body: {},
                };
            },

            invalidatesTags: (result, error, { visitId }) => [
                { type: TagType.Estimate, id: visitId },
                { type: TagType.Instruction, id: visitId },
            ],
            transformResponse: (response: { data: null }) => {
                return response.data;
            },
        }),
        approveAllEstimate: builder.mutation<
            null,
            {
                visitId: number;
            }
        >({
            query: ({ visitId }) => {
                return {
                    url: `visit/${visitId}/estimate/all`,
                    method: 'PUT',
                    body: {},
                };
            },

            invalidatesTags: (result, error, { visitId }) => [
                { type: TagType.Estimate, id: visitId },
                { type: TagType.Instruction, id: visitId },
            ],
            transformResponse: (response: { data: null }) => {
                return response.data;
            },
        }),
        orderEstimate: builder.mutation<
            null,
            {
                visitId: number;
                estimateId: number;
            }
        >({
            query: ({ visitId, estimateId}) => {
                return {
                    url: `visit/${visitId}/estimate/${estimateId}/order`,
                    method: 'PUT',
                };
            },

            invalidatesTags: (result, error, { visitId }) => [
                { type: TagType.Estimate, id: visitId },
                { type: TagType.Instruction, id: visitId },
            ],
            transformResponse: (response: { data: null }) => {
                return response.data;
            },
        }),
        orderNonMedicalEstimate: builder.mutation<
            null,
            {
                visitId: number;
                nonMedicalOrderId: number;
            }
        >({
            query: ({ visitId, nonMedicalOrderId}) => {
                return {
                    url: `visit/${visitId}/non_medical/${nonMedicalOrderId}/order`,
                    method: 'PUT',
                };
            },

            invalidatesTags: (result, error, { visitId }) => [
                { type: TagType.Estimate, id: visitId },
                { type: TagType.Instruction, id: visitId },
            ],
            transformResponse: (response: { data: null }) => {
                return response.data;
            },
        }),
        declineEstimate: builder.mutation<
        null,
        {
            visitId: number;
            estimateId: number;
        }
        >({
            query: ({ visitId, estimateId }) => {

                return {
                    url: `visit/${visitId}/estimate/${estimateId}/decline`,
                    method: 'PUT',
                };
            },

            invalidatesTags: (result, error, { visitId }) => [
                { type: TagType.Estimate, id: visitId },
                { type: TagType.Instruction, id: visitId },
            ],
            transformResponse: (response: { data: null }) => {
                return response.data;
            },
        }),
        transferMultipleToTx: builder.mutation<
            void,
            {
                visitId: number;
                itemsToTransfer: TransferEstimateItemToTx[];
            }
        >({
            query: ({ visitId, itemsToTransfer }) => ({
                url: `visit/${visitId}/estimate/transfer_items_to_tx`,
                method: 'POST',
                body: {
                    items_to_transfer: itemsToTransfer
                }
            }),
            invalidatesTags: (_result, _error, { visitId }) => [
                { type: TagType.Estimate, id: visitId },
                { type: TagType.Instruction, id: visitId },
            ],
        }),
        hideMultipleItems: builder.mutation<
            void,
            {
                visitId: number;
                itemsToHide: { id: number; type_id: string; }[];
            }
        >({
            query: ({ visitId, itemsToHide }) => ({
                url: `visit/${visitId}/estimate/hide_multiple_instructions`,
                method: 'POST',
                body: {
                    items_to_hide: itemsToHide
                }
            }),
            invalidatesTags: (_result, _error, { visitId }) => [
                { type: TagType.Estimate, id: visitId },
                { type: TagType.Instruction, id: visitId },
            ],
        }),
    }),
});

export const estimatePimsService = pimsApi.injectEndpoints({
    endpoints: (builder) => ({
        getEstimateTax: builder.query<number, number>({
            query: (estimate_id: number) => `/estimate/${estimate_id}/tax`,
            transformResponse: (response: { data: number }) => {    
                return response.data;
            },
            providesTags: () => [
                { type: TagType.Discount },
            ],
        }),
        getTaxForMultipleEstimates: builder.query<number, number>({
            query: (visit_id) => `/estimates/visit/${visit_id}/tax`,
            transformResponse: (response: { data: number }) => {
                return response.data;
            },
            providesTags: () => [
                { type: TagType.Discount },
            ],
        }),
    }),
});

export const {
    useGetEstimatesByVisitIdQuery,
    useGetEstimatesByVisitIdNewQuery,
    useLazyGetEstimatesByVisitIdNewQuery,
    useGetLatestEstimateFormLinkQuery,
    useLazyGetLatestEstimateFormLinkQuery,
    useAddNewEstimateMutation,
    useEditEstimateMutation,
    useApproveAllEstimateMutation,
    useApproveEstimateMutation,
    useOrderEstimateMutation,
    useOrderNonMedicalEstimateMutation,
    useDeclineEstimateMutation,
    useTransferMultipleToTxMutation,
    useHideMultipleItemsMutation,
} = estimateService;

export const {
    useGetEstimateTaxQuery,
    useGetTaxForMultipleEstimatesQuery,
} = estimatePimsService;
