export enum USER_PERMISSIONS {
	visit_claim = 'pims:visit:claim',
	visit_claim_as_nurse = 'pims:visit:claim_as_nurse',
	visit_close = 'pims:visit:close',
	billing_transfer = 'pims:billing:transfer',
	billing_receive = 'pims:billing:receive',
	earnings_view = 'pims:earnings:view',
	reports_view = 'pims:reports:view',
	macros_view = 'pims:macros:view',
	admin_panel_access = 'ap:access',
}

export function checkIfUserHasNecessaryPermission(
	userPermissions: USER_PERMISSIONS[] = [],
	necessaryPermission: USER_PERMISSIONS,
) {
	return (
		userPermissions?.some(
			(permission) => permission === necessaryPermission,
		) ?? false
	);
}
