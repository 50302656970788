import { ArrowsAltOutlined } from '@ant-design/icons';
import { Modal, Typography, Button, Row, Col } from 'antd';
import { useState } from 'react';
import { MedicineCard } from 'utils/dataTypes';
import { ClosedMedicines } from './ClosedMediciness';
import { OpenMedicines } from './OpenMedicines';
import OverviewTabStyles from './overview-tab.module.css';

interface MedicationModelProps {
    openMedicines: MedicineCard[];
    closedMedicines: MedicineCard[];
}
export const MedicationModel = ({
    openMedicines,
    closedMedicines,
}: MedicationModelProps) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Modal
                title={
                    <Typography.Text
                        className={OverviewTabStyles['ModalTitle']}
                    >
                        Medications
                    </Typography.Text>
                }
                onOk={() => setOpen(false)}
                visible={open}
                width={'90%'}
                onCancel={() => setOpen(false)}
                destroyOnClose
                footer={
                    <Button type='primary' onClick={() => setOpen(false)} autoFocus>
                        Close
                    </Button>
                }
            >
                <Row gutter={60} style={{ height: '600px' }}>
                    <Col span={24} lg={{ span: 12 }}>
                        <OpenMedicines
                            medicines={openMedicines}
                            showFullTable
                        />
                    </Col>
                    <Col span={24} lg={{ span: 12 }}>
                        <ClosedMedicines
                            medicines={closedMedicines}
                            showFullTable
                        />
                    </Col>
                </Row>
            </Modal>
            <Button
                icon={<ArrowsAltOutlined />}
                onClick={() => setOpen(true)}
            />
        </>
    );
};
