import moment from 'moment';
import { matchPath } from 'react-router';
import { Breed, Species } from 'services/specieService';
import { MINIMUM_STRIPE_PAYMENT_AMOUNT_IN_CENTS, VITAL_DIAGNOSTIC_IDS } from './constants';
import {
	BaseExistingInstruction,
	ExistingDiagInstruction,
	ExpandedPatient,
	isInstanceOfExistingDiagInstruction,
	VisitVital,
} from './dataTypes';
import { roundTo } from './formatFuncs';
import { EarnedRevenueLineItem, EarnedRevenueLineItemDisplay } from './types/billingTypes';
import { MedicineSearchOption } from './types/InstructionOrderTypes';

export function disabledDate(current: any) {
	// Can not select days before today and today
	return current && current < moment().startOf('day');
}

export const getRecentVitalsFromInstructions = (
	pastInstructions?: BaseExistingInstruction[],
) => {
	return pastInstructions
		?.filter(
			(instruct) =>
				instruct.category === 'Vitals' &&  //Todo revisit this and make this less brittle
				isInstanceOfExistingDiagInstruction(instruct),
		)
		.map((vitInst) => {
			return {
				diagnostic_id: (vitInst as ExistingDiagInstruction)
					.diagnostic_id,
				name: vitInst.name,
				unit: isInstanceOfExistingDiagInstruction(vitInst)
					? vitInst.result_entry.unit
					: null,
				lastAction: vitInst.actions
					.filter((action) => action.status === 'complete')
					.sort((a, b) => (a.completed_at || 0) < (b.completed_at || 0) ? 1 : -1)[0],
			};
		});
};
const INSTRUCTION_FREQUENCIES: { [key: string]: number } = {
	qqh: 4,
	qid: 6,
	tid: 8,
	bid: 12,
	qd: 24,
	sid: 24,
	qod: 48,
};
const FREQUENCY_HOURLY_REGEX: RegExp = /^q([1-9][0-9]*)(?:h|hr|hour)$/g;
const FREQUENCY_DAILY_REGEX: RegExp = /^q([1-9][0-9]*)(?:d|day)$/g;
/**
 *
 * @param frequencyString e.g. q4h, etc.
 * @returns number of hours between each "action" -- normalizeFreq('q2h') => 2
 */
export const normalizeFreq = (frequencyString: string): number => {
	if (INSTRUCTION_FREQUENCIES[frequencyString] !== undefined) {
		return INSTRUCTION_FREQUENCIES[frequencyString];
	} else {
		if (frequencyString.match(FREQUENCY_HOURLY_REGEX)) {
			return parseInt(frequencyString.replace(/\D/g, ''));
		} else {
			//e.g. q2d, every 2 days
			return parseInt(frequencyString.replace(/\D/g, '')) * 24;
		}
	}
};

export const testPath = (
	pathToMatch: string | string[],
	currentPath: string,
): boolean => {
	const isMatch =
		matchPath(currentPath, {
			path: pathToMatch,
			exact: true,
			strict: true,
		})?.isExact || false;
	return isMatch;
};

export const isInstructionOver = ({ end_time }: { end_time: number | null }): boolean => {
	if (end_time === null) {
		return false;
	} else {
		return !moment().isAfter(end_time)
	}
}

export function checkConsentFormType(prefix_char: string): string {
	if (prefix_char === 'a') {
		return 'Cremation';
	} else if (prefix_char === 'c') {
		return 'Euthanasia';
	} else if (prefix_char === 'b') {
		return 'Estimate';
	} else if (prefix_char === 'd') {
		return 'Self Check In Form';
	} else if (prefix_char === 'f') {
		return 'Dispensed Medication';
	} else {
		return '';
	}
};

export const  calculateTotalCost = (billingItems: {earned_revenue_line_items: EarnedRevenueLineItem[] | EarnedRevenueLineItemDisplay[]}) => {
	let price = 0;

	billingItems.earned_revenue_line_items.forEach((earnedRevenueItem) => {
		if (earnedRevenueItem.is_comped) return;

		price += Math.floor(earnedRevenueItem.price_cents * earnedRevenueItem.quantity);
	});

	return price;
}


export const formatBasisAsPercentage = (basis: number): string => {
	return roundTo((basis / 10000) * 100, 0) + "%";
}

export const convertBasisToDecimal = (basis: number): number => {
    return basis / 10000;
}

interface LedgerValues {
	due: number;
	subtotalWithDiscount: number;
	total: number;
	discountAmount: number
}


// Todo: expand this when actual calculations of taxes and payment are involved
export const  getLedgerValues = (
	subtotal: number = 0,
    paid: number = 0,
    tax: number = 0,
    discountBasis: number = 0,
	capAmount?: number,
): LedgerValues => {

    const discountRatio = convertBasisToDecimal(discountBasis);
    const discountAmount = getDiscountAmount(subtotal, discountRatio, capAmount);
    const subtotalWithDiscount = subtotal - discountAmount;
    const total = roundTo(subtotalWithDiscount + tax, 0);
    const due = total - paid;

	return {
		due,
		subtotalWithDiscount,
		total,
		discountAmount
	}

}

export const getDiscountAmount = (subtotal: number, discountRatio: number = 0, capAmount?: number) => {
	if (capAmount === 0 || !!capAmount) {
		if (capAmount >= subtotal) {
			return 0;
		}

		const totalDiscount = subtotal - roundTo(capAmount, 2);

		return totalDiscount >= 0 ? totalDiscount : subtotal;
	}

	return roundTo(subtotal * discountRatio, 0);
}

export const validatePaymentAmountInput = (value: string, maxAmountCents: number, isStripePayment: boolean): Promise<void> => {
	const amountCents = roundTo(parseFloat(value.replaceAll(",", "")) * 100, 0);
	if (amountCents <= 0) {
		return Promise.reject(new Error('Invalid amount'));
	}
	else if (isStripePayment && amountCents < MINIMUM_STRIPE_PAYMENT_AMOUNT_IN_CENTS) {
		return Promise.reject(new Error('Minimum Stripe payment is $0.50'));
	}
	else if (amountCents > maxAmountCents) {
		return Promise.reject(new Error('Exceeds allowed amount'));
	}
	else {
		return Promise.resolve();
	}
}

export const getMedicationConcentration = (medication: MedicineSearchOption) => {
	if (medication.numerator_value) {
		return medication.numerator_value / medication.denominator_value;
	}
}

export const checkCookieExists = (name: string): boolean => {
	return document.cookie.split(';').some((item) => item.trim().startsWith(name))
}
export const deleteCookie = (name: string) => {
    const doesExist = checkCookieExists(name);
    if (doesExist){
        document.cookie = name + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}


export const getAgeYearsMonths = (patientData: ExpandedPatient | undefined) => {
	let ageYears = 0;
	let ageMonths = 0;
	if (!!patientData?.birthday && !!patientData?.deceased_at) {
		ageYears = moment
			.unix(patientData?.deceased_at)
			.diff(moment(patientData?.birthday), 'years');
		ageMonths = (moment
			.unix(patientData?.deceased_at)
			.diff(moment(patientData?.birthday), 'months'))
			- (ageYears * 12);
	}
	else if (!!patientData?.birthday) {
		ageYears = moment().diff(moment(patientData?.birthday), 'years');
		ageMonths = (moment()
			.diff(moment(patientData?.birthday), 'months'))
			- (ageYears * 12);
	}
	return {ageYears, ageMonths}
}

export const getIntactSpeciesBreedString = (patientData: ExpandedPatient | undefined) => {
	let intact = '';
	let species = '';
	let breed = '';
	if (patientData) {
		if (patientData.is_intact !== null) {
			if (patientData.sex) {
				if (patientData.sex === 'M') {
					intact = patientData.is_intact ? 'Intact' : 'Neutered';
				}
				else {
					intact = patientData.is_intact ? 'Intact' : 'Spayed';
				}
			}
		}
		if (patientData.species) {
			species = (patientData.is_intact !== null && patientData.sex) ?
				' - ' + patientData.species : patientData.species;
		}
		if (patientData.breed) {
			breed = (patientData.is_intact !== null || patientData.species) ?
				' - ' + patientData.breed : patientData.breed;
		}
	}
	return intact + species + breed;
};

export function checkIfVitalsAreCompleted(vitalInstructions?: VisitVital[]) {
    const temperatureRegx = new RegExp(VITAL_DIAGNOSTIC_IDS[1], 'gi');
    const heartRateRegx = new RegExp(VITAL_DIAGNOSTIC_IDS[2], 'gi');
    const respiratoryRateRegx = new RegExp(VITAL_DIAGNOSTIC_IDS[3], 'gi');

    return (
        vitalInstructions?.some(checkVitalStatus(temperatureRegx)) &&
        vitalInstructions?.some(checkVitalStatus(heartRateRegx)) &&
        vitalInstructions?.some(checkVitalStatus(respiratoryRateRegx))
    );
}

export function checkVitalStatus(vitalRegx: RegExp) {
    return (vital: VisitVital) =>
        vital.name.match(vitalRegx) &&
        vital.lastAction?.status === 'complete';
}

export function checkPatientInfo(patientData?: ExpandedPatient, species?: Species[], breeds?: Breed[]): boolean {
	if (!patientData) return false;

	const currentSpeciesId = species?.find(
		(spe) => spe.name === patientData.species,
	)?.id;
	const availableBreeds =
		breeds?.filter((breed) => breed.species_id === currentSpeciesId) || [];

	const patientNameCheck = Boolean(patientData.name);
	const patientSpeciesCheck = Boolean(patientData.species);
	const patientBreedCheck =
		availableBreeds.length > 0 ? Boolean(patientData.breed) : true;
	const patientSexCheck = Boolean(patientData.sex);
	const patientIntactCheck = patientData.is_intact !== null;
	const patientBirthdayCheck = Boolean(patientData.birthday);

	return (
		patientNameCheck &&
		patientSpeciesCheck &&
		patientBreedCheck &&
		patientSexCheck &&
		patientIntactCheck &&
		patientBirthdayCheck
	);
};

export function hexToRGB(hex: string, alpha: string | null) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export function substractHoursFromDate(date: any, hours: number) {
	date.setHours(date.getHours() - hours);
  return date/1000;
}

export function substractMinutesFromDate(date: any, minutes: number) {
	date.setMinutes(date.getMinutes() - minutes);
  return date/1000;
}

export function getMedicationAlertDescription(
    dosePerKG: number,
    doseUnit: string | null,
    lowDoseAlert?: number | null,
    highDoseAlert?: number | null,
) {
    if (lowDoseAlert && !highDoseAlert && dosePerKG < lowDoseAlert) {
        return `The current dose of ${dosePerKG} ${doseUnit}/kg is less than the recommended minimum dose of ${lowDoseAlert} ${doseUnit}/kg.`;
    }
    if (highDoseAlert && !lowDoseAlert && dosePerKG > highDoseAlert) {
        return `The current dose of ${dosePerKG} ${doseUnit}/kg is higher than the recommended maximum dose of ${highDoseAlert} ${doseUnit}/kg.`;
    }
    if (!!lowDoseAlert && !!highDoseAlert && (dosePerKG < lowDoseAlert || dosePerKG > highDoseAlert)) {
        return `The current dose of ${dosePerKG} ${doseUnit}/kg is not within the recommended range of ${lowDoseAlert}-${highDoseAlert} ${doseUnit}/kg.`;
    }
}

export function dateIsInThePast(currentDate: moment.Moment) {
	return currentDate < moment();
};
