import { CheckOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, FormInstance, Input, Row, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetNonMedicalWhyTypesQuery } from 'services/instructionService';
import { PackageNonMedInstruction } from 'utils/types/InstructionOrderTypes';
import { validateFieldIsShorterThan } from 'utils/validationFuncs';
import OrderFrequency from 'views/visit/TreatmentSheet/OrderFrequency';
import { PackageFormInstance } from '.';
import { HiddenInput } from '../fields/HiddenInput';
import EditLineButton from './EditLineButton';
import PackageItemTitleContainer from './PackageItemTitleContainer';

interface NonMedicalsFormProps {
    nonMed: PackageNonMedInstruction;
    form: FormInstance<PackageFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    fromEstimate?: boolean;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const NonMedicalsForm: React.FC<NonMedicalsFormProps> = ({
    form,
    index,
    nonMed,
    fromEstimate,
    hiddenSectionClass,
    activeLineItem,
    setActiveLineItem,
}) => {
    const [hasError, setHasError] = useState(false);
    const [showWhyOther, setShowWhyOther] = useState(false);
    const lineId = `N-${index}`;
    const isEditing = activeLineItem === lineId || hasError;
    const nonMedFields = [['nonMed', index, 'quantity']];
    const nonMedErrorArray = form.getFieldsError(nonMedFields);
    const handleShouldUpdate = (prev: PackageFormInstance, curr: PackageFormInstance) => {
        return prev.nonMed[index].checked !== curr.nonMed[index].checked || prev.nonMed[index].quantity !== curr.nonMed[index].quantity;
    };
    const { data: nonMedicalWhyTypes } = useGetNonMedicalWhyTypesQuery(null);

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['nonMed', index, 'quantity'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (nonMedErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, nonMedErrorArray]);

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const checked = getFieldValue(['nonMed', index, 'checked']) ?? (nonMed.required ? nonMed.required : nonMed.toggled) ?? true;
                const disabledClass = !checked ? 'disabled-line' : '';
                const initialError = !(getFieldValue(['nonMed', index, 'quantity']) ?? nonMed.quantity);

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass} ${disabledClass} ${
                            nonMed.has_reason && !fromEstimate ? 'grid-table__row-non-med' : ''
                        }`}
                        onClick={() => {
                            if (!isEditing) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['nonMed', index, 'id']} initialValue={nonMed.non_med_id} />
                            <HiddenInput fieldName={['nonMed', index, 'unit_cost_cents']} initialValue={nonMed.cents} />
                            <HiddenInput fieldName={['nonMed', index, 'name']} initialValue={nonMed.name} />
                            <HiddenInput fieldName={['nonMed', index, 'required']} initialValue={nonMed.required} />
                            <HiddenInput fieldName={['nonMed', index, 'no_charge']} initialValue={nonMed.no_charge} />
                        </div>

                        <Form.Item
                            className='grid-table__sticky-column'
                            initialValue={nonMed.required ? nonMed.required : nonMed.toggled}
                            valuePropName='checked'
                            name={['nonMed', index, 'checked']}
                        >
                            {nonMed.required ? (
                                <Tooltip title='Required order. Cannot be removed.'>
                                    <CheckOutlined />
                                </Tooltip>
                            ) : (
                                <Checkbox />
                            )}
                        </Form.Item>

                        <div className='grid-table__sticky-column'>
                            <PackageItemTitleContainer checked={checked} no_charge={nonMed.no_charge}>
                                <Tooltip title={nonMed.name.length > 45 ? nonMed.name : null}>
                                    <span className='treatment-sheet-side-column__title'>{nonMed.name}</span>
                                </Tooltip>
                            </PackageItemTitleContainer>

                            {!fromEstimate && checked && (
                                <div className='diagnostic-data'>
                                    <OrderFrequency>
                                        {nonMed.unit === 'dollar' ? '$' : ''}
                                        {getFieldValue(['nonMed', index, 'quantity']) ?? nonMed.quantity}{' '}
                                        {nonMed.unit === 'dollar' ? '' : nonMed.unit}
                                    </OrderFrequency>
                                </div>
                            )}
                        </div>

                        {checked ? (
                            <>
                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    {nonMed.unit === 'dollar' ? 'Amount' : 'Quantity'}
                                </div>
                                <div className='non-medical-quantity'>
                                    <Form.Item
                                        name={['nonMed', index, 'quantity']}
                                        initialValue={nonMed.quantity}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (isNaN(value)) {
                                                        return Promise.reject('Required');
                                                    }

                                                    if (value <= 0) {
                                                        return Promise.reject('Cannot be smaller or equal to 0');
                                                    }

                                                    if (value % 1 !== 0) {
                                                        return Promise.reject(
                                                            nonMed.unit === 'dollar'
                                                                ? 'Amount cannot be decimal'
                                                                : 'Unit cannot be decimal',
                                                        );
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        {isEditing ? (
                                            <Input
                                                type='number'
                                                prefix={nonMed.unit === 'dollar' ? '$' : undefined}
                                                suffix={nonMed.unit === 'dollar' ? undefined : nonMed.unit}
                                                min={1}
                                            />
                                        ) : (
                                            <span>
                                                {nonMed.unit === 'dollar' ? '$' : ''}
                                                {form.getFieldValue(['nonMed', index, 'quantity']) ?? nonMed.quantity}{' '}
                                                {nonMed.unit === 'dollar' ? '' : 'unit'}
                                            </span>
                                        )}
                                    </Form.Item>
                                </div>

                                {nonMed.has_reason && !fromEstimate && (
                                    <div>
                                        <>
                                            <Form.Item
                                                label='Why'
                                                name={['nonMed', index, 'why']}
                                                labelCol={{ span: 4 }}
                                                wrapperCol={{ span: 20 }}
                                                rules={[{ required: true, message: 'Why is required' }]}
                                            >
                                                <Select<string>
                                                    allowClear
                                                    showSearch
                                                    options={nonMedicalWhyTypes?.map((item: string) => {
                                                        return { label: item, value: item };
                                                    })}
                                                    filterOption={(inputValue, option) =>
                                                        (option?.label as string)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    onChange={(value) => {
                                                        setShowWhyOther(value === 'Other');
                                                    }}
                                                />
                                            </Form.Item>
                                            {showWhyOther && (
                                                <Form.Item
                                                    preserve={false}
                                                    name={['nonMed', index, 'why_other']}
                                                    label='Other'
                                                    labelCol={{ span: 4 }}
                                                    wrapperCol={{ span: 20 }}
                                                    rules={[{ required: true, message: 'Other is required' }]}
                                                >
                                                    <Input placeholder='Please provide short answer' />
                                                </Form.Item>
                                            )}
                                            <Row className='non-medical-reason-info'>
                                                <Col span={20} offset={4}>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>For internal use only.</span>
                                                </Col>
                                            </Row>
                                            <Form.Item
                                                preserve={false}
                                                name={['nonMed', index, 'reason']}
                                                label='Reason'
                                                labelCol={{ span: 4 }}
                                                wrapperCol={{ span: 20 }}
                                                rules={[
                                                    { required: true, message: 'Reason is required' },
                                                    { validator: validateFieldIsShorterThan('reason', 151) },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Row className='non-medical-reason-info'>
                                                <Col span={20} offset={4}>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                                                        Provide an explanation of cost for customer awareness and insurance purposes.
                                                    </span>
                                                    <strong>This will appear on the invoice.</strong>
                                                </Col>
                                            </Row>
                                        </>
                                    </div>
                                )}
                                {(!nonMed.has_reason || fromEstimate) && <div className='empty-element' />}

                                <div className='empty-element' />

                                {!fromEstimate && (
                                    <>
                                        <div className='empty-element' />
                                        <div className='empty-element' />
                                        <div className='empty-element' />
                                    </>
                                )}

                                <EditLineButton
                                    disabled={isEditing}
                                    hasError={hasError || initialError}
                                    onClick={() => {
                                        if (!hasError) setActiveLineItem(lineId);
                                    }}
                                />
                            </>
                        ) : (
                            <div className='disabled-line__form-inputs' />
                        )}
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default NonMedicalsForm;
