import { Button, Col, Divider, Input, Modal, Row, message } from 'antd';
import { Avatar, MarkdownNote } from 'components/lib';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import {
    useCreateEditDischargeNoteCacheMutation,
    useModifyDischargeNoteMutation,
    useModifyVisitMutation,
    useRemoveEditDischargeNoteCacheMutation,
} from 'services/visitService';
import { DischargeNote, PatientRecordVisit } from 'utils/dataTypes';
import { isLastValueSame } from 'utils/formFuncs';

interface DischargeNotesModalProps {
    existingNote?: DischargeNote;
    recheckTime: string | null;
    currentVisit: PatientRecordVisit;
    isDischargeNotesModalVisible: boolean;
    setIsDischargeNotesModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    modalDischargeNote: string;
    setModalDischargeNote: React.Dispatch<React.SetStateAction<string>>;
}

export const DischargeNotesModal = ({
    existingNote,
    recheckTime,
    currentVisit,
    isDischargeNotesModalVisible,
    setIsDischargeNotesModalVisible,
    modalDischargeNote,
    setModalDischargeNote,
}: DischargeNotesModalProps) => {
    const [currentNoteValue, setCurrentNoteValue] = useState({ content: existingNote?.content ?? '' });
    const [currentRecheckTime, setCurrentRecheckTime] = useState(recheckTime);
    const [autosavedAt, setAutosavedAt] = useState<moment.Moment>();
    const [justAutosaved, setJustAutosaved] = useState(false);

    const dischargeNoteRef = useRef<any>();

    let { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const [modifyDischargeNote, { isLoading: loadingModifyDischargeNote }] = useModifyDischargeNoteMutation();
    const [modifyVisit] = useModifyVisitMutation();
    const [createEditDischargeNoteCache] = useCreateEditDischargeNoteCacheMutation();
    const [removeEditDischargeNoteCache] = useRemoveEditDischargeNoteCacheMutation();

    const isContentSame = isLastValueSame(existingNote?.content);
    const isRecheckTimeSame = isLastValueSame(recheckTime);

    useEffect(() => {
        if (existingNote?.content) {
            setCurrentNoteValue({ content: existingNote.content });
        }
    }, [existingNote]);

    useEffect(() => {
        dischargeNoteRef.current = currentNoteValue;
    }, [currentNoteValue]);

    useEffect(() => {
        setCurrentRecheckTime(recheckTime);
    }, [recheckTime]);

    useEffect(() => {
        if (isDischargeNotesModalVisible) {
            createEditDischargeNoteCache({ visitId });
        } else {
            removeEditDischargeNoteCache({ visitId });
        }
    }, [isDischargeNotesModalVisible]);

    useEffect(() => {
        return () => {
            removeEditDischargeNoteCache({ visitId });
        };
    }, []);

    useEffect(() => {
        const handleClose = (event: any) => {
            event.preventDefault();

            removeEditDischargeNoteCache({ visitId });

            return (event.returnValue = '');
        };

        if (isDischargeNotesModalVisible) {
            window.addEventListener('beforeunload', handleClose);
        }

        return () => {
            window.removeEventListener('beforeunload', handleClose);
        };
    }, [visitId, isDischargeNotesModalVisible]);

    const autoSaveFunction = useMemo(
        () =>
            _.debounce(() => {
                modifyDischargeNote({
                    content: dischargeNoteRef.current.content,
                    visitId,
                })
                    .unwrap()
                    .then(() => {
                        message.success('Discharge note successfully autosaved.');
                        setAutosavedAt(moment());
                        setJustAutosaved(true);
                        _.delay(() => {
                            setJustAutosaved(false);
                        }, 1000);
                    });
            }, 3000),
        [],
    );

    const handleOk = () => {
        if (!isContentSame(currentNoteValue.content)) {
            modifyDischargeNote({
                content: currentNoteValue.content,
                visitId,
            });
            setModalDischargeNote(currentNoteValue.content);
        }

        if (!isRecheckTimeSame(currentRecheckTime)) {
            modifyVisit({
                visitId: visitId,
                body: {
                    recheck_time: currentRecheckTime,
                    rdvm_id: currentVisit?.rdvm_id || null,
                    discharge_summary_location: null,
                    owner_id: currentVisit?.customer_id || null,
                    override_display_name: null,
                    override_email: null,
                },
            });
        }
        setIsDischargeNotesModalVisible(false);
        setAutosavedAt(undefined);
    };

    const handleClose = () => {
        setAutosavedAt(undefined);
        setCurrentNoteValue({ content: modalDischargeNote });
        setCurrentRecheckTime(recheckTime);
        removeEditDischargeNoteCache({ visitId });
        setIsDischargeNotesModalVisible(false);
        autoSaveFunction.cancel();
    };

    return (
        <Modal
            visible={isDischargeNotesModalVisible}
            title='Discharge Notes and Follow-up'
            centered={true}
            okText='Save'
            footer={
                <div className='discharge-note__footer'>
                    {autosavedAt && (
                        <div>
                            <Avatar
                                firstName={loggedInUserData?.first_name ?? ''}
                                lastName={loggedInUserData?.last_name ?? ''}
                                className={loggedInUserData?.role_name === 'Nurse' ? 'nurse-avatar' : undefined}
                            />
                            <span
                                style={{
                                    color: justAutosaved ? 'var(--veg-secondary-blue)' : 'inherit',
                                    fontWeight: justAutosaved ? 600 : 'inherit',
                                }}
                            >
                                Discharge note autosaved {moment(autosavedAt).format('YYYY-MM-DD, hh:mm a')}
                            </span>
                        </div>
                    )}
                    <div className='discharge-note__button-container'>
                        <Button
                            disabled={loadingModifyDischargeNote}
                            loading={loadingModifyDischargeNote}
                            type='primary'
                            onClick={handleOk}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            }
            destroyOnClose={true}
            onCancel={handleClose}
            width='75%'
        >
            <Row style={{ marginBottom: '10px' }}>
                <Col span={24}>
                    <span className='discharge-modal-section-title'>
                        Primary Vet Follow-up{' '}
                        <span
                            style={{
                                color: 'var(--gray-7)',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}
                        >
                            (Recommended)
                        </span>
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <Input
                        value={currentRecheckTime ?? ''}
                        onChange={(e) => {
                            setCurrentRecheckTime(e.target.value);
                        }}
                        placeholder='e.g. 2 weeks'
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col>
                    <span className='discharge-modal-section-title'>Discharge Notes</span>
                </Col>
            </Row>
            <MarkdownNote
                content={currentNoteValue.content}
                fieldName='content'
                onFormChange={(value: { content: string }) => {
                    setCurrentNoteValue(value);
                    if (!!value.content && value.content !== existingNote?.content) {
                        autoSaveFunction();
                    }
                }}
                getFormData={() => dischargeNoteRef.current}
            />
        </Modal>
    );
};
