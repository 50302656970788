import { Skeleton } from 'antd';
import React from 'react';
import { WhiteboardItem } from 'services/whiteboardService';
import StatusItem from '../StatusItem';
import './StatusSection.css';

interface StatusSectionProps {
    isLoading: boolean;
    title: string;
    titleCounter: number;
    titleClassName?: string;
    whiteboardItems?: WhiteboardItem[];
}

const StatusSection: React.FC<StatusSectionProps> = ({ isLoading, titleClassName, titleCounter, title, whiteboardItems }) => {
    return (
        <section className={`whiteboard__status-section ${titleClassName}`}>
            <h2 className='whiteboard__status-section-title'>
                <span>{title}</span>
                <span style={{ fontWeight: 'normal', fontSize: '14px' }}> ({titleCounter})</span>
            </h2>

            {isLoading ? <Skeleton active /> : whiteboardItems?.map((item) => <StatusItem item={item} />)}
        </section>
    );
};

export default StatusSection;
