import { convertBillingItemToLocal } from 'utils/dataTypes';
import { downloadFile } from 'utils/triggerFuncs';
import {
	Billing,
	BillingItem,
	DiscountItem,
	LedgerItem,
	StripePaymentErrorItem,
	VisitDiscount,
} from 'utils/types/billingTypes';
import { TagType } from 'utils/types/enums';
import { pimsApi } from './pimsApi';
import moment from 'moment';

// Define a service using a base URL and expected endpoints
export const billingService = pimsApi.injectEndpoints({
	endpoints: (builder) => ({
		getBillingByVisitId: builder.query<BillingItem[], number>({
			/**
			 * TODO when real endpoint gets written, change this URL
			 * Will also need to change the api from `instructionApi` to wherever `billing` lives
			 * And don't map the data with a random price...
			 */
			query: (visitId) => `visit/${visitId}/billing_items`,
			providesTags: (result, error, id) => [
				{ type: TagType.Billing, id },
			],
			transformResponse: (response: { data: BillingItem[] }) => {
				const billingData: BillingItem[] = response.data.map(convertBillingItemToLocal);

				return billingData;
			},
		}),
		getBillingData: builder.query<Billing, {visitId: number, isRefresh?: boolean}>({
			query: ({visitId, isRefresh}) => {
                if (isRefresh) {
                    const lastCheckTime = moment().subtract(2, 'minutes').unix();
                    return `visit/${visitId}/billing_data?lastchecktime=${lastCheckTime}`;
                } else {
                    return `visit/${visitId}/billing_data`;
                }
            },
			providesTags: (result, error, {visitId}) => [
				{ type: TagType.Billing, id: visitId },
			],
			transformResponse: (response: { data: Billing }) => {
                    const billingData = response.data.billing_items.map(convertBillingItemToLocal);

                    return {
                        ...response.data,
                        billing_items: billingData
                    };
			},
		}),
		getDiscounts: builder.query<DiscountItem[], null>({
			/**
			 * TODO when real endpoint gets written, change this URL
			 * Will also need to change the api from `instructionApi` to wherever `billing` lives
			 * And don't map the data with a random price...
			 */
			query: (visitId) => `discounts`,
			transformResponse: (response: { data: DiscountItem[] }) => {
				return response.data;
			},
		}),
		setVisitDiscounts: builder.mutation<
			null,
			{
				visitId: number;
				body: {
					discount_id: number;
					cap_amount_cents?: number;
				};
			}
		>({
			query: ({ visitId, body }) => {
				return {
					url: `visit/${visitId}/discount`,
					method: 'POST',
					body: body,
				};
			},
			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.Discount },
                { type: TagType.Billing, id: visitId }    
			],
			transformResponse: (response: { data: null }) => {
				return response.data;
			},
		}),
		getVisitDiscount: builder.query<VisitDiscount, number>({
			query: (visitId) => `visit/${visitId}/discount`,
			providesTags: (result, error, visitId) => [{ type: TagType.Discount,  id: visitId }],
			transformResponse: (response: { data: VisitDiscount }) => {
				return response.data;
			},
		}),
		getLedgerByVisitId: builder.query<LedgerItem[], number>({
			query: (visitId) => `visit/${visitId}/ledger_items`,
			providesTags: (result, error, id) => [{ type: TagType.Ledger, id }],
			transformResponse: (response: { data: LedgerItem[] }) => {
				return response.data;
			},
		}),
		getStripePaymentErrorsByVisitId: builder.query<StripePaymentErrorItem[], number>({
			query: (visitId) => `visit/${visitId}/stripe_payment_errors`,
			providesTags: (result, error, id) => [{ type: TagType.StripePaymentError, id }],
			transformResponse: (response: { data: StripePaymentErrorItem[] }) => {
				return response.data;
			},
		}),
		updateAttribution: builder.mutation<
			null,
			{
				visitId: number;
				earnedRevenueId: number;
				body: {
					doctor_id: number | null;
				};
			}
		>({
			query: ({ visitId, earnedRevenueId, body }) => {
				return {
					url: `visit/${visitId}/earned_revenue/${earnedRevenueId}/update_attribution`,
					method: 'POST',
					body: body,
				};
			},

			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.Billing, id: visitId },
				{ type: TagType.AttributionSummary },
			],
			transformResponse: (response: { data: null }) => {
				return response.data;
			},
		}),
		refundBillingItem: builder.mutation<
			null,
			{
				visitId: number;
				body: {
					earned_revenue_ids: number[];
				};
			}
		>({
			query: ({ visitId, body }) => {
				return {
					url: `visit/${visitId}/refund`,
					method: 'POST',
					body: body,
				};
			},

			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.Billing, id: visitId },
			],
			transformResponse: (response: { data: null }) => {
				return response.data;
			},
		}),
		refundNonMedBillingItem: builder.mutation<
			null,
			{
				visitId: number;
				body: {
					non_medical_order_id: number;
					reason: string | null;
				};
			}
		>({
			query: ({ visitId, body }) => {
				return {
					url: `visit/${visitId}/non_med_refund`,
					method: 'POST',
					body: body,
				};
			},

			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.Billing, id: visitId },
			],
			transformResponse: (response: { data: null }) => {
				return response.data;
			},
		}),
		deleteBillingItem: builder.mutation<
			void,
			{
				visitId: number;
				earned_revenue_ids: number[];
			}
		>({
			query: ({ visitId, earned_revenue_ids }) => {
				return {
					url: `visit/${visitId}/billing_item/delete`,
					method: 'DELETE',
					body: { earned_revenue_ids },
				};
			},
			invalidatesTags: (_result, _error, { visitId }) => [
				{ type: TagType.Billing, id: visitId },
			],
		}),
		downloadInvoice: builder.query<any, { visitId: number; }>({
			query: ({ visitId }) => ({
				url: `visit/${visitId}/generate_invoice`,
				cache: 'no-cache',
				responseHandler: async (response) => {
					if (response.status === 200) {
						const fileName = `invoice_visit_${visitId}.pdf`;
	
						await downloadFile(response, fileName);
					}
				},
			}),
			providesTags: () => [{ type: 'Invoice' }],
		}),

	}),
});

export const {
	useGetBillingByVisitIdQuery,
	useGetBillingDataQuery,
	useUpdateAttributionMutation,
	useGetLedgerByVisitIdQuery,
	useGetStripePaymentErrorsByVisitIdQuery,
	useRefundBillingItemMutation,
	useRefundNonMedBillingItemMutation,
	useGetDiscountsQuery,
	useGetVisitDiscountQuery,
	useSetVisitDiscountsMutation,
	useLazyDownloadInvoiceQuery,

	useLazyGetBillingDataQuery,
	useDeleteBillingItemMutation,
	useLazyGetStripePaymentErrorsByVisitIdQuery,
} = billingService;
