import { ClockCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Timeline, { DateHeader, ReactCalendarItemRendererProps, TimelineHeaders } from 'react-calendar-timeline';
// make sure you include the timeline stylesheet or the timeline will not be styled
import { PatientAlertTag } from 'components/tags/PatientAlertTag';
import 'react-calendar-timeline/lib/Timeline.css';
import { Link } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import { useGetMainTreatmentSheetQuery, useModifyActionMutation } from 'services/instructionService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { instructionType } from 'utils/dataTypes';
import { filterDiscontinuedInstructions } from 'utils/filterFuncs';
import { hasOverdueActions, insertGhostActionsIntoRealActions } from 'utils/ghostActionUtils';
import { MainTsAction, MainTsData, MainTsItem, MainTsRow, TsRowHeader } from 'utils/types/MainTreatmentSheetTypes';
import './MainTs.css';

const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start_time',
    itemTimeEndKey: 'end_time',
    groupLabelKey: 'id',
};

const TS_WINDOW_RANGE = 5;
const TS_PAST_HOURS = 1;

const TS_WINDOW = {
    start: parseInt(moment().startOf('hour').subtract(TS_PAST_HOURS, 'hour').format('x')),
    current: parseInt(moment().startOf('hour').format('x')),
    end: parseInt(moment().startOf('hour').subtract(TS_PAST_HOURS, 'hour').add(TS_WINDOW_RANGE, 'hour').format('x')),
};

const windowHours = () => {
    const hours = [];
    for (let i = 0; i < TS_WINDOW_RANGE + 1; i++) {
        hours.push(TS_WINDOW.start / 1000 + 3600 * i);
    }
    return hours;
};

const bucketActionsByHour = (data: MainTsData[] | undefined) => {
    const hours = windowHours();
    const bucketedActionsData: MainTsRow[] = [];

    data?.forEach((visit) => {
        const hourlyData: { [key: number]: MainTsAction[] } = {};
        let hasOverdue = false;
        for (const key of hours.slice(0, hours.length - 1)) {
            hourlyData[key] = [];
        }

        (visit.instructions || [])
            .filter((instruction) => filterDiscontinuedInstructions(instruction))
            .forEach((instruction) => {
                buildMtsActionObj(instruction, hourlyData, hours);
                if (!hasOverdue) {
                    hasOverdue = hasOverdueActions(instruction, TS_WINDOW.current / 1000);
                }
            });

        bucketedActionsData.push({
            pet_id: visit.pet_id,
            pet_name: visit.pet_name,
            pet_species: visit.pet_species,
            pet_breed: visit.pet_breed,
            pet_sex: visit.pet_sex,
            is_intact: visit.is_intact,
            last_name: visit.last_name,
            visit_id: visit.visit_id,
            visit_status: visit.visit_status,
            hours: hourlyData,
            hasOverdue: hasOverdue,
            reason_for_visit: visit.reason_for_visit,
            pet_alerts: visit.pet_alerts,
        });
    });

    return bucketedActionsData;
};

const buildMtsActionObj = (instruction: instructionType, hourlyData: { [key: number]: MainTsAction[] }, hours: number[]) => {
    const actions = insertGhostActionsIntoRealActions(
        instruction,
        Math.floor(TS_WINDOW.start / 1000 - 3600 * TS_PAST_HOURS),
        Math.floor(TS_WINDOW.end / 1000),
    );

    actions.forEach((action) => {
        const due_at = action.completed_at ?? action.scheduled_time ?? action.due_at;

        for (let i = 0; i < hours.length - 1; i++) {
            if (due_at >= hours[i] && due_at < hours[i + 1]) {
                const key = hours[i];
                const {
                    id: instruction_id,
                    visit_id,
                    start_time,
                    end_time,
                    created_at,
                    created_by,
                    ordered_by,
                    actions,
                    ...restOfInstruction
                } = instruction;
                const { id: action_id, status: action_status, assigned_to, due_at, scheduled_time } = action;
                const frequency = restOfInstruction.frequency || 'continuous';
                hourlyData[key].push({
                    action_id: action_id,
                    action_status: action_status,
                    assigned_to: assigned_to,
                    due_at: due_at,
                    instruction_id: instruction_id,
                    frequency: frequency,
                    scheduled_time,
                    ...restOfInstruction,
                });
            }
        }
    });
};

interface MainTsProps {
    mainTsType: 'outpatient' | 'inpatient';
}

const MainTs = ({ mainTsType }: MainTsProps) => {
    const [visibleTime, setVisibleTime] = useState(TS_WINDOW);
    const mainTreatmentSheetResponse = useGetMainTreatmentSheetQuery(null, { refetchOnMountOrArgChange: true, pollingInterval: 30000 });
    const { data } = mainTreatmentSheetResponse;
    const bucketedActionsData = bucketActionsByHour(data);

    let sidebarWidth = 420;
    const maxSidebarWidth = 420;

    useEffect(() => {
        TS_WINDOW.start = parseInt(moment().startOf('hour').subtract(TS_PAST_HOURS, 'hour').format('x'));
        TS_WINDOW.current = parseInt(moment().startOf('hour').format('x'));
        TS_WINDOW.end = parseInt(moment().startOf('hour').subtract(TS_PAST_HOURS, 'hour').add(TS_WINDOW_RANGE, 'hour').format('x'));

        setVisibleTime(TS_WINDOW);
    }, [mainTreatmentSheetResponse]);

    const getTreatmentSheetTypeFilter = (patient: MainTsRow) => {
        return mainTsType === 'outpatient'
            ? patient.visit_status === 'arrived' || patient.visit_status === 'inprogress'
            : patient.visit_status === 'hospitalized';
    };

    const tsGroups: TsRowHeader[] = bucketedActionsData?.filter(getTreatmentSheetTypeFilter).map((patient) => {
        const intactLetter =
            patient.is_intact !== null && patient.pet_sex
                ? patient.pet_sex === 'M'
                    ? patient.is_intact
                        ? 'I'
                        : 'N'
                    : patient.is_intact
                    ? 'I'
                    : 'S'
                : '';
        const calcSidebarWidth = (text: string) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if (context) {
                const padding = 18; // account for padding of other elements in sidebar
                context.font = '18px sans-serif'; // sans-serif seems to be close in size to system font (which we cannot specify)
                return Math.ceil(context.measureText(text).width * 1.1) + padding; // give extra 10% as buffer for system font
            } else {
                return text.length * 12; // attempt to guess, err on the larger side, average letter width given font and font size
            }
        };
        const petNamePixelWidth = calcSidebarWidth(patient.pet_name);
        if (petNamePixelWidth > sidebarWidth && petNamePixelWidth < maxSidebarWidth) {
            sidebarWidth = petNamePixelWidth;
        } else if (petNamePixelWidth > maxSidebarWidth) {
            sidebarWidth = maxSidebarWidth;
        }
        return (
            {
                id: `${patient.pet_id}_${patient.visit_id}`,
                title: patient.pet_name,
                last_name: patient.last_name ? patient.last_name : '',
                visit_id: patient.visit_id,
                signalment: `${patient.pet_sex ?? ''}${intactLetter} ${patient.pet_species ? '| ' + patient.pet_species : ''} ${
                    patient.pet_breed ? ' | ' + patient.pet_breed : ''
                }`,
                hasOverdue: patient.hasOverdue,
                reason_for_visit: patient.reason_for_visit,
                pet_alerts: patient.pet_alerts,
            } ?? []
        );
    });

    const tsItems: MainTsItem[] | undefined = bucketedActionsData?.reduce((items, patient) => {
        const patientItems = Object.keys(patient.hours)
            .filter((hourTimeStamp) => patient.hours[parseInt(hourTimeStamp)].length > 0)
            .map((hourTimeStamp): MainTsItem => {
                const { pet_name, visit_id, pet_id } = patient;
                return {
                    pet_id,
                    pet_name,
                    visit_id,
                    hasPriorityAction: patient.hours[parseInt(hourTimeStamp)].some((action) => action.priority),
                    hourTimeStamp: parseInt(hourTimeStamp),
                    id: `${patient.pet_id}_${hourTimeStamp}_${patient.visit_id}`,
                    group: `${patient.pet_id}_${patient.visit_id}`,
                    start_time: parseInt(moment.unix(parseInt(hourTimeStamp)).format('x')),
                    end_time: parseInt(moment.unix(parseInt(hourTimeStamp)).add(1, 'hour').format('x')),
                    actions: patient.hours[parseInt(hourTimeStamp)],
                    pet_alerts: patient.pet_alerts,
                };
            });

        return items.concat(patientItems);
    }, [] as MainTsItem[]);

    return (
        <Row style={{ padding: '24px' }}>
            <h1 className='main-ts-title'>{mainTsType === 'outpatient' ? 'Outpatient' : 'Inpatient'} Treatment Sheet</h1>

            <Col>
                {tsGroups && tsItems.length !== 0 && (
                    <div className='main-ts-timeline'>
                        <Timeline
                            keys={keys}
                            groups={tsGroups}
                            items={tsItems}
                            itemRenderer={itemRenderer}
                            groupRenderer={groupRenderer}
                            lineHeight={80}
                            itemTouchSendsClick={false}
                            sidebarWidth={sidebarWidth}
                            stackItems={false}
                            itemHeightRatio={1}
                            canMove={false}
                            canResize={false}
                            visibleTimeStart={visibleTime.start}
                            visibleTimeEnd={visibleTime.end}
                            onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
                                let newTimeStart = visibleTimeStart;
                                let newCurrentTime = visibleTimeStart + 3600000 * TS_PAST_HOURS;
                                let newEndTime = visibleTimeEnd;

                                if (visibleTimeStart < TS_WINDOW.start) {
                                    newTimeStart = TS_WINDOW.start;
                                    newCurrentTime = newTimeStart + 3600000 * TS_PAST_HOURS;
                                    newEndTime = newTimeStart + 3600000 * TS_WINDOW_RANGE;
                                } else if (visibleTimeEnd > TS_WINDOW.end) {
                                    newTimeStart = TS_WINDOW.end - 3600000 * TS_WINDOW_RANGE;
                                    newCurrentTime = newTimeStart + 3600000 * TS_PAST_HOURS;
                                    newEndTime = TS_WINDOW.end;
                                }
                                setVisibleTime({
                                    start: newTimeStart,
                                    current: newCurrentTime,
                                    end: newEndTime,
                                });
                                updateScrollCanvas(newTimeStart, newEndTime);
                            }}
                            minZoom={60 * 60 * 1000 * 5} //5 hours
                            maxZoom={60 * 60 * 1000 * 5} //5 hours
                            verticalLineClassNamesForTime={(start, end) => {
                                if (moment().startOf('hour').isBefore(start)) {
                                    return ['ts-future-cell'];
                                } else if (moment().endOf('hour').isAfter(end)) {
                                    return ['ts-past-cell'];
                                } else {
                                    return ['main-ts-current-cell'];
                                }
                            }}
                        >
                            <TimelineHeaders className='main-ts-primary-header'>
                                <DateHeader
                                    unit='primaryHeader'
                                    className={
                                        mainTsType === 'inpatient'
                                            ? 'main-ts-primary-header__inpatient'
                                            : 'main-ts-primary-header__outpatient'
                                    }
                                />
                                <DateHeader labelFormat='hh:00 A' />
                            </TimelineHeaders>
                        </Timeline>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default MainTs;

const groupRenderer = ({ group }: { group: TsRowHeader }) => {
    return (
        <section className='main-ts-row-header'>
            <div className='main-ts-row-header__first-row'>
                <Link className='main-ts-row-header__patient-name' to={`/visit/${group.visit_id}?section=treatment_sheet`}>
                    {group.title}
                </Link>

                {group.pet_alerts?.length > 0 && (
                    <span style={{ display: 'flex', marginLeft: '4px', alignItems: 'center' }}>
                        {group.pet_alerts.map((alert) => {
                            return <PatientAlertTag alert={alert} direction='horizontal' noText={true} />;
                        })}
                    </span>
                )}

                <div className='main-ts-row-header__functions'>
                    {group.hasOverdue && <ClockCircleFilled style={{ color: 'var(--veg-red)' }} title='Action overdue' />}
                    <Button className='main-ts-row-header__view-visit-button'>
                        <Link to={`/visit/${group.visit_id}?section=treatment_sheet`}>View</Link>
                    </Button>
                </div>
            </div>
            <div>
                <span className='main-ts-row-header__patient-signalment'>{group.signalment}</span>
            </div>
            <Tooltip title={group.reason_for_visit && group.reason_for_visit.length > 40 ? group.reason_for_visit : null}>
                <div className='main-ts-row-header__reason-for-visit'>
                    <span>{group.reason_for_visit}</span>
                </div>
            </Tooltip>
        </section>
    );
};

const itemRenderer = ({ item, itemContext, getItemProps }: ReactCalendarItemRendererProps<MainTsItem>) => {
    const cellHasOverdueAction = item.actions?.some(
        (action) => action.action_status !== 'complete' && (action.scheduled_time ?? action.due_at) < TS_WINDOW.current / 1000,
    );
    const cellHasIncompleteAction = item.actions?.some(
        (action) => action.action_status !== 'complete' && action.action_status !== 'skipped' && action.action_status !== 'missed',
    );

    const itemClassName = () => {
        if (!cellHasIncompleteAction) {
            return 'main-ts-actions-complete';
        } else if (cellHasOverdueAction) {
            return 'main-ts-cell-overdue';
        } else {
            return 'main-ts-cell';
        }
    };

    const actionItemsCount = item.actions.filter(
        (action) => action.is_shown_on_tx_sheet === true || action.is_shown_on_tx_sheet === undefined,
    ).length;

    if (actionItemsCount === 0) {
        return null;
    }

    return (
        <Popover
            overlayClassName='main-ts-popover'
            content={
                <>
                    <Row>
                        <Col span={24}>
                            {item.actions.map((action) => (
                                <PopoverActionRow
                                    key={`${action.action_id}_popover_action_row`}
                                    action={action}
                                    visit_id={item.visit_id}
                                    hourTimeStamp={TS_WINDOW.current / 1000}
                                />
                            ))}
                        </Col>
                    </Row>

                    <div
                        style={{
                            borderBottom: '1px solid var(--gray-4)',
                            width: 'calc(100% + 32px)',
                            margin: '5px 0 12px -16px',
                        }}
                    />

                    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button style={{ padding: '0 8px', margin: '0 4px' }}>
                            <Link to={`/visit/${item.visit_id}`}>View visit</Link>
                        </Button>
                    </Row>
                </>
            }
            title={<PopoverTitle item={item} />}
            trigger={'click'}
            destroyTooltipOnHide={true}
        >
            <div {...getItemProps(item.itemProps ?? {})}>
                <div
                    className={'rct-item-content ' + itemClassName()}
                    style={{
                        maxHeight: `${itemContext.dimensions.height}`,
                        padding: '4px',
                    }}
                >
                    <Row>
                        {item.hasPriorityAction && (
                            <Col>
                                <ExclamationCircleOutlined
                                    style={{
                                        marginRight: '4px',
                                        fontSize: '14px',
                                        color: 'red',
                                    }}
                                    title='Priority action'
                                />
                            </Col>
                        )}
                        <Col className='main-ts-actions-due'>
                            {actionItemsCount}
                        </Col>
                    </Row>
                </div>
            </div>
        </Popover>
    );
};

interface PopoverActionRowProps {
    action: MainTsAction;
    hourTimeStamp: number;
    visit_id: number;
}
const PopoverActionRow = (props: PopoverActionRowProps) => {
    const { action, hourTimeStamp, visit_id } = props;

    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    const [modifyAction] = useModifyActionMutation();

    const isActionClaimed = action.action_status === 'claimed';
    const isActionPriority = action.priority;
    const isActionInProgress = action.action_status === 'inprogress';
    const isActionComplete = action.action_status === 'complete';
    const isActionOverdue = !isActionComplete && (action.scheduled_time ?? action.due_at) < hourTimeStamp;
    const isActionSkipped = action.action_status === 'skipped';

    let className = '';

    if (isActionClaimed) {
        className += ' main-ts-action-claimed';
    }
    if (isActionOverdue) {
        className += ' main-ts-action-overdue';
    }
    if (isActionInProgress) {
        className += ' main-ts-action-inprogress';
    }
    if (isActionComplete) {
        className += ' main-ts-action-completed';
    }
    if (isActionSkipped) {
        className += ' main-ts-action-skipped';
    }

    const { instruction_id, action_id } = action;

    if (action.type_id === 'TGH' && !action.is_shown_on_tx_sheet) {
        return null;
    }

    return (
        <Row justify='space-between' className={className} gutter={[8, 0]} style={{ margin: '1px 0' }}>
            {isActionPriority && (
                <Col span={2}>
                    <ExclamationCircleOutlined
                        style={{
                            marginRight: '2px',
                            color: '#01a00d',
                        }}
                        title='Priority action'
                    />
                </Col>
            )}
            {isActionOverdue && (
                <Col span={2}>
                    <ClockCircleFilled
                        style={{
                            marginRight: '2px',
                            color: 'var(--veg-red)',
                        }}
                        title='Action overdue'
                    />
                </Col>
            )}
            <Col className='main-ts-action-name' span={18}>
                {action.name}
                {isActionClaimed ? ' (claimed)' : ''}
                {isActionInProgress ? ' (in progress)' : ''}
                {isActionComplete ? ' (complete)' : ''}
                {isActionSkipped ? ' (skipped)' : ''}
            </Col>
            <Col span={4}>
                {!isActionComplete && !isActionInProgress && !isActionSkipped && (
                    <Button
                        type='primary'
                        size='small'
                        onClick={() => {
                            modifyAction({
                                visitId: visit_id,
                                body: {
                                    instructionId: instruction_id,
                                    actionId: action_id,
                                    status: 'claimed',
                                    assigned_to: loggedInUserData?.user_id,
                                    due_at: action.due_at,
                                },
                            });
                        }}
                    >
                        Claim
                    </Button>
                )}
            </Col>
        </Row>
    );
};

interface PopoverTitleProps {
    item: MainTsItem;
}
const PopoverTitle = (props: PopoverTitleProps) => {
    const { item } = props;
    const { pet_name } = item;

    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);
    const [modifyAction] = useModifyActionMutation();

    return (
        <Row justify='space-between' style={{ padding: '0 4px' }}>
            <Col>{pet_name}</Col>
            <Col>
                <Button
                    size='small'
                    type='primary'
                    onClick={() => {
                        const unclaimedActions = item.actions.filter(
                            (action) =>
                                action.action_status !== 'claimed' &&
                                action.action_status !== 'complete' &&
                                action.action_status !== 'inprogress',
                        );

                        unclaimedActions.forEach((action) => {
                            modifyAction({
                                visitId: item.visit_id,
                                body: {
                                    instructionId: action.instruction_id,
                                    actionId: action.action_id,
                                    status: 'claimed',
                                    assigned_to: loggedInUserData?.user_id,
                                    due_at: action.due_at,
                                },
                            });
                        });
                    }}
                >
                    Claim all unclaimed
                </Button>
            </Col>
        </Row>
    );
};
