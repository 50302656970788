import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Radio, Row, message } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import React, { CSSProperties, useState } from 'react';
import { useModifyTriageLevelMutation } from 'services/visitService';
import { PATIENT_LEVELS } from 'utils/constants';
import { PatientRecordVisit, TRIAGE_LEVELS } from 'utils/dataTypes';
import { escapeClose } from 'utils/formFuncs';
import './LevelPopover.css';

interface LevelPopoverProps {
    currentVisit: PatientRecordVisit;
}

const LevelPopover: React.FC<LevelPopoverProps> = ({ currentVisit }) => {
    const [triageLevel, setTriageLevel] = useState(currentVisit.triage_level);
    const [popover, setPopover] = useState(false);

    const [modifyTriageLevel, { isLoading: isModifyingTriageLevel }] = useModifyTriageLevelMutation();

    return (
        <Popover
            visible={popover}
            destroyTooltipOnHide={true}
            onVisibleChange={(show) => {
                setPopover(show);
                escapeClose(popover, setPopover);
            }}
            overlayClassName='level-popover'
            trigger='click'
            title={
                <>
                    <span>Triage Level</span>
                    <Button
                        icon={<CloseOutlined />}
                        onClick={() => {
                            setPopover(false);
                        }}
                    ></Button>
                </>
            }
            content={
                <>
                    <TriageLevelGroup triageLevel={triageLevel} setTriageLevel={setTriageLevel} gutter={32} />
                    <div className='level-popover__footer'>
                        <Button onClick={() => setPopover(false)}>Cancel</Button>
                        <Button
                            disabled={currentVisit.triage_level === triageLevel}
                            loading={isModifyingTriageLevel}
                            onClick={() => {
                                modifyTriageLevel({ visit_id: currentVisit.id, triage_level: triageLevel })
                                    .then((res: any) => {
                                        if (res.data.status === 200) {
                                            message.success('Triage level successfully saved.');
                                        }
                                    })
                                    .finally(() => {
                                        setPopover(false);
                                    });
                            }}
                            className='level-popover__save'
                        >
                            Save
                        </Button>
                    </div>
                </>
            }
            placement='leftTop'
        >
            <span>Triage Level</span>
        </Popover>
    );
};

export default LevelPopover;

interface TriageLevelGroupProps {
    triageLevel: TRIAGE_LEVELS;
    setTriageLevel: React.Dispatch<React.SetStateAction<TRIAGE_LEVELS>>;
    gutter?: Gutter | [Gutter, Gutter];
    showTick?: boolean;
    style?: CSSProperties;
}

export const TriageLevelGroup: React.FC<TriageLevelGroupProps> = ({ triageLevel, setTriageLevel, gutter, showTick, style }) => {
    return (
        <>
            <Radio.Group className='triage-level__content' style={{ width: '100%', ...style }} value={triageLevel}>
                <Row gutter={gutter}>
                    {PATIENT_LEVELS.map((option, index) => {
                        return (
                            <Col span={8} key={index}>
                                <Radio.Button
                                    onClick={(e: any) => {
                                        const newTriageLevel = e.target.value;
                                        setTriageLevel(newTriageLevel === triageLevel ? null : newTriageLevel);
                                    }}
                                    value={option}
                                    style={{ width: '100%', textAlign: 'center' }}
                                    className={`triage-level__btn-${option}`}
                                >
                                    {!!showTick && triageLevel === option && <CheckOutlined style={{ marginRight: '5px' }} />}
                                    {option[0].toUpperCase() + option.substring(1)}
                                </Radio.Button>
                            </Col>
                        );
                    })}
                </Row>
            </Radio.Group>
        </>
    );
};
