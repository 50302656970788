import React, { CSSProperties } from 'react';
import { formatCurrency } from 'utils/formatFuncs';




interface PriceDisplayProps {
    price: number;
    isFreeOrRefund: boolean;

}

const PriceDisplay = React.memo(({price, isFreeOrRefund}: PriceDisplayProps) => {
    const priceStyle: CSSProperties = {
        'opacity': isFreeOrRefund ? '.5' : '1',
    };
    const priceToDisplay = isFreeOrRefund ? 0 : price
	return (
		<span>
            <span style={priceStyle}>{formatCurrency(priceToDisplay)}</span>
		</span>
	);
});

export default PriceDisplay;
