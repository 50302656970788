import { useEffect } from 'react';
import { Macro } from 'utils/dataTypes';

interface NoteMacrosProps {
    macros: Macro[];
    hilightedMacroIndex: number;
    handleSelect: (macro: Macro) => void;
    handleHilight: (macro: Macro) => void;
}

// It seems we can't focus on this component's ref, probably because it's
// being passed to antd popover. This we have to handle the focus and state
// in the note editor
const NoteMacros: React.FC<NoteMacrosProps> = ({ macros, hilightedMacroIndex, handleSelect, handleHilight }) => {
    useEffect(() => {
        handleHilight(macros[hilightedMacroIndex]);
    }, [hilightedMacroIndex, macros.length]);

    return (
        <ul>
            {!macros.length ? <li className='no-items'>No macros matching that query.</li> : null}
            {macros.map((macro, i) => (
                <li
                    key={macro.is_veg ? macro.id : `${macro.id}_${macro.created_by}`}
                    onClick={() => {
                        handleSelect(macro);
                    }}
                    className={`macro${hilightedMacroIndex === i ? ' selected' : ''}`}
                >
                    <b>{macro.key_word}</b> - {macro.content}
                </li>
            ))}
        </ul>
    );
};

export default NoteMacros;
