import { DownOutlined, ExclamationCircleFilled, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Alert, Button, Col, Drawer, Form, Row, Spin, Tooltip, message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import {
    TransferEstimateItemToTx,
    useGetEstimatesByVisitIdNewQuery,
    useHideMultipleItemsMutation,
    useTransferMultipleToTxMutation,
} from 'services/estimateService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useGetVisitByIdQuery } from 'services/visitService';
import { FALLBACK_FLUID_ROUTES } from 'utils/constants';
import { convertToAPI } from 'utils/dataTypes';
import { roundTo } from 'utils/formatFuncs';
import {
    DiagnosticEstimateItemNew,
    FluidEstimateItemNew,
    MedicalEstimateItemNew,
    NonMedicalEstimateItemNew,
    TaskEstimateItemNew,
} from 'utils/types/estimateTypesNew';
import '../../views/visit/TransferEstimateModal/TransferEstimateModal.css';
import EstimateDiagnosticsForm from './EstimateDiagnosticsForm';
import EstimateFluidsForm from './EstimateFluidsForm';
import EstimateMedicationForm from './EstimateMedicationForm';
import EstimateNonMedicalsForm from './EstimateNonMedicalsForm';
import EstimateOxygenTherapyForm from './EstimateOxygenTherapyForm';
import EstimateTasksForm from './EstimateTasksForm';
import './TransferEstimateDrawer.css';
import { getOxygenTherapyUnit } from 'views/visit/EstimatesNew/utils';

interface TransferEstimateDrawerProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    patientWeight?: number;
}

export interface TransferEstimateItemValues {
    id: number;
    type_id: string;
    action: 0 | 1 | 2;
    start_time?: moment.Moment | null;
    end_time?: moment.Moment | null;
    notes?: string | null;
    quantity?: number | null;
    unit?: string | null;
}

interface SharedValues {
    id: number;
    checked: boolean;
    name: string;
    unit_cost_cents: number;
    required: boolean;
    is_free?: boolean;
}

interface StartEndTimeAndInstruction {
    end_time: moment.Moment;
    start_time: moment.Moment;
    instruction: string;
}

interface Frequency {
    frequency: string;
}

interface EstimateNonMeds extends SharedValues, StartEndTimeAndInstruction, Frequency {
    action: 0 | 1 | 2;
    type_id: string;
    quantity: string;
    notes?: string | null;
    instruction_id: number;
    is_prn?: boolean | null;
    prn_condition?: any | null;
    priority?: boolean | null;
    non_med_id: number;
    created_at?: number | null;
    unit: string;
    why: string | null;
    why_other: string | null;
    reason: string | null;
}

interface EstimateTask extends SharedValues, StartEndTimeAndInstruction, Frequency {
    action: 0 | 1 | 2;
    type_id: string;
    notes?: string | null;
    instruction_id: number;
    is_prn?: boolean | null;
    prn_condition?: any | null;
    priority?: boolean | null;
    task_id: number;
    serial: boolean;
    serial_hours: number | null;
}

interface EstimateDiagnostic extends SharedValues, StartEndTimeAndInstruction, Frequency {
    action: 0 | 1 | 2;
    type_id: string;
    notes?: string | null;
    instruction_id: number;
    is_prn?: boolean | null;
    prn_condition?: any | null;
    priority?: boolean | null;
    diagnostic_id: number;
    serial: boolean;
    serial_hours: number | null;
}

interface EstimateFluids extends SharedValues, StartEndTimeAndInstruction {
    rate: number;
    route_id: any;
    volume: any;
    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;

    action: 0 | 1 | 2;
    type_id: string;
    notes?: string | null;
    instruction_id: number;
    is_prn?: boolean | null;
    prn_condition?: any | null;
    priority?: boolean | null;
    fluids_id: number;
}

interface EstimateOxygenTherapy extends SharedValues, StartEndTimeAndInstruction {
    quantity: string;
    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;

    action: 0 | 1 | 2;
    type_id: string;
    notes?: string | null;
    instruction_id: number;
    is_prn?: boolean | null;
    prn_condition?: any | null;
    priority?: boolean | null;
    fluids_id: number;
}

interface EstimateMedication extends SharedValues, StartEndTimeAndInstruction, Frequency {
    numerator_value: number;
    route_id: string;
    calculator_type: string;
    concentration: number;
    controlled_drug: boolean;
    denominator_unit: string;
    dose_unit: string;
    instruction: string;
    numerator_unit: string;
    latest_patient_weight_kg: number | null;
    approx_patient_weight_kg: number | null;
    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;

    action: 0 | 1 | 2;
    type_id: string;
    notes?: string | null;
    quantity?: number | null;
    unit?: string | null;

    instruction_id: number;
    is_prn?: boolean | null;
    prn_condition?: any | null;
    priority?: boolean | null;
    medication_id: number;
    serial: boolean;
    serial_hours: number | null;
}

export interface EstimateFormInstance {
    patient_weight: number;
    end_time: moment.Moment;
    start_time: moment.Moment;
    action: 0 | 1 | 2;
    diagnostics: Array<EstimateDiagnostic>;
    fluids: Array<EstimateFluids>;
    medications: Array<EstimateMedication>;
    tasks: Array<EstimateTask>;
    nonMed: Array<EstimateNonMeds>;
    oxygen_therapy: Array<EstimateOxygenTherapy>;
}

export const TransferEstimateDrawer = ({ isOpen, setIsOpen, patientWeight }: TransferEstimateDrawerProps) => {
    let { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const [collapseSection, setCollapseSection] = useState({ D: false, F: false, M: false, T: false, N: false, OT: false });
    const [activeLineItem, setActiveLineItem] = useState('');
    const [hasError, setHasError] = useState(false);

    const [form] = Form.useForm();

    const mainDivRef = useRef<HTMLDivElement>(null);
    const syncDivRef = useRef<HTMLDivElement>(null);
    const gridTableRef = useRef<HTMLDivElement>(null);

    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const { data: currentVisit } = useGetVisitByIdQuery(visitId ?? skipToken);
    const { data: estimates, isLoading: loadingEstimates } = useGetEstimatesByVisitIdNewQuery(visitId ? { visitId } : skipToken);
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id || '', { skip: !currentVisit?.pet_id });

    const [transferMultipleToTx, { isLoading: transferMultipleToTxLoading }] = useTransferMultipleToTxMutation();
    const [hideMultipleItems, { isLoading: hideMultipleItemsTxLoading }] = useHideMultipleItemsMutation();

    const handleClose = () => {
        form.resetFields();
        setHasError(false);
        setActiveLineItem('');
        setIsOpen(false);
    };

    useEffect(() => {
        if (!transferMultipleToTxLoading && !hideMultipleItemsTxLoading) {
            handleClose();
        }
    }, [transferMultipleToTxLoading, hideMultipleItemsTxLoading]);

    const handleSubmit = (values: EstimateFormInstance) => {
        const itemsToTransfer: TransferEstimateItemToTx[] = [];
        const itemsToHide: { id: number; type_id: string }[] = [];

        values?.medications?.forEach((item) => {
            const formattedStart = item.start_time
                ? typeof item.start_time === 'number'
                    ? item.start_time
                    : item.start_time.unix()
                : null;

            const formattedEnd = item.end_time ? (typeof item.end_time === 'number' ? item.end_time : item.end_time.unix()) : null;

            if (item.action === 0) {
                const { dose, dose_unit } = convertToAPI(item.numerator_value, item.dose_unit);
                itemsToTransfer.push({
                    id: item.instruction_id,
                    notes: item.instruction || null,
                    start_time: formattedStart,
                    end_time: formattedEnd,
                    type_id: item.type_id,
                    unit: item.unit ?? null,
                    quantity: item.quantity ?? null,
                    frequency: item.frequency ?? null,
                    is_prn: item.is_prn || false,
                    prn_condition: item.prn_condition || null,
                    priority: item.priority || false,
                    medication_id: item.id,
                    dose: dose,
                    dose_unit: dose_unit,
                    route_id: item.route_id,
                    controlled_drug: item.controlled_drug,
                    approx_patient_weight_kg: item.latest_patient_weight_kg
                        ? null
                        : item.approx_patient_weight_kg
                        ? item.approx_patient_weight_kg
                        : null,
                    latest_patient_weight_kg: item.latest_patient_weight_kg ?? null,
                    ordered_by: loggedInUserData?.user_id || 0,
                    serial: item.serial ?? false,
                    is_free: item.is_free,
                });
            } else if (item.action === 1) {
                itemsToHide.push({ id: item.instruction_id, type_id: item.type_id });
            }
        });

        values?.fluids?.forEach((item) => {
            const formattedStart = item.start_time
                ? typeof item.start_time === 'number'
                    ? item.start_time
                    : item.start_time.unix()
                : null;
            const formattedEnd = item.end_time ? (typeof item.end_time === 'number' ? item.end_time : item.end_time.unix()) : null;
            const formattedVolume = item.volume ? (typeof item.end_time === 'string' ? item.volume : parseFloat(item.volume)) : null;

            const formattedRoute = FALLBACK_FLUID_ROUTES[item.route_id] || item.route_id;

            if (item.action === 0) {
                itemsToTransfer.push({
                    id: item.instruction_id,
                    type_id: item.type_id,
                    start_time: formattedStart,
                    end_time: formattedEnd,
                    is_prn: item.is_prn ?? null,
                    prn_condition: item.prn_condition ?? null,
                    priority: item.priority ?? null,
                    notes: item.instruction ?? null,
                    ordered_by: loggedInUserData?.user_id || 0,
                    fluids_id: item.id,
                    route_id: formattedRoute,
                    fluids_volume_ml: formattedVolume,
                    rate_mcl_per_hr: roundTo(item.rate * 100, 0) * 10,
                    is_free: item.is_free,
                });
            } else if (item.action === 1) {
                itemsToHide.push({ id: item.instruction_id, type_id: item.type_id });
            }
        });

        values?.diagnostics?.forEach((item) => {
            const formattedStart = item.start_time
                ? typeof item.start_time === 'number'
                    ? item.start_time
                    : item.start_time.unix()
                : null;
            const formattedEnd = item.end_time ? (typeof item.end_time === 'number' ? item.end_time : item.end_time.unix()) : null;
            if (item.action === 0) {
                itemsToTransfer.push({
                    id: item.instruction_id,
                    type_id: item.type_id,
                    start_time: formattedStart,
                    end_time: formattedEnd,
                    frequency: item.frequency ?? null,
                    is_prn: item.is_prn || false,
                    prn_condition: item.prn_condition || null,
                    priority: item.priority || false,
                    notes: item.instruction || null,
                    ordered_by: loggedInUserData?.user_id || 0,
                    diagnostic_id: item.id,
                    serial: item.serial ?? false,
                    is_free: item.is_free,
                });
            } else if (item.action === 1) {
                itemsToHide.push({ id: item.instruction_id, type_id: item.type_id });
            }
        });

        values?.tasks?.forEach((item) => {
            const formattedStart = item.start_time
                ? typeof item.start_time === 'number'
                    ? item.start_time
                    : item.start_time.unix()
                : null;
            const formattedEnd = item.end_time ? (typeof item.end_time === 'number' ? item.end_time : item.end_time.unix()) : null;
            if (item.action === 0) {
                itemsToTransfer.push({
                    id: item.instruction_id,
                    type_id: item.type_id,
                    start_time: formattedStart,
                    end_time: formattedEnd,
                    frequency: item.frequency ?? null,
                    is_prn: item.is_prn || false,
                    prn_condition: item.prn_condition || null,
                    priority: item.priority || false,
                    notes: item.instruction || null,
                    ordered_by: loggedInUserData?.user_id || 0,
                    task_id: item.id,
                    serial: item.serial ?? false,
                    is_free: item.is_free,
                });
            } else if (item.action === 1) {
                itemsToHide.push({ id: item.instruction_id, type_id: item.type_id });
            }
        });

        values?.nonMed?.forEach((item) => {
            if (item.action === 0) {
                itemsToTransfer.push({
                    id: item.instruction_id,
                    type_id: item.type_id,
                    ordered_by: loggedInUserData?.user_id || 0,
                    non_medical_id: item.id ?? null,
                    unit: item.unit,
                    quantity: parseInt(item.quantity),
                    why: item.why ?? null,
                    why_other: item.why_other ?? null,
                    reason: item.reason ?? null,
                    created_at: item.created_at ?? null,
                    is_free: item.is_free,
                });
            } else if (item.action === 1) {
                itemsToHide.push({ id: item.instruction_id, type_id: item.type_id });
            }
        });

        values?.oxygen_therapy?.forEach((item) => {
            const formattedStart = item.start_time
                ? typeof item.start_time === 'number'
                    ? item.start_time
                    : item.start_time.unix()
                : null;
            const formattedEnd = item.end_time ? (typeof item.end_time === 'number' ? item.end_time : item.end_time.unix()) : null;

            if (item.action === 0) {
                itemsToTransfer.push({
                    id: item.instruction_id,
                    type_id: item.type_id,
                    start_time: formattedStart,
                    end_time: formattedEnd,
                    is_prn: item.is_prn ?? null,
                    prn_condition: item.prn_condition ?? null,
                    priority: item.priority ?? null,
                    notes: item.instruction ?? null,
                    ordered_by: loggedInUserData?.user_id || 0,
                    fluids_id: item.id,
                    quantity: roundTo(parseFloat(item.quantity) * 100, 0),
                    unit: getOxygenTherapyUnit(item.name),
                    is_free: item.is_free,
                });
            } else if (item.action === 1) {
                itemsToHide.push({ id: item.instruction_id, type_id: item.type_id });
            }
        });

        if (!itemsToTransfer.length && !itemsToHide.length) {
            handleClose();
        }

        if (itemsToTransfer.length) {
            transferMultipleToTx({ visitId, itemsToTransfer })
                .unwrap()
                .then(() => {
                    message.success(`${itemsToTransfer.length} order(s) successfully transfer to treatment sheet.`);
                })
                .catch(() => {
                    message.error('Unable to transfer to treatment sheet.');
                });
        }

        if (itemsToHide.length) {
            hideMultipleItems({ visitId, itemsToHide })
                .unwrap()
                .then(() => {
                    message.success(`${itemsToHide.length} order(s) successfully excluded.`);
                })
                .catch(() => {
                    message.error('Unable to exclude item.');
                });
        }
    };

    const DrawerFooter = () => {
        return (
            <>
                <Button
                    type='default'
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Cancel
                </Button>

                <Button type='primary' onClick={form.submit}>
                    Save
                </Button>
            </>
        );
    };

    const approvedEstimateItems = estimates
        ?.filter((estimate) => estimate.estimate_status === 'approved')
        .flatMap((estimate) => estimate.estimate_items)
        .filter((item) => item.unit !== 'dollar')
        ?.filter((item) => !item.is_ordered && !item.is_processed && item.is_shown_on_tx_sheet);

    const estimateItemsGrouped = useMemo(() => _.groupBy(approvedEstimateItems, 'type_id'), [approvedEstimateItems]);

    const tooltipText = (
        <>
            <p>Transfer moves order to treatment sheet.</p>

            <p> Exclude removes it from this view and does not show on treatment sheet.</p>

            <p style={{ marginBottom: 0 }}>Save for Later remains visible on this list.</p>
        </>
    );

    const handleScroll = () => {
        if (mainDivRef.current && syncDivRef.current) {
            const MAX_WIDTH_SIZE = mainDivRef.current.scrollWidth - mainDivRef.current.clientWidth;

            if (mainDivRef.current.scrollLeft !== 0) {
                gridTableRef.current?.classList.add('show-left-estimate-shadow');
            }
            if (mainDivRef.current.scrollLeft === 0) {
                gridTableRef.current?.classList.remove('show-left-estimate-shadow');
            }
            if (mainDivRef.current.scrollLeft !== MAX_WIDTH_SIZE) {
                gridTableRef.current?.classList.add('show-right-estimate-shadow');
            }
            if (mainDivRef.current.scrollLeft === MAX_WIDTH_SIZE) {
                gridTableRef.current?.classList.remove('show-right-estimate-shadow');
            }

            syncDivRef.current.scrollLeft = mainDivRef.current.scrollLeft;
        }
    };

    return (
        <Drawer
            title='Transfer New Approved Orders to Treatment Sheet'
            className='side-drawer'
            placement='right'
            closable={true}
            width={'100%'}
            onClose={() => {
                handleClose();
            }}
            visible={isOpen}
            key='transfer_order_drawer'
            mask={true}
            maskClosable={true}
            footer={<DrawerFooter />}
            destroyOnClose={true}
        >
            {loadingEstimates ? (
                <Spin />
            ) : (
                <div className='transfer-estimate-orders-container'>
                    <Form form={form} onFinish={handleSubmit} preserve={false} scrollToFirstError>
                        <Row style={{ marginBottom: '16px', marginTop: '16px' }}>
                            <Col>
                                <Alert type='warning' showIcon message='Visit the Overview tab to transfer approved CRI orders.' />
                            </Col>
                        </Row>
                        <h1 className='package-modal__title' style={{ marginTop: '2px' }}>
                            <span>{patientData?.name}</span>
                            {patientWeight ? (
                                <span>{`${patientWeight}KG`}</span>
                            ) : (
                                <span className='transfer-estimate-orders--no-weight'>No weight recorded</span>
                            )}
                        </h1>

                        {hasError && (
                            <div className='package-modal__error-message'>
                                Missing required information <ExclamationCircleFilled style={{ color: 'var(--veg-red)' }} />
                            </div>
                        )}

                        <div className={'grid-table transfer-order-table'} ref={gridTableRef}>
                            <div className='grid-table__header' ref={syncDivRef}>
                                <div className='grid-table__sticky-column'>Order Name</div>
                                <div className='transfer-estimate-orders__transfer-options'>
                                    <span>Transfer Options</span>
                                    <Tooltip placement='left' title={tooltipText}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </div>
                                <div>Dose/QTY</div>
                                <div>Route</div>
                                <div>Frequency</div>
                                <div>Start Time</div>
                                <div>End Time</div>
                                <div>Instructions</div>
                                <div className='grid-table__sticky-column' style={{ height: '100%' }} />
                            </div>

                            <div className='grid-table__body' ref={mainDivRef} onScroll={handleScroll}>
                                {estimateItemsGrouped['D']?.length && (
                                    <>
                                        <section className='grid-table__full-width-row'>
                                            <div
                                                className='instruction-type-section'
                                                onClick={() => setCollapseSection((oldState) => ({ ...oldState, D: !oldState['D'] }))}
                                            >
                                                {collapseSection['D'] ? <DownOutlined /> : <UpOutlined />}
                                                <span>Diagnostics</span>
                                            </div>
                                        </section>

                                        {estimateItemsGrouped['D']?.map((diag, index) => (
                                            <EstimateDiagnosticsForm
                                                form={form}
                                                diagnostic={diag as DiagnosticEstimateItemNew}
                                                index={index}
                                                key={`${diag.type_id}_${index}`}
                                                hiddenSectionClass={collapseSection['D'] ? 'hidden-section' : ''}
                                                setActiveLineItem={setActiveLineItem}
                                                activeLineItem={activeLineItem}
                                            />
                                        ))}
                                    </>
                                )}

                                {estimateItemsGrouped['M']?.length && (
                                    <>
                                        <section className='grid-table__full-width-row'>
                                            <div
                                                className='instruction-type-section'
                                                onClick={() => setCollapseSection((oldState) => ({ ...oldState, M: !oldState['M'] }))}
                                            >
                                                {collapseSection['M'] ? <DownOutlined /> : <UpOutlined />}
                                                <span>Medication</span>
                                            </div>
                                        </section>

                                        {estimateItemsGrouped['M']?.map((med, index) => (
                                            <EstimateMedicationForm
                                                form={form}
                                                medication={med as MedicalEstimateItemNew}
                                                index={index}
                                                key={`${med.type_id}_${index}`}
                                                hiddenSectionClass={collapseSection['M'] ? 'hidden-section' : ''}
                                                setActiveLineItem={setActiveLineItem}
                                                activeLineItem={activeLineItem}
                                                currentPatientWeight={patientWeight}
                                            />
                                        ))}
                                    </>
                                )}

                                {estimateItemsGrouped['F']?.length && (
                                    <>
                                        <section className='grid-table__full-width-row'>
                                            <div
                                                className='instruction-type-section'
                                                onClick={() => setCollapseSection((oldState) => ({ ...oldState, F: !oldState['F'] }))}
                                            >
                                                {collapseSection['F'] ? <DownOutlined /> : <UpOutlined />}
                                                <span>Fluids</span>
                                            </div>
                                        </section>

                                        {estimateItemsGrouped['F']?.map((fluid, index) => (
                                            <EstimateFluidsForm
                                                fluid={fluid as FluidEstimateItemNew}
                                                form={form}
                                                index={index}
                                                hiddenSectionClass={collapseSection['F'] ? 'hidden-section' : ''}
                                                key={`${fluid.type_id}_${index}`}
                                                setActiveLineItem={setActiveLineItem}
                                                activeLineItem={activeLineItem}
                                            />
                                        ))}
                                    </>
                                )}

                                {estimateItemsGrouped['OT']?.length && (
                                    <>
                                        <section className='grid-table__full-width-row'>
                                            <div
                                                className='instruction-type-section'
                                                onClick={() => setCollapseSection((oldState) => ({ ...oldState, OT: !oldState['OT'] }))}
                                            >
                                                {collapseSection['OT'] ? <DownOutlined /> : <UpOutlined />}
                                                <span>Oxygen Therapy</span>
                                            </div>
                                        </section>

                                        {estimateItemsGrouped['OT']?.map((fluid, index) => (
                                            <EstimateOxygenTherapyForm
                                                fluid={fluid as FluidEstimateItemNew}
                                                form={form}
                                                index={index}
                                                hiddenSectionClass={collapseSection['OT'] ? 'hidden-section' : ''}
                                                key={`${fluid.type_id}_${index}`}
                                                setActiveLineItem={setActiveLineItem}
                                                activeLineItem={activeLineItem}
                                            />
                                        ))}
                                    </>
                                )}

                                {estimateItemsGrouped['T']?.length && (
                                    <>
                                        <section className='grid-table__full-width-row'>
                                            <div
                                                className='instruction-type-section'
                                                onClick={() => setCollapseSection((oldState) => ({ ...oldState, T: !oldState['T'] }))}
                                            >
                                                {collapseSection['T'] ? <DownOutlined /> : <UpOutlined />}
                                                <span>Tasks</span>
                                            </div>
                                        </section>

                                        {estimateItemsGrouped['T']?.map((task, index) => (
                                            <EstimateTasksForm
                                                form={form}
                                                task={task as TaskEstimateItemNew}
                                                index={index}
                                                key={`${task.type_id}_${index}`}
                                                hiddenSectionClass={collapseSection['T'] ? 'hidden-section' : ''}
                                                setActiveLineItem={setActiveLineItem}
                                                activeLineItem={activeLineItem}
                                            />
                                        ))}
                                    </>
                                )}

                                {estimateItemsGrouped['N']?.length && (
                                    <>
                                        <section className='grid-table__full-width-row'>
                                            <div
                                                className='instruction-type-section'
                                                onClick={() => setCollapseSection((oldState) => ({ ...oldState, N: !oldState['N'] }))}
                                            >
                                                {collapseSection['N'] ? <DownOutlined /> : <UpOutlined />}
                                                <span>Non-Medicals</span>
                                            </div>
                                        </section>

                                        {estimateItemsGrouped['N']?.map((nonMed, index) => (
                                            <EstimateNonMedicalsForm
                                                form={form}
                                                nonMed={nonMed as NonMedicalEstimateItemNew}
                                                index={index}
                                                key={`${nonMed.type_id}_${index}`}
                                                hiddenSectionClass={collapseSection['N'] ? 'hidden-section' : ''}
                                                setActiveLineItem={setActiveLineItem}
                                                activeLineItem={activeLineItem}
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Drawer>
    );
};
