import { Alert, Checkbox, Form, message, Modal, Tooltip } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useEmailRecordToCustomerMutation, useEmailRecordToRdvmMutation, useGetVisitByIdQuery } from 'services/visitService';
import './SendMedicalRecordModal.css';
import { useSearchRdvmQuery } from 'services/rdvmService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { RDVM } from 'utils/dataTypes';

interface SendMedicalRecordModalProps {}

interface SendMedicalRecordModalRef {
    openModal: () => void;
}

interface SendMedicalRecordFormFields {
    sendToRdvm: boolean;
    sendToCustomer: boolean;
}

const SendMedicalRecordModal: React.ForwardRefRenderFunction<SendMedicalRecordModalRef, SendMedicalRecordModalProps> = (props, ref) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const [isVisible, setIsVisible] = useState(false);

    const [form] = Form.useForm();

    const { data: currentVisit } = useGetVisitByIdQuery(visitId);
    const { data: allRdvms } = useSearchRdvmQuery(visitId, BASE_QUERY_OPTIONS);
    const rDVM: RDVM | undefined = allRdvms?.find((rdvm) => rdvm.clinic_id === currentVisit?.rdvm_id);
    const rdvmEmail = rDVM?.display_name === 'No Primary Vet' ? null : rDVM?.display_name === 'My Vet Is Not On the List' && !rDVM?.override_email ? null : rDVM?.override_email || rDVM?.email


    const { data: existingCustomer } = useGetCustomerByIdQuery(currentVisit?.customer_id ?? '', { skip: !currentVisit?.customer_id });
    const [emailRecordToCustomer, { isLoading: emailRecordToCustomerLoading }] = useEmailRecordToCustomerMutation();
    const [emailRecordToRdvm, { isLoading: emailRecordToRdvmLoading }] = useEmailRecordToRdvmMutation();

    const isRdvmEmailMissing = !rdvmEmail;
    const isCustomerEmailMissing = !existingCustomer?.email;
    let alertMessage = '';
    
    if (isRdvmEmailMissing && isCustomerEmailMissing) {
        alertMessage = 'No email on record for this rDVM and customer.'
    }else if (isRdvmEmailMissing) {
        alertMessage = 'No email on record for this rDVM.'
    } else if (isCustomerEmailMissing) {
        alertMessage = 'No email on record for this customer.'
    }

    useImperativeHandle(ref, () => ({
        openModal: () => setIsVisible(true),
    }));

    const handleSubmit = (values: SendMedicalRecordFormFields) => {
        if (values.sendToRdvm) {
            emailRecordToRdvm({
                visitId,
                email_type: 'medical record',
            })
                .then(() => {
                    message.success('Email to rDVM sent successfully');
                })
                .catch(() => {
                    message.error('Email to rDVM failed');
                });
        }

        if (values.sendToCustomer) {
            emailRecordToCustomer({
                visitId,
                email_type: 'medical record',
            })
                .then(() => {
                    message.success('Email to customer sent successfully');
                })
                .catch(() => {
                    message.error('Email to customer failed');
                });
        }
    };

    useEffect(() => {
        form.setFieldsValue({ sendToCustomer: !isCustomerEmailMissing, sendToRdvm: !isRdvmEmailMissing });
        setIsDisabled(isCustomerEmailMissing && isRdvmEmailMissing)
    }, [form, isCustomerEmailMissing, isRdvmEmailMissing]);

    useEffect(() => {
        if (!emailRecordToCustomerLoading && !emailRecordToRdvmLoading) {
            setIsVisible(false);
        }
    }, [emailRecordToCustomerLoading, emailRecordToRdvmLoading]);

    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <Modal
            okText='Send Email'
            okButtonProps={{
                loading: emailRecordToCustomerLoading || emailRecordToRdvmLoading,
                autoFocus: true,
                disabled: isDisabled
            }}
            destroyOnClose
            title='Send Full Medical Record'
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            onOk={form.submit}
            
        >
            <Form form={form} onFinish={handleSubmit} onChange={(values) => {
                const sendToCustomer = form.getFieldValue('sendToCustomer');
                const sendToRdvm = form.getFieldValue('sendToRdvm');
                setIsDisabled(!sendToRdvm && !sendToCustomer)
            }}>
                <p>Send an email containing the full medical record.</p>

                <p>Content includes:</p>
                <ul>
                    <li>Medical Record</li>
                    <li>Discharge Summary</li>
                    <li>All diagnostic and note attachments</li>
                    <li>Invoice (customer only)</li>
                </ul>

                <div className='send-record-to'>
                    <div className='send-record-to__title'>Email Full Medical Record to:</div>
                    {(alertMessage) && (
                        <Alert className='send-record-to__alert' message={alertMessage} type='warning' showIcon />
                    )}
                    <div className='send-record-to__checkbox-container'>
                        <Form.Item name='sendToRdvm'>
                            <Tooltip
                                className='send-record-to__checkbox'
                                title={
                                    !currentVisit?.rdvm_id
                                        ? 'No rDVM assigned to this visit.'
                                        : isRdvmEmailMissing
                                        ? 'rDVM missing email.'
                                        : null
                                }
                            >
                                <Checkbox
                                    defaultChecked={form.getFieldValue('sendToRdvm')}
                                    disabled={isRdvmEmailMissing}
                                    onChange={(e) => form.setFieldsValue({ sendToRdvm: e.target.checked })}
                                >
                                    rDVM
                                </Checkbox>
                            </Tooltip>
                        </Form.Item>
                        <Form.Item name='sendToCustomer'>
                            <Tooltip
                                className='send-record-to__checkbox'
                                title={isCustomerEmailMissing ? 'Primary contact missing email.' : null}
                            >
                                <Checkbox
                                    defaultChecked={form.getFieldValue('sendToCustomer')}
                                    disabled={isCustomerEmailMissing}
                                    onChange={(e) => form.setFieldsValue({ sendToCustomer: e.target.checked })}
                                >
                                    Customer
                                </Checkbox>
                            </Tooltip>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default forwardRef(SendMedicalRecordModal);
