import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { warningConfirm } from 'components/confirmModals';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteBillingItemMutation } from 'services/billingService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useGetVisitByIdQuery } from 'services/visitService';
import { formatCurrency } from 'utils/formatFuncs';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { BillingItem } from 'utils/types/billingTypes';

interface DeleteRevenueProps {
    isVisitFinalized: boolean;
    selected: number[];
    billingItems: BillingItem[];
    setSelected: (array: []) => void;
}

const DeleteRevenue: React.FC<DeleteRevenueProps> = ({ billingItems, selected, setSelected, isVisitFinalized }) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const { data: currentVisit } = useGetVisitByIdQuery(visitId, { skip: !selected.length });
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id ?? '', { skip: !currentVisit?.pet_id });

    const [deleteBillingItem] = useDeleteBillingItemMutation();

    const getItemsList = (parentItems: BillingItem[]) => (
        <div style={{ marginBottom: 'var(--spacing-md)' }}>
            {parentItems.map((item) => {
                const lineItems = item.earned_revenue_line_items.filter((li) => selected.some((sel) => sel === li.id));

                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{item.name}</span>
                        <span>{formatCurrency(lineItems.reduce((prev, curr) => prev + curr.price_cents * curr.quantity, 0))}</span>
                    </div>
                );
            })}
        </div>
    );

    const handleClick = () => {
        const parentItems = billingItems.filter((item) => item.earned_revenue_line_items.some((rli) => selected.some((s) => s === rli.id)));

        warningConfirm(
            {
                title: selected.length === 1 ? 'Remove Invoice Item' : 'Remove Invoice Items',
                content:
                    selected.length === 1 ? (
                        <>
                            <p>By removing this invoice item, the item and charge will no longer appear on the invoice.</p>

                            {getItemsList(parentItems)}

                            <p>
                                Are you sure you want to remove this item from {capitalizeFirstLetter(patientData?.name ?? '')}'s invoice?
                            </p>
                        </>
                    ) : (
                        <>
                            <p>By removing these invoice items, these items and charges will no longer appear on the invoice.</p>

                            {getItemsList(parentItems)}

                            <p>
                                Are you sure you want to remove these items from {capitalizeFirstLetter(patientData?.name ?? '')}'s invoice?
                            </p>
                        </>
                    ),
                onOk: async () => {
                    const res = await deleteBillingItem({ visitId, earned_revenue_ids: selected });

                    if ('data' in res) {
                        parentItems.forEach((item) => {
                            message.success(`${item.name} has been removed from the invoice.`);
                        });
                    } else {
                        message.error('Something went wrong. Please try again.');
                        console.error(res.error);
                    }

                    setSelected([]);
                },
            },
            {
                okText: 'Remove',
                onCancel: () => {
                    setSelected([]);
                },
            },
        );
    };

    const tooltipMessage = isVisitFinalized ? 'Remove (Disabled After Close)' : 'Remove'

    return (
        <Tooltip title={tooltipMessage}>
            <Button
                disabled={!selected.length || isVisitFinalized}
                icon={<DeleteOutlined />}
                style={{ marginLeft: 'var(--spacing-sm)' }}
                onClick={handleClick}
                data-cy={'deleteRevenueButton'}
            />
        </Tooltip>
    );
};

export default DeleteRevenue;
