import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Upload, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { HiddenInput } from 'components/forms/fields/HiddenInput';
import { MarkdownNote } from 'components/lib';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PatientNote, useCreatePatientNoteMutation, useUpdatePatientNoteMutation } from 'services/patientService';
import { fileUploadRules } from 'views/visit/Notes/utils';

interface CreatePatientNoteProps {}

interface CreatePatientNoteRef {
    openCreatePatientNoteDrawer: () => void;
    setNoteToUpdate: (note: PatientNote) => void;
}

interface CreatePatientNoteFormValues {
    note: string;
    fileList?: any[];
}

interface UpdatePatientNoteFormValues {
    id: number;
    note: string;
    fileList?: any[];
}

const CreatePatientNoteDrawer: React.ForwardRefRenderFunction<CreatePatientNoteRef, CreatePatientNoteProps> = (props, ref) => {
    const { pet_id } = useParams<{ pet_id: string }>();
    const [isVisible, setIsVisible] = useState(false);
    const [noteToUpdate, setNoteToUpdate] = useState<PatientNote | null>(null);

    const [form] = Form.useForm();

    const [createPatientNote, { isLoading: createPatientNoteLoading }] = useCreatePatientNoteMutation();
    const [updatePatientNote, { isLoading: updatePatientNoteLoading }] = useUpdatePatientNoteMutation();

    useImperativeHandle(ref, () => ({
        openCreatePatientNoteDrawer: () => setIsVisible(true),
        setNoteToUpdate: (note: PatientNote) => {
            setIsVisible(true);
            setNoteToUpdate(note);
        },
    }));

    const checkUnsavedChanges = () => {
        const { note, fileList } = form.getFieldsValue();

        if ((!!note || fileList.length) && !noteToUpdate) {
            confirm({
                title: 'Unsaved Changes',
                icon: <ExclamationCircleOutlined style={{ color: 'var(--gold-6)' }} />,
                content: 'You have unsaved changes. Select cancel to continue editing.',
                okText: 'Confirm',
                okType: 'primary',
                cancelText: 'Cancel',
                onOk() {
                    handleCloseModal();
                },
            });
        } else {
            handleCloseModal();
        }
    };

    const handleCloseModal = () => {
        setIsVisible(false);
        setNoteToUpdate(null);
    };

    const handleSubmit = (values: CreatePatientNoteFormValues | UpdatePatientNoteFormValues) => {
        if ('id' in values) {
            updatePatientNote({
                id: values.id,
                note: values.note,
                pet_id,
                file_obj: values.fileList?.[0]?.originFileObj ?? null,
                file_name: values.fileList?.[0]?.name ?? null,
                file_link: values.fileList?.[0]?.url ?? null,
            })
                .unwrap()
                .then(() => {
                    message.success('Patient note updated successfully');
                    handleCloseModal();
                })
                .catch((e) => {
                    message.error('There was an error updating the patient note');
                    console.error(e);
                });
        } else {
            createPatientNote({
                ...values,
                pet_id,
                file_obj: values.fileList?.[0]?.originFileObj ?? null,
                file_name: values.fileList?.[0]?.name ?? null,
            })
                .unwrap()
                .then(() => {
                    message.success('Patient note successfully created');
                    handleCloseModal();
                })
                .catch((e) => {
                    message.error('There was an error saving the patient note');
                    console.error(e);
                });
        }
    };

    const getDefaultFilesList = () => {
        return !!noteToUpdate && noteToUpdate.file_link && noteToUpdate.file_name
            ? [
                  {
                      uid: '-1',
                      name: noteToUpdate.file_name,
                      url: noteToUpdate.file_link,
                      status: 'done',
                  },
              ]
            : [];
    };

    useEffect(() => {
        form.resetFields();
    }, [isVisible, form]);

    return (
        <Drawer
            bodyStyle={{ display: 'flex', flexDirection: 'column' }}
            destroyOnClose={true}
            mask={false}
            placement='bottom'
            style={{ right: 130, left: 'auto' }}
            title='Add Patient Note'
            visible={isVisible}
            onClose={checkUnsavedChanges}
        >
            <Form form={form} onFinish={handleSubmit}>
                {noteToUpdate && <HiddenInput initialValue={noteToUpdate.id} fieldName='id' />}

                <MarkdownNote
                    fieldName='note'
                    content={noteToUpdate?.note ?? form.getFieldValue('note')}
                    onFormChange={form.setFieldsValue}
                    getFormData={form.getFieldsValue}
                />

                <Form.Item
                    name='fileList'
                    valuePropName='fileList'
                    label='Attachments'
                    initialValue={getDefaultFilesList()}
                    getValueFromEvent={(event) => {
                        if (Array.isArray(event)) {
                            return event;
                        }

                        return event?.fileList;
                    }}
                    rules={fileUploadRules}
                >
                    <Upload maxCount={1} accept='.pdf, .png, .jpg, .doc, .docx' beforeUpload={() => false}>
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>
            </Form>

            <Button
                loading={createPatientNoteLoading || updatePatientNoteLoading}
                style={{ marginLeft: 'auto', marginRight: '5px' }}
                type='primary'
                onClick={form.submit}
            >
                Submit
            </Button>
        </Drawer>
    );
};

export default forwardRef(CreatePatientNoteDrawer);
