import { ClockCircleOutlined } from '@ant-design/icons';
import { Row, Empty, Table, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserNameWrapper, ItemPastDueIcon } from 'utils/componentWrappers';
import { MISSION_CONTROL_DATE_FORMAT, VISIT_TABS_LINKS } from 'utils/constants';
import { DiagnosticCard, ExistingDiagInstruction } from 'utils/dataTypes';
import OverviewTabStyles from './overview-tab.module.css';

const isPastDue = (dueAt: number) => moment().unix() > dueAt;

interface OpenDiagnosticsProps {
    diagnostics: DiagnosticCard[];
    showFullTable?: boolean;
}
export const OpenDiagnostics = React.memo(
    ({ diagnostics, showFullTable }: OpenDiagnosticsProps) => {
        const history = useHistory();

        const columns: any[] = [
            {
                title: 'Type',
                dataIndex: 'name',
                key: 'name',
                align: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (name: string, record: DiagnosticCard) => (
                    <Tooltip placement='topLeft' title={name}>
                        {name}
                    </Tooltip>
                ),
            },
            {
                title: 'Due Date',
                dataIndex: 'dueAt',
                key: 'dueAt',
                align: 'left',
                render: (value: number, record: DiagnosticCard) => (
                    <Row justify='space-between'>
                        {moment.unix(value).format(MISSION_CONTROL_DATE_FORMAT)}
                        {isPastDue(record.dueAt) && !showFullTable ? (
                            <ItemPastDueIcon />

                        ) : (
                            ''
                        )}
                    </Row>
                ),
                sorter: (a: DiagnosticCard, b: DiagnosticCard) => a.dueAt - b.dueAt,
                defaultSortOrder: 'ascend',
                sortDirections: ['ascend', 'descend', 'ascend']


            },
        ];
        let scroll = {
            y: 120,
        };
        if (showFullTable) {
            const claimedColumn = {
                title: 'Claimed By',
                dataIndex: 'existingInstruction',
                key: 'existingInstruction',
                align: 'left',
                render: (value: any, record: DiagnosticCard) => {
                    if (record.action.assigned_to) {
                        return (
                            <Row justify='space-between'>
                                <UserNameWrapper>
                                    {record.action.assigned_to}
                                </UserNameWrapper>
                                {isPastDue(record.dueAt) && showFullTable ? (
                                    <ItemPastDueIcon />
                                ) : (
                                    ''
                                )}
                            </Row>
                        );
                    }
                    return isPastDue(record.dueAt) && showFullTable ? (
                        <Row justify='end'>
                            <ItemPastDueIcon />
                        </Row>
                    ) : (
                        ''
                    );
                },
            };
            columns.push(claimedColumn);

            scroll = {
                y: 525
            };
        }

        return (
            <Table
                data-cy="pendingDiagnosticsTable"
                className='overview-order-table'
                onRow={() => ({
                    onClick: () => {
                        if (!window.getSelection()?.toString()) {
                            history.push(
                                `${history.location.pathname}?section=${VISIT_TABS_LINKS.treatmentSheet}`,
                            );
                        }
    
                    }
                })}
                title={() => (
                    <Row>
                        <Typography.Text className='overview-card-content-header'>
                            Pending
                        </Typography.Text>
                    </Row>
                )}
                columns={columns}
                pagination={false}
                dataSource={diagnostics ?? []}
                rowClassName={(record, index) =>
                    {
                       const pastDueClass = isPastDue(record.dueAt) ? OverviewTabStyles.rowAlert : ''

                       return `overview-order-table-row + ${pastDueClass}`

                    }
                }
                size='small'
                scroll={scroll}
            />
        );
    },
);
