import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { PIMS_HOST } from 'utils/constants';
import './ControlledDrugs.css';

const ControlledDrugsReport = () => {
	return (
		<div className='controlled-drugs-report-container'>
			<h1>Controlled Drugs Report</h1>
			<Button
				type='primary'
				href={`${PIMS_HOST}/controlled_drugs_report`}
				download
			>
				<DownloadOutlined />
				<span>Download Controlled Drugs Report</span>
			</Button>
		</div>
	);
};

export default ControlledDrugsReport;
