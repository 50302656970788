import { Tag } from 'antd';
import { PatientRecordVisit } from 'utils/dataTypes';

interface ReasonForVisitSectionProps {
    currentVisit: PatientRecordVisit;
}

export const ReasonForVisitSection = ({ currentVisit }: ReasonForVisitSectionProps) => {

    if (!currentVisit.reason_for_visit) {
        return (
            <span className='sidebar-instructional-text' style={{ color: 'var(--gray-8)' }}>
                Click edit icon to add a reason for this visit.
            </span>
        );
    } else {
        return (
            <Tag color={'cyan'} className='reason-for-visit-tag'>
                {currentVisit.reason_for_visit}
            </Tag>
        );
    }
};
