import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Typography, Tooltip, Table, Empty, Row } from 'antd';
import moment from 'moment';
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDownloadIdexxResultFileQuery } from 'services/instructionService';
import { UserNameWrapper } from 'utils/componentWrappers';
import { MISSION_CONTROL_DATE_FORMAT, VISIT_TABS_LINKS } from 'utils/constants';
import { DiagnosticCard, InstructionAction } from 'utils/dataTypes';
import { roundTo } from 'utils/formatFuncs';

const DownloadIdexxFileButton = memo(
	({ actionId }: { actionId: number }) => {
		const { data: resultsFile, isLoading: isFileDownloading } =
			useDownloadIdexxResultFileQuery({ actionId: actionId });

		return (
			<>
				<Button
					style={{ border: 'none' }}
					href={resultsFile?.url}
					onClick={(e) => e.stopPropagation()}
          target='_blank'
  				rel='noopener'
					icon={<DownloadOutlined />}
					loading={isFileDownloading}
					disabled={isFileDownloading}
					download
				/>
				{!!resultsFile?.metadata?.result_url &&
					<Button
						style={{ border: 'none' }}
						href={resultsFile?.metadata?.result_url}
						target='_blank'
						onClick={(e) => e.stopPropagation()}
						icon={<EyeOutlined />}
						loading={isFileDownloading}
						disabled={isFileDownloading}
					/>
				}
			</>
		);
	},
);

const Results = ({ record }: { record: DiagnosticCard }) => {
	let result;
	if (
		record.existingInstruction.category === 'IDEXX' ||
		record.existingInstruction.category === 'WebPACS'
	) {
		result = (
			<DownloadIdexxFileButton actionId={record.action.id} />
		);
	} else if (record.existingInstruction.result_entry.widget === 'numeric') {
		result = roundTo(record.value, 3);
	} else {
		result = record.value;
	}
	return (
		<Typography.Text>
			{result} {record.existingInstruction.result_entry.unit}
		</Typography.Text>
	);
};

interface ClosedDiagnosticsProps {
	diagnostics: DiagnosticCard[];
	showFullTable?: boolean;
}
export const ClosedDiagnostics = memo(
	({ diagnostics, showFullTable }: ClosedDiagnosticsProps) => {
		const history = useHistory();
		const columns: any[] = [
			{
				title: 'Type',
				dataIndex: 'name',
				key: 'name',
				align: 'left',
				ellipsis: {
					showTitle: false,
				},
				render: (name: string) => (
					<Tooltip placement='topLeft' title={name}>
						{name}
					</Tooltip>
				),
			},
			{
				title: 'Completed',
				dataIndex: 'action',
				key: 'action',
				align: 'left',
				render: (value: InstructionAction, record: DiagnosticCard) => {
					if (record.action.completed_at) {
						return moment
							.unix(record.action.completed_at)
							.format(MISSION_CONTROL_DATE_FORMAT);
					}
					return '';
				},
				sorter: (a: DiagnosticCard, b: DiagnosticCard) => (a.action.completed_at || 0) - (b.action.completed_at || 0),
				defaultSortOrder: 'ascend',
				sortDirections: ['ascend', 'descend', 'ascend']

			},
			{
				title: 'Results',
				dataIndex: 'value',
				key: 'value',
				align: 'left',
				render: (
					value: number | string | boolean | null,
					record: DiagnosticCard,
				) => <Results record={record} />,
			},
		];
		let scroll = {
			y: 120,
		};
		if (showFullTable) {
			const completedByColumn = {
				title: 'Completed By',
				dataIndex: 'action',
				key: 'action',
				align: 'left',
				render: (value: InstructionAction, record: DiagnosticCard) => {
					return (
						<UserNameWrapper>
							{record.action.status_transition_by}
						</UserNameWrapper>
					);
				},
			};

			columns.push(completedByColumn);

			scroll = {
				y: 525
			};
		}

		return (
			<Table
				data-cy="closedDiagnosticsTable"
				className='overview-order-table'
				onRow={() => ({
					onClick: () => {
						if (!window.getSelection()?.toString()) {
							history.push(
								`${history.location.pathname}?section=${VISIT_TABS_LINKS.treatmentSheet}`,
							);
						}

					}
				})}
				title={() => (
					<Row>
						<Typography.Text className='overview-card-content-header'>
							Completed
						</Typography.Text>
					</Row>
				)}
				columns={columns}
				pagination={false}
				dataSource={diagnostics ?? []}
				size='small'
				scroll={scroll}
				rowClassName="overview-order-table-row"
			/>
		);
	},
);
