import { CSSProperties, useState } from 'react';
import { Button, Col, Row, Form, Input } from 'antd';
import { useModifyReasonForVisitMutation } from 'services/visitService';
import { PatientRecordVisit } from 'utils/dataTypes';

const formItemStyle: CSSProperties = {
    width: '100%',
};

interface ReasonForVisitFormProps {
    currentVisit: PatientRecordVisit;
    setPopoverState?: Function;
}

export const ReasonForVisitForm = (props: ReasonForVisitFormProps) => {
    const [reasonForVisit, setReasonForVisit] = useState(props.currentVisit.reason_for_visit || '');
    const [modifyReasonForVisit] = useModifyReasonForVisitMutation();

    return (
        <Row gutter={[12, 12]} style={{ width: '100%' }}>
            <Col span={24}>
                <Form.Item
                    name='reason_for_visit'
                    label='Reason:'
                    style={formItemStyle}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    help={
                        reasonForVisit.length > 205 ? (
                            <span style={{ color: 'var(--red-5)' }}>Reason must not be longer than 205 characters.</span>
                        ) : null
                    }
                >
                    <Input
                        defaultValue={reasonForVisit}
                        placeholder='Short reason for visit'
                        onChange={(e) => {
                            setReasonForVisit(e.target.value);
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={6} offset={18}>
                <Button
                    type='primary'
                    data-cy='formSubmitButton'
                    onClick={() => {
                        if (reasonForVisit.length > 205) {
                            return;
                        }
                        modifyReasonForVisit({
                            visitId: props.currentVisit.id,
                            body: {
                                reason_for_visit: reasonForVisit || null,
                            },
                        });
                        if (props.setPopoverState) {
                            props.setPopoverState(false);
                        }
                    }}
                >
                    Save
                </Button>
            </Col>
        </Row>
    );
};
