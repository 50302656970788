import { CustomerInfo, ExpandedPatient, PatientRecordVisit } from 'utils/dataTypes';
import { PatientSection } from './PatientSection';
import { shortPetId } from 'utils/formatFuncs';
import { AssignedVetSection } from './AssignedVetSection';
import { VisitSection } from './VisitSection';
import { Alert, Button, Col, FormInstance, Row, Tag } from 'antd';
import heartbreak from 'assets/img/heartbreak_icon.svg';
import { useFormSubmission } from 'hooks';
import moment from 'moment';
import { UserNameWrapper } from 'utils/componentWrappers';
import { FormName } from 'utils/constants';
import { AssignedNurseSection } from './AssignedNurseSection';
import VisitDischargeAlert from './VisitDischargeAlert';    

interface VisitHeaderProps {
    currentVisit: PatientRecordVisit;
    customerInfo: CustomerInfo | undefined;
    patientData: ExpandedPatient | undefined;
    patientWeight: number | undefined;
    modalForm: FormInstance<any>;
    setModalstate: Function;
}

export const VisitHeader = ({ currentVisit, customerInfo, patientData, patientWeight, modalForm, setModalstate }: VisitHeaderProps) => {
    const [onFinishHook] = useFormSubmission();

    const visitClosedMessage = (finalized_at: number, finalized_by: number | null) => (
        <>
            <span>Visit closed by </span>
            <UserNameWrapper>{finalized_by}</UserNameWrapper>
            <span> on </span>
            <span>{moment.unix(finalized_at).format('MM/DD/YYYY HH:mm A')}</span>
        </>
    );
    const reopenChart = () => {
        if (canReopenChart) {
            onFinishHook({}, FormName.reopen_chart);
        }
    };

    const canReopenChart = currentVisit.finalized_at ? moment().unix() < currentVisit.finalized_at + 24 * 60 * 60 : false;

    return (
        <>
            {currentVisit?.status === 'checked_out' && !currentVisit?.finalized_at && (
                <VisitDischargeAlert
                    visitId={currentVisit.id}
                    modalForm={modalForm}
                    setModalState={setModalstate}
                />
            )}
            {currentVisit?.finalized_at && (
                <Alert
                    data-cy='closedVisitAlert'
                    className='closed-visit-message'
                    message={visitClosedMessage(currentVisit.finalized_at, currentVisit.finalized_by)}
                    banner
                    action={
                        canReopenChart ? (
                            <Button data-cy='reopenChartButton' size='small' onClick={reopenChart}>
                                Reopen Chart
                            </Button>
                        ) : (
                            <span style={{ color: 'var(--gray-7)' }}>Visits closed over 24 hrs cannot be reopened.</span>
                        )
                    }
                />
            )}
            <Row className='visit-header'>
                <Col span={24}>
                    <Row align='bottom'>
                        <Col xs={{ span: 14 }} xl={{ span: 16 }} xxl={{ span: 16 }}>
                            <Row align='middle'>
                                <Col style={{ color: '#858383' }} data-cy='petId'>
                                    #{shortPetId(currentVisit.pet_id)}
                                </Col>
                                {!!currentVisit.triage_level && (
                                    <Col style={{ marginLeft: '14px' }}>
                                        <Tag
                                            style={{
                                                backgroundColor:
                                                    currentVisit.triage_level === 'critical'
                                                        ? 'var(--veg-red)'
                                                        : currentVisit.triage_level === 'urgent'
                                                        ? 'var(--veg-tint-gold)'
                                                        : 'var(--gray-8)',
                                                color: currentVisit.triage_level === 'urgent' ? 'var(--gray-8)' : 'white',
                                                border:
                                                    '1px solid ' +
                                                    (currentVisit.triage_level === 'critical'
                                                        ? 'var(--veg-secondary-red)'
                                                        : currentVisit.triage_level === 'urgent'
                                                        ? 'var(--veg-secondary-gold)'
                                                        : 'var(--gray-9)'),
                                                fontSize: '12px',
                                                padding: '1px 8px',
                                            }}
                                        >
                                            {currentVisit.triage_level.toUpperCase()}
                                        </Tag>
                                    </Col>
                                )}

                                {!!patientData?.deceased_at && (
                                    <Col style={{ marginLeft: '4px' }}>
                                        <Tag
                                            icon={<img src={heartbreak} width='16' style={{ margin: '0 5px 2px 0' }} />}
                                            style={{
                                                backgroundColor: '#D32027',
                                                color: 'white',
                                                fontWeight: 'bold',
                                                border: 'none',
                                                fontSize: '12px',
                                                padding: '2px 7px',
                                            }}
                                        >
                                            Deceased
                                        </Tag>
                                    </Col>
                                )}
                            </Row>
                            <PatientSection currentVisit={currentVisit} customerInfo={customerInfo} patientWeight={patientWeight} />
                        </Col>
                        <Col xs={{ span: 10 }} xl={{ span: 8 }} xxl={{ span: 6, offset: 2 }}>
                            <VisitSection currentVisit={currentVisit} modalForm={modalForm} setModalstate={setModalstate} patient={patientData!} />
                            <AssignedVetSection currentVisit={currentVisit} />
                            <AssignedNurseSection currentVisit={currentVisit} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
