import { AutoComplete, Col, Row, Divider, message, Tooltip } from 'antd';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyPatientSearchQuery } from 'services/patientService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { PetGroupedSearch, PetOwner } from 'utils/dataTypes';
import { appendRouteId, formatPhoneNumber } from 'utils/formatFuncs';
import { ROUTES } from 'utils/types/enums';
import topBarStyles from './top-bar.module.css';
import { useAddNewVisitMutation } from 'services/visitService';
import { usePostCheckinTprInstructionsMutation } from 'services/instructionService';
import circled_paw_icon from 'assets/img/Paw.svg';
import circled_user_icon from 'assets/img/CircledUserIcon.svg';
import calendar_icon from 'assets/img/CalendarIcon.svg';
import calendar_plus_icon from 'assets/img/CalendarPlus.svg';
import { useGetUserDataQuery } from 'services/authService';
import './topbar.css';

interface patientDropDownProps { petGroupedSearchResult: PetGroupedSearch, index?: number, dataLength?: number, setSearchValueFunction: Function, patientSearchFunction: Function }

const PatientDropDown = ({ petGroupedSearchResult, index, dataLength, setSearchValueFunction, patientSearchFunction }: patientDropDownProps) => {
    const history = useHistory();

    const getOwnerInfo = (primaryContact: PetOwner) => {
        const { first_name, last_name, phone_number } = primaryContact;
        let ownerInfo = "";
        if (first_name) {
            ownerInfo += first_name
        }
        if (last_name) {
            ownerInfo += ` ${last_name}`
        }
        ownerInfo = ownerInfo.substring(0, 30)
        if (phone_number) {
            if (last_name || first_name) {
                ownerInfo += ` | ${formatPhoneNumber(phone_number)}`
            } else {
                ownerInfo += formatPhoneNumber(phone_number)
            }
        }
        return ownerInfo;
    }

    const { hospital_display_name, pets } = petGroupedSearchResult
    let hospitalDisplayName = hospital_display_name ?? "No Hospital"

    const [createNewVisit] = useAddNewVisitMutation();
    const [postCheckinTprInstructions] = usePostCheckinTprInstructionsMutation();

    return (
        <div className='search-result' style={{
            paddingTop: index === 0 ? "24px" : "", 
            paddingBottom: dataLength && dataLength - 1 === index ? "24px" : ""
        }}>
            <Row
                className={topBarStyles['hospital-search-name']}
            >
                <Col span={24}>{hospitalDisplayName}</Col>
            </Row>
            <Divider style={{marginTop: '2px', marginBottom: '10px'}}/>
            {pets?.map((pet, index) => {
                return (
                    <>
                    <Row
                    className={topBarStyles['patient-search-name']}
                    >
                        <Col span={24}>
                            <div style={{display:'flex', justifyContent: 'space-between'}}>
                                <div style={{display:'flex', gap: '8px', cursor: 'pointer'}} onClick={() => history.push(appendRouteId(ROUTES.patient, String(pet.pet_id)))}>
                                    <img
                                        alt={"circled_paw_icon"}
                                        src={circled_paw_icon}
                                        width={'20px'}
                                        height={'20px'}
                                    />
                                    {pet.name.substring(0, 30)}
                                </div>
                                <div style={{display:'flex', gap: '16px'}}>
                                    <Tooltip placement='top' title={'View recent visit'}>
                                        <img
                                            alt={"calendar_icon"}
                                            src={calendar_icon}
                                            width={'20px'}
                                            height={'20px'}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => pet.last_visit_id ? history.push(appendRouteId(ROUTES.visit, String(pet.last_visit_id))) : null}
                                        />
                                    </Tooltip>
                                    <Tooltip placement='top' title={'Create new visit'}>
                                        <img
                                            alt={"calendar_plus_icon"}
                                            src={calendar_plus_icon}
                                            width={'20px'}
                                            height={'20px'}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                createNewVisit({ patientId: pet.pet_id })
                                                    .unwrap()
                                                    .then((data) => {
                                                        postCheckinTprInstructions({ visitId: data.id });
                                                        history.push(appendRouteId(ROUTES.visit, String(data.id)));
                                                        setSearchValueFunction("");
                                                        patientSearchFunction("");
                                                    })
                                                    .catch((e) => {
                                                        message.error('There was an error creating the visit');
                                                        console.error(e);
                                                    });
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {pet.primary_contacts?.map((primaryContact, index) => {
                        return (
                            <Row
                            className={topBarStyles['owner-search-name']}
                            >
                                <Col span={24}>
                                    <div style={{display:'flex', gap: '8px', cursor: 'pointer'}} onClick={() => primaryContact.customer_id ? history.push(appendRouteId(ROUTES.customer, String(primaryContact.customer_id))) : null}>
                                        <img
                                            alt={"circled_user_icon"}
                                            src={circled_user_icon}
                                            width={'20px'}
                                            height={'20px'}
                                        />
                                        {primaryContact.customer_id ? getOwnerInfo(primaryContact) : "No customer record"}
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}
                    {pets?.length - 1 !== index && <Divider style={{marginTop: '2px', marginBottom: '10px'}}/>}
                    </>
                );
            })}
        </div>)
}

export const PatientSearchBox = () => {
    const [value, setValue] = useState<string>()

    const [getPatients, { data }] = useLazyPatientSearchQuery(BASE_QUERY_OPTIONS);

    const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);

    const hospital_name = loggedInUserData ? loggedInUserData?.hospital_name : '';

    const sortedData = data ? [
        ...data.filter(x => x.hospital_display_name === hospital_name),
        ...data.filter(x => x.hospital_display_name !== hospital_name)
    ] : data;

    const patientSearchDebounced = useMemo(
        () =>
            _.debounce((searchContent: string) => {
                getPatients({ search_term: searchContent.trim() });
            }, 500),
        [],
    );

    return (
        <AutoComplete
            allowClear
            filterOption={false}
            data-cy="patientSearch"
            value={value}
            dropdownMatchSelectWidth={390}
            notFoundContent={(
                <div className='search-result' style={{
                    paddingTop: "24px", 
                    paddingBottom: "24px"
                }}>
                    <Row className={topBarStyles['no-result-found']}>
                        <Col span={24}>No Results</Col>
                    </Row>
                </div>
            )}
            className={topBarStyles['patient-search-input']}
            options={sortedData?.map((petGroupedSearchResult, index) => ({
                key: index,
                label: (<PatientDropDown petGroupedSearchResult={petGroupedSearchResult} index={index} dataLength={sortedData?.length} setSearchValueFunction={setValue} patientSearchFunction={patientSearchDebounced} />),
                value: petGroupedSearchResult?.hospital_id ?? "-"
            })) ?? []}
            placeholder="Search..."
            onSearch={(searchContent) => {
                setValue(searchContent);
                patientSearchDebounced(searchContent);
            }}
        />
    );
};
