import { ArrowsAltOutlined } from '@ant-design/icons';
import { Modal, Typography, Button, Row, Col, Select } from 'antd';
import { useState, useEffect } from 'react';
import { DiagnosticCard } from 'utils/dataTypes';
import { ClosedDiagnostics } from './ClosedDiagnostics';
import { OpenDiagnostics } from './OpenDiagnostics';
import OverviewTabStyles from './overview-tab.module.css';


interface DiagnosticModelProps {
    openDiagnostics: DiagnosticCard[];
    closedDiagnostics: DiagnosticCard[];
}

interface Diagnostic {
    label: string;
    value: string;
}
export const DiagnosticModel = ({
    openDiagnostics,
    closedDiagnostics,
}: DiagnosticModelProps) => {
    const [open, setOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const allDiagnostics = [...openDiagnostics, ...closedDiagnostics].reduce<Diagnostic[]>((prev, curr) => {
            return !prev.some((item) => item.label === curr?.name)
                ? [
                      ...prev,
                      {
                          value: curr?.name,
                          label: curr?.name,
                      },
                  ]
                : prev;
        }, []);

    useEffect(() => {
      setSelectedItems([])
    }, [open]);

    return (
        <>
            <Modal
                title={
                    <Typography.Text
                        className={OverviewTabStyles['ModalTitle']}
                    >
                        Diagnostics
                    </Typography.Text>
                }
                onOk={() => setOpen(false)}
                visible={open}
                width={'90%'}
                onCancel={() => setOpen(false)}
                destroyOnClose
                footer={
                    <Button autoFocus type='primary' onClick={() => setOpen(false)}>
                        Close
                    </Button>
                }
                data-cy={'diagnosticsModal'}
            >

                <Row>
                  <Select
                    mode="multiple"
                    placeholder="Filter by diagnostic"
                    value={selectedItems}
                    onChange={setSelectedItems}
                    allowClear={true}
                    style={{ width: '25%' }}
                    options={allDiagnostics}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Row>
                <Row gutter={60} style={{ height: '600px' }}>
                    <Col span={24} lg={{ span: 12 }}>
                        <OpenDiagnostics
                            diagnostics={
                              selectedItems.length > 0
                              ? openDiagnostics.filter(diagnostic =>
                                selectedItems.includes(diagnostic?.name))
                              : openDiagnostics
                            }
                            showFullTable
                        />
                    </Col>
                    <Col span={24} lg={{ span: 12 }}>
                        <ClosedDiagnostics
                            diagnostics={
                              selectedItems.length > 0
                              ? closedDiagnostics.filter(diagnostic =>
                                selectedItems.includes(diagnostic?.name))
                              : closedDiagnostics
                            }
                            showFullTable
                        />
                    </Col>
                </Row>
            </Modal>
            <Button
                icon={<ArrowsAltOutlined />}
                onClick={() => setOpen(true)}
                data-cy={'openDiagnosticsModalButton'}
            />
        </>
    );
};
