import _ from 'lodash';
import { useMemo } from 'react';
import { useGetWhiteboardInfoQuery } from 'services/whiteboardService';
import CPRStatusSubtitle from './CPRStatusSubtitle';
import Indicator from './Indicator';
import StatusSection from './StatusSection';
import './Whiteboard.css';

const Whiteboard = () => {
    const { data: whiteboardInfo, isFetching: fetchingGetWhiteboardInfo } = useGetWhiteboardInfoQuery(undefined, {
        pollingInterval: 60000,
    });

    const whiteboardInfoGrouped = useMemo(() => _.groupBy(whiteboardInfo?.visits, 'section'), [whiteboardInfo?.visits]);

    return (
        <section className='whiteboard-container'>
            <header className='whiteboard__header'>
                <picture className='whiteboard__VEG-logo'>
                    <source srcSet='/img/VEGtangle.svg' media='(max-width: 1320px)' />
                    <img srcSet='/img/VEGLogo.svg' alt='VEG logo' />
                </picture>

                <CPRStatusSubtitle />

                <h1>
                    <div className='whiteboard__title'>Emergency is our middle name</div>
                    <div className='whiteboard__sub-title'>It's all we do. So we do it best.</div>
                </h1>

                <div className='whiteboard__indicators-container'>
                    <Indicator
                        information={`${whiteboardInfo?.indicator_waiting_spike_3 ?? 0}`}
                        isLoading={fetchingGetWhiteboardInfo}
                        title='Waiting spike 3'
                    />
                    <Indicator
                        information={`${whiteboardInfo?.indicator_awaiting_doctor ?? 0}`}
                        isLoading={fetchingGetWhiteboardInfo}
                        title='Awaiting doctor'
                    />
                    <Indicator
                        information={`${whiteboardInfo?.indicator_outpatient ?? 0}/${whiteboardInfo?.indicator_inpatient ?? 0}`}
                        isLoading={fetchingGetWhiteboardInfo}
                        title='Outpatient / Inpatient'
                    />
                    {/* 
                        Keep commented out for now, till this issue is fixed https://github.com/vegolution/issues/issues/2841#issuecomment-1843017918
                    <Indicator
                        information={`${whiteboardInfo?.indicator_patients ?? 0}`}
                        isLoading={fetchingGetWhiteboardInfo}
                        title={`Today's Patients`}
                    /> 
                    */}
                </div>
            </header>

            <section className='whiteboard__board'>
                <div className='whiteboard__board-first-column'>
                    <StatusSection
                        isLoading={fetchingGetWhiteboardInfo}
                        titleClassName='whiteboard__status-section--waiting-spike-3'
                        title='Waiting spike 3'
                        titleCounter={whiteboardInfoGrouped['waiting_spike_3']?.length ?? 0}
                        whiteboardItems={whiteboardInfoGrouped['waiting_spike_3']}
                    />
                    <StatusSection
                        isLoading={fetchingGetWhiteboardInfo}
                        titleClassName='whiteboard__status-section--assessment'
                        title='Assessment'
                        titleCounter={whiteboardInfoGrouped['assessment']?.length ?? 0}
                        whiteboardItems={whiteboardInfoGrouped['assessment']}
                    />
                </div>
                <StatusSection
                    isLoading={fetchingGetWhiteboardInfo}
                    titleClassName='whiteboard__status-section--plan-and-consent'
                    title='Plan & Consent'
                    titleCounter={whiteboardInfoGrouped['plan_and_consent']?.length ?? 0}
                    whiteboardItems={whiteboardInfoGrouped['plan_and_consent']}
                />
                <StatusSection
                    isLoading={fetchingGetWhiteboardInfo}
                    titleClassName='whiteboard__status-section--treatment'
                    title='Treatment'
                    titleCounter={whiteboardInfoGrouped['treatment']?.length ?? 0}
                    whiteboardItems={whiteboardInfoGrouped['treatment']}
                />
                <StatusSection
                    isLoading={fetchingGetWhiteboardInfo}
                    titleClassName='whiteboard__status-section--tgh-review'
                    title='TGH Review'
                    titleCounter={whiteboardInfoGrouped['tgh_review']?.length ?? 0}
                    whiteboardItems={whiteboardInfoGrouped['tgh_review']}
                />
                <StatusSection
                    isLoading={fetchingGetWhiteboardInfo}
                    titleClassName='whiteboard__status-section--checkout'
                    title='Checkout'
                    titleCounter={whiteboardInfoGrouped['checkout']?.length ?? 0}
                    whiteboardItems={whiteboardInfoGrouped['checkout']}
                />
            </section>
        </section>
    );
};

export default Whiteboard;
