import { ClockCircleOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Form, Modal, Tooltip, message } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import { useModifyActionMutation } from 'services/instructionService';
import { setActiveActionItem } from 'store/slices/treatmentSheetSlice';
import { TreatmentSheetInstructionAction, instructionType } from 'utils/dataTypes';
import './RescheduleActionModal.css';

interface RescheduleActionModalProps {
    allInstructions: instructionType[];

}

interface RescheduleActionFormValues {
    newTime: moment.Moment;
}

const RescheduleActionModal: React.FC<RescheduleActionModalProps> = ({allInstructions}) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const [value, setValue] = useState<moment.Moment | null>(moment());
    const [isMinusOneHourButtonDisabled, setIsMinusOneHourButtonDisabled] = useState(true);

    const [form] = Form.useForm();

    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const [modifyAction, { isLoading: isModifyActionLoading }] = useModifyActionMutation();

    const appDispatch = useAppDispatch();
    const activeActionItem = useAppSelector((state) => state.treatmentSheetSlice.activeActionItem);
    const activeActionModal = useAppSelector((state) => state.treatmentSheetSlice.activeActionModal);

    if (!activeActionItem){
        return <></>
    }
    let actionItem: TreatmentSheetInstructionAction = activeActionItem;
    let parentOrder: instructionType | undefined;


    if (activeActionItem?.instruction_id) {
        parentOrder = allInstructions.find((instruction) => {
            return instruction.actions.some((action) => {
                if (action.due_at === activeActionItem.due_at && action.instruction_id === activeActionItem.instruction_id) {
                    const { id, ...fieldsThatCanBeUpdated } = action;
                    actionItem = {
                        ...activeActionItem,
                        ...fieldsThatCanBeUpdated,
                    };
                    return true;
                } else {
                    return false;
                }
            });
        });
    }




    const handleSubmit = (values: RescheduleActionFormValues) => {
        const unixTime = values.newTime.unix();
        const scheduled_time = unixTime - (unixTime % 60);

        modifyAction({
            body: {
                actionId: actionItem.action_id,
                due_at: actionItem.due_at,
                instructionId: actionItem.instruction_id,
                status: actionItem.status,
                assigned_to: actionItem.assigned_to ?? undefined,
                note: actionItem.note,
                scheduled_time,
                scheduled_by: loggedInUserData?.user_id,
            },
            visitId,
        })
            .then((res) => {
                if ('error' in res) return Promise.reject();
                form.setFieldsValue({ newTime: moment() });
                appDispatch(setActiveActionItem(null));
                message.success(`${parentOrder?.name} has been successfully rescheduled.`);
            })
            .catch(() => {
                message.error(`There was an error with ${parentOrder?.name} rescheduling.`);
            });
    };

    const plusMinusHours = (type: 'plus' | 'minus') => {
        const currentType = form.getFieldValue('newTime');
        const timeToAdd = type === 'plus' ? 1 : -1;
        const newTime = moment(currentType).add(timeToAdd, 'hour');

        setNewTimeField(newTime);
    };

    const checkIfDatesAreEqual = () => {
        const scheduledTime = moment.unix(actionItem.scheduled_time ?? actionItem.due_at).format('MM/DD/YYYY hh:mm a');
        const formattedValue = value?.format('MM/DD/YYYY hh:mm a');

        return scheduledTime === formattedValue;
    };

    const dateIsInThePast = (currentDate: moment.Moment) => {
        return currentDate < moment();
    };

    const setNewTimeField = (date: moment.Moment | null) => {
        const dateMinusOneHour = moment(date).subtract(1, 'hour');

        setIsMinusOneHourButtonDisabled(dateIsInThePast(dateMinusOneHour));
        setValue(date);
        form.setFieldsValue({ newTime: date });
        form.validateFields(['newTime']);
    };

    if (actionItem?.category) return null;

    return (
        <Modal
            title={parentOrder?.name}
            visible={
                activeActionModal === 'RescheduleActionModal' &&
                `${activeActionItem.id}_${activeActionItem.instruction_id}` === `${actionItem.id}_${actionItem.instruction_id}` && !actionItem?.category
            }
            // visible={`${activeActionItem.id}_${activeActionItem.instruction_id}` === `${actionItem.id}_${actionItem.instruction_id}` && !actionItem?.category}
            onCancel={() => {
                appDispatch(setActiveActionItem(null));
                setValue(moment());
                form.setFieldsValue({ newTime: moment() });
            }}
            onOk={form.submit}
            okText='Update Schedule Time'
            okButtonProps={{
                disabled: checkIfDatesAreEqual() || !value,
                loading: isModifyActionLoading,
            }}
            maskClosable={false}
            destroyOnClose
        >
            <Form form={form} onFinish={handleSubmit} className='reschedule-action-form' initialValues={{ newTime: moment() }}>
                <Form.Item label='Scheduled For' labelAlign='left' labelCol={{ span: 6 }}>
                    <span className='reschedule-action-form__scheduled-for'>
                        {moment.unix(actionItem.scheduled_time ?? actionItem.due_at).format('MM/DD/YYYY hh:mm a')}
                    </span>
                </Form.Item>

                <Form.Item
                    name='newTime'
                    label='New Time'
                    rules={[{ required: true, message: 'A new time is required' }]}
                    labelAlign='left'
                    labelCol={{ span: 6 }}
                >
                    <DatePicker
                        autoFocus
                        defaultValue={form.getFieldValue('newTime')}
                        value={value ?? form.getFieldValue('newTime')}
                        onChange={setNewTimeField}
                        format={'MM/DD/YYYY hh:mm a'}
                        suffixIcon={<ClockCircleOutlined />}
                        disabledDate={dateIsInThePast}
                        showTime
                    />

                    <span className='reschedule-action-form__new-time-funcs'>
                        <Tooltip title={isMinusOneHourButtonDisabled ? 'New time cannot be in the past' : null}>
                            <Button onClick={() => plusMinusHours('minus')} disabled={isMinusOneHourButtonDisabled || !value}>
                                - 1hr
                            </Button>
                        </Tooltip>
                        <Button onClick={() => plusMinusHours('plus')} disabled={!value}>
                            + 1hr
                        </Button>
                    </span>
                </Form.Item>

                {actionItem.scheduled_time ? (
                    <Alert
                        className='reschedule-action-form__alert'
                        message={`This order was originally scheduled for ${moment.unix(actionItem.due_at).format('MM/DD/YYYY hh:mm a')}`}
                        type='info'
                        showIcon
                    />
                ) : (
                    <Alert
                        className='reschedule-action-form__alert'
                        message='This change only applies to this specific occurrence.'
                        type='warning'
                        showIcon
                    />
                )}
            </Form>
        </Modal>
    );
};

export default RescheduleActionModal;
