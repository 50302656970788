import { Alert, Button, Col, Collapse, DatePicker, Form, FormInstance, Radio, Row, Space, Tooltip, message } from "antd";
import { CSSProperties, useState } from "react";
import {
    ExistingCriInstruction,
    ExistingDiagInstruction,
    ExistingFluidInstruction,
    ExistingMedInstruction,
    ExistingOxygenTherapyInstruction,
    ExistingToGoMedInstruction,
    PimsUser,
    TreatmentSheetInstructionAction,
    isInstanceOfCRIInstruction,
    isInstanceOfMedicineInstruction,
} from "utils/dataTypes";
import { HiddenInput } from "../fields/HiddenInput";
import { ClockCircleOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import moment from 'moment';
import { UserNameWrapper } from "utils/componentWrappers";
import './CompleteStatusActionForm.css';
import { roundTo } from "utils/formatFuncs";
import { useGetInstructionOptionsQuery, useSubmitXRayGenerateLinkMutation } from "services/instructionService";
import { useAppDispatch } from "hooks/useRedux";
import { setActiveActionModal, setActiveTreatmentSheetAsk } from "store/slices/treatmentSheetSlice";
import { INITIAL_MODAL_STATE } from "views/visit/Visit";
import { WastedControlledDrugs } from "./WastedControlledDrugs";
import MedicationDoseAlert from "../MedicationDoseAlert";
import { MedicineSearchOption } from "utils/types/InstructionOrderTypes";
import { formatFluidAdditiveNameList } from "views/visit/TreatmentSheet";

const formItemStyle: CSSProperties = {
    width: '100%',
};

const valueColumnStyle: CSSProperties = {
    fontWeight: 500
};

const DEFAULT_LABEL_SPAN = 6;
const DEFAULT_VALUE_SPAN = 18;

interface CompleteStatusActionFormProps {
    instruction: ExistingMedInstruction | ExistingFluidInstruction | ExistingCriInstruction | ExistingDiagInstruction | ExistingToGoMedInstruction | ExistingOxygenTherapyInstruction;
    action: TreatmentSheetInstructionAction;
    user: PimsUser;
    onFormChange: Function;
    formWidget: JSX.Element;
    inFullForm: boolean;
    setModalState?: Function,
    modalForm?: FormInstance<any>,
    patientWeight?: number;
    hideMedicationDoseAlert?: boolean;
}

export const CompleteStatusActionForm = ({
    instruction,
    action,
    user,
    onFormChange,
    formWidget,
    inFullForm,
    setModalState,
    modalForm,
    patientWeight,
    hideMedicationDoseAlert,
}: CompleteStatusActionFormProps) => {
    const appDispatch = useAppDispatch();
    const [submitXRayGenerateLink] = useSubmitXRayGenerateLinkMutation();
    const { data: instructionOptions } = useGetInstructionOptionsQuery(null);

    const [completedTimeRadioValue, setCompletedTimeRadioValue] = useState(
        action.completed_at ? 2 : 1
    );
    const [isPlusOneHourButtonDisabled, setIsplusOneHourButtonDisabled] = useState(true);
    const [selectedTime, setSelectedTime] = useState<moment.Moment | null>(
        action.completed_at
            ? moment.unix(action.completed_at)
            : moment()
    );

    const isControlledDrug = (
        isInstanceOfMedicineInstruction(instruction) || isInstanceOfCRIInstruction(instruction) || instruction.type_id === 'TGH'
    ) && instruction.controlled_drug;


    const dateIsInTheFuture = (currentDate: moment.Moment) => {
        return currentDate > moment();
    };

    const setNewTimeField = (date: moment.Moment | null) => {
        const datePlusOneHour = moment(date).add(1, 'hour');
        setIsplusOneHourButtonDisabled(dateIsInTheFuture(datePlusOneHour));
        setSelectedTime(date);
        onFormChange({ completed_at: date });

    };

    const plusMinusHours = (type: 'plus' | 'minus') => {
        const timeToAdd = type === 'plus' ? 1 : -1;
        const newTime = moment(selectedTime).add(timeToAdd, 'hour');
        setNewTimeField(newTime);
    };

    const getCompletedTimeLabel = () => {
        if (instruction.type_id === 'C' || instruction.type_id === 'F' || instruction.type_id === 'OT') {
            return 'Start Time';
        }
        return 'Completed Time';
    };

    const getRadioItems = () => {
        if (instruction.type_id === 'C' || instruction.type_id === 'F' || instruction.type_id === 'OT') {
            return (
                <>
                    <Radio value={1}>Started Now</Radio>
                    <Radio value={2}>Started Earlier</Radio>
                </>
            );
        }
        return (
            <>
                <Radio value={1}>Completed Now</Radio>
                <Radio value={2}>Completed Earlier</Radio>
            </>
        );
    };

    const isRateChangeAction = () => {
        if ((instruction.type_id === 'C' || instruction.type_id === 'F' || instruction.type_id === 'OT') && action.value) {
            return true;
        }
        return false;
    };

    const getMedicationAlert = () => {
        if (isInstanceOfMedicineInstruction(instruction)) {
            const medication = instructionOptions?.find(
                (m: any) => m.type_id === 'M' && m.id === instruction.medication_id,
            ) as MedicineSearchOption;

            return (
                <MedicationDoseAlert
                    doseUnit={medication.numerator_unit}
                    patientWeight={patientWeight ?? instruction.latest_patient_weight_kg ?? instruction.approx_patient_weight_kg}
                    dose={instruction.dose}
                    highDoseAlert={medication.high_dose_alert}
                    lowDoseAlert={medication.low_dose_alert}
                    medication={medication}
                    hideConfirmation
                />
            );
        }
    };

    const getFluidsAlertMessage = (actionValue: string | number | boolean | null, type_id: string) => {
        if (actionValue) {
            if (type_id === 'OT') {
                return 'Because oxygen is billed incrementally, Oxygen Rate changes cannot be marked as completed earlier.';
            } else {
                return 'Because fluids are billed incrementally, Fluid Rate changes cannot be marked as completed earlier.';
            }
        } else {
            if (type_id === 'OT') {
                return 'Billing of this medication begins when clicking “Start Oxygen”';
            } else {
                return 'Billing of this medication begins when clicking “Start Fluids”';
            }
        }
    }

    return (
        <>


            {!inFullForm &&
                <>
                    <HiddenInput fieldName='assigned_to' initialValue={user.user_id} />
                    <HiddenInput fieldName='status' initialValue={'complete'} />
                    <HiddenInput fieldName='note' initialValue={action.note} />
                </>
            }
            {instruction.type_id === 'C' &&
                <>
                    {(action.value && !inFullForm) &&
                        <>
                            <Row style={{ width: "100%" }}>
                                <Col span={6}>
                                    Action Request:
                                </Col>
                                <Col span={18}>
                                    <h4>{`Change fluid rate to ${action.value} ml/hr`}</h4>
                                </Col>
                            </Row>
                        </>
                    }
                    {(!action.value && !inFullForm) &&
                        <div style={{ width: '100%', marginTop: '10px' }}>
                            <Row style={formItemStyle}>
                                <Col span={DEFAULT_LABEL_SPAN}>Fluid rate:</Col>
                                <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                                    {`${roundTo(instruction.initial_rate_ml_per_hr, 3)} ml/hr`}
                                </Col>
                            </Row>

                            <Row style={formItemStyle}>
                                <Col span={DEFAULT_LABEL_SPAN}>Dose:</Col>
                                <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                                    {
                                        instruction.default_cri_unit === 'mcg/kg/min' ? 
                                        `${roundTo(instruction.dose * 1000 / 60, 3)} ${instruction.default_cri_unit}` : 
                                        `${roundTo(instruction.dose, 3)} ${instruction.dose_unit}`
                                    }
                                </Col>
                            </Row>

                            <Row style={formItemStyle}>
                                <Col span={DEFAULT_LABEL_SPAN}>Route:</Col>
                                <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                                    CRI
                                </Col>
                            </Row>
                        </div>
                    }
                </>
            }

            {instruction.type_id === 'F' &&
                <>
                    {(action.value && !inFullForm) &&
                        <>
                            <Row style={{ width: "100%" }}>
                                <Col span={6}>
                                    Action Request:
                                </Col>
                                <Col span={18}>
                                    <h4>{`Change fluid rate to ${action.value} ml/hr`}</h4>
                                </Col>
                            </Row>
                        </>
                    }
                    {(!action.value && !inFullForm) &&
                        <div style={{ width: '100%', marginTop: '10px' }}>
                            <Row style={formItemStyle}>
                                <Col span={DEFAULT_LABEL_SPAN}>Fluid rate:</Col>
                                <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                                    {`${roundTo(instruction.initial_rate_ml_per_hr, 3)} ml/hr`}
                                </Col>
                            </Row>

                            <Row style={formItemStyle}>
                                <Col span={DEFAULT_LABEL_SPAN}>Bag volume:</Col>
                                <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                                    {`${roundTo(instruction.fluids_volume_ml, 3)} ml`}
                                </Col>
                            </Row>

                            <Row style={formItemStyle}>
                                <Col span={DEFAULT_LABEL_SPAN}>Route:</Col>
                                <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                                    {instruction.route_id}
                                </Col>
                            </Row>
                            {instruction.fluid_additives.filter(additive => additive.initial_order).length > 0 &&
                                <Row style={formItemStyle}>
                                    <Col span={DEFAULT_LABEL_SPAN}>Initial Additives:</Col>
                                    <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                                        {formatFluidAdditiveNameList(
                                            instruction.fluid_additives.filter(additive => additive.initial_order),
                                            true
                                        )}
                                    </Col>
                                </Row>
                            }
                        </div>
                    }
                </>
            }

            {!inFullForm && instruction.type_id === 'D' && instruction.category === 'WebPACS' &&
                <Alert
                    showIcon
                    message={
                        <>
                            To link results automatically, choose <Button
                                type='link'
                                style={{ display: 'inline', margin: 0, padding: 0 }}
                                onClick={() => {
                                    submitXRayGenerateLink({ instruction_id: action.instruction_id, due_at: action.due_at })
                                        .then((response: any) => {
                                            if (!!response.error) {
                                                message.error('Unable to fetch results.');
                                            }
                                            else {
                                                message.info('Fetching results. The order will automatically be marked completed when results are received.');
                                            }
                                            if (modalForm && setModalState) {
                                                setModalState({
                                                    ...INITIAL_MODAL_STATE,
                                                    form: modalForm,
                                                });
                                                modalForm.resetFields();
                                                appDispatch(setActiveActionModal(null));
                                                appDispatch(setActiveTreatmentSheetAsk(null));
                                            }
                                        });
                                }}
                            >
                                fetch results
                            </Button>.
                        </>
                    }
                    type="warning"
                    style={{ width: '100%', marginBottom: '12px' }}
                />
            }

            {(!inFullForm && instruction.type_id === 'D') && formWidget}

            {isControlledDrug && isInstanceOfMedicineInstruction(instruction) &&
                <WastedControlledDrugs
                    drawn_only={false}
                    initial_numerator_dose={instruction.dose}
                    numerator_value={instruction.numerator_value}
                    denominator_value={instruction.denominator_value}
                    denominator_unit={instruction.denominator_unit} />
            }
            {!isRateChangeAction() &&
                <div style={{
                    width: '100%',
                    padding: inFullForm
                        ? '16px 0'
                        : (instruction.type_id === 'D' ? '16px 0 0' : 0)
                }}>
                    <Form.Item
                        className="completed-action-modal__radio"
                        label={getCompletedTimeLabel()}
                        labelAlign='left'
                        style={formItemStyle}
                        labelCol={{ span: 6 }}
                    >
                        <Radio.Group
                            onChange={(e) => {
                                setCompletedTimeRadioValue(e.target.value);
                            }}
                            value={completedTimeRadioValue}
                        >
                            <Space direction="vertical">
                                {getRadioItems()}
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    {completedTimeRadioValue === 2 &&
                        <Form.Item
                            name='completed_at'
                            rules={[{ required: true, message: 'A new time is required' }]}
                            wrapperCol={{ span: 18, offset: 6 }}
                            style={formItemStyle}
                            initialValue={selectedTime}
                        >
                            <DatePicker
                                value={selectedTime}
                                onChange={setNewTimeField}
                                format={'MM/DD/YYYY hh:mm a'}
                                suffixIcon={<ClockCircleOutlined />}
                                disabledDate={dateIsInTheFuture}
                                showTime
                            />
                            <span className='reschedule-action-form__new-time-funcs'>
                                <Button onClick={() => plusMinusHours('minus')}>- 1hr</Button>
                                <Tooltip title={isPlusOneHourButtonDisabled ? 'The date cannot be in the future' : null}>
                                    <Button onClick={() => plusMinusHours('plus')} disabled={isPlusOneHourButtonDisabled}>
                                        + 1hr
                                    </Button>
                                </Tooltip>
                            </span>
                        </Form.Item>
                    }

                    {!hideMedicationDoseAlert && getMedicationAlert()}
                </div>
            }

            {(!inFullForm && (instruction.type_id === 'C' || instruction.type_id === 'F' || instruction.type_id === 'OT')) &&
                <Alert
                    message={getFluidsAlertMessage(action.value, instruction.type_id)}
                    type='warning'
                    showIcon
                    className='completed-action-modal__alert'
                />
            }

            {!inFullForm &&
                <Collapse
                    expandIconPosition='right'
                    ghost
                    className='completed-action-modal__details'
                    style={{ width: '100%' }}
                    expandIcon={(panelProps) => panelProps.isActive
                        ? <UpOutlined />
                        : <DownOutlined />
                    }
                >
                    <Collapse.Panel header='Details' key={0} style={{ paddingLeft: 0 }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            {action.assigned_to &&
                                <Row>
                                    <Col span={7}>Claimed By:</Col>
                                    <Col span={17}>
                                        <UserNameWrapper>
                                            {action.assigned_to}
                                        </UserNameWrapper>
                                        {((action.status === 'complete' || action.status === 'claimed') && action.status_transition_at) &&
                                            <>
                                                {' '}
                                                {moment(moment.unix(action.status_transition_at)).format(
                                                    'MM/DD/YYYY hh:mm a',
                                                )}
                                            </>
                                        }
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col span={7}>Scheduled For:</Col>
                                <Col span={17}>
                                    {moment.unix(action.scheduled_time ?? action.due_at).format('MM/DD/YYYY hh:mm a')}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Created By:</Col>
                                <Col span={17}>
                                    <UserNameWrapper>
                                        {instruction.ordered_by}
                                    </UserNameWrapper>
                                </Col>
                            </Row>
                        </Space>
                    </Collapse.Panel>
                </Collapse>
            }
        </>
    );
};
