import { Button, Col, Empty, List, Row, Typography, Select, Tooltip } from 'antd';
import { RecentExamCard } from 'components/cards/RecentExamCard';
import { useParams } from 'react-router-dom';
import {
    useGetExamsByVisitIdQuery,
    useGetNotesByVisitIdQuery,
    useGetPatientHistoryByVisitIdQuery,
} from 'services/visitService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import {
    isInstanceOfPhysicalExam,
    PatientRecordVisit,
} from 'utils/dataTypes';
import { generateDateSort } from 'utils/sortFuncs';
import { AddNoteButton } from './AddNoteButton';
import NoteItem from './NoteItem';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { NoteCategories } from 'utils/types/enums';
import { SortAscendingNonAlphaOutlined, SortDescendingNonAlphaOutlined } from './icons';
import NotesDrawer from 'components/NotesDrawer';

const notesPageDateSortAsc = generateDateSort('note_date_time', 'asc');
const notesPageDateSortDesc = generateDateSort('note_date_time', 'desc');

interface ProgressNotesProps {
    currentVisit: PatientRecordVisit;
    isActive: boolean;
}

export const Notes = (props: ProgressNotesProps) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const notesDrawerRef = useRef<ElementRef<typeof NotesDrawer>>(null);
    const visitId = parseInt(urlVisitId);
    const isFinalizedVisit = props.currentVisit
        ? Boolean(props.currentVisit.finalized_at)
        : undefined; // currentVisit is rendered first as undefined then loads

    const { data: progressNoteData, refetch: refetchNotes } = useGetNotesByVisitIdQuery(
        visitId,
        BASE_QUERY_OPTIONS,
    );
    const { data: examData, refetch: refetchExams } = useGetExamsByVisitIdQuery(
        visitId,
        BASE_QUERY_OPTIONS,
    );

    const { data: patientHistoryData, refetch: refetchPatientHistory } =
        useGetPatientHistoryByVisitIdQuery(visitId);

    useEffect(() => {
        if(props.isActive){
            refetchNotes();
            refetchExams();
            refetchPatientHistory();
        }
    }, [props.isActive])

    const [isAsc, setIsAsc] = useState(false);

    let allListData: any[] = [];
    if (examData && examData.length) {
        allListData = allListData.concat(examData);
    }
    if (progressNoteData && progressNoteData.length) {
        allListData = allListData.concat(progressNoteData);
    }

    if (patientHistoryData?.content) {
        allListData = allListData.concat({
            ...patientHistoryData,
            author_id: patientHistoryData.created_by,
            category: NoteCategories.PATIENT_HISTORY_NOTE,
        });
    }

    allListData.sort(
        isAsc
        ? notesPageDateSortAsc
        : notesPageDateSortDesc
    );

    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [selectedAuthors, setSelectedAuthors] = useState<string[]>([]);

    const allTypes = allListData.map(item => {
        return item.category
    }).sort()
    let typeOptions = [
        {
            label: 'Communication Note',
            value: 'Communication Note',
        },
        {
            label: 'Physical Exam',
            value: 'Physical Exam',
        },
        {
            label: 'Procedure Note',
            value: 'Procedure Note',
        },
        {
            label: 'Progress/Soap Note',
            value: 'Progress/Soap Note',
        },
        {
            label: 'Recommendation Note',
            value: 'Recommendation Note',
        },
    ]
    if (allTypes.includes('Addendum Note')) {
        typeOptions.unshift({label: 'Addendum Note', value: 'Addendum Note'});
    }
    const allAuthors = allListData.map(item => {
        return item.author_name
    }).sort()
    const authorOptions = [...new Set(allAuthors)].map(author => {
        return {label: author, value: author}
    })
    
    const filterData = () => {
        let filteredData = allListData
        if (selectedTypes.length > 0) {
            filteredData = filteredData.filter(note => selectedTypes.includes(note.category))
        }
        if (selectedAuthors.length > 0) {
            filteredData = filteredData.filter(note => selectedAuthors.includes(note.author_name))
        }
        return filteredData
    }

    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
				<Col span={24}>
                    <Typography.Text
                        style={{
                            fontSize: '20px',
                            fontWeight: 500,
                            lineHeight: '28px',
                        }}
                    >
                        Notes
                    </Typography.Text>
                </Col>
			</Row>

            <Row gutter={[12, 12]}>
                <Col span={24}>
                    <AddNoteButton
                        isFinalizedVisit={isFinalizedVisit}
                        openNoteDrawer={notesDrawerRef.current?.openNotesDrawer}
                    />
                    <Select
                        mode="multiple"
                        placeholder="Filter by type"
                        value={selectedTypes}
                        onChange={setSelectedTypes}
                        allowClear={true}
                        style={{ width: '250px', marginLeft: '8px' }}
                        options={typeOptions}
                        getPopupContainer={(trigger) => trigger.parentElement}
                    />
                    <Select
                        mode="multiple"
                        placeholder="Filter by author"
                        value={selectedAuthors}
                        onChange={setSelectedAuthors}
                        allowClear={true}
                        style={{ width: '250px', marginLeft: '8px' }}
                        options={authorOptions}
                        getPopupContainer={(trigger) => trigger.parentElement}
                    />
                    <Tooltip title={isAsc ? "Sort In Ascending Order" : "Sort In Descending Order"}>
                        <Button
                            icon={isAsc
                                ? <SortAscendingNonAlphaOutlined />
                                : <SortDescendingNonAlphaOutlined />
                            }
                            style={{marginLeft: '8px'}}
                            onClick={() => setIsAsc(!isAsc)}
                        />
                    </Tooltip>
                </Col>

                <Col span={24}>
                    <List
                        className='comment-list'
                        dataSource={filterData()}
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description='No notes created'
                                />
                            ),
                        }}
                        renderItem={(item) => {
                            if (isInstanceOfPhysicalExam(item)) {
                                return (
                                    <li>
                                        <RecentExamCard
                                            exam={item}
                                            key={`notes_exam_${item.id}`}
                                            isFinalizedVisit={isFinalizedVisit}
                                            openEditNotesDrawer={notesDrawerRef.current?.openEditNotesDrawer}
                                        />
                                    </li>
                                );
                            } else {
                                return (
                                    <NoteItem
                                        key={`notes_note_${item.id}`}
                                        note={item}
                                        isFinalizedVisit={isFinalizedVisit}
                                        openEditNotesDrawer={notesDrawerRef.current?.openEditNotesDrawer}
                                    />
                                );
                            }
                        }}
                    />
                </Col>
            </Row>
            <NotesDrawer ref={notesDrawerRef} isFinalizedVisit={isFinalizedVisit}/>
        </div>
    );
};
