import { SwapOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Row, Tooltip, Typography } from "antd";
import { PriceDisplay, VetSearch } from "components/lib";
import moment from "moment";
import { useState } from "react";
import { useGetUserDataQuery } from "services/authService";
import { useUpdateAttributionMutation } from "services/billingService";
import { useGetPimsUsersQuery } from "services/userService";
import { PimsUser } from "utils/dataTypes";
import { displayUser } from "utils/formatFuncs";
import { BillingItem } from "utils/types/billingTypes";
import { USER_PERMISSIONS, checkIfUserHasNecessaryPermission } from "utils/userPermissions";

interface AttributionSwapProps {
    selected: number[],
    billingItems: BillingItem[],
    setSelected: (array: []) => void;
    visitId: number;
    visitFinalizedAt?: number | null;
}

export const AttributionSwap = ({ selected, billingItems, setSelected, visitId, visitFinalizedAt }: AttributionSwapProps) => {
    const [selectedUser, setSelectedUser] = useState<PimsUser | null>(null);
    const [isOpen, setIsOpen] = useState(false)

    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const { data: pimsUsers } = useGetPimsUsersQuery(
        undefined,
        { skip: !selected.length }
    );
    const [updateAttribution] = useUpdateAttributionMutation();

    const userHasPermissionToTransferProduction = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.billing_transfer,
    );

    const lockProductionTransfer = visitFinalizedAt
        ? moment().isSameOrAfter(moment.unix(visitFinalizedAt).add(1, 'months').startOf('month').add(10, 'days'))
        : false;

    const transferProductionToolTip = lockProductionTransfer
        ? 'Doctor production can no longer be changed after the 10th of the following month'
        : 'Transfer production between doctors';

    return (
        <>
            <Tooltip title={transferProductionToolTip}>
                <Button
                    data-cy={'transferProductionBetweenDoctorsButton'}
                    disabled={selected.length === 0 || !userHasPermissionToTransferProduction || lockProductionTransfer}
                    style={{ "marginLeft": 'var(--spacing-sm)' }}
                    onClick={() => setIsOpen(true)}
                    icon={<SwapOutlined />}
                />
            </Tooltip>
            <Modal
                width='520px'
                visible={isOpen}
                maskClosable={true}
                title="Transfer Production"
                onOk={() => {
                    if (selectedUser) {
                        selected.forEach((earnedRevenueId) => {
                            updateAttribution({
                                visitId,
                                earnedRevenueId,
                                body: { doctor_id: selectedUser?.user_id ?? null },
                            })
                        })
                        setSelected([]);
                        setSelectedUser(null);
                        setIsOpen(false)
                    }


                }}
                okText='Transfer'
                onCancel={() => {
                    setSelected([]);
                    setSelectedUser(null);
                    setIsOpen(false);
                }}
                destroyOnClose={true}
            >
                <Row>
                    <Typography.Paragraph>Your selection of charges have been grouped and totalled by the current Vet assignment. </Typography.Paragraph>

                    <AffectedDoctorsRows billingItems={billingItems} selected={selected} selectedUser={selectedUser} />


                    <Typography.Paragraph>You can assign the above charges to a different vet.</Typography.Paragraph>
                    <Form.Item
                        label="Assign to"
                        required={!selectedUser}
                        style={{ width: "100%" }}
                        data-cy={'assignToInput'}
                    >
                        <VetSearch
                            usersList={pimsUsers?.filter((user) => user.can_receive_production) || []}
                            disabled={selected.length === 0}
                            setUser={setSelectedUser}
                            user={selectedUser}
                        />
                    </Form.Item>

                </Row>

            </Modal>
        </>
    );
}

interface AffectedDoctorsRowsProps {
    billingItems: BillingItem[];
    selected: number[];
    selectedUser: PimsUser | null;
}
const AffectedDoctorsRows = ({ billingItems, selected, selectedUser }: AffectedDoctorsRowsProps) => {
    const AffectedDoctorsRows = billingItems
        ?.map(billingItem => (billingItem
            .earned_revenue_line_items.map(item => ({
                ...item,
                first_name: billingItem.doctor_first_name,
                last_name: billingItem.doctor_last_name,
            }))))
        ?.flat()
        ?.filter(earnedRevenueLineItem => selected.includes(earnedRevenueLineItem.id))
        ?.reduce<{ doctorId: number | null, amount: number, first_name: string | null, last_name: string | null }[]>((amountsByDoctor, earnedRevenueLineItem) => {
            const alreadyAddedIndex = amountsByDoctor.findIndex(amount => amount.doctorId === earnedRevenueLineItem.doctor_id)
            if (alreadyAddedIndex !== -1) {
                const amountByDoctor = { ...amountsByDoctor[alreadyAddedIndex] }
                amountByDoctor.amount += Math.floor(earnedRevenueLineItem.price_cents * earnedRevenueLineItem.quantity)
                amountsByDoctor[alreadyAddedIndex] = amountByDoctor;
                return amountsByDoctor;

            } else {
                return [
                    ...amountsByDoctor,
                    {
                        doctorId: earnedRevenueLineItem.doctor_id,
                        first_name: earnedRevenueLineItem.first_name,
                        last_name: earnedRevenueLineItem.last_name,
                        amount: Math.floor(earnedRevenueLineItem.price_cents * earnedRevenueLineItem.quantity)

                    }
                ]
            }
        }, [])
        ?.map(item => (
            <Row key={item.doctorId} justify="space-between" style={{ "width": "60%", "marginBottom": '12px', "marginTop": '12px' }}>
                <Typography.Text strong>  {displayUser(item)} </Typography.Text>
                <Typography.Text strong>  <PriceDisplay price={item.amount} isFreeOrRefund={false} /> </Typography.Text>
            </Row>
        ))
    return <>{AffectedDoctorsRows}</>
}
