import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, Row } from 'antd';
import PatientInfoPopover from 'components/popups/PatientInfoPopover';
import moment from 'moment';
import { useGetPatientAlertsByIdQuery, useGetPatientByIdQuery } from 'services/patientService';
import { BASE_QUERY_OPTIONS, TASKS_HOST } from 'utils/constants';
import { CustomerInfo, ExpandedPatient, PatientRecordVisit } from 'utils/dataTypes';
import { closedErrorMsg } from 'utils/disableFuncs';
import { PatientSignalment } from './PatientSignalment';

import { Link } from 'react-router-dom';
import { getAgeYearsMonths } from 'utils/miscFuncs';

interface PatientSectionProps {
    currentVisit: PatientRecordVisit;
    customerInfo: CustomerInfo | undefined;
    patientWeight: number | undefined;
}

export const PatientSection = ({ currentVisit, customerInfo, patientWeight }: PatientSectionProps) => {
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id as string, {
        ...BASE_QUERY_OPTIONS,
        skip: !currentVisit?.pet_id,
    });
    const { data: patientAlerts } = useGetPatientAlertsByIdQuery(currentVisit?.pet_id as string, {
        ...BASE_QUERY_OPTIONS,
        skip: !currentVisit?.pet_id,
    });
    const isFinalizedVisit = Boolean(currentVisit.finalized_at);
    const { ageYears, ageMonths } = getAgeYearsMonths(patientData);

    const printPatientLabelOnClick = () => {
        fetch(`${TASKS_HOST}/visit/${currentVisit.id}/patient_label/print`);
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={printPatientLabelOnClick}>Print Patient Label</Menu.Item>
            <Menu.Item data-cy='patientProfileButton'>
                <Link to={`/patient/${currentVisit.pet_id}`}>View patient profile</Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Row align='middle' justify='start'>
                <Col span={24} style={{ display: 'flex' }}>
                    <PatientInfoPopover
                        initialContent={{
                            pet_id: patientData?.pet_id,
                            name: patientData?.name,
                            species: patientData?.species,
                            breed: patientData?.breed,
                            sex: patientData?.sex,
                            is_intact: patientData?.is_intact,
                            birthday: patientData?.birthday ? moment(patientData?.birthday) : undefined,
                            ageYears: ageYears,
                            ageMonths: ageMonths,
                            is_deceased: !!patientData?.deceased_at,
                            deceased_at: patientData?.deceased_at ? moment.unix(patientData?.deceased_at) : undefined,
                            latest_weight: patientWeight || undefined,
                            patientAlerts: patientAlerts,
                            note: patientData?.note,
                        }}
                        disabled={isFinalizedVisit}
                    >
                        <Col span={24} className='sidebar-pet-name edit-sidebar-icon' data-cy='patientName'>
                            {patientData?.name}
                            {customerInfo?.last_name ? ` (${customerInfo?.last_name})` : ``}
                            <EditOutlined
                                data-cy='editPatient'
                                onClick={() => closedErrorMsg(isFinalizedVisit)}
                                style={{ fontSize: '16px', margin: '0 5px 0 10px' }}
                            />
                        </Col>
                    </PatientInfoPopover>

                    <span style={{ fontSize: '14px', marginTop: '8px', color: 'var(--gray-6)' }}> | </span>
                    <Col span={0} className='sidebar-pet-name more-sidebar-icon'>
                        <Dropdown overlay={menu}>
                            <MoreOutlined data-cy='patientMoreOptions' style={{ fontSize: '16px', padding: '0 10px' }} />
                        </Dropdown>
                    </Col>
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{ display: 'inline-flex', marginTop: '4px' }}>
                    <PatientSignalment
                        patientData={patientData as ExpandedPatient}
                        patientWeight={patientWeight}
                        patientAlerts={patientAlerts}
                    />
                </Col>
            </Row>
        </>
    );
};
