import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import buttonStyles from './button.module.css';
interface ButtonProps extends AntButtonProps {}

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => {
	return (
		<AntButton
			{...rest}
			className={`${buttonStyles.button} ${rest?.className ?? ''}`}
		>
			{children}
		</AntButton>
	);
};

export default Button;
