import { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { DatePickerProps } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { Button, Modal, DatePicker } from 'antd';
import { PIMS_HOST } from 'utils/constants';
import './DoctorProduction.css';


const DoctorProductionReport = () => {
	const [month, setMonth] = useState<number>(0);
	const [year, setYear] = useState<number>(0);
	const [downloadEnabled, setDownloadEnabled] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const setDate = (m: number, y: number) => {
		setMonth(m);
		setYear(y);
		setDownloadEnabled(Boolean(m) && Boolean(y))
	}

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const disabledDate: RangePickerProps['disabledDate'] = (current) => {
		return current > moment().endOf('month');
	};

	const onChange: DatePickerProps['onChange'] = (date, dateString) => {
		if (date) {
			setDate(
				date.get("month") + 1,
				date.get("year"),
			);
		} else {
			setDate(0, 0);
		}
		
	};

	return (
		<div className='doctor-production-report-container'>
			<h1>Doctor Production Report</h1>

			<div className="sub-header">This report contains doctor production details from all VEG locations.</div>
			
			<Button type='primary' onClick={showModal} >
				<DownloadOutlined />
				<span>Download Doctor Production Report</span>
			</Button>
			<Modal
			 	title="Doctor Production Report"
                destroyOnClose={true}
				visible={isModalOpen}
				onOk={handleOk}
				okText="Download Report"
				onCancel={handleCancel}
				okButtonProps={{ 
					disabled: !downloadEnabled,
					href: `${PIMS_HOST}/anonymous_hospital_attribution_summary?month=${month}&year=${year}`,
					download: true,
				}}
			>
				<DatePicker autoFocus onChange={onChange} picker="month" disabledDate={disabledDate} />
			</Modal>
		</div>
	);
};

export default DoctorProductionReport;
