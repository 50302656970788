import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    DownOutlined,
    EditOutlined,
    StopOutlined,
    SwapRightOutlined,
    UpOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { Badge, Button, Checkbox, Col, FormInstance, Modal, Popover, Row, Spin, Switch, Tooltip, Typography } from 'antd';
import { CriOrderReview } from 'components/forms/CriOrder';
import { useOutsideClickAlert } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import _ from 'lodash';
import moment from 'moment';
import React, { ElementRef, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import Timeline, { CursorMarker, CustomMarker, DateHeader, SidebarHeader, TimelineHeaders, TimelineMarkers } from 'react-calendar-timeline';
import RescheduleOrder from 'components/Modals/RescheduleFrequency';
import { TransferEstimateDrawer } from 'components/TransferEstimateDrawer';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
// @ts-ignore
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';
import { useGetUserDataQuery } from 'services/authService';
import { useGetEstimatesByVisitIdNewQuery } from 'services/estimateService';
import {
    useDischargePatientInstructionsMutation,
    useGetInstructionOptionsQuery,
    useGetInstructionsByVisitIdQuery,
} from 'services/instructionService';
import { useGetEncountersByVisitIdQuery, useGetNotesByVisitIdQuery } from 'services/visitService';
import { setActiveTreatmentSheetAsk, setActiveTreatmentSheetGroup } from 'store/slices/treatmentSheetSlice';
import { UserNameWrapper } from 'utils/componentWrappers';
import { closedErrorMsg } from 'utils/disableFuncs';
import { roundTo, weightMultiplier } from 'utils/formatFuncs';
import { insertGhostActionsIntoRealActions } from 'utils/ghostActionUtils';
import { isInstructionOver } from 'utils/miscFuncs';
import { BaseSearchOption, isInstanceOfMedicineSearchOption } from 'utils/types/InstructionOrderTypes';
import {
    ALL_INSTRUCTIONS,
    BASE_QUERY_OPTIONS,
    FormName,
    INITIAL_CONTEXT_MENU,
    PATIENT_WEIGHT_INSTRUCTION_NAME,
    TEMP_ENCOUNTER_ROW_ID,
    TS_ITEM_COLORS,
    TS_ITEM_TIME_WIDTH,
} from '../../utils/constants';
import {
    BaseExistingInstruction,
    BaseNote,
    ExistingDiagInstruction,
    ExistingMedInstruction,
    FluidAdditive,
    INSTRUCTION_STATUSES,
    INSTRUCTION_TYPES,
    InstructionAction,
    PatientRecordVisit,
    PimsUser,
    TreatmentSheetFluidAdditiveGroup,
    TreatmentSheetInstructionAction,
    TsGroupType,
    VisitVital,
    instructionType,
    isInstanceOfBaseInstruction,
    isInstanceOfExistingCriInstruction,
    isInstanceOfExistingFluidInstruction,
    isInstanceOfExistingOxygenTherapyInstruction,
    isInstanceOfTreatmentSheetFluidAdditiveGroup,
    isInstanceOfTreatmentSheetInstructionAction,
} from '../../utils/dataTypes';
import AddNote from './Notes/AddNote';
import { AddNoteButton } from './Notes/AddNoteButton';
import './TreatmentSheet.css';
import BonusActionModal from './TreatmentSheet/BonusActionModal';
import { ContextPopover } from './TreatmentSheet/ContextPopover';
import FluidAdditiveModal from './TreatmentSheet/FluidAdditiveModal';
import RescheduleActionModal from './TreatmentSheet/RescheduleActionModal';
import { TreatmentSheetFluidAdditiveMarker } from './TreatmentSheet/TreatmentSheetFluidAdditiveMarker';
import { TreatmentSheetMarker } from './TreatmentSheet/TreatmentSheetMarker';
import { TreatmentSheetMarkerModal } from './TreatmentSheet/TreatmentSheetMarkerModal';
import TreatmentSheetSideColumn from './TreatmentSheet/TreatmentSheetSideColumn';
import { generateAdjustFluidRateModalState } from './TreatmentSheet/generateAdjustFluidRateModalState';
import { generateDiscontinueModalState } from './TreatmentSheet/generateDiscontinueModalState';
import NotesDrawer from 'components/NotesDrawer';
import { BulkRescheduleDrawer } from 'components/BulkRescheduleDrawer';

const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start_time',
    itemTimeEndKey: 'end_time',
    groupLabelKey: 'id',
};

const CRI_DURATION_ACTION_ID = -4444; //TODO temp in some way -- need something to indicate which action is the "cri duration"
let cri_duration_action_id_count = CRI_DURATION_ACTION_ID; // added count so additional orders do not have same key
/**
 *
 *TODO
 * TREAMENTSHEET RENDERING LOOKS WONKY
 * BECAUSE IT RENDERS THE GROIUPS IN THE ORDER IT RECEIVES THEM
 * YOU NEED TO CHANGE THE `CONCAT` call to make this not garbage
 */

interface TreatmentSheetProps {
    currentVisit: PatientRecordVisit;
    patientName: string;
    vitals: VisitVital[];
    setModalstate: Function;
    modalForm: FormInstance<any>;
    onModalFinish: (values: any, formName: FormName) => void;
    isActive: boolean;
}

const windowHoursBefore = 2;
const windowHoursAfter = window.innerWidth > 1200 ? 6 : 4;

const INITIAL_TS_WINDOW = {
    start: parseInt(moment().subtract(windowHoursBefore, 'hour').format('x')),
    end: parseInt(moment().add(windowHoursAfter, 'hour').format('x')),
};

const getFinalizedTSWindow = (discharged_at: number) => {
    return {
        start: (discharged_at - windowHoursBefore * 60 * 60) * 1000,
        end: (discharged_at + windowHoursAfter * 60 * 60) * 1000,
    };
};

const onSelectMany = (
    groups: TsGroupType[],
    selectedGroups: (number | string)[],
    setSelectedGroups: React.Dispatch<React.SetStateAction<(number | string)[]>>,
) => {
    const allSelected = isSelectedAll(groups, selectedGroups);
    const newSelected: (number | string)[] = groups.reduce((selectedItems: (number | string)[], item: TsGroupType) => {
        if (allSelected) {
            return selectedItems.filter((selectedId) => selectedId !== item.id);
        } else {
            return selectedItems.some((selectedId) => selectedId === item.id) ? selectedItems : [...selectedItems, item.id];
        }
    }, selectedGroups as (number | string)[]);
    setSelectedGroups(newSelected);
};

const isSelected = (id: number | string, selectedGroups: (number | string)[]) => selectedGroups.some((selectedId) => selectedId === id);
const isSelectedAll = (groups: TsGroupType[], selectedGroups: (number | string)[]) => {
    return selectedGroups.length > 0 && groups.every((group) => isSelected(group.id, selectedGroups));
};

const TreatmentSheetLocal = (props: TreatmentSheetProps) => {
    const [selectedItems, setSelectedItems]: [number[], any] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState<(number | string)[]>([]);
    const [isBulkRescheduleDrawerOpen, setIsBulkRescheduleDrawerOpen] = useState(false);
    const [displayDiscontinued, setDisplayDiscontinued] = useState(true);
    const [contextMenuState, setContextMenuState] = useState(INITIAL_CONTEXT_MENU);
    const appDispatch = useAppDispatch();
    const notesDrawerRef = useRef<ElementRef<typeof NotesDrawer>>(null);
    const { activeTreatmentSheetAsk, activeActionItem, activeTreatmentSheetGroup } = useAppSelector((state) => state.treatmentSheetSlice);
    const { addComposeBox } = useComposeBoxContext();

    const contextMenuRef = useRef(null);
    const updateScrollCanvasRef = useRef<(start: number, end: number) => void>();
    useOutsideClickAlert(contextMenuRef);
    const timelineHeaderRef = useRef<HTMLElement | null>(null);

    const [visibleTime, setVisibleTime] = useState(
        props.currentVisit.discharged_at === null ? INITIAL_TS_WINDOW : getFinalizedTSWindow(props.currentVisit.discharged_at),
    );
    const WINDOW_BOOKEND = 60 * 60 * 12;

    //Search options are used to `enrich` CRI Instructions with full info about medication
    const { data } = useGetInstructionOptionsQuery(null, BASE_QUERY_OPTIONS);
    const medicineOptions: BaseSearchOption[] | undefined = data;
    const DIAG_CATEGORIES = ['Vitals', 'Monitoring']; // TODO get these from backend with a rank to sort
    const pollingInterval = props.isActive ? 20000 : 0;
    const [isRefresh, setIsRefresh] = useState(false);
    const [isTransferEstimateDrawerOpen, setIsTransferEstimateDrawerOpen] = useState(false);

    const {
        data: instData,
        refetch: refetchVisitInstructions,
        isLoading: isLoadingInstructions,
        isFetching: isFetchingInstruction,
        isSuccess: isSuccessInstruction,
    } = useGetInstructionsByVisitIdQuery(
        {
            visitId: props.currentVisit?.id,
            isRefresh,
        },
        {
            pollingInterval,
        },
    );

    const [discontinuePatientInstructions] = useDischargePatientInstructionsMutation();
    const discontinueCriAndFluidInstructions = () => {
        if (props.currentVisit?.id) {
            discontinuePatientInstructions({
                visitId: props.currentVisit?.id,
                ids:
                    criAndFluidInstructions
                        ?.filter((instruction) => instruction.discontinued_at === null)
                        .map((instruction) => instruction.id) ?? [],
            });
        }
    };

    const [instructionResults, setInstructionResults] = useState<instructionType[]>([]);

    useEffect(() => {
        if (isSuccessInstruction && !isFetchingInstruction) {
            if (instData.length > 0) {
                setIsRefresh(true);
                setInstructionResults(instData);
            }
        }
    }, [instData, isSuccessInstruction, isFetchingInstruction]);

    useEffect(() => {
        setIsRefresh(false);
        if (props.isActive) {
            refetchVisitInstructions();
        }
    }, [props.isActive]);

    const { data: encounters, isLoading: isLoadingEncounters } = useGetEncountersByVisitIdQuery(props.currentVisit?.id, BASE_QUERY_OPTIONS);
    const allInstructions: instructionType[] = useMemo(() => {
        const hideDiscontinuedActions = true;

        return (
            instructionResults?.map((instruction) => ({
                ...instruction,
                actions: insertGhostActionsIntoRealActions(
                    instruction,
                    Math.floor(visibleTime.start / 1000 - WINDOW_BOOKEND),
                    Math.floor(visibleTime.end / 1000 + WINDOW_BOOKEND),
                    hideDiscontinuedActions,
                ),
            })) ?? []
        );
    }, [instructionResults, visibleTime, WINDOW_BOOKEND]);

    const patientWeight =
        parseFloat((props?.vitals?.find((vital) => vital?.name === PATIENT_WEIGHT_INSTRUCTION_NAME)?.lastAction?.value as string) ?? '0') ||
        undefined;

    const { data: notesData } = useGetNotesByVisitIdQuery(props.currentVisit?.id, BASE_QUERY_OPTIONS);

    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    const { data: estimates } = useGetEstimatesByVisitIdNewQuery({ visitId: props.currentVisit?.id });

    const transferEstimateItemsValues = useMemo(() => {
        const approvedEstimates = estimates?.filter((estimate) => estimate.estimate_status === 'approved');
        const hasItemsToTransfer = approvedEstimates?.flatMap((estimate) =>
            estimate.estimate_items.filter(
                (item) =>
                    !item.is_ordered && !item.is_processed && item.is_shown_on_tx_sheet && item.unit !== 'dollar' && item.type_id !== 'C',
            ),
        );

        return hasItemsToTransfer?.length;
    }, [estimates]);

    const isFinalizedVisit = Boolean(props.currentVisit.finalized_at);

    let tsGroups: TsGroupType[] = [];

    //Initialize array to hold items, gets pushed items later
    let tsInstructionItems: (TreatmentSheetInstructionAction | TreatmentSheetFluidAdditiveGroup)[] = [];

    useEffect(() => {
        updateDateLabels();
    }, [visibleTime]);

    const updateDateLabels = () => {
        if (!timelineHeaderRef.current) {
            return;
        }
        const header = timelineHeaderRef.current;
        const dateHeaders: NodeListOf<HTMLElement> = header.querySelectorAll('.rct-dateHeader-primary');
        dateHeaders.forEach((item) => {
            const dateLabel = item.querySelector('span');
            if (dateLabel) {
                const scrolledPos = item.offsetLeft - header.scrollLeft;
                const startPos = scrolledPos < 0 ? -scrolledPos : 0;
                const endPos = -scrolledPos + header.offsetWidth > item.offsetWidth ? item.offsetWidth : header.offsetWidth - scrolledPos;
                if (endPos - startPos < dateLabel.offsetWidth) {
                    dateLabel.style.transform = 'none';
                    if (scrolledPos > 0) {
                        dateLabel.style.left = '0px';
                        dateLabel.style.right = 'auto';
                    } else {
                        dateLabel.style.left = 'auto';
                        dateLabel.style.right = '0px';
                    }
                } else {
                    const leftPos = (endPos + startPos - dateLabel.offsetWidth) * 0.5;
                    dateLabel.style.left = '0px';
                    dateLabel.style.right = 'auto';
                    dateLabel.style.transform = `translateX(${leftPos}px)`;
                }
            }
        });
    };

    const addTsGroups = (instruction: instructionType[]) => {
        const instructionSorted = _.sortBy(instruction, ['sort_rank', 'name']);
        const notDiscontinuedInstruction = instructionSorted.filter((instr) => !instr.discontinued_at && !isInstructionOver(instr));
        const discontinuedInstruction = displayDiscontinued ? instructionSorted.filter((instr) => !!instr.discontinued_at) : [];

        tsGroups.push(...notDiscontinuedInstruction.map((intr) => generateTsGroup(intr, DIAG_CATEGORIES)));
        tsGroups.push(
            ...discontinuedInstruction
                .filter((instr) => instr.actions.some((a) => a.status === 'complete'))
                .map((intr) => generateTsGroup(intr, DIAG_CATEGORIES)),
        );
    };

    const addTsItems = (instruction: instructionType[]) => {
        tsInstructionItems.push(
            ...instruction
                .map((instr) => {
                    if (displayDiscontinued && !!instr.discontinued_at && instr.actions.some((a) => a.status === 'complete')) {
                        return instr.actions.filter((a) => a.status === 'complete').map((action) => generateTsItem(instr, action));
                    }

                    return instr.actions ? instr.actions.map((action) => generateTsItem(instr, action)) : [];
                })
                .flat(),
        );
    };

    const diagInstructions: instructionType[] = [];
    const medInstructions: instructionType[] = [];
    const taskInstructions: instructionType[] = [];
    const togomedInstructions: instructionType[] = [];
    const criAndFluidInstructions: instructionType[] = [];

    if (allInstructions?.length) {
        allInstructions.forEach((instruction) => {
            if (instruction.type_id === 'D') {
                diagInstructions.push(instruction);
            } else if (instruction.type_id === 'M') {
                medInstructions.push(instruction);
            } else if (instruction.type_id === 'T') {
                taskInstructions.push(instruction);
            } else if (instruction.type_id === 'TGH' && instruction.is_shown_on_tx_sheet) {
                togomedInstructions.push(instruction);
            } else if (instruction.type_id === 'C' || instruction.type_id === 'F' || instruction.type_id === 'OT') {
                criAndFluidInstructions.push(instruction);
            }
        });

        const diagInstVitals = diagInstructions?.filter((instruction) => instruction.category === 'Vitals');
        const diagInstWithCategory: { category: string; id: string; items: instructionType[] }[] = [];
        DIAG_CATEGORIES.forEach((category) => {
            if (category !== 'Vitals') {
                diagInstWithCategory.push({
                    category: category,
                    id: category,
                    items: diagInstructions?.filter((instruction) => instruction.category === category),
                });
            }
        });
        diagInstWithCategory.push({
            category: 'Diagnostics',
            id: 'D',
            items: diagInstructions?.filter((instruction) => !DIAG_CATEGORIES.includes(instruction.category || '')),
        });

        if (diagInstVitals && diagInstVitals.length) {
            tsGroups.push({
                id: 'Vitals',
                title: 'Vitals',
                isRoot: true,
            });
            addTsGroups(diagInstVitals);
            addTsItems(diagInstVitals);
        }

        diagInstWithCategory.forEach((diagCategory) => {
            if (diagCategory.items && diagCategory.items.length) {
                tsGroups.push({
                    id: diagCategory.id,
                    title: diagCategory.category,
                    isRoot: true,
                });
                addTsGroups(diagCategory.items);
                addTsItems(diagCategory.items);
            }
        });

        if (medInstructions && medInstructions.length) {
            tsGroups.push(ALL_INSTRUCTIONS[0]); //Add "header" row, {id, title, isRoot}
            addTsGroups(medInstructions);
            addTsItems(medInstructions);
        }

        if (criAndFluidInstructions && criAndFluidInstructions.length) {
            tsGroups.push(ALL_INSTRUCTIONS[2]); //Add "header" row to TS
            addTsGroups(criAndFluidInstructions);

            tsInstructionItems.push(
                ...criAndFluidInstructions
                    .map((instruction) => {
                        const criBaseActionItem = instruction.actions.find((action) => !action.value);
                        const completedTime =
                            criBaseActionItem?.status === 'complete'
                                ? criBaseActionItem.completed_at || criBaseActionItem.status_transition_at
                                : null;
                        const dueAtTime = criBaseActionItem?.scheduled_time ?? instruction.start_time;

                        const criBar = {
                            ...instruction,
                            ...TS_ITEM_COLORS,
                            id: `cri_bar_${cri_duration_action_id_count}`,
                            instruction_id: instruction.id,
                            status: 'scheduled',
                            status_transition_at: instruction.created_at,
                            status_transition_by: instruction.created_by,
                            note: '',
                            value: '',
                            due_at: parseInt(moment.unix(dueAtTime).format('x')),
                            start_time: parseInt(moment.unix(completedTime ?? dueAtTime).format('x')),
                            end_time: parseInt(moment.unix(instruction.end_time || visibleTime.end).format('x')),
                            group: instruction.id,
                            title: '',
                            assigned_to: null,
                            action_id: -cri_duration_action_id_count,
                        } as TreatmentSheetInstructionAction;

                        cri_duration_action_id_count -= 1;

                        const actionItems = instruction.actions
                            ? instruction.actions.map((action) => generateTsItem(instruction, action))
                            : [];

                        let treatmentSheetFluidAdditiveGroups: TreatmentSheetFluidAdditiveGroup[] = [];
                        if (instruction.type_id === 'F') {
                            instruction.fluid_additives
                                .filter((additive) => additive.initial_order === false)
                                .forEach((additive) => {
                                    const startOfHour = moment.unix(additive.created_at).startOf('hour');
                                    const halfOfHour = moment.unix(additive.created_at).startOf('hour').add(30, 'minutes');
                                    const groupMoment = moment.unix(additive.created_at).isBefore(halfOfHour) ? startOfHour : halfOfHour;
                                    const existingGroup = treatmentSheetFluidAdditiveGroups.find(
                                        (item) => item.created_at === groupMoment.unix(),
                                    );

                                    if (existingGroup) {
                                        existingGroup.fluidAdditives.push(additive);
                                    } else {
                                        treatmentSheetFluidAdditiveGroups.push({
                                            id: 'additive_group_' + instruction.id + '_' + groupMoment.unix().toString(),
                                            type_id: 'FluidAdditive',
                                            created_at: groupMoment.unix(),
                                            start_time: parseInt(groupMoment.format('x')),
                                            end_time: parseInt(groupMoment.add(TS_ITEM_TIME_WIDTH * 2, 'minute').format('x')),
                                            fluidAdditives: [additive],
                                            group: instruction.id,
                                        } as TreatmentSheetFluidAdditiveGroup);
                                    }
                                });
                        }

                        return [criBar, ...actionItems, ...treatmentSheetFluidAdditiveGroups];
                    })
                    .flat(),
            );
        }
        if (taskInstructions && taskInstructions.length) {
            tsGroups.push(ALL_INSTRUCTIONS[5]); //Add "header" row to TS
            addTsGroups(taskInstructions);
            addTsItems(taskInstructions);
        }

        if (togomedInstructions && togomedInstructions.length) {
            tsGroups.push(ALL_INSTRUCTIONS[6]); //Add "header" row to TS
            addTsGroups(togomedInstructions);
            addTsItems(togomedInstructions);
        }

        if (notesData && notesData.length) {
            tsGroups.push(ALL_INSTRUCTIONS[3]); //Add "header" row to TS

            if (notesData.some((note) => (note.category as string) === FormName.communication_note)) {
                tsGroups.push({
                    group: FormName.communication_note,
                    id: FormName.communication_note,
                    title: FormName.communication_note,
                    isRoot: false,
                    parent: 'Other',
                });
            }

            if (notesData.some((note) => (note.category as string) === FormName.prog_note)) {
                tsGroups.push({
                    group: FormName.prog_note,
                    id: FormName.prog_note,
                    title: FormName.prog_note,
                    isRoot: false,
                    parent: 'Other',
                });
            }

            if (notesData.some((note) => (note.category as string) === FormName.procedure_note)) {
                tsGroups.push({
                    group: FormName.procedure_note,
                    id: FormName.procedure_note,
                    title: FormName.procedure_note,
                    isRoot: false,
                    parent: 'Other',
                });
            }

            if (notesData.some((note) => (note.category as string) === FormName.recommendation_note)) {
                tsGroups.push({
                    group: FormName.recommendation_note,
                    id: FormName.recommendation_note,
                    title: FormName.recommendation_note,
                    isRoot: false,
                    parent: 'Other',
                });
            }

            notesData.forEach((note: BaseNote) => {
                const [noteInstruction, noteAction] = transformNote(note);

                if (!!noteInstruction && !!noteAction) {
                    tsInstructionItems.push(
                        generateTsItem(noteInstruction as ExistingMedInstruction, noteAction as any as InstructionAction),
                    );
                }
            });
        }
        if (encounters && encounters.length) {
            const headers = ALL_INSTRUCTIONS[4];
            tsGroups.push(headers);
            const encountersGroup = {
                group: TEMP_ENCOUNTER_ROW_ID,
                id: TEMP_ENCOUNTER_ROW_ID,
                title: '',
                isRoot: false,
                parent: 'E',
            };
            tsGroups.push(encountersGroup);
            encounters.forEach((encounter, index) => {
                const status: INSTRUCTION_STATUSES = encounter.end_time ? 'complete' : 'inprogress';
                const itemReturn = {
                    ...TS_ITEM_COLORS,
                    id: `encounter_${encounter.encounter_id}`,
                    category: 'encounter',
                    encounter_id: encounter.encounter_id,
                    instruction_id: encounter.encounter_id,
                    due_at: encounter.start_time,
                    status,
                    start_time: parseInt(moment.unix(encounter.start_time).subtract(TS_ITEM_TIME_WIDTH, 'minute').format('x')),
                    end_time: parseInt(
                        moment
                            .unix(encounter.end_time || moment().unix())
                            .add(TS_ITEM_TIME_WIDTH, 'minute')
                            .format('x'),
                    ),
                    group: TEMP_ENCOUNTER_ROW_ID,
                    title: <UserNameWrapper>{encounter.doctor_id}</UserNameWrapper>,
                    status_transition_at: encounter.start_time,
                    status_transition_by: encounter.doctor_id,
                    note: '',
                    value: '',
                    assigned_to: null,
                    action_id: -123,
                    type_id: '',
                };
                tsInstructionItems.push(itemReturn);
            });
        }
    }

    const [openGroups, setOpenGroups] = useState({
        ...ALL_INSTRUCTIONS.reduce((prevValue: any, currentValue, index) => {
            prevValue[currentValue.id] = true;
            return prevValue;
        }, {}),
        ...DIAG_CATEGORIES.reduce((prevValue: any, currentValue, index) => {
            prevValue[currentValue] = true;
            return prevValue;
        }, {}),
    });

    const toggleGroup = (id: string | number) => {
        setOpenGroups({
            ...openGroups,
            [id]: !openGroups[id],
        });
    };

    const getNewGroups = () => {
        return tsGroups
            .filter((g: any) => g.isRoot || openGroups[g.parent])
            .map((group) => {
                const filteredItems = tsGroups.filter(
                    (g: any) => !g.isRoot && g.parent === group.id && isInstanceOfBaseInstruction(g) && !g.discontinued_at,
                );
                const enableBulkRescheduleCheckbox =
                    group.id === 'Vitals' || group.id === 'Monitoring' || group.id === 'D' || group.id === 'M' || group.id === 'T';
                return {
                    ...group,
                    name: group.title,
                    title: group.isRoot ? (
                        <>
                            {group.id !== 'E' && group.id !== 'Other' && (
                                <Tooltip title={!enableBulkRescheduleCheckbox || filteredItems.length === 0 ? null : 'Click to bulk edit'}>
                                    <Checkbox
                                        style={{ marginRight: '8px', fontSize: '14px' }}
                                        checked={filteredItems.length > 0 && isSelectedAll(filteredItems, selectedGroups)}
                                        onClick={(e) => {
                                            onSelectMany(filteredItems, selectedGroups, setSelectedGroups);
                                        }}
                                        disabled={!enableBulkRescheduleCheckbox || filteredItems.length === 0}
                                    />
                                </Tooltip>
                            )}
                            <Row style={{ padding: '0', cursor: 'pointer' }} onClick={() => toggleGroup(group.id)}>
                                <Col className='opened-ts-item-group'>
                                    {openGroups[group.id] ? <UpOutlined /> : <DownOutlined />} {group.title}
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <TreatmentSheetSideColumn
                            baseSearchOption={data}
                            group={group}
                            modalForm={props.modalForm}
                            onModalFinish={props.onModalFinish}
                            patientWeight={patientWeight as number}
                            setModalState={props.setModalstate}
                            setContextMenuState={setContextMenuState}
                            user={loggedInUserData}
                            selected={selectedGroups}
                            setSelected={setSelectedGroups}
                        />
                    ),
                    height: 60,
                } as any;
            });
    };

    const itemRenderer = ({
        item,
        itemContext,
        getItemProps,
    }: {
        item: TreatmentSheetInstructionAction | TreatmentSheetFluidAdditiveGroup;
        itemContext: any;
        getItemProps: any;
        getResizeProps: any;
    }) => {
        const itemStyle = { ...getItemProps(item.itemProps).style, border: 'initial', borderLeft: '1px solid white', lineHeight: 'normal', height: 60 };

        if (isInstanceOfTreatmentSheetInstructionAction(item)) {
            const due_at = item.scheduled_time ?? item.due_at;
            const isItemComplete = item.status === 'complete';
            //Status can explicitly be set to `missed`
            //Or, if the status is `null`, but we are past the `missedAfter`, we render that as `missed`
            const missedAfter = (due_at + 60 * 30) * 1000;
            const isItemMissed = item.status === 'missed' || moment().isAfter(missedAfter);
            const isItemInProgress = item.status === 'inprogress';
            const isItemSkipped = item.status === 'skipped';
            const isItemDue = moment().isBetween(due_at, missedAfter);
            const canAdministerItem = moment().isAfter(moment.unix(due_at).subtract(30, 'minutes'));

            if (isItemComplete) {
                itemStyle.background = item.bgColor;
            } else if (isItemInProgress) {
                itemStyle.background = item.inProgressColor;
            } else if (isItemSkipped) {
                itemStyle.background = item.skippedColor;
            } else if (isItemMissed) {
                itemStyle.background = item.missedColor;
            } else if (!canAdministerItem) {
                itemStyle.background = item.schedBgColor;
            } else if (isItemDue) {
                itemStyle.background = item.dueBgColor;
            } else {
                itemStyle.background = item.bgColor;
            }

            const itemWidth = itemStyle.width.replace('px', '');
            const widthNumber = parseFloat(itemWidth);

            if (!!item.scheduled_time) {
                if (widthNumber > 24) {
                    itemStyle.border = `${widthNumber > 24 ? '4px' : '2px'} solid rgba(255, 255, 255, 0.6)`;
                }
            }

            const parentOrder = allInstructions?.find((order) => order.id === item.instruction_id);
            const itemGroup = parentOrder?.type_id as INSTRUCTION_TYPES;

            if (
                (itemGroup === 'C' || itemGroup === 'F' || itemGroup === 'OT') &&
                parseInt(item.id.split('cri_bar_')[1]) <= CRI_DURATION_ACTION_ID // Todo find a better way of identify CRI bars
            ) {
                itemStyle.height = '24px';
                itemStyle.marginTop = '24px';
                itemStyle.background = 'grey';
            }

            return (
                <>
                    <TreatmentSheetMarker
                        patientName={props.patientName}
                        item={item}
                        itemGroup={itemGroup}
                        visitId={props.currentVisit.id}
                        allInstructions={allInstructions}
                        parentOrder={parentOrder}
                        getItemProps={getItemProps}
                        itemStyle={itemStyle}
                        itemContext={itemContext}
                        onModalFinish={props.onModalFinish}
                        setModalState={props.setModalstate}
                        modalForm={props.modalForm}
                        loggedInUserData={loggedInUserData}
                        hideContextMenu={widthNumber < 24}
                        isFinalizedVisit={isFinalizedVisit}
                    />
                </>
            );
        }

        if (isInstanceOfTreatmentSheetFluidAdditiveGroup(item)) {
            itemStyle.background = item.fluidAdditives.some((additive) => additive.discontinued_at === null)
                ? 'var(--veg-green)'
                : 'var(--gray-8)';

            return (
                <>
                    <TreatmentSheetFluidAdditiveMarker
                        fluidAdditives={item.fluidAdditives}
                        visitId={props.currentVisit.id}
                        getItemProps={getItemProps}
                        itemStyle={itemStyle}
                        itemContext={itemContext}
                    />
                </>
            );
        }
    };
    const groupRenderer = ({ group }: any) => {
        const className = group.isRoot ? 'root-group' : 'sidebar-group';

        const trigger =
            !group.isRoot && (isInstanceOfExistingCriInstruction(group) || isInstanceOfExistingFluidInstruction(group)) ? 'click' : '';

        const popoverTitle = <span className='treatment-sheet-side-column__title'>{group.name}</span>;
        let popoverContent = null;
        let overlayClassName = '';
        if (isInstanceOfExistingCriInstruction(group)) {
            overlayClassName = 'ts-cri-review-popover';
            const localWeight = patientWeight ?? group.latest_patient_weight_kg ?? group.approx_patient_weight_kg ?? 0;
            const baseMed = medicineOptions?.find((item) => item.id === group.medication_id && item.type_id === 'M');
            const dose = group.dose * weightMultiplier(group, patientWeight);
            popoverContent = (
                <CriOrderReview
                    numerator_unit={isInstanceOfMedicineSearchOption(baseMed) ? baseMed.numerator_unit : ''}
                    numerator_value={isInstanceOfMedicineSearchOption(baseMed) ? baseMed.numerator_value : 0}
                    denominator_unit={isInstanceOfMedicineSearchOption(baseMed) ? baseMed.denominator_unit : ''}
                    fluidRate={group.rate_ml_per_hr}
                    dose_unit={group.default_cri_unit}
                    dose={group.default_cri_unit === 'mcg/kg/min' ? (dose / 60) * 1000 : dose}
                    patientWeight={localWeight}
                    startTime={group.start_time ? moment.unix(group.start_time) : undefined}
                    endTime={group.end_time ? moment.unix(group.end_time) : undefined}
                    fluidVolume={group.fluids_volume_ml ?? null}
                    fluidsId={group.fluids_id}
                />
            );
        } else if (isInstanceOfExistingFluidInstruction(group)) {
            overlayClassName = 'ts-fluid-review-popover';
            popoverContent = (
                <>
                    <Row>
                        <Col span={12}>
                            <u>
                                <b>Fluid rate:</b>
                            </u>{' '}
                            {group.rate_ml_per_hr ? group.rate_ml_per_hr : ''}
                        </Col>
                        <Col span={12}>
                            <u>
                                <b>Bag volume:</b>
                            </u>{' '}
                            {group.fluids_volume_ml} mL
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '12px' }}>
                        <Col span={24}>
                            <u>
                                <b>Additives:</b>
                            </u>{' '}
                            {formatFluidAdditiveNameList(group.fluid_additives, true)}
                        </Col>
                    </Row>
                </>
            );
        }

        const discontinuedClassName = group.discontinued_at ? 'ts-group-wrapper__discontinued' : '';

        return (
            <div className={'ts-group-wrapper ' + discontinuedClassName} onAnimationStart={(e) => e.stopPropagation()}>
                <Popover
                    overlayClassName={overlayClassName}
                    content={popoverContent}
                    title={popoverTitle}
                    trigger={trigger}
                    destroyTooltipOnHide={true}
                    visible={activeTreatmentSheetGroup === group.id}
                    onVisibleChange={(show) => {
                        if (show) {
                            appDispatch(setActiveTreatmentSheetGroup(group.id));
                        } else {
                            appDispatch(setActiveTreatmentSheetGroup(null));
                        }
                    }}
                >
                    <div className={className}>
                        {group.title}
                        {group.isRoot &&
                            group.name === 'CRI, Fluids, O2' &&
                            criAndFluidInstructions.some((instruction) => instruction.discontinued_at === null) && (
                                <Button
                                    style={{
                                        marginLeft: 'auto',
                                        height: 'auto',
                                        padding: '2px 8px',
                                    }}
                                    icon={<StopOutlined />}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Discontinue All CRI, Fluids, and Oxygen',
                                            content: (
                                                <>
                                                    <p>CRIs, Fluids and Oxygen are automatically billed at an hourly rate.</p>
                                                    <p>
                                                        In the case of euthanasia and to get an accurate final invoice amount, all CRI,
                                                        fluid and oxygen orders should be discontinued.
                                                    </p>
                                                </>
                                            ),
                                            okText: 'Discontinue All',
                                            centered: true,
                                            maskClosable: true,
                                            onOk: discontinueCriAndFluidInstructions,
                                        });
                                    }}
                                >
                                    Discontinue All
                                </Button>
                            )}
                    </div>
                </Popover>
            </div>
        );
    };

    const visitStartDate = moment.unix(props.currentVisit?.arrived_at as number);

    const onContextMenu = (groupId: number | string, time: number, e: SyntheticEvent) => {
        if (!isFinalizedVisit) {
            if (typeof groupId === 'string') {
                //Instruction-type-header
                //Do not pop context menu
                //Hide context menu if it is up
                setContextMenuState(INITIAL_CONTEXT_MENU);
                const notesTypeStringArray = [
                    FormName.prog_note,
                    FormName.communication_note,
                    FormName.recommendation_note,
                    FormName.procedure_note,
                ];

                if (notesTypeStringArray.includes(groupId as FormName)) {
                    addComposeBox(
                        {
                            title: groupId,
                            content: <AddNote composeBoxId={_.uniqueId()} formType={groupId} />,
                        },
                        isFinalizedVisit,
                    );
                }
            } else if (contextMenuState.visible) {
                //if menu is already visible, hide menu
                setContextMenuState(INITIAL_CONTEXT_MENU);
            } else {
                setContextMenuState({
                    visible: true,
                    x: (e.nativeEvent as PointerEvent).pageX,
                    y: (e.nativeEvent as PointerEvent).pageY,
                    groupId: groupId,
                    time,
                });
            }
        } else {
            closedErrorMsg(isFinalizedVisit);
        }
    };

    const filteredBulkRescheduleGroups = tsGroups?.filter(
        (group) => selectedGroups.includes(group.id) && isInstanceOfBaseInstruction(group) && !group.discontinued_at,
    );
    const selectedBulkRescheduleItems = filteredBulkRescheduleGroups.map((item) => {
        const i = item as BaseExistingInstruction;
        return {
            id: i.id,
            type_id: i.type_id,
            name: i.name,
        };
    });
    const filteredBulkRescheduleGroupsHasInProgressActions = filteredBulkRescheduleGroups.some(
        (group) => isInstanceOfBaseInstruction(group) && group.actions.some((action) => action.status === 'inprogress'),
    );

    return (
        <div className='visit-treatment-sheet'>
            <Row style={{ marginBottom: '24px' }}>
                <Col span={24}>
                    <Typography.Text
                        style={{
                            fontSize: '20px',
                            fontWeight: 500,
                            lineHeight: '28px',
                        }}
                    >
                        Treatment Sheet
                    </Typography.Text>
                </Col>
            </Row>
            <Spin size='large' spinning={isLoadingEncounters || isLoadingInstructions} />
            {!isLoadingEncounters && !isLoadingInstructions && (
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        <Row justify='space-between'>
                            <Row style={{ gap: '8px' }}>
                                <Col>
                                    <AddNoteButton
                                        isFinalizedVisit={!!props.currentVisit.finalized_at}
                                        openNoteDrawer={notesDrawerRef.current?.openNotesDrawer}
                                    />
                                </Col>
                                <Col>
                                    <Badge count={transferEstimateItemsValues}>
                                        <Button
                                            disabled={!transferEstimateItemsValues}
                                            onClick={() => setIsTransferEstimateDrawerOpen(true)}
                                        >
                                            <SwapRightOutlined /> Transfer from Estimate
                                        </Button>
                                    </Badge>
                                </Col>
                            </Row>

                            <Row style={{ gap: '8px' }}>
                                <Col>
                                    <CalendarButtons
                                        visibleTime={visibleTime}
                                        setVisibleTime={setVisibleTime}
                                        updateScrollCanvasRef={updateScrollCanvasRef.current}
                                    />
                                </Col>
                                <Col>
                                    <ZoomButtons
                                        visibleTime={visibleTime}
                                        setVisibleTime={setVisibleTime}
                                        updateScrollCanvasRef={updateScrollCanvasRef.current}
                                    />
                                </Col>
                            </Row>
                        </Row>
                    </Col>

                    <Col
                        span={24}
                        onAnimationStart={() => window.dispatchEvent(new Event('resize'))}
                        style={{ border: '1px solid var(--gray-5)' }}
                    >
                        <Timeline
                            resizeDetector={containerResizeDetector}
                            groups={getNewGroups()}
                            verticalLineClassNamesForTime={(start, end) => {
                                if (visitStartDate.isAfter(moment(start).startOf('hour'))) {
                                    return ['ts-before-visit-cell'];
                                } else if (moment().startOf('hour').isBefore(start)) {
                                    //Is at least 8 hours in future
                                    return ['ts-future-cell'];
                                } else if (moment().endOf('hour').isAfter(end)) {
                                    //Is at least 8 hours in past
                                    return ['ts-past-cell'];
                                } else {
                                    return ['ts-current-cell'];
                                }
                            }}
                            horizontalLineClassNamesForGroup={(group) => {
                                if (isInstanceOfBaseInstruction(group) && group.discontinued_at) {
                                    return ['ts-discontinued-group'];
                                } else if (group.isRoot) {
                                    return ['row-root'];
                                }

                                return ['row-item'];
                            }}
                            itemRenderer={itemRenderer}
                            groupRenderer={groupRenderer}
                            items={tsInstructionItems}
                            keys={keys}
                            itemTouchSendsClick={false}
                            stackItems={false}
                            itemHeightRatio={1}
                            canMove={false}
                            canResize={false}
                            selected={selectedItems}
                            onItemClick={(itemId, e, time) => {
                                setContextMenuState(INITIAL_CONTEXT_MENU);
                                setSelectedItems([itemId]);
                            }}
                            onItemDeselect={(e) => {
                                setSelectedItems([]);
                            }}
                            onCanvasClick={(groupId, time, e: SyntheticEvent) => {
                                //Hide context menu
                                setContextMenuState(INITIAL_CONTEXT_MENU);
                            }}
                            onItemContextMenu={(itemId: string, e: SyntheticEvent, time) => {
                                if (itemId.indexOf('action_') === 0 || itemId.indexOf('cri_bar_') === 0) {
                                    let item = tsInstructionItems.find((item) => item.id === itemId);
                                    let groupId = item?.group;
                                    if (groupId) {
                                        // If we have this item's popover open, without this line below,
                                        // both context menu and popover would be visible at the same time
                                        appDispatch(setActiveTreatmentSheetAsk(null));
                                        onContextMenu(groupId, time, e);
                                    }
                                }
                            }}
                            onCanvasContextMenu={(groupId, time, e: SyntheticEvent) => {
                                onContextMenu(groupId, time, e);
                            }}
                            defaultTimeStart={moment(visibleTime.start)}
                            defaultTimeEnd={moment(visibleTime.end)}
                            onBoundsChange={() => {
                                updateDateLabels();
                            }}
                            onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
                                if (!!activeTreatmentSheetAsk || !!activeActionItem) return;

                                setVisibleTime({
                                    start: visibleTimeStart,
                                    end: visibleTimeEnd,
                                });
                                updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
                                updateScrollCanvasRef.current = updateScrollCanvas;
                            }}
                            sidebarWidth={430}
                            headerRef={(el) => (timelineHeaderRef.current = el)}
                        >
                            <TimelineHeaders className='sticky'>
                                <SidebarHeader>
                                    {({ getRootProps }) => {
                                        const filteredItems = tsGroups.filter(
                                            (g) =>
                                                !g.isRoot &&
                                                g.parent !== 'E' &&
                                                g.parent !== 'Other' &&
                                                g.parent !== 'C' &&
                                                g.parent !== 'F' &&
                                                g.parent !== 'TGH' &&
                                                isInstanceOfBaseInstruction(g) &&
                                                !g.discontinued_at,
                                        );
                                        return (
                                            <Row {...getRootProps()} align='middle' className='show-discontinued-container'>
                                                <Tooltip title={filteredItems.length === 0 ? null : 'Click to bulk edit'}>
                                                    <Checkbox
                                                        style={{ marginRight: '8px', fontSize: '14px' }}
                                                        checked={filteredItems.length > 0 && isSelectedAll(filteredItems, selectedGroups)}
                                                        onClick={(e) => {
                                                            onSelectMany(filteredItems, selectedGroups, setSelectedGroups);
                                                        }}
                                                        disabled={filteredItems.length === 0}
                                                    />
                                                </Tooltip>
                                                {selectedGroups.length > 0 && (
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        onClick={() => {
                                                            setIsBulkRescheduleDrawerOpen(true);
                                                        }}
                                                    />
                                                )}
                                                <Col className='show-discontinued-container__button'>
                                                    <Switch
                                                        onClick={() => setDisplayDiscontinued(!displayDiscontinued)}
                                                        checked={displayDiscontinued}
                                                    />
                                                </Col>
                                                <Col>{displayDiscontinued ? 'Showing discontinued' : 'Hiding discontinued'}</Col>
                                            </Row>
                                        );
                                    }}
                                </SidebarHeader>
                                <DateHeader unit='primaryHeader' />
                                <DateHeader labelFormat={'ha'} />
                            </TimelineHeaders>
                            <TimelineMarkers>
                                <CustomMarker date={Date.now()} />
                                <CursorMarker />
                            </TimelineMarkers>
                        </Timeline>
                    </Col>

                    <ContextPopover
                        {...contextMenuState}
                        closePopRef={contextMenuRef}
                        user={loggedInUserData as PimsUser}
                        setContextMenuState={setContextMenuState}
                        groups={getNewGroups()}
                        setModalstate={props.setModalstate}
                        modalForm={props.modalForm}
                        onModalFinish={props.onModalFinish}
                        patientWeight={patientWeight as number}
                        generateDiscontinueModalState={generateDiscontinueModalState}
                        generateAdjustFluidRateModalState={generateAdjustFluidRateModalState}
                    />
                    {<BonusActionModal patientWeight={patientWeight} />}
                    <TreatmentSheetMarkerModal
                        allInstructions={allInstructions}
                        onModalFinish={props.onModalFinish}
                        setModalState={props.setModalstate}
                        modalForm={props.modalForm}
                        loggedInUserData={loggedInUserData}
                        isFinalizedVisit={isFinalizedVisit}
                        patientWeight={patientWeight}
                        patientName={props.patientName}
                    />
                    {activeActionItem && <RescheduleActionModal allInstructions={allInstructions} />}
                    <TransferEstimateDrawer
                        isOpen={isTransferEstimateDrawerOpen}
                        setIsOpen={setIsTransferEstimateDrawerOpen}
                        patientWeight={patientWeight}
                    />
                    <RescheduleOrder />
                    <FluidAdditiveModal />
                    <NotesDrawer ref={notesDrawerRef} isFinalizedVisit={isFinalizedVisit} />
                    <BulkRescheduleDrawer
                        isOpen={isBulkRescheduleDrawerOpen}
                        setIsOpen={setIsBulkRescheduleDrawerOpen}
                        selectedItems={selectedBulkRescheduleItems}
                        deselectItems={() => {
                            setSelectedGroups([]);
                        }}
                        hasInProgressActions={filteredBulkRescheduleGroupsHasInProgressActions}
                    />
                </Row>
            )}
        </div>
    );
};

export const TreatmentSheet = React.memo(TreatmentSheetLocal); // Wrapping in React.memo to reduce the issues caused with constant rerendering caused by ghost actions

const generateTsGroup = (instruction: BaseExistingInstruction, diagCategories: string[]) => {
    const title = instruction.serial ? `${instruction.name} - Serial ${instruction.serial_hours} Hours` : instruction.name;
    return {
        ...instruction,
        group: instruction.id,
        id: instruction.id,
        title,
        isRoot: false,
        parent:
            instruction.type_id === 'F' || instruction.type_id === 'OT'
                ? 'C' //we force CRI and Fluid into same parent grouping
                : diagCategories.includes(instruction.category || '')
                ? instruction.category || ''
                : instruction.type_id,
    };
};

const transformNote = (note: BaseNote): Array<object> => {
    const noteCategory: string = note.category;
    const noteArray = [];

    if (
        noteCategory === FormName.communication_note ||
        noteCategory === FormName.prog_note ||
        noteCategory === FormName.recommendation_note ||
        noteCategory === FormName.procedure_note
    ) {
        noteArray.push(
            { id: noteCategory },
            {
                ...note,
                due_at: note.note_date_time,
                status: 'complete',
                assigned_to: null,
                note: note.content,
                status_transition_by: note.created_by,
                value: null,
                instruction_id: noteCategory,
                id: `note_${note.id}`,
            },
        );
    }

    return noteArray;
};

export const generateTsItem = (instruction: BaseExistingInstruction, action: InstructionAction) => {
    let due_at = action.scheduled_time ?? action.due_at;

    if (action.status === 'complete' && !('category' in action)) {
        due_at = action.completed_at || action.status_transition_at;
    }

    return {
        ...action,
        ...TS_ITEM_COLORS,
        start_time: parseInt(moment.unix(due_at).subtract(TS_ITEM_TIME_WIDTH, 'minute').format('x')),
        end_time: parseInt(moment.unix(due_at).add(TS_ITEM_TIME_WIDTH, 'minute').format('x')),
        group: instruction.id,
        title: getTsItemTitle(instruction, action),
        action_id: action.id,
        id: `action_${action.id}_${getTsItemTitle(instruction, action) ?? ''}`,
        isGhostAction: action.isGhostAction ?? false,
        type_id: instruction.type_id,
    };
};

const getTsItemTitle = (instruction: BaseExistingInstruction, action: InstructionAction) => {
    if (isInstanceOfExistingFluidInstruction(instruction) || isInstanceOfExistingCriInstruction(instruction)) {
        return (action.value || instruction.initial_rate_ml_per_hr) + ' mL/hr';
    }

    if (isInstanceOfExistingOxygenTherapyInstruction(instruction)) {
        const value = action.value ? roundTo((action.value as number) / 100, 2) : roundTo(instruction.initial_oxygen_quantity / 100, 2);

        return `${value}${instruction.oxygen_unit}`;
    }

    if (action.value) {
        const unit = (instruction as ExistingDiagInstruction).result_entry
            ? (instruction as ExistingDiagInstruction).result_entry.unit || ''
            : '';
        return (instruction as ExistingDiagInstruction).result_entry.widget === 'numeric'
            ? `${roundTo(action.value, 3)} ${unit}`
            : ['idexx', 'webpacs'].includes((instruction as ExistingDiagInstruction).result_entry.widget)
            ? 'file'
            : `${action.value} ${unit}`;
    }
    return '';
};

export const shiftCalView = (
    direction: 'add' | 'subtract' | 'today',
    visibleTime: { start: number; end: number },
    setVisibleTime: Function,
    updateScrollCanvasRef?: (start: number, end: number) => void,
) => {
    if (direction !== 'today') {
        const start = parseInt(moment(visibleTime.start)[direction](1, 'day').format('x'));
        const end = parseInt(moment(visibleTime.end)[direction](1, 'day').format('x'));
        setVisibleTime({
            start,
            end,
        });
        if (updateScrollCanvasRef) {
            updateScrollCanvasRef(start, end);
        }
    } else {
        setVisibleTime(INITIAL_TS_WINDOW);
        if (updateScrollCanvasRef) {
            updateScrollCanvasRef(INITIAL_TS_WINDOW.start, INITIAL_TS_WINDOW.end);
        }
    }
};

interface CalendarButtonsProps {
    visibleTime: { start: number; end: number };
    setVisibleTime: Function;
    updateScrollCanvasRef?: (start: number, end: number) => void;
}
export const CalendarButtons = ({ visibleTime, setVisibleTime, updateScrollCanvasRef }: CalendarButtonsProps) => {
    return (
        <Row justify='end'>
            <Col>
                <Button
                    className='btn-group-first'
                    onClick={() => {
                        shiftCalView('subtract', visibleTime, setVisibleTime, updateScrollCanvasRef);
                    }}
                >
                    <ArrowLeftOutlined />
                </Button>
            </Col>
            <Col>
                <Button
                    className='btn-group-mid'
                    onClick={() => {
                        shiftCalView('today', visibleTime, setVisibleTime, updateScrollCanvasRef);
                    }}
                >
                    Today
                </Button>
            </Col>
            <Col>
                <Button
                    className='btn-group-last'
                    onClick={() => {
                        shiftCalView('add', visibleTime, setVisibleTime, updateScrollCanvasRef);
                    }}
                >
                    <ArrowRightOutlined />
                </Button>
            </Col>
        </Row>
    );
};

export const zoomCalView = (
    direction: 'out' | 'in' | 'reset',
    visibleTime: { start: number; end: number },
    setVisibleTime: Function,
    updateScrollCanvasRef?: (start: number, end: number) => void,
) => {
    if (direction === 'out') {
        const start = parseInt(moment(visibleTime.start).subtract(1, 'hour').format('x'));
        const end = parseInt(moment(visibleTime.end).add(1, 'hour').format('x'));

        setVisibleTime({
            start,
            end,
        });
        if (updateScrollCanvasRef) {
            updateScrollCanvasRef(start, end);
        }
    } else if (direction === 'in') {
        const start = parseInt(moment(visibleTime.start).add(1, 'hour').format('x'));
        const end = parseInt(moment(visibleTime.end).subtract(1, 'hour').format('x'));

        setVisibleTime({
            start,
            end,
        });
        if (updateScrollCanvasRef) {
            updateScrollCanvasRef(start, end);
        }
    } else {
        const timeDiff = visibleTime.end - visibleTime.start;
        const midpoint = visibleTime.end - timeDiff / 2;
        const start = parseInt(moment(midpoint).subtract(windowHoursAfter, 'hour').format('x'));
        const end = parseInt(moment(midpoint).add(windowHoursAfter, 'hour').format('x'));
        setVisibleTime({
            start,
            end,
        });
        if (updateScrollCanvasRef) {
            updateScrollCanvasRef(start, end);
        }
    }
};

interface ZoomButtonsProps {
    visibleTime: { start: number; end: number };
    setVisibleTime: Function;
    updateScrollCanvasRef?: (start: number, end: number) => void;
}
export const ZoomButtons = ({ visibleTime, setVisibleTime, updateScrollCanvasRef }: ZoomButtonsProps) => {
    return (
        <Row justify='end'>
            <Col>
                <Button
                    className='btn-group-first'
                    onClick={() => {
                        zoomCalView('out', visibleTime, setVisibleTime, updateScrollCanvasRef);
                    }}
                >
                    <ZoomOutOutlined />
                </Button>
            </Col>
            <Col>
                <Button
                    className='btn-group-mid'
                    onClick={() => {
                        zoomCalView('reset', visibleTime, setVisibleTime, updateScrollCanvasRef);
                    }}
                >
                    Reset
                </Button>
            </Col>
            <Col>
                <Button
                    className='btn-group-last'
                    onClick={() => {
                        zoomCalView('in', visibleTime, setVisibleTime, updateScrollCanvasRef);
                    }}
                >
                    <ZoomInOutlined />
                </Button>
            </Col>
        </Row>
    );
};

export function formatFluidAdditiveNameList(fluidAdditives: FluidAdditive[], withDoseQty?: boolean) {
    return fluidAdditives.map((additive, index) => {
        return (
            <span
                style={{
                    color: additive.discontinued_at ? 'var(--gray-6)' : 'inherit',
                }}
            >
                {additive.short_name || additive.name}
                {withDoseQty ? ' ' + additive.dose_qty : ''}
                {fluidAdditives.length > 1 && index < fluidAdditives.length - 1 ? ', ' : ''}
            </span>
        );
    });
}
