import { Alert, Button, Col, Divider, Form, Image, Input, Row, Space, Tooltip, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ContactIcon } from 'assets/img/ContactIcon';
import Paw from 'assets/img/Paw.svg';
import PawPlus from 'assets/img/PawPlus.svg';
import CalendarPlus from 'assets/img/CalendarPlus.svg';
import { FormWrapper } from 'components/forms/FormWrapper';
import PatientInfoForm from 'components/forms/PatientInfo';
import { PhoneNumberField } from 'components/forms/fields/PhoneNumberField';
import { CSSProperties, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAddNewPetVisitCheckinMutation, useSearchCustomerByPhoneQuery, useSubmitNewPatientMutation } from 'services/admissionService';
import { usePostCheckinTprInstructionsMutation, useSetInitialWeightMutation } from 'services/instructionService';
import { useAddNewVisitMutation } from 'services/visitService';
import { CustomerInfo, Hospital, HospitalCustomers } from 'utils/dataTypes';
import { appendRouteId, formatPhoneNumber } from 'utils/formatFuncs';
import { testPath } from 'utils/miscFuncs';
import { ROUTES } from 'utils/types/enums';
import { BASE_QUERY_OPTIONS, SUPPORTED_PHONE_NUMBER_REGEX } from '../../utils/constants';
import './admit-patient.css';
import { DrawerRadioGroup } from 'components/drawers';

const formItemStyle: CSSProperties = {
    width: '100%',
};

interface AdmitPatientProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    hospitalData?: Hospital;
}
const AdmitPatient: React.FC<AdmitPatientProps> = (props) => {
    const [formType, setFormType] = useState<string | boolean>('New Visit');
    const [phoneInputValue, setPhoneInputValue] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerInfo | undefined>();
    const [enteredPatientName, setEnteredPatientName] = useState<string | undefined>();
    const { isOpen, setIsOpen } = props;
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const history = useHistory();
    const location = useLocation();

    const { data: allCustomers, isLoading: isLoadingCustomers } = useSearchCustomerByPhoneQuery(phoneInputValue, {
        ...BASE_QUERY_OPTIONS,
        skip: phoneInputValue.length !== 10,
    });
    const [submitNewPatient, { isSuccess: newPatientSuccess, data: newPatient, isLoading: submitNewPatientLoading }] =
        useSubmitNewPatientMutation();
    const [postCheckinTprInstructions, { isSuccess: checkinTprInstructionsSuccess, isLoading: checkinTprInstructionsLoading }] =
        usePostCheckinTprInstructionsMutation();
    const [setInitialWeight, { isSuccess: setInitialWeightSuccess, isLoading: setInitialWeightLoading }] = useSetInitialWeightMutation();
    const [addNewPetVisitCheckin] = useAddNewPetVisitCheckinMutation();

    useEffect(() => {
        if (newPatientSuccess && checkinTprInstructionsSuccess && setInitialWeightSuccess && isOpen) {
            message.success('New patient successfully created.');
            closeModal();
            form.resetFields();
            if (testPath('/visit/:id', location.pathname)) {
                window.open(appendRouteId(ROUTES.visit, String(newPatient?.id)));
            } else {
                history.push(appendRouteId(ROUTES.visit, String(newPatient?.id)));
            }
        }
    }, [newPatientSuccess, checkinTprInstructionsSuccess, setInitialWeightSuccess]);

    useEffect(() => {
        form.setFieldsValue({ name: enteredPatientName });
        if (formType !== 'New Visit') {
            setSelectedCustomer(undefined);
        }
    }, [formType]);

    const closeModal = () => {
        setIsOpen(false);
        form.resetFields();
        setPhoneInputValue('');
        setFormType('New Visit');
        setEnteredPatientName(undefined);
        setSelectedCustomer(undefined);
    };

    const onCustomerSelect = (customer: any) => {
        setFormType('New Visit');
        form2.setFieldsValue({ form_type: 'New Visit' });
        setSelectedCustomer(customer);
    };

    const onPatientNameChange = (patientName: string) => {
        setEnteredPatientName(patientName);
    };

    const handleChange = (phoneNumber: string | null) => {
        if (form.getFieldError(['mobile_phone']).length) {
            form.setFields([
                {
                    name: 'mobile_phone',
                    errors: [],
                },
            ]);
        }
        if (phoneNumber) {
            setPhoneInputValue(phoneNumber);
        }
    };

    const getHospitalCustomers = (customers: CustomerInfo[] | undefined, currentHospitalId: number | undefined) => {
        const hospitalCustomers: HospitalCustomers[] = [];
        customers?.forEach((customer: CustomerInfo) => {
            customer.pets?.forEach((pet) => {
                const hospitalIndex = hospitalCustomers.findIndex((group) => group.hospital_id === pet.hospital_id);
                if (hospitalIndex === -1) {
                    hospitalCustomers.push({
                        hospital_id: pet.hospital_id,
                        hospital_name: pet.hospital_name,
                        customers: [{ ...customer, pets: [pet] }],
                    });
                } else {
                    const hospital = hospitalCustomers[hospitalIndex];
                    const customerIndex = hospital.customers.findIndex((item) => item.customer_id === customer.customer_id);
                    if (customerIndex === -1) {
                        hospital.customers.push({
                            ...customer,
                            pets: [pet],
                        });
                    } else {
                        const pets = hospital.customers[customerIndex].pets;
                        hospital.customers[customerIndex].pets = [...(pets ?? []), pet];
                    }
                }
            });
        });
        hospitalCustomers.sort((a, b) => a.hospital_name.localeCompare(b.hospital_name));
        const currentHospital = hospitalCustomers.find((hospital) => hospital.hospital_id === currentHospitalId);
        if (currentHospital) {
            hospitalCustomers.splice(hospitalCustomers.indexOf(currentHospital), 1);
            hospitalCustomers.unshift(currentHospital);
        }
        return hospitalCustomers;
    };

    const hospitalCustomers = getHospitalCustomers(allCustomers, props.hospitalData?.id);
    const hasCustomersFromOtherHospitals = hospitalCustomers.some((hospital) => hospital.hospital_id !== props.hospitalData?.id);
    const alertMessage = hasCustomersFromOtherHospitals ? (
        <>
            A customer already exists with this phone number.
            <br />
            <br />
            If creating a visit from a customer from a different location, a new visit will be part of{' '}
            <strong>{props.hospitalData?.display_name}</strong>.
        </>
    ) : (
        <>A customer already exists with this phone number.</>
    );

    return (
        <Modal
            className='admit-patient-modal'
            visible={isOpen}
            maskClosable={true}
            title='New Patient Visit'
            onOk={form.submit}
            okText={formType === 'New Visit' ? 'Save' : 'Send Check-in Link'}
            onCancel={() => {
                closeModal();
            }}
            confirmLoading={submitNewPatientLoading || checkinTprInstructionsLoading || setInitialWeightLoading}
            destroyOnClose={true}
            width={480}
            okButtonProps={{
                disabled:
                    formType === 'New Visit'
                        ? !enteredPatientName
                        : !enteredPatientName || !phoneInputValue || hospitalCustomers.length > 0,
            }}
        >
            <Space direction='vertical' size='small'>
                <Form form={form2} style={{ width: '100%' }}>
                    <Form.Item
                        name='form_type'
                        style={{ width: '100%', paddingTop: 0 }}
                        initialValue={'New Visit'}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        preserve={false}
                    >
                        <DrawerRadioGroup
                            options={['New Visit', 'Check-in Link']}
                            onChange={(value) => {
                                setFormType(value);
                            }}
                        />
                    </Form.Item>
                </Form>

                {formType === 'New Visit' && (
                    <FormWrapper
                        data-cy='newPatientForm'
                        form={form}
                        onFormChange={form.setFieldsValue}
                        getFormData={form.getFieldsValue}
                        onFinish={async function (values: any): Promise<void> {
                            const visit = await submitNewPatient({
                                newPatientData: values,
                            });
                            if ('data' in visit) {
                                postCheckinTprInstructions({ visitId: visit.data.id })
                                    .unwrap()
                                    .then(() => {
                                        const weight = parseFloat(values.initial_weight);

                                        setInitialWeight({
                                            visitId: visit.data.id,
                                            weight: isNaN(weight) ? null : weight.toString(),
                                        });
                                    });
                            }
                        }}
                        onFinishFailed={function (values: any): void {
                            console.error(values);
                        }}
                        bottomBar={
                            <>
                                <Button hidden htmlType='submit' />
                            </>
                        }
                    >
                        <>
                            {selectedCustomer && (
                                <>
                                    <Form.Item name='customer_id' hidden initialValue={selectedCustomer.customer_id}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label='Customer'
                                        style={{ width: '100%' }}
                                        labelCol={{ span: 7 }}
                                        wrapperCol={{ span: 17 }}
                                        preserve={false}
                                    >
                                        {`${selectedCustomer.first_name} ${selectedCustomer.last_name}`}
                                    </Form.Item>
                                    <Form.Item
                                        name='customer_mobile_phone'
                                        label='Mobile Phone'
                                        style={{ width: '100%' }}
                                        labelCol={{ span: 7 }}
                                        wrapperCol={{ span: 17 }}
                                        preserve={false}
                                        initialValue={selectedCustomer.phone_number}
                                    >
                                        {formatPhoneNumber(selectedCustomer.phone_number)}
                                    </Form.Item>
                                </>
                            )}
                        </>
                        <PatientInfoForm patientInfo={{}} onPatientNameChange={onPatientNameChange} patientAlerts={[]} form={form} />
                    </FormWrapper>
                )}
                {formType !== 'New Visit' && (
                    <>
                        <FormWrapper
                            data-cy='admitPatientPatientForm'
                            form={form}
                            onFormChange={form.setFieldsValue}
                            getFormData={form.getFieldsValue}
                            onFinish={async function (values: any): Promise<void> {
                                if (!values.mobile_phone?.toString().match(SUPPORTED_PHONE_NUMBER_REGEX)) {
                                    form.setFields([
                                        {
                                            name: 'mobile_phone',
                                            errors: ['invalid phone number'],
                                        },
                                    ]);
                                    return;
                                }
                                addNewPetVisitCheckin({
                                    name: values.name,
                                    mobile_phone: values.mobile_phone.toString(),
                                }).then((visit) => {
                                    if ('data' in visit) {
                                        message.success('Check-in link successfully created.');
                                        closeModal();
                                    }
                                });
                            }}
                            onFinishFailed={function (values: any): void {
                                console.error(values);
                            }}
                            bottomBar={
                                <>
                                    <Button hidden htmlType='submit' />
                                </>
                            }
                            gutter={0}
                        >
                            <Form.Item
                                name='name'
                                label='Patient’s Name'
                                style={{ width: '100%' }}
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 15 }}
                                rules={[{ required: true }]}
                                preserve={false}
                            >
                                <Input
                                    prefix={<Image preview={false} src={Paw} style={{ width: '16px' }} alt='' />}
                                    onChange={(e) => {
                                        onPatientNameChange(e.target.value);
                                    }}
                                />
                            </Form.Item>

                            <PhoneNumberField
                                autoFocus={true}
                                formItemProps={{
                                    preserve: false,
                                    name: 'mobile_phone',
                                    label: 'Mobile Number',
                                    style: formItemStyle,
                                    labelCol: { span: 9 },
                                    wrapperCol: { span: 15 },
                                    initialValue: phoneInputValue,
                                    rules: [{ required: true }],
                                }}
                                onChange={(value: string | null) => {
                                    handleChange(value);
                                }}
                            />
                        </FormWrapper>

                        {!isLoadingCustomers && allCustomers && allCustomers.length > 0 && (
                            <>
                                <Col span={24}>
                                    <Alert className='alert-with-icon-top' type='info' showIcon message={alertMessage} />
                                </Col>
                                <MatchingCustomersAndPatientsTable
                                    hospitalCustomers={hospitalCustomers}
                                    onCustomerSelect={onCustomerSelect}
                                    closeModal={closeModal}
                                />
                            </>
                        )}
                    </>
                )}
            </Space>
        </Modal>
    );
};

interface MatchingCustomersTableProps {
    hospitalCustomers: HospitalCustomers[];
    onCustomerSelect: Function;
    closeModal: Function;
}
const MatchingCustomersAndPatientsTable = ({ hospitalCustomers, onCustomerSelect, closeModal }: MatchingCustomersTableProps) => {
    const [createNewVisit, { data: newVisit, isSuccess: createNewVisitSuccess }] = useAddNewVisitMutation();
    const [postCheckinTprInstructions, { isSuccess: checkinTprInstructionsSuccess }] = usePostCheckinTprInstructionsMutation();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (createNewVisitSuccess && checkinTprInstructionsSuccess) {
            message.success('New visit successfully created.');
            closeModal();
            if (testPath('/visit/:id', location.pathname)) {
                window.open(appendRouteId(ROUTES.visit, String(newVisit?.id)));
            } else {
                history.push(appendRouteId(ROUTES.visit, String(newVisit?.id)));
            }
        }
    }, [createNewVisitSuccess, checkinTprInstructionsSuccess]);

    return (
        <div className='admit-patient-hospitals'>
            {hospitalCustomers.map((hospital) => {
                return (
                    <div className='admit-patient-hospital'>
                        <div className='admit-patient-hospital-name'>{hospital.hospital_name}</div>
                        <Divider style={{ margin: '4px 0px 8px' }} />
                        {hospital.customers.map((customer) => {
                            return (
                                <div className='admit-patient-customer'>
                                    <Row>
                                        <Col className='admit-patient-customer-name'>
                                            <ContactIcon
                                                style={{
                                                    color: 'var(--gray-8)',
                                                    marginRight: 'var(--spacing-sm)',
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                            {`${customer.first_name} ${customer.last_name} | ${formatPhoneNumber(customer.phone_number)}`}
                                        </Col>
                                        <Col className='admit-patient-action-button-col'>
                                            <Tooltip title='Create new patient'>
                                                <Button
                                                    type='ghost'
                                                    onClick={() => {
                                                        onCustomerSelect(customer);
                                                    }}
                                                >
                                                    <Image preview={false} src={PawPlus} style={{ width: '20px' }} alt='' />
                                                </Button>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                    {customer.pets?.map((pet) => {
                                        return (
                                            <Row>
                                                <Col style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Image preview={false} src={Paw} style={{ width: '20px', marginRight: '8px' }} alt='' />
                                                    {`${pet.pet_name}`}
                                                </Col>
                                                <Col className='admit-patient-action-button-col'>
                                                    <Button
                                                        type='ghost'
                                                        onClick={() => {
                                                            createNewVisit({ patientId: pet.pet_id })
                                                                .then((response) => {
                                                                    if ('data' in response) {
                                                                        postCheckinTprInstructions({ visitId: response.data.id });
                                                                    }
                                                                })
                                                                .catch((e) => {
                                                                    message.error('There was an error creating the visit');
                                                                    console.error(e);
                                                                });
                                                        }}
                                                    >
                                                        <Tooltip title='Create new visit'>
                                                            <Image preview={false} src={CalendarPlus} style={{ width: '20px' }} alt='' />
                                                        </Tooltip>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                    <Divider className='admit-patient-customer-divider' />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default AdmitPatient;
