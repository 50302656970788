import { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { DatePickerProps } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { Button, Modal, DatePicker } from 'antd';
import { PIMS_HOST } from 'utils/constants';
import './PaymentsReport.css';


const PaymentsReport = () => {
    const [year, setYear] = useState<number>(0);
    const [downloadEnabled, setDownloadEnabled] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const setDate = (y: number) => {
        setYear(y);
        setDownloadEnabled(Boolean(y));
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current > moment().endOf('month');
    };

    const onChange: DatePickerProps['onChange'] = (date) => {
        if (date) {
            setDate(date.get('year'));
        } else {
            setDate(0);
        }
    };

    return (
        <div className='payments-report-container'>
            <h1>Payments Report</h1>

            <div className='sub-header'>This report contains payment details such as date, type, amount, and visit id.</div>

            <Button type='primary' onClick={showModal}>
                <DownloadOutlined />
                <span>Download Payments Report</span>
            </Button>
            <Modal
                title='Payments Report'
                destroyOnClose={true}
                visible={isModalOpen}
                onOk={handleOk}
                okText='Download Report'
                onCancel={handleCancel}
                okButtonProps={{
                    disabled: !downloadEnabled,
                    href: `${PIMS_HOST}/report/accounting/${year}`,
                    download: true,
                }}
            >
                <DatePicker autoFocus onChange={onChange} picker='year' disabledDate={disabledDate} />
            </Modal>
        </div>
    );
};

export default PaymentsReport;
