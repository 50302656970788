import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import TopBar from 'components/TopBar';
import SideBar from 'components/SideBar';
import { ROUTES } from 'utils/types/enums';
import { useQuery } from 'hooks';

interface ProtectedRouteProps {
	component: React.ReactNode;
	isUserLoggedIn: boolean;
	path: string;
	hasAssignedHospital?: boolean;
	exact?: boolean;
	removeNav?: boolean;
}

// Redirects user to login page if they are not authenticated
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
	component: Component,
	isUserLoggedIn,
	hasAssignedHospital,
	removeNav,
	...rest
}: any) => {
	const history = useHistory();
	const searchParams = useQuery();

	useEffect(() => {
		if (!isUserLoggedIn) {
			// Get the current url pathname and add pathname as a redirect querystring
			searchParams.append('redirect', history.location.pathname);
			const search = searchParams.toString();
			history.push({ pathname: ROUTES.sso, search });
		}
	}, [isUserLoggedIn]);

	useEffect(() => {
		if (isUserLoggedIn && !hasAssignedHospital) {
			searchParams.append('redirect', history.location.pathname)
			const search = searchParams.toString();
			history.push({ pathname: ROUTES.hospitalSelection, search })
		}
	}, [hasAssignedHospital])

	useEffect(() => {
		window.scrollTo(0, 0);
	});

	if (removeNav) {
		return <Route
			{...rest}
			render={(props) => {
				return (
					<Layout>
						<Layout.Content>
							<Component {...props} />
						</Layout.Content>
					</Layout>
				);
			}}
		/>
	} else {
		return (
			<Route
				{...rest}
				render={(props) => {
					return (
						<Layout style={{ "height": 'fit-content' }}>
							<SideBar />
							<Layout>
								<TopBar />
								<Layout.Content className='content-wrapper'>
									<Component {...props} />
								</Layout.Content>
							</Layout>
						</Layout>
					);
				}}
			/>
		);
	}

};

export default ProtectedRoute;
