import { DownOutlined, UpOutlined} from '@ant-design/icons';
import { Button } from 'antd';
import { ReactElement, useState } from 'react';
import './CollapseContent.css';

interface ICollapseContentProps {
	Title: ReactElement | string;
	isExpandedDefault?: boolean;
	children: ReactElement;
}

const CollapseContent = ({
	isExpandedDefault,
	Title,
	children,
}: ICollapseContentProps) => {
	const [isExpanded, setIsExpanded] = useState(isExpandedDefault ?? false);

	return (
        <>
            <div
                className='collapse-content__wrapper'
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {isExpanded ? (
                    <UpOutlined onClick={() => setIsExpanded(!isExpanded)} />
                ) : (
                    <DownOutlined onClick={() => setIsExpanded(!isExpanded)} />
                )}

                <span className='collapse-content__title'>{Title}</span>
            </div>

            {isExpanded && (
                <div className='collapse-content--expanded'>{children}</div>
            )}
        </>
    );
};

export default CollapseContent;
