import { FileTextOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Radio, message } from 'antd';
import { Avatar } from 'components/lib';
import { Rule } from 'antd/lib/form';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { useDebounce } from 'hooks/useDebounce';
import useIsMounted from 'hooks/useIsMounted';
import _ from 'lodash';
import moment from 'moment';
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import { useAddPhysicalExamMutation, useCreateEditExamCacheMutation, useEditPhysicalExamMutation, useGetExamByIdQuery, useRemoveEditExamCacheMutation } from 'services/visitService';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { NoteRules, PhysicalExamRules } from 'utils/types/validations';
import { validateNoteDateTime } from 'utils/validationFuncs';
import { PHYSICAL_EXAM_VALUES } from '../../utils/constants';
import { Exam, ExamSectionLabel, NewExamSection, PHYSICAL_EXAM_STATUSES } from '../../utils/dataTypes';
import { FreeNote } from './FreeNote';
import { HiddenInput } from './fields/HiddenInput';

const { TextArea } = Input;

const normalStyle: CSSProperties = { backgroundColor: '#73c64c33' };
const abnormalStyle: CSSProperties = { backgroundColor: '#E1555533' };
const notObservedStyle: CSSProperties = { backgroundColor: '#DBDBDB' };

interface PhysicalExamProps {
	examId?: number;
	composeBoxId?: string;
	isInDrawer?: boolean;
	rows?: number;
}

interface PhysicalExamRef {
    getExamId: () => string | undefined;
	submitExam: () => void;
}

const PhysicalExam: React.ForwardRefRenderFunction<PhysicalExamRef, PhysicalExamProps> = ({composeBoxId, examId, isInDrawer, rows = 3 }, ref) => {
	const [exam, setExam] = useState<Exam | undefined>();
	const [autosavedAt, setAutosavedAt] = useState<moment.Moment>();
	const [justAutosaved, setJustAutosaved] = useState(false);

	const { boxState, removeComposeBox } = useComposeBoxContext();
	const getIsMounted = useIsMounted();

	const { urlVisitId } = useParams<{ urlVisitId: string }>();
	const visitId = parseInt(urlVisitId);

	const [form] = Form.useForm();

	const { data: loggedInUserData } = useGetUserDataQuery(null);
	const { data: editExam, isFetching: fetchingGetExamById } = useGetExamByIdQuery(examId ?? 1, { skip: !examId, refetchOnMountOrArgChange: true });
	const [addPhysicalExam, { isLoading: loadingAddPhysicalExam }] = useAddPhysicalExamMutation();
	const [editPhysicalExam, { isLoading: loadingEditPhysicalExam }] = useEditPhysicalExamMutation();
	const [createEditExamCache] = useCreateEditExamCacheMutation();
	const [removeEditExamCache] = useRemoveEditExamCacheMutation();

	useEffect(() => {
		if (examId) {
			createEditExamCache({ examId });
		}
	}, []);

	useEffect(() => {
		return () => {
			if (!getIsMounted() && exam) {
				removeEditExamCache({ examId: +exam.id });
			}
		}
	}, [exam, getIsMounted]);

	useEffect(() => {
		const handleClose = (event: any) => {
			event.preventDefault();
			
			if (exam) {
				removeEditExamCache({ examId: +exam.id });
			}

			return (event.returnValue = '');
		};

		window.addEventListener('beforeunload', handleClose);

		return () => {
			window.removeEventListener('beforeunload', handleClose);
		};
	}, [exam]);

	useEffect(() => {
		if (editExam) {
			setExam(editExam);
		}
	}, [editExam]);

	useEffect(() => {
		// initialize form data so that we send a null status when appropriate
		const initData: { [key: string]: any } = {};
		Object.keys(ExamSectionLabel).forEach((sectionKey) => {
			const initialValue = exam?.sections.find(sec => sec.section === sectionKey);

			if (initialValue) {
				initData[sectionKey] = initialValue.status;
			} else {
				initData[sectionKey] = PHYSICAL_EXAM_VALUES['NORMAL'];
			}
		});
		form.setFieldsValue(initData);
	}, [exam]);

	const physicalExamRules: PhysicalExamRules =  {
		note: [{
			type: 'string',
		}],
		exam_section_note: [{
			type: 'string',
		}],
		exam_section_severity: [{
			type: 'string',
		}]
	}

	const noteRules: NoteRules = {
		note_date_time: [{
			validator: validateNoteDateTime('note_date_time')
		}],
	}

	useImperativeHandle(ref, () => ({
        getExamId: () => {
            return exam?.id;
        },
        submitExam: () => form.submit(),
    }));

	const autoSaveFunction = useDebounce(() => {
		form.validateFields().then(() => {
			handleFinish(form.getFieldsValue(), true);
		});
	}, [exam], 3000);

	const handleFinish = (values: any, autosave?: boolean) => {
		const formattedExam = Object.keys(values)
			.filter(
				(exam_section) =>
					!exam_section.includes('comment') &&
					!exam_section.includes('note') &&
					!exam_section.includes('exam_id') &&
					!exam_section.includes('note_date_time') &&
					!exam_section.includes('created_at'),
			)
			.map((exam_section) => {
				return {
					section: exam_section,
					status: values[exam_section],
					note: values[exam_section + '_comment'],
				} as NewExamSection;
			});

		if (!!exam) {
			editPhysicalExam({
				id: values.exam_id ?? exam.id,
				visit_id: visitId,
				exam: formattedExam,
				note: values.note,
				note_date_time: values.note_date_time ? values.note_date_time.unix() : null,
			})
			.unwrap()
			.then(() => {
				if (autosave) {
					setAutosavedAt(moment());
					message.success('Exam successfully autosaved.');
					setJustAutosaved(true);
					_.delay(() => {
						setJustAutosaved(false);
					}, 1000);
				} else {
					removeCurrentComposeBox();
					message.success('Exam successfully saved.');
				}
			});
		} else {
			addPhysicalExam({
				visit_id: visitId,
				exam: formattedExam,
				note: values.note,
				note_date_time: values?.note_date_time?.unix(),
			})
			.unwrap()
			.then((exam: Exam) => {
				setExam(exam);
				
				if (autosave) {
					setAutosavedAt(moment());
					message.success('Exam successfully autosaved.');
				} else {
					removeCurrentComposeBox();
					message.success('Exam successfully saved.');
				}
			});
		}
	}

	const removeCurrentComposeBox = () => {
        if (composeBoxId) {
            const boxItem = boxState.find((box) => box.id === composeBoxId);

            if (!!boxItem) {
                removeComposeBox(boxItem.slot);
            }
        }
    };

	if (examId && (!editExam || fetchingGetExamById)) {
		return null;
	}

	return (
        <Form form={form} onFinish={handleFinish}>
            <HiddenInput fieldName={'exam_id'} initialValue={examId ?? exam?.id} />

            <Form.Item
                label='Comments'
                name='note'
                preserve={false}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                labelAlign='left'
                rules={physicalExamRules.note}
                initialValue={exam?.note ?? editExam?.note}
            >
                <TextArea
                    data-cy={'physicalExamComments'}
                    rows={3}
                    onChange={() => {
                        autoSaveFunction();
                    }}
                    autoFocus
                />
            </Form.Item>

            {Object.keys(ExamSectionLabel).map((sectionKey) => {
                const initialValue = editExam?.sections.find((sec) => sec.section === sectionKey);

                return (
                    <PhysicalExamItem
                        autoSaveFunction={autoSaveFunction}
                        selectRules={physicalExamRules.exam_section_severity}
                        noteRules={physicalExamRules.exam_section_note}
                        inputName={sectionKey}
                        key={`phys_exam_${sectionKey}`}
                        options={[PHYSICAL_EXAM_VALUES['NORMAL'], PHYSICAL_EXAM_VALUES['ABNORMAL'], PHYSICAL_EXAM_VALUES['NOT_OBSERVED']]}
                        initialStatusValue={initialValue?.status}
                        initialNoteValue={initialValue?.note}
                    />
                );
            })}

            <Form.Item
                preserve={false}
                name='note_date_time'
                label='Date/Time'
                rules={noteRules.note_date_time}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                labelAlign='left'
                style={{ width: '100%', marginTop: '16px' }}
                initialValue={editExam?.note_date_time ? moment.unix(editExam.note_date_time) : moment()}
            >
                <DatePicker
                    showTime={true}
                    format={'YYYY-MM-DD, hh:mm a'}
                    style={{ width: '100%' }}
                    onChange={() => {
                        autoSaveFunction();
                    }}
					getPopupContainer={(triggerNode) => {
						return triggerNode.parentNode as HTMLElement; 
					}}
                />
            </Form.Item>

            {editExam && (
                <div>
                    <span style={{ display: 'inline-block', width: '25%', marginTop: '10px' }}>Created</span>
                    <span>{editExam?.created_at && moment.unix(editExam.created_at).format('YYYY-MM-DD, hh:mm a')}</span>
                </div>
            )}

            <div className='note__submit-container'>
                {autosavedAt && (
                    <>
                        <Avatar
                            firstName={loggedInUserData?.first_name ?? ''}
                            lastName={loggedInUserData?.last_name ?? ''}
                            className={loggedInUserData?.role_name === 'Nurse' ? 'nurse-avatar' : undefined}
                        />
                        <span
                            style={{
                                color: justAutosaved ? 'var(--veg-secondary-blue)' : 'inherit',
                                fontWeight: justAutosaved ? 600 : 'inherit',
                            }}
                        >
                            Autosaved {moment(autosavedAt).format('YYYY-MM-DD, hh:mm a')}
                        </span>
                    </>
                )}
                {!isInDrawer && (
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldsError }) => (
                            <Button
                                disabled={
                                    getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                    loadingAddPhysicalExam ||
                                    loadingEditPhysicalExam
                                }
                                loading={loadingAddPhysicalExam || loadingEditPhysicalExam}
                                type='primary'
                                onClick={form.submit}
                            >
                                Submit
                            </Button>
                        )}
                    </Form.Item>
                )}
            </div>
        </Form>
    );
};

export default forwardRef(PhysicalExam);

interface PhysicalExamItemProps {
	autoSaveFunction: () => void;
	inputName: string;
	options: any[];
	selectRules: Rule[];
	noteRules: Rule[];
	initialStatusValue?: PHYSICAL_EXAM_STATUSES;
	initialNoteValue?: string;
}

const PhysicalExamItem = ({ autoSaveFunction, inputName, noteRules, options, selectRules, initialNoteValue, initialStatusValue }: PhysicalExamItemProps) => {
	const [displayNote, setDisplayNote] = useState(false);
	const [currentValue, setCurrentValue] = useState<PHYSICAL_EXAM_STATUSES>(
		initialStatusValue !== undefined
			? initialStatusValue
			: PHYSICAL_EXAM_VALUES['NORMAL'],
	);
	const onChange = (e: any) => {
		setCurrentValue(e.target.value);
		if (e.target.value === PHYSICAL_EXAM_VALUES['ABNORMAL']) {
			setDisplayNote(true);
		}
		autoSaveFunction();
	};

	const addNoteButtonStyle: CSSProperties = displayNote
		? { borderRadius: '0 4px 4px 0', backgroundColor: '#55a2e133' }
		: { borderRadius: '0 4px 4px 0' };

	const getRadioOptionStyle = (option: string) => {
		if (option === PHYSICAL_EXAM_VALUES['NOT_OBSERVED']) {
			return notObservedStyle;
		} else if (option === PHYSICAL_EXAM_VALUES['ABNORMAL']) {
			return abnormalStyle;
		} else {
			return normalStyle;
		}
	}

	useEffect(() => {
		if (!!initialNoteValue) {
			setDisplayNote(true);
		}

		if (initialStatusValue !== undefined) {
			setCurrentValue(initialStatusValue);
		}
	}, []);

	return (
		<>
			<Form.Item
				label={capitalizeFirstLetter(inputName)}
				name={inputName}
				preserve={false}
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 18 }}
				labelAlign='left'
				rules={selectRules}
				data-cy={'physicalExamRow'}
				style={{ whiteSpace: 'nowrap' }}
			>
				<Radio.Group name={inputName} value={currentValue} onChange={onChange}>
					{options.map((option: string) => (
						<Radio.Button
							key={inputName + '_' + option}
							value={option}
							style={
								currentValue === option ? getRadioOptionStyle(option) : {}
							}
							checked={currentValue === option}
							data-cy={'physicalExamRadioButton'}
						>
							{option ? capitalizeFirstLetter(option) : 'Not observed'}
						</Radio.Button>
					))}
					<Button
						style={addNoteButtonStyle}
						icon={<FileTextOutlined />}
						onClick={() => setDisplayNote(!displayNote)}
					/>
				</Radio.Group>
			</Form.Item>

			{displayNote && (
				<FreeNote
					autoSaveFunction={autoSaveFunction}
					key={inputName + '_comment'}
					name={inputName + '_comment'}
					noteRules={noteRules ?? []}
					initialNoteValue={initialNoteValue}
				/>
			)}
		</>
	);
};
