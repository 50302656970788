import DrawerRadioGroup from './DrawerRadioGroup';

interface DrawerBoolGroupProps {
    value?: string;
    onChange?: (value: string | boolean) => void;
}

export const DrawerBoolGroup: React.FC<DrawerBoolGroupProps> = ({ value, onChange }) => {
    return (
        <DrawerRadioGroup
            options={
                new Map([
                    ['Yes', true],
                    ['No', false],
                ])
            }
            value={value}
            onChange={onChange}
        />
    );
};

export default DrawerBoolGroup;
