import { Drawer, Button, Row, Col } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import AddNote from 'views/visit/Notes/AddNote';
import PhysicalExam from 'components/forms/PhysicalExam';
import { ElementRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { FormName } from 'utils/constants';
import _ from 'lodash';
import './NotesDrawer.css';
import { BaseNote, PatientHistory } from 'utils/dataTypes';
import PatientHistoryNote from 'views/visit/PatientHistoryNote';
import { drawerSize } from 'styles/drawer';
import useWindowDimensions from 'utils/window';

interface EditData {
    formType: string;
    noteData?: BaseNote;
    examId?: number;
    userId?: number;
    editPatientHistory?: PatientHistory;
}
export interface NotesDrawerRef {
    openNotesDrawer: (formType: string) => void;
    openEditNotesDrawer: (editData: EditData) => void;
}

interface NotesDrawerProps {
    isFinalizedVisit?: boolean;
}

const NotesDrawer: React.ForwardRefRenderFunction<NotesDrawerRef, NotesDrawerProps> = (props, ref) => {
    const [isNoteDrawerOpen, setIsNoteDrawerOpen] = useState<boolean>(false);
    const [editNoteData, setEditNoteData] = useState<BaseNote | null>(null);
    const [editPatientHistory, setEditPatientHistory] = useState<PatientHistory | undefined>(undefined);
    const [editExamId, setEditExamId] = useState<number | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    const addNoteRef = useRef<ElementRef<typeof AddNote>>(null);
    const physicalExamRef = useRef<ElementRef<typeof PhysicalExam>>(null);
    const patientHistoryNoteRef = useRef<ElementRef<typeof PatientHistoryNote>>(null);
    const { width } = useWindowDimensions();

    const [formType, setFormType] = useState<string>('');
    const { addComposeBox } = useComposeBoxContext();

    // we'll use this ref to keep track of the note to force a rerender
    const newNoteKeyRef = useRef<string>(`new-note-${new Date().getTime()}`);

    const openComposeBox = (title: string) => {
        setIsNoteDrawerOpen(false);
        const override = title === FormName.addendum_note;
        const composeBoxId = _.uniqueId();

        const noteId = addNoteRef.current?.getNoteId();
        const physicalExamId = physicalExamRef.current?.getExamId();

        let additionalState = {};
        if (editNoteData) {
            additionalState = { ...editNoteData, author_id: userId };
        }

        if (formType === FormName.physical_exam) {
            addComposeBox({
                id: composeBoxId,
                title: editExamId ? `Edit ${formType}` : formType,
                content: <PhysicalExam examId={editExamId ? editExamId : parseInt(physicalExamId!)} />,
            });
        } else if (formType === FormName['patient_history']) {
            addComposeBox({
                title: FormName['patient_history'],
                content: <PatientHistoryNote editPatientHistory={editPatientHistory} composeBoxId={composeBoxId} />,
                id: composeBoxId,
            });
        }
        else {
            addComposeBox(
                {
                    title: editNoteData ? `Edit ${formType}` : formType,
                    content: <AddNote formType={formType} noteId={editNoteData ? editNoteData.id : noteId} />,
                    id: composeBoxId,
                    additionalState,
                },
                props.isFinalizedVisit,
                override,
            );
        }
    };

    useImperativeHandle(ref, () => ({
        openNotesDrawer: (formType: string) => {
            setIsNoteDrawerOpen(true);
            setFormType(formType);
            newNoteKeyRef.current = `new-note-${new Date().getTime()}`;
            setEditNoteData(null);
            setUserId(null);
            setEditExamId(null);
        },
        openEditNotesDrawer: (editData: EditData) => {
            setFormType(editData.formType);
            if (editData.formType === FormName.physical_exam && editData.examId) {
                setEditExamId(editData.examId);
            } else if (editData.formType === FormName['patient_history']) {
                setEditPatientHistory(editData.editPatientHistory);
            } else if (editData.noteData) {
                setEditNoteData(editData.noteData);
                setUserId(editData.userId || null);
            }

            // this force a rerender by changing the key 
            if (editData.noteData) {
                newNoteKeyRef.current = `edit-note-${editData.noteData.id}`;
            } else {
                newNoteKeyRef.current = `new-note-${new Date().getTime()}`;
            }
    
            setIsNoteDrawerOpen(true);
        },
    }));

    return (
        <Drawer
            title={<NoteTitle minimizeNotesDrawer={openComposeBox} title={editNoteData ? `Edit ${formType}` : formType} />}
            style={{ zIndex: 99999 }}
            className='veg-drawer notes-drawer'
            placement='right'
            closable={true}
            width={width >= 600 ? drawerSize.lg : drawerSize.extended}
            onClose={() => {
                setIsNoteDrawerOpen(false);
            }}
            visible={isNoteDrawerOpen}
            key='notes_drawer'
            mask={false}
            maskClosable={true}
            destroyOnClose
            footer={
                <Button
                    type='primary'
                    onClick={() => {
                        if (formType === FormName.physical_exam) {
                            physicalExamRef.current?.submitExam();
                        } else if (formType === FormName['patient_history']) {
                            patientHistoryNoteRef.current?.submitPatientHistoryNote();
                        } else {
                            addNoteRef.current?.submitNote();
                        }
                        setIsNoteDrawerOpen(false);
                    }}
                >
                    Submit
                </Button>
            }
        >
            {formType === FormName.physical_exam ? (
                <PhysicalExam isInDrawer rows={6} ref={physicalExamRef} examId={editExamId ? editExamId : undefined} />
            ) : formType === FormName['patient_history'] ? (
                <PatientHistoryNote rowsNoteEditor={20} ref={patientHistoryNoteRef} editPatientHistory={editPatientHistory} isInDrawer/>
            ) : (
                <AddNote key={newNoteKeyRef.current} rowsNoteEditor={16} formType={formType} ref={addNoteRef} isInDrawer noteId={editNoteData ? editNoteData.id : undefined} />
            )}
        </Drawer>
    );
};

export default forwardRef(NotesDrawer);

interface NoteTitleProps {
    title?: string;
    minimizeNotesDrawer: Function;
}

export const NoteTitle = ({ title, minimizeNotesDrawer }: NoteTitleProps) => {
    return (
        <Row align='middle' justify='space-between' style={{ width: '100%' }}>
            <Col flex='auto'>{title}</Col>
            <Col>
                <Button
                    type='primary'
                    ghost
                    onClick={() => minimizeNotesDrawer()}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        marginRight: '4px',
                    }}
                >
                    <MinusOutlined style={{ verticalAlign: 'middle' }} />
                </Button>
            </Col>
        </Row>
    );
};
