import { Button, Collapse, Divider, Drawer, Form, FormInstance, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { DrawerBoolGroup } from 'components/drawers';
import { TriageLevelGroup } from 'components/popups/LevelPopover';
import { CSSProperties, useEffect, useState } from 'react';
import { useModifyVisitIntakeMutation } from 'services/vetDashService';
import { drawerSize } from 'styles/drawer';
import { TRIAGE_LEVELS, VetDashboardVisit } from 'utils/dataTypes';
import useWindowDimensions from 'utils/window';

const formItemStyle: CSSProperties = {
    width: '100%',
    marginBottom: '.8rem',
};

interface IntakeInfoDrawerProps {
    visit: VetDashboardVisit;
    form: FormInstance;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    triageLevel: TRIAGE_LEVELS;
    setTriageLevel: React.Dispatch<React.SetStateAction<TRIAGE_LEVELS>>;
}

export const IntakeInfoDrawer = ({ visit, form, open, setOpen, triageLevel, setTriageLevel }: IntakeInfoDrawerProps) => {
    const { width } = useWindowDimensions();
    const [modifyVisitIntakeMutation, { isLoading: isIntakeMutationLoading }] = useModifyVisitIntakeMutation();
    const formVal = form.getFieldValue;
    const isIntakeFormPristine = () => {
        return (
            visit.triage_level === triageLevel &&
            ((formVal('spike_3') === true && !!visit.spiked_by) || (formVal('spike_3') === false && !visit.spiked_by)) &&
            formVal('reason_for_visit') === visit.reason_for_visit
        );
    };

    const handleCloseDrawer = () => {
        setOpen(false);
    };

    const onFormSave = () => {
        modifyVisitIntakeMutation({ visit_id: visit.visit_id, intakeForm: { ...form.getFieldsValue(), triage_level: triageLevel } })
            .unwrap()
            .then(() => {
                setOpen(false);
                message.success('Successfully updated visit intake');
            })
            .catch((err: string) => message.error(err));
    };

    const [isDisabled, setIsDisabled] = useState(isIntakeFormPristine()); 

    useEffect(() => {
        setIsDisabled(isIntakeFormPristine());
    }, [triageLevel])

    return (
        <Drawer
            title={visit?.owner_last_name ? `${visit?.pet_name} (${visit?.owner_last_name})` : visit?.pet_name}
            className='veg-drawer'
            placement='right'
            closable={true}
            width={width >= 540 ? drawerSize.md : drawerSize.extended}
            onClose={handleCloseDrawer}
            visible={open}
            key='intake_info_drawer'
            destroyOnClose={true}
            mask={true}
            maskClosable={true}
            footer={
                <>
                    <Button onClick={handleCloseDrawer}>Cancel</Button>
                    <Button
                        htmlType='submit'
                        style={{ color: 'white', backgroundColor: 'var(--veg-secondary-teal)', borderWidth: 0 }}
                        disabled={isDisabled}
                        onClick={onFormSave}
                        loading={isIntakeMutationLoading}
                    >
                        Save
                    </Button>
                </>
            }
        >
            <Form form={form} layout='vertical' onFieldsChange={() => {setIsDisabled(isIntakeFormPristine());}}>
                <Collapse ghost bordered={false} className='veg-drawer-collapse' defaultActiveKey={['triage']}>
                    <Collapse.Panel
                        className='veg-drawer-panel'
                        style={{ marginTop: '-12px' }}
                        key='triage'
                        header={<Divider orientation='left'>Triage</Divider>}
                    >
                        <label>Reason:</label>
                        <Form.Item name='reason_for_visit' style={formItemStyle}>
                            <TextArea allowClear />
                        </Form.Item>

                        <label>Spike 3:</label>
                        <Form.Item name='spike_3' style={formItemStyle}>
                            <DrawerBoolGroup />
                        </Form.Item>

                        <label>Status:</label>
                        <TriageLevelGroup
                            triageLevel={triageLevel}
                            setTriageLevel={setTriageLevel}
                            gutter={8}
                            showTick={true}
                            style={{ paddingTop: '8px' }}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </Drawer>
    );
};
