import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, Divider, Form, FormInstance, Input, Row, Tooltip } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import { useGetAdditiveOptionsQuery } from 'services/instructionService';
import { BASE_QUERY_OPTIONS, MAX_FLUID_ADDITIVES } from 'utils/constants';
import { getFullAdditiveName } from 'utils/formatFuncs';

const formItemStyle: CSSProperties = {
    width: '100%',
};
const labelCol = { span: 6 };
const wrapperCol = { span: 18 };

interface AdditivesProps {
    initialOrder: boolean;
    form: FormInstance<any>;
    maxAdditives: number;
}

export const Additives = ({ initialOrder, form, maxAdditives }: AdditivesProps) => {
    const { data: additiveOptions } = useGetAdditiveOptionsQuery(null, BASE_QUERY_OPTIONS);
    const [filteredOptions, setFilteredOptions] = useState(additiveOptions);

    useEffect(() => {
        setFilteredOptions(additiveOptions);
    }, [additiveOptions]);

    return (
        <>
            {initialOrder && <Divider />}
            <Form.List name='fluid_additives' initialValue={initialOrder ? undefined : [{}]}>
                {(fields, { add, remove }) => (
                    <>
                        {initialOrder && fields.length > 0 && <h3 style={{ marginTop: 0 }}>Additives</h3>}
                        {fields.map((field) => (
                            <>
                                <Form.Item hidden name={[field.name, 'additive_id']}>
                                    <Input />
                                </Form.Item>
                                <Row style={{ width: '100%' }}>
                                    <Col span={22}>
                                        <Form.Item
                                            rules={[
                                                { required: true, message: 'Additive is required' },
                                                {
                                                    validator: (_, value) => {
                                                        if (additiveOptions?.find((item) => getFullAdditiveName(item) === value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject('Additive not found. Add as a new CRI order.');
                                                    },
                                                },
                                            ]}
                                            validateFirst
                                            preserve={false}
                                            name={[field.name, 'name']}
                                            label='Additive'
                                            style={{ width: '100%', paddingTop: 0 }}
                                            labelCol={labelCol}
                                            wrapperCol={wrapperCol}
                                        >
                                            <AutoComplete
                                                options={filteredOptions?.map((additive, index) => ({
                                                    key: index,
                                                    label: getFullAdditiveName(additive),
                                                    value: getFullAdditiveName(additive),
                                                }))}
                                                onSearch={(value) => {
                                                    setFilteredOptions(
                                                        additiveOptions?.filter((item) => {
                                                            return getFullAdditiveName(item)
                                                                .toLocaleLowerCase()
                                                                .includes(value.toLocaleLowerCase());
                                                        }),
                                                    );
                                                }}
                                                onSelect={(value) => {
                                                    const selectedItem = additiveOptions?.find(
                                                        (item) => getFullAdditiveName(item) === value,
                                                    );
                                                    if (selectedItem) {
                                                        const { fluid_additives } = form?.getFieldsValue();
                                                        Object.assign(fluid_additives[field.name], { additive_id: selectedItem.id });
                                                        form?.setFieldsValue({ fluid_additives });
                                                    }
                                                }}
                                                suffixIcon={<DeleteOutlined />}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ display: 'flex' }}>
                                        <Button
                                            type='ghost'
                                            icon={<DeleteOutlined />}
                                            style={{ marginLeft: 'auto', border: 0 }}
                                            disabled={!initialOrder ? fields.length === 1 : false}
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%' }}>
                                    <Col span={22}>
                                        <Form.Item
                                            rules={[{ required: true, message: 'Dose/Qty is required' }]}
                                            label='Dose/Qty'
                                            preserve={false}
                                            name={[field.name, 'dose_qty']}
                                            style={formItemStyle}
                                            labelCol={labelCol}
                                            wrapperCol={wrapperCol}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                            </>
                        ))}
                        <Tooltip
                            title={fields.length >= maxAdditives ? `A fluid cannot have more than ${MAX_FLUID_ADDITIVES} additives.` : null}
                        >
                            <Button
                                onClick={() => {
                                    add();
                                }}
                                disabled={fields.length >= maxAdditives}
                            >
                                <PlusOutlined /> Additive
                            </Button>
                        </Tooltip>
                    </>
                )}
            </Form.List>
        </>
    );
};
