import { Modal } from 'antd';
import dont_get_mad_at_donkey_kong from 'assets/img/dont_get_mad_at_donkey_kong.png';
import './ReconfirmModal.css';

interface ReconfirmModalProps {
    visible: boolean;
    handleOk: React.MouseEventHandler<HTMLElement>;
    handleCancel: React.MouseEventHandler<HTMLElement>;
    title: string;
}

export const ReconfirmModal: React.FC<ReconfirmModalProps> = ({ visible, handleOk, handleCancel, title, children }) => {
    return (
        <Modal
            visible={visible}
            closable={false}
            okText='Mark Deceased and Save'
            cancelText='Return to Patient Profile'
            okButtonProps={{ danger: true, autoFocus: true }}
            onOk={handleOk}
            onCancel={handleCancel}
            width={445}
            className='reconfirmModal'
        >
            <div className='ant-modal-confirm-body'>
                <img
                    alt={"Don't Get Mad at Donkey Kong"}
                    style={{margin: 'auto', display: 'block', marginBottom: '16px'}}
                    src={dont_get_mad_at_donkey_kong}
                    width={'268px'}
                    height={'128px'}
                />
                <span className='ant-modal-confirm-title' style={{fontSize:'18px', marginBottom: '16px'}}>{title}</span>
                <div className='ant-modal-confirm-content'>{children}</div>
            </div>
        </Modal>
    );
};
