import { EditOutlined } from '@ant-design/icons';
import { Col, Form, Row, Tabs, Typography } from 'antd';
import PatientInfoForm from 'components/forms/PatientInfo';
import { DeceasedTag } from 'components/tags/DeceasedTag';
import { useFormSubmission } from 'hooks/useFormSubmission';
import moment from 'moment';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import { Link, useParams } from 'react-router-dom';
import {
    useGetCustomerByPetIdQuery,
    useGetPatientAlertsByIdQuery,
    useGetPatientByIdQuery,
    useGetRdvmsByIdQuery,
    useGetVisitsByPatientIdQuery,
} from 'services/patientService';
import { useGetProblemListByVisitIdQuery } from 'services/visitService';
import { BASE_QUERY_OPTIONS, FormName } from 'utils/constants';
import { ExpandedPatient } from 'utils/dataTypes';
import { formatPhoneNumber, roundTo, shortPetId } from 'utils/formatFuncs';
import { getAgeYearsMonths } from 'utils/miscFuncs';
import { ComposeBoxContainer } from '../visit/ComposeBoxContainer';
import { PatientSignalment, SidebarSection } from '../visit/VisitSidebar';
import Documents from './Documents';
import VisitsAndNotes from './VisitsAndNotes';
import patientPageStyles from './patient-page.module.css';
import { ComposeBoxProvider, useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { ContactIcon } from 'assets/img/ContactIcon';
import { PatientAlertTag } from 'components/tags/PatientAlertTag';
import { ElementRef, useRef } from 'react';

interface PatientProps {}
const Patient = (props: PatientProps) => {
    let { pet_id } = useParams<{ pet_id: string }>();

    const { addComposeBox } = useComposeBoxContext();
    const [onFinish] = useFormSubmission();
    const [form] = Form.useForm();

    const { data: patientData } = useGetPatientByIdQuery(pet_id, { refetchOnMountOrArgChange: true });
    const { data: patientAlerts } = useGetPatientAlertsByIdQuery(pet_id, { refetchOnMountOrArgChange: true });

    const { data: allVisits } = useGetVisitsByPatientIdQuery(pet_id, BASE_QUERY_OPTIONS);

    const { data: patientOwners } = useGetCustomerByPetIdQuery(pet_id, BASE_QUERY_OPTIONS);

    const { data: rdvmPatients } = useGetRdvmsByIdQuery(pet_id, BASE_QUERY_OPTIONS);

    const { ageYears, ageMonths } = getAgeYearsMonths(patientData);

    const patientInfoRef = useRef<ElementRef<typeof PatientInfoForm>>(null);
    const cancelDeceasedReverted = () => {
		patientInfoRef.current?.cancelDeceasedReverted();
	};

    const sortedRdvmPatients = rdvmPatients
        ?.slice()
        .sort((a, b) => Number(a.is_active) - Number(b.is_active));

    const ownerSection = (
        <SidebarSection header='Owner(s)'>
            {patientOwners?.map((owner) => (
                <Link
                    to={`/customer/${owner.customer_id}`}
                    key={owner.customer_id}
                    style={{ color: '#000', display: 'flex', alignItems: 'center', marginTop: 'var(--spacing-sm)' }}
                >
                    <Col span={24} style={{ marginBottom: 'var(--spacing-md)' }}>
                        <Row>
                            <Col span={20}>
                                <span className='sidebar-owner-name'>{`${owner.first_name || ''} ${owner.last_name || ''}`}</span>
                            </Col>
                            <Col span={4}>
                                <ContactIcon
                                    style={{
                                        color: 'var(--gray-8)',
                                        width: '20px',
                                        height: '20px',
                                        float: 'right',
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <span className='sidebar-owner-phone'>{`${formatPhoneNumber(owner.phone_number ?? '') || ''}`}</span>
                        </Row>

                        {owner?.note && (
                            <Row>
                                <Col style={{ paddingTop: 'var(--spacing-sm)' }}>
                                    <Markdown
                                        remarkPlugins={[remarkBreaks]}
                                        children={owner?.note}
                                        components={{
                                            p: 'span',
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Link>
            ))}
        </SidebarSection>
    );

    return (
        <>
            <div className={patientPageStyles.pageWrapper}>
                <div className={patientPageStyles.patientSidebar}>
                    <SidebarSection header=''>
                        <Row align='middle' justify='space-between'>
                            <Col
                                span={24}
                                className={`${patientPageStyles.sidebarPetName} ${patientPageStyles.editSidebarIcon}`}
                                onClick={() => {
                                    addComposeBox({
                                        formName: FormName['patient_info'],
                                        content: (
                                            <PatientInfoForm
                                                patientInfo={{
                                                    ...patientData,
                                                    pet_id,
                                                    ageYears: ageYears,
                                                    ageMonths: ageMonths,
                                                    is_deceased: !!patientData?.deceased_at,
                                                    deceased_at: patientData?.deceased_at
                                                        ? moment.unix(patientData?.deceased_at)
                                                        : undefined,
                                                }}
                                                patientAlerts={patientAlerts ?? []}
                                                form={form}
                                                ref={patientInfoRef}
                                                cancelDeceasedReverted={cancelDeceasedReverted}
                                            />
                                        ),
                                        id: FormName['patient_info'],
                                    });
                                }}
                                data-cy='patientName'
                            >
                                {patientData?.name}
                                <EditOutlined
                                    style={{
                                        fontSize: '16px',
                                        marginLeft: '6px',
                                    }}
                                />
                            </Col>
                        </Row>

                        {!!patientData?.deceased_at && (
                            <Col span={24}>
                                <DeceasedTag />
                            </Col>
                        )}

                        <PatientSignalment patientData={patientData as ExpandedPatient} />
                        <Row>
                            <Col>
                                <Markdown remarkPlugins={[remarkBreaks]} children={patientData?.note as string} />
                            </Col>
                        </Row>
                        {!!patientAlerts?.length &&
                            <Row>
                                <Col>
                                    {patientAlerts.map((alert) => {
                                        return <div><PatientAlertTag alert={alert} direction='vertical' /></div>
                                    })}
                                </Col>
                            </Row>
                        }
                    </SidebarSection>
                    <SidebarSection header='Patient ID'>
                        <Row>
                            <Col>
                                <span>{shortPetId(patientData?.pet_id)}</span>
                            </Col>
                        </Row>
                    </SidebarSection>
                    {patientData?.latest_weight && (
                        <SidebarSection header='Weight'>
                            <Row>
                                <Col>
                                    <span>{roundTo(patientData.latest_weight, 3)} kg</span>
                                </Col>
                            </Row>
                        </SidebarSection>
                    )}

                    {ownerSection}

                    <SidebarSection header='rDVM(s)'>
                        <Row>
                            {sortedRdvmPatients?.map((rDVM) => (
                                <Col span={24} key={`rDVM_${rDVM.clinic_id}`}>
                                    {`${rDVM.override_display_name || rDVM.display_name}: ${formatPhoneNumber(rDVM?.phone_number || '')}`}
                                    {!rDVM.is_active && (
                                        <Typography.Paragraph style={{ color: 'var(--veg-red)' }}>(Deactivated)</Typography.Paragraph>
                                    )}
                                </Col>
                            ))}
                        </Row>
                    </SidebarSection>
                    <SidebarSection header='Chronic problem(s)'>
                        <Row style={{ display: 'block' }}>
                            {allVisits && <ChronicProblems visit_id={allVisits[allVisits.length - 1].id} />}
                        </Row>
                    </SidebarSection>
                </div>
                <div className={patientPageStyles.patientMainContent}>
                    <Tabs
                        className={patientPageStyles.patientTabs}
                        defaultActiveKey='visits'
                        size='large'
                        tabBarStyle={{
                            borderBottom: '1px solid #D9D9D9',
                            margin: '0 0 8px',
                        }}
                    >
                        <Tabs.TabPane tab='Visits and Notes' key='visits'>
                            <VisitsAndNotes />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab='Documents' key='documents'>
                            <Documents />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
            <ComposeBoxContainer
                onFinish={(values: any, formName: FormName) => {
                    onFinish(values, formName);
                    return Promise.resolve();
                }}
            />
        </>
    );
};

interface ChronicProblemsProps {
    visit_id: number;
}

const ChronicProblems = ({ visit_id }: ChronicProblemsProps) => {
    const { data: problemListData } = useGetProblemListByVisitIdQuery(visit_id, BASE_QUERY_OPTIONS);
    const chronicProblems = problemListData?.problemList.filter((item) => item.chronic === true);

    return (
        <>
            {chronicProblems?.map((item, index) => (
                <div key={index}>{item.common_name}</div>
            ))}
        </>
    );
};

const PatientWithProvider = () => (
    <ComposeBoxProvider>
        <Patient />
    </ComposeBoxProvider>
);

export default PatientWithProvider;
