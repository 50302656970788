import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ExistingCriInstruction,
    ExistingDiagInstruction,
    ExistingFluidInstruction,
    ExistingMedInstruction,
    ExistingTaskInstruction,
    FluidAdditive,
    TreatmentSheetInstructionAction,
} from 'utils/dataTypes';

interface TreatmentSheetState {
    isBonusActionModalVisible: boolean;
    activeActionModal?: string;
    bonusActionModalData?:
        | ExistingDiagInstruction
        | ExistingMedInstruction
        | ExistingFluidInstruction
        | ExistingCriInstruction;
    activeTreatmentSheetAsk?: TreatmentSheetInstructionAction | null;
    instructionToReschedule?: ExistingDiagInstruction | ExistingTaskInstruction | ExistingMedInstruction | null;
    activeTreatmentSheetGroup?: string;
    activeActionItem?: TreatmentSheetInstructionAction | null;
    isFluidAdditiveModalVisible: boolean;
    fluidAdditiveData?: FluidAdditive[];
}

const initialState: TreatmentSheetState = {
    isBonusActionModalVisible: false,
    isFluidAdditiveModalVisible: false,
};

export const treatmentSheetSlice = createSlice({
    name: 'treatmentSheet',
    initialState,
    reducers: {
        setBonusActionData: (
            state,
            action: PayloadAction<ExistingDiagInstruction | ExistingMedInstruction | ExistingFluidInstruction | ExistingCriInstruction>,
        ) => {
            state.bonusActionModalData = action.payload;
        },
        setIsBonusActionModalVisible: (state, action: PayloadAction<boolean>) => {
            state.isBonusActionModalVisible = action.payload;
        },
        setActiveTreatmentSheetAsk: (state, action) => {
            state.activeTreatmentSheetAsk = action.payload;
        },
        setActiveActionItem: (state, action) => {
            state.activeActionItem = action.payload;
        },
        setActiveTreatmentSheetGroup: (state, action) => {
            state.activeTreatmentSheetGroup = action.payload;
        },
        setActiveActionModal: (state, action) => {
            state.activeActionModal = action.payload;
        },
        setInstructionToReschedule: (state, action) => {
            state.instructionToReschedule = action.payload;
        },
        setFluidAdditiveData: (
            state,
            action: PayloadAction<FluidAdditive[] | undefined>,
        ) => {
            state.fluidAdditiveData = action.payload;
        },
        setIsFluidAdditiveModalVisible: (state, action: PayloadAction<boolean>) => {
            state.isFluidAdditiveModalVisible = action.payload;
        },
    },
});

export const {
    setBonusActionData,
    setIsBonusActionModalVisible,
    setActiveTreatmentSheetAsk,
    setActiveActionItem,
    setActiveTreatmentSheetGroup,
    setActiveActionModal,
    setInstructionToReschedule,
    setFluidAdditiveData,
    setIsFluidAdditiveModalVisible,
} =
    treatmentSheetSlice.actions;
