export enum ROUTES {
	dashboard = '/',
	outpatientTS = '/treatment_sheet/outpatients',
	inpatientTS = '/treatment_sheet/inpatients',
	sso = '/login',
	hospitalSelection = '/hospital-selection',
	login = '/private/login',
	inbox = '/inbox',
	admission = '/admission',
	patient = '/patient/:pet_id',
	visit = '/visit/:urlVisitId',
	mobile = '/mobile',
	consentForms = '/print/:urlFormId',
	approvedConsentForms = '/print/:urlFormId/approved',
	earnings = '/earnings',
	checkout = '/checkout/:urlFormId',
	allvisits = '/allvisits',
	contacts = '/contacts',
	customer = '/customer/:customerId',
	controlled_drug_report = '/report/controlled_drugs',
	doctor_production_report = '/report/doctor_production',
	payments_report = '/report/payments',
	accounts_receivable_report = '/report/accounts_receivable',
	whiteboard = '/whiteboard',
	vaccination_form = '/vaccination_form/:formId',
}

export enum Keys {
	PERIOD = '.',
	SPACE = ' ',
	ENTER = 'Enter',
	BACKSPACE = 'Backspace',
	ARROW_RIGHT = 'ArrowRight',
	ARROW_LEFT = 'ArrowLeft',
	ARROW_DOWN = 'ArrowDown',
	ARROW_UP = 'ArrowUp',
	META = 'Meta',
	BACKSLASH = '\\'
}

export enum NoteCategories {
	PROGRESS_NOTE = 'Progress/Soap Note',
	COMMUNICATION_NOTE = 'Communication Note',
	PROCEDURE_NOTE = 'Procedure Note',
	RECOMMENDATION_NOTE = 'Recommendation Note',
	POSTOP_NOTE = 'Post-op Note',
	DISCHARGE_NOTE = 'Discharge Note',
	ADDENDUM_NOTE = 'Addendum Note',
	PATIENT_HISTORY_NOTE= 'Patient History Note',
}

export enum TagType {
	Instruction = 'Instruction',
    InstructionId = 'InstructionId',
	ActionId = 'ActionId',
	Problem = 'Problem',
	ProgNote = 'ProgNote',
	DischargeNote = 'DischargeNote',
	Exam = 'Exam',
	Visit = 'Visit',
	PatientHistory = 'PatientHistory',
	DischargeMed = 'DischargeMed',
	Encounter = 'Encounter',
	EncounterNurse = 'EncounterNurse',
	MainTs = 'MainTs',
	Estimate = 'Estimate',
	InboundCall = 'InboundCall',
	Billing = 'Billing',
	Discount = 'Discount',
	User = 'User',
	ConsentForm = 'ConsentForm',
	WebCheckout = 'WebCheckout',
	Ledger = 'Ledger',
	NoteFile = 'NoteFile',
	Species = 'Species',
	Breed = 'Breed',
	AttributionSummary = 'AttributionSummary',
	PatientFile = 'PatientFile',
	StripePaymentError = 'StripePaymentError',
	Macro = 'Macro',
    VaccinationForm = 'VaccinationForm',
}
